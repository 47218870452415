import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Collaborator } from '../../models/collaborators/collaborator';
import { UserInfo } from '../../models/user/user-info';
import { CollaboratorService } from '../../services/collaborators/collaborator.service';
import { ProjectService } from '../../services/projects/project.service';
import { UserInfoService } from '../../services/user/user-info.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import * as platformConstants from '../../constant';
import { AnalyticsService } from '../../services/analytics/analytics.service';

const ELEMENT_DATA: Collaborator[] = [];

@Component({
    selector: 'niq-duplicate-project',
    templateUrl: './duplicate-project.component.html',
    styleUrls: ['./duplicate-project.component.scss'],
})
export class DuplicateProjectComponent implements OnInit, AfterContentChecked {
    /**
   * Placeholder text for WorkbenchId field.
   *
   * @type     {string}
   * @memberof DuplicateProjectComponent
   */
    public workbenchIdPlaceHolder: string = this.translate.instant(
        'project.create_project.workbench_id_place_holder'
    );

    /**
   * Placeholder text for project name field.
   *
   * @type     {string}
   * @memberof DuplicateProjectComponent
   */
    public projectNamePlaceHolder: string = this.translate.instant(
        'project.create_project.project_name_place_holder'
    );

    public labelName: string = this.translate.instant('project.form_label.name');
    public labelWorkbench: string = this.translate.instant(
        'project.form_label.workbenchId'
    );
    public selectedCollaboratorCount = 0;
    public includeAllChecked = false;

    public expandCollaborator = true;
    public solutionName = '';
    public solutionCode = '';
    public allComplete: boolean = false;
    public isPrjNameValid: boolean = true;
    public isAllDuplicationOptsUnselect: boolean = false;
    public prjNameErrorMessage: string = '';
    public linkHref: string = '';
    public spinner: boolean = false;
    public userInfo: UserInfo;
    public showSpinnerOnDuplicate: boolean = false;
    public displayedColumns: string[] = [
        'select',
        'displayName',
        'projectRole',
        'organization',
    ];
    public dataSource = new MatTableDataSource<Collaborator>(ELEMENT_DATA);
    public selection = new SelectionModel<Collaborator>(true, []);

    public categoryList = [
        { value: 'includeCollaboratorsChecked', isSelected: false },
        { value: 'includeConceptsSkuListChecked', isSelected: true },
        { value: 'includeAudienceChecked', isSelected: true },
        { value: 'includeAnalysisChecked', isSelected: true },
        { value: 'includeDocumentsChecked', isSelected: true },
    ];
    constructor(
    public dialogRef: MatDialogRef<DuplicateProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private _projService: ProjectService,
    private _collaboratorService: CollaboratorService,
    private _cdref: ChangeDetectorRef,
    private _userInfoService: UserInfoService,
    private _analyticsService: AnalyticsService
    ) {}

    public ngOnInit(): void {
        if (!this.data.duplicateProject) {
            return;
        }
        this.solutionName = `Copy of ${this.data.duplicateProject.name}`;
        this.solutionCode = this.data.duplicateProject.solutionCode;
        this.linkHref = this.data.duplicateProject.collaboratorsLink
            ? this.data.duplicateProject.collaboratorsLink
            : '';
    }

    public ngAfterContentChecked(): void {
        this._cdref.detectChanges();
    }

    public setDuplicateData(objValue: string, objKey: string): void {
        this.data.duplicateProject[objKey] = objValue;
    }

    public createDuplicateProject(): void {
        this.data.duplicateProject.status =
      this.data.duplicateProject.status.toUpperCase();
        this.data.duplicateProject.canDuplicateConceptsSkuList =
      this.categoryList[1].isSelected;
        this.data.duplicateProject.canDuplicateAudience =
      this.categoryList[2].isSelected;

      if(this.solutionCode != "NOx"){
        this.data.duplicateProject.canDuplicateAnalysis = this.categoryList[3].isSelected;
      }

        this.data.duplicateProject.canDuplicateDocuments =
      this.categoryList[4].isSelected;

        this.data.duplicateProject.collaborators = [];
        if (this.categoryList[0].isSelected && this.dataSource.data.length > 0) {
            this.data.duplicateProject.collaborators = this.selection.selected;
        }
        this.showSpinnerOnDuplicate = true;
        this._projService.save(this.data.duplicateProject, true).subscribe(
            (data) => {
                if (data && data.id) {
                    this.dialogRef.close(data);
                    this.showSpinnerOnDuplicate = false;
                }
            },
            (error) => {
                this.showSpinnerOnDuplicate = false;
                const err = JSON.parse(JSON.stringify(error.error));
                if (err.error.status === 500) {
                    this.prjNameErrorMessage = 'There is some error please try again.';
                    return;
                }
                this.prjNameErrorMessage = err.error.details[0];
            }
        );
    }

    public getCollaborators(): void {
        if (
            this.categoryList[0].isSelected &&
      this.dataSource.data.length === 0 &&
      this.linkHref
        ) {
            this.spinner = true;
            this._userInfoService.get().subscribe((data: any) => {
                this.userInfo = data;
            });
            this._collaboratorService
                .get(this.linkHref)
                .subscribe((data: Array<Collaborator>) => {
                    if (data) {
                        this.spinner = false;

                        data.map((ele) => {
                            ele.selected = true;
                            ele.displayName =
                ele.firstName || ele.lastName
                    ? `${ele.firstName} ${ele.lastName}`
                    : ele.userName;
                            ele.isDisableDefault =
                ele.projectRole === platformConstants.PROJECT_OWNER &&
                ele.userId === this.userInfo.userId
                    ? true
                    : false;
                            this.selection.select(ele);
                        });

                        this.dataSource.data = data;
                        this.selectedCollaboratorCount = this.selection.selected.length;
                    }
                });
        }
        if (this.categoryList[0].isSelected) {
            this._analyticsService.track('Include Colloborators Check', {});
        } else {
            this._analyticsService.track('Include colloborators Uncheck', {});
        }
    }

    public selectAllOptionDuplicate(): void {
        for (var i = 0; i < this.categoryList.length; i++) {
            this.categoryList[i].isSelected = this.includeAllChecked;
        }
        this.getCollaborators();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllOptionsSelected(): boolean {
        var count = 0;
        for (var i = 0; i < this.categoryList.length; i++) {
            if (this.categoryList[i].isSelected) {
                count++;
            }
        }
        this.isAllDuplicationOptsUnselect = count == 0;
        return count === this.categoryList.length;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle(): void {
        if (this.isAllSelected()) {
            this._analyticsService.track('Colloborators Unselect', {});
            this.selection.clear();
            this.selectedCollaboratorCount = this.selection.selected.length;
            return;
        }
        this._analyticsService.track('Colloborators Select', {});
        this.selection.select(...this.dataSource.data);
        this.selectedCollaboratorCount = this.selection.selected.length;
    }

    public partialComplete(): boolean {
        var count = 0;
        for (var i = 0; i < this.categoryList.length; i++) {
            if (this.categoryList[i].isSelected) {
                count++;
            }
        }
        if (count === 0) {
            return false;
        }
        return count !== this.categoryList.length;
    }

    isConcept(): any {
        return !['LPO','DD'].includes(this.data.duplicateProject.solutionCode);
    }
}
