import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';

@Injectable({
    providedIn: 'root',
})
export class CardviewService {
    private _url: string;

    constructor(private _http: HttpClient, private _cs: AppConfigService) {
        this._url = this._cs?.config?.projectservice.url;
    }

    public saveView(cardView: boolean, cardViewId: string): Observable<any> {
        return this._http.put<any>(
            `${this._cs?.config?.projectservice.url}/api/v1/cardView/${cardViewId}`,
            { "isBigCardView": cardView }
        );
    }

    public getSavedView(): Observable<any> {
        return this._http.get<any>(
            `${this._cs?.config?.projectservice.url}/api/v1/cardView`
        );
    }
}
