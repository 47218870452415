// project colors constants
const COLOR_GREY_500 = '#999999';
const COLOR_COOL_GREY_900 = '#151921';
const COLOR_GREY_50 = '#f9f9f9';

export const MODAL_WIDTH = '47vw';
export const BENEFIT_MODAL_WIDTH = '30vw';

// fielding spinner color constants
const COLOR_COOL_GREY_FIELDING_TOTAL = '#00568B';
const COLOR_COOL_GREY_FIELDING_LESS = '#9EB2C3';
const COLOR_BLUE_400 = '#00AEEF';
const COLOR_ORANGE_FIELDING_MORE = '#E98438';

const COLOR_GREY_500_INCIDENCE = '#999999';
const COLOR_GREY__600_COMPLETE = '#707276';

export const PROJECT_DATE_FORMAT = 'ddMMMyyyy';
export const PROJECT_DEFAULT_LOCALE = 'en_US';
export const PROJECT_STATUS = 'INPUTS';
export const PROJECT_DEFUALT_BG_COLOR = 'COLOR_GREY_50';

export const DEFAULT_PRIMARY_LANG = 'English';
export const DEFAULT_PRIMARY_LANG_COUNTRY = 'English (United States)';
export const PROJECT_DEFAULT_LANG_CODE = 'en';
export const ENGLISH_US_LOCALE = 'en_US';
export const PROJECTS_API_LIMIT = 15;
export const PROJECTS_API_OFFEST = 0;
export const SNACK_BAR_TYPE_SUCCESS = 'success';
export const SNACK_BAR_TYPE_ERROR = 'error';
export const SNACK_BAR_TYPE_WARN = 'warn';
export const SNACK_BAR_TYPE_INFO = 'info';

export const FIELDING_STATUS_COMPLETED = 'completed';
export const FIELDING_STATUS_PUBLISHED = 'published';

export const CONCEPT_ARCHIVE = 'CONCEPT_ARCHIVE';

export const PERMISSION_READ_LPO_SIMULATOR = 'READ_LPO_SIMULATOR';
export const PERMISSION_VIEW_ALL_PROJECTS = 'VIEW_ALL_PROJECTS';
export const PERMISSION_READ_ANALYTICS = 'READ_ANALYTICS';
export const PERMISSION_READ_FIELDING = 'READ_FIELDING';
export const PERMISSION_VIEW_ALL_CLIENTS = 'VIEW_ALL_CLIENTS';
export const PERMISSION_VIEW_WORKBENCH_INFO = 'VIEW_WORKBENCH_INFO';
export const PERMISSION_VIEW_PROJECT_ANALYSIS_STATUS =
  'VIEW_PROJECT_ANALYSIS_STATUS';
export const PERMISSION_NAV_STD_ADMINISTRATION =
  'NAVIGATE_TO_STUDIO_PROJECT_ADMINISTRATION';
export const DEFAULT_IMAGE_NAME = 'place-holder.png';

export const PROJECT_OWNER = 'OWNER';
export const SOLUTION_CODE_LPO = 'LPO';

export const NIELSENIQ = 'NielsenIQ';
export const UNITED_STATES = 'United States';
export const INNOVATION_SCREENER = 'INNOVATION_SCREENER';
export const STUDIO_DESIGN_PRODUCT = 'STUDIO_DESIGN_PRODUCT';
export const REQUEST_ACCESS = 'RequestAccess';
export const SOLUTIONS = [
    {
        code: 'QQ',
        icon: 'sif-help',
        color: COLOR_COOL_GREY_900,
        bgColor: COLOR_GREY_50,
    },
    {
        code: 'QS',
        icon: 'sif-clock-1',
        color: COLOR_COOL_GREY_900,
        bgColor: COLOR_GREY_50,
    },
    {
        code: 'QP',
        icon: 'sif-quick-predict',
        color: COLOR_COOL_GREY_900,
        bgColor: COLOR_GREY_50,
    },
    {
        code: 'QU',
        icon: 'sif-decision',
        color: COLOR_COOL_GREY_900,
        bgColor: COLOR_GREY_50,
    },
    {
        code: 'RS',
        icon: 'sif-iterate',
        color: COLOR_COOL_GREY_900,
        bgColor: COLOR_GREY_50,
    },
    {
        code: 'LPO',
        icon: 'sif-sales-increase',
        color: COLOR_COOL_GREY_900,
        bgColor: COLOR_GREY_50,
    },
    {
        code: 'PA',
        icon: 'sif-comment-filled-nielsen',
        color: COLOR_COOL_GREY_900,
        bgColor: COLOR_GREY_50,
    },
    {
        code: 'O',
        icon: 'sif-light-bulb',
        color: COLOR_COOL_GREY_900,
        bgColor: COLOR_GREY_50,
    },
    {
        code: 'Ox',
        icon: 'sif-above-average-hb',
        color: COLOR_COOL_GREY_900,
        bgColor: COLOR_GREY_50,
    },
    
];

export const DEFAULT_STATE = {
    value: 0,
    projectIcon: 'sif-inputs',
    color: COLOR_GREY_500,
};

export const PROJECT_STATUSES = {
    Inputs: {
        value: 33,
        icon: 'sif-inputs',
    },
    Locked: {
        value: 66,
        icon: 'sif-lock',
    },
    Analysis: {
        value: 100,
        icon: 'sif-results',
    },
    Results_Ready: {
        value: 100,
        icon: 'sif-results',
    },
};

export const PROJECT_STATUS_BG_COLOR = {
    color: COLOR_COOL_GREY_900,
    bgColor: COLOR_GREY_50,
};

export const FIELDING_SPINNER_COLOR = {
    published: {
        total: COLOR_COOL_GREY_FIELDING_TOTAL,
        less: COLOR_COOL_GREY_FIELDING_LESS,
        more: COLOR_ORANGE_FIELDING_MORE,
        incidence: COLOR_BLUE_400,
    },
    completed: {
        total: COLOR_GREY__600_COMPLETE,
        less: COLOR_GREY__600_COMPLETE,
        more: COLOR_ORANGE_FIELDING_MORE,
        incidence: COLOR_GREY_500_INCIDENCE,
    },
};

// constant filter values
export const FILTER_PROJECTS = [
    {
        name: 'All Projects',
        selected: true,
        allComplete: true,
        subtasks: [
            { name: 'My Projects', selected: true },
            { name: 'Shared Projects', selected: true },
        ],
    },
];

export const FILTER_SOLUTION_TYPES = [
    {
        name: 'All Types',
        selected: true,
        allComplete: true,
        subtasks: [
            { name: 'Quick Question', selected: true },
            { name: 'Quick Screen', selected: true },
            { name: 'Quick Predict', selected: true },
            { name: 'Quick Use', selected: true },
            { name: 'Restage', selected: true },
            { name: 'Quick Advisor', selected: true },
            { name: 'Line & Price Optimiser', selected: true },
            { name: 'Other', selected: true },
        ],
    },
];

export const FILTER_STATUSES = [
    {
        name: 'All Statuses',
        selected: true,
        allComplete: true,
        subtasks: [
            { name: 'Inputs', selected: true },
            { name: 'In Field', selected: true },
            { name: 'Results Ready', selected: true },
            { name: 'Results Delivered', selected: true },
        ],
    },
];

export const FILTER_SORT = [
    { value: 'Last Updated', display: 'Last Updated', selected: true },
    { value: 'Newest First', display: 'Newest First', selected: false },
];

export const FILTER_NAME_LIST = {
    projects: 'Project Associations',
    types: 'Products',
    statuses: 'Statuses',
    countries: 'Countries',
    name: 'Default View',
};

export const EMPTY_STATES = {
    request_access_search: {
        icon: 'no-results',
        lead_text: 'NO PROJECTS MATCH YOUR CRITERIA',
        support_text: 'Search looks for Project numbers, names or concept names. Use commas to search for multiples.',
    },
    request_access_empty: {
        icon: 'search',
        lead_text: 'ENTER SEARCH CRITERIA',
        support_text: "Search for one or multiple Projects you need access to first. Once found, click 'Request' to gain access.",
    },
    pending_request_empty: {
        icon: 'empty',
        lead_text: 'NO PROJECTS HAVE BEEN REQUESTED',
        support_text: 'Requested projects waiting to be approved by an existing Collaborator reside here until approved.',

    },
    approval_request_empty: {
        icon: 'empty',
        lead_text: 'NO PROJECTS ACCESS REQUIRES YOUR APPROVAL',
        support_text: 'There are no requests to access Projects you collaborate on. Hang tight until someone requests access.'
    },

    filter_search: {
        icon: 'search',
        lead_text: 'No Projects Found',
        support_text:
      'No projects matching the criteria you have requested can be found. Please refine your search and filter criteria.',
    },
    no_projects: {
        lead_text: 'No Projects Available',
        support_text: 'You have not created any projects yet to show.',
    },
    fieldings: {
        icon: 'audience',
        lead_text: 'No Fieldings Available',
        support_text: 'No fieldings have been created for this project yet.',
    },
    sku_lists: {
        icon: 'season',
        lead_text: 'No SKU List Available',
        support_text: 'No SKU list has been created for this project yet.',
    },
    concepts: {
        icon: 'concept',
        lead_text: 'No Concepts Available',
        support_text: 'No concepts have been created for this project yet.',
    },
    design_options_lists: {
        icon: 'season',
        lead_text: 'No Design Options Available',
        support_text: 'No Design Options list has been created for this project yet.',
    },
};

export const ERROR_STATES = {
    fieldings: {
        icon: 'warning-2',
        lead_text: 'ERROR LOADING FIELDING DATA',
        support_text:
      'There was an error loading fielding data for this project. Please contact administrator if the issue persists.',
    },
};

export const FILTER_DEFAULTS = {
    clientsFilter: [],
    countriesFilter: [],
    solutionsFilter: [],
    statusesFilter: [],
    lookupsFilter: [
        {
            identifier: 'ALL',
            isUserPreference: true,
            name: 'All Projects',
        },
    ],
    sortByFilter: [],
    names: [],
};
