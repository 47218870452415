import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { SearchModel } from '../../models/filter/filter-model';

@Injectable({
    providedIn: 'root',
})
export class SearchFiltersService {
    private _url: string;
    constructor(private _http: HttpClient, private _cs: AppConfigService) {
        const resourceUri = '/api/v1/projectSearch';
        this._url = `${this._cs?.config?.projectservice.url}${resourceUri}`;
    }

    public get(
        searchObj: SearchModel,
        limit: number,
        offset: number
    ): Observable<SearchModel> {
        return this._http.post<SearchModel>(
            `${this._url}?limit=${limit}&offset=${offset}`,
            searchObj
        );
    }
}
