import { Action, createReducer, on } from '@ngrx/store';
import { addImageBlob } from '../actions/image.actions';

const imageBlobReducer = createReducer(
    null,
    on(addImageBlob, (state, { imageBlob }) => {
        const newImageBlob = Object.assign({}, state, imageBlob);
        return newImageBlob;
    })
);

export function reducer(state: any | undefined, action: Action): any {
    return imageBlobReducer(state, action);
}
