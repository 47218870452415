import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    OnChanges,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { FILTER_NAME_LIST } from '../../constant';
import { CheckboxArray } from '../../models/checkbox-array/checkbox-array';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AnalyticsService } from '../../services/analytics/analytics.service';
@Component({
    selector: 'niq-drop-down',
    templateUrl: './drop-down.component.html',
    styleUrls: ['./drop-down.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DropDownComponent implements OnInit, OnChanges {
    @Input() public displayType: string = 'regular';
    @Input() public displayOptions: Array<any> = [];
    @Input() public arrayName: string = '';
    @Input() public childKey: string = 'subtasks';

    // display and rendering purpose
    @Input() public flagKey: string = 'selected'; // both parent and child should have the same key name for selected flag
    @Input() public displayKey: string = '';
    @Input() public displayChildKey: string = '';
    @Input() public addRadioIndent: boolean = false;
    @Input() displayInput: string;
        // Define other input properties as needed


    // emitting the updated values
    @Output() public userSelections = new EventEmitter();
    @Output() public nullSelections = new EventEmitter();
    @Output() public ddMenuClosed = new EventEmitter();
    @Output() public isProductFilterDirty: EventEmitter<any> = new EventEmitter();
    @Output() public isCountryFilterDirty: EventEmitter<any> = new EventEmitter();
    @Output() public isProductCheckboxFilterDirty: EventEmitter<any> = new EventEmitter();
    @Output() public isCountryCheckboxFilterDirty: EventEmitter<any> = new EventEmitter();
    @Output() public isProductFilterNotEmpty: EventEmitter<any> = new EventEmitter();
    @Output() public isCountryFilterNotEmpty: EventEmitter<any> = new EventEmitter();
    @Output() public isSubmitButtonEnabled: EventEmitter<any> = new EventEmitter();
    public RequestAccess: string = "REQUEST_ACCESS";
    public selectedOption: string = '';
    public toggle = false;
    public copyOriginal: Array<any> = [];
    public typeAheadText: string = '';
    public originalSize: number = 0;
    public parentCheckBoxTobeDisabled = false;  // flag to identify if country filter populated using search box and disable parent list item (All Countries)
    @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;
    @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;
    public scrollPosition: number;

    constructor(private _analyticsService: AnalyticsService,) { }

    public ngOnInit(): void {
        this.originalSize = this.displayOptions && this.displayOptions[0] ? this.displayOptions[0].length : 0;
        this.copyOriginal = JSON.parse(JSON.stringify(this.displayOptions));

        this.setDropdown();
    }

    /**
     * Method to set dropdown selections
     * 
     * @method updateAllComplete
     * @returns {void}
     */
    public setDropdown(): void {
        if (this.displayType === 'checkbox') {
            this.setSelectedValue(this.displayOptions);
        } else {
            if (this.displayOptions.length === 0) {
                return;
            }
            if (this.displayInput !== "RequestAccess"){
                this.selectedOption =
                this.displayOptions[
                    this.displayOptions.findIndex((ele) => ele[this.flagKey])][this.displayKey];
            }
            if (this.displayInput === "RequestAccess"){
                this.selectedOption = "Select Client"
            }
            this.userSelections.emit(this.displayOptions);
        }
    }

    public ngOnChanges(): void {
        this.setDropdown();
    }

    /**
     * Method to mark all parent node based on child node selections.
     *
     * @method updateAllComplete
     * @param parentObj
     * @type  {any}
     * @returns {void}
     */
    public updateAllComplete(parentObj: CheckboxArray): void {
        if (!this.parentCheckBoxTobeDisabled) {
                if(this.displayInput === "RequestAccess"){
                    this.isCountryCheckboxFilterDirty.emit(true);
                    this.isProductCheckboxFilterDirty.emit(true);
                }
            parentObj.selectedOptionsCount = parentObj[this.childKey].filter(
                (t: any) => t[this.flagKey]
            ).length;
            parentObj.allComplete =
                parentObj[this.displayKey] !== null &&
                parentObj[this.childKey].every((t: any) => t[this.flagKey]);
                this.setSelectedValue(this.displayOptions);
        }
        else {
            this.setSelectedValue(this.copyOriginal);
        }
        this.filterData(this.typeAheadText);
        var input = document.getElementsByClassName("search-input ng-valid ng-dirty ng-touched")[0] as HTMLInputElement;
        if(this.arrayName==="countries" && this.displayInput === "RequestAccess"){
            this._analyticsService.track('uf-ProjAcc-ReqAccT-CountryFltr-Chckbx-clicked', {
                reqProjectAccCountrySearchText: input?.value,
                selectedCountryValue: this.displayOptions[0].tooltipText,
            });
        }
        if(this.arrayName==="types" && this.displayInput === "RequestAccess"){
            this._analyticsService.track('uf-ProjAcc-ReqAccT-ProductFltr-Chckbx-clicked', {
                selectedProductValue: this.displayOptions[0].tooltipText,
            });
        }
    }

    /**
     *  Method to know whether or not all the child node of a parent node is selected.
     *
     * @method partialComplete
     * @param parentObj
     * @type  {any}
     * @returns {boolean}
     *
     */
    public partialComplete(parentObj: any): boolean {
        if (!parentObj[this.childKey] || parentObj[this.childKey] === null) {
            return false;
        }
        return (
            parentObj[this.childKey].filter((t: any) => t[this.flagKey]).length > 0 &&
            !parentObj.allComplete
        );
    }

    /**
     * Method to mark all child node of a parent node.
     *
     * @method partialComplete
     * @param {selected, parentObj}
     * @type  {boolean, any}
     * @returns {void}
     *
     */
    public setAll(selected: boolean, parentObj: any): void {
        parentObj.allComplete = selected;
        if (!parentObj[this.childKey] || parentObj[this.childKey] === null) {
            return;
        }
        parentObj[this.childKey].forEach((t: any) => (t[this.flagKey] = selected));
        parentObj.selectedOptionsCount = selected
            ? parentObj[this.childKey].length
            : 0;

        this.setSelectedValue(this.displayOptions);
    }

    /**
     * Set Value for regular dropdown
     * @param arrList
     * @param option
     */
    // for regular
    public setValue(arrList: Array<any>, option: any): void {
        arrList.forEach((ele) => {
            if (ele[this.displayKey] === option[this.displayKey]) {
                ele[this.flagKey] = true;
                this.selectedOption = ele[this.displayKey];
            } else {
                ele[this.flagKey] = false;
            }
        });
        this.userSelections.emit(arrList);
        var input = document.getElementsByClassName("search-input ng-valid ng-dirty ng-touched")[0] as HTMLInputElement;
        if(this.displayInput === "RequestAccess"){
            this._analyticsService.track('uf-ProjAcc-ReqAccT-ClientFltr-Chckbx-clicked', {
                reqProjectAccClientSearchText: input?.value,
                selectedClientValue: this.selectedOption,
        });
        }
    }

    /**
     * Set Value for dropdown with checkboxs
     * @param data
     */
    // for checkbox
    public setSelectedValue(data: any): void {
        this.selectedOption = '';
        const subArray: Array<any> = data[0][this.childKey];
        data[0].tooltipText = '';
        data[0].selectedOptionsCount = 0;
        subArray.forEach((element) => {
            if (element[this.flagKey]) {
                data[0].tooltipText = data[0].tooltipText
                    ? `${data[0].tooltipText}, ${element[this.displayChildKey]}`
                    : element[this.displayChildKey];
                data[0].selectedOptionsCount++;
                data[0].singleSelectedOptionObject =
                    data[0].selectedOptionsCount === 1 ? element : '';
            }
        });

        if (this.displayInput === "RequestAccess" && data[0].selectedOptionsCount === 0){
            data[0].tooltipText = this.selectedOption = `Select ${FILTER_NAME_LIST[this.arrayName]}`;
            data[0].selectedOptionsCount = data[0][this.childKey].length;
        }
        else if ( !this.parentCheckBoxTobeDisabled && data[0].selectedOptionsCount === data[0].subtasks.length && this.displayInput !== "RequestAccess"){
            data[0].tooltipText = this.selectedOption = `All ${FILTER_NAME_LIST[this.arrayName]}`;
            data[0].selectedOptionsCount = data[0][this.childKey].length;
        }
        else {
            data[0].allComplete = false;
            if (data[0].selectedOptionsCount > 0) {
                this.selectedOption =
                    data[0].selectedOptionsCount > 1
                        ? `${data[0].selectedOptionsCount} ${FILTER_NAME_LIST[this.arrayName]
                        }`
                        : data[0].singleSelectedOptionObject[this.displayChildKey];
            }
        }

        if (data[0].selectedOptionsCount !== 0) {
            this.userSelections.emit(data);
            this.nullSelections.emit(false);
            if (data[0].tooltipText == "Select Products" && `${FILTER_NAME_LIST[this.arrayName]}` == "Products"){
                this.isProductFilterDirty.emit(false);
                this.isProductFilterNotEmpty.emit(false);
                this.isProductCheckboxFilterDirty.emit(false);
                this.isSubmitButtonEnabled.emit(false);
            }
            if(data[0].tooltipText == "Select Countries" && `${FILTER_NAME_LIST[this.arrayName]}` == "Countries"){
                this.isCountryFilterDirty.emit(false);
                this.isCountryFilterNotEmpty.emit(false);
                this.isCountryCheckboxFilterDirty.emit(false);
                this.isSubmitButtonEnabled.emit(false);
            }
            if (data[0].tooltipText !== "Select Products" && `${FILTER_NAME_LIST[this.arrayName]}` == "Products"){
                this.isProductFilterNotEmpty.emit(true);
                this.isSubmitButtonEnabled.emit(false);
            }
            if(data[0].tooltipText !== "Select Countries" && `${FILTER_NAME_LIST[this.arrayName]}` == "Countries"){
                this.isCountryFilterNotEmpty.emit(true);
                this.isSubmitButtonEnabled.emit(false);
            }
        }
        else if ((data[0]?.selectedOptionsCount == 0) && this.copyOriginal[0]?.selectedOptionsCount > 0 && this.parentCheckBoxTobeDisabled) {
            this.userSelections.emit(data);
            this.nullSelections.emit(false);
            if (data[0].tooltipText == "Select Products" && `${FILTER_NAME_LIST[this.arrayName]}` == "Products"){
                this.isProductFilterDirty.emit(false);
                this.isProductFilterNotEmpty.emit(false);
                this.isProductCheckboxFilterDirty.emit(false);
                this.isSubmitButtonEnabled.emit(false);
            }
            if(data[0].tooltipText == "Select Countries" && `${FILTER_NAME_LIST[this.arrayName]}` == "Countries"){
                this.isCountryFilterDirty.emit(false);
                this.isCountryFilterNotEmpty.emit(false);
                this.isCountryCheckboxFilterDirty.emit(false);
                this.isSubmitButtonEnabled.emit(false);
            }
            if (data[0].tooltipText !== "Select Products" && `${FILTER_NAME_LIST[this.arrayName]}` == "Products"){
                this.isProductFilterNotEmpty.emit(true);
                this.isSubmitButtonEnabled.emit(false);
            }
            if(data[0].tooltipText !== "Select Countries" && `${FILTER_NAME_LIST[this.arrayName]}` == "Countries"){
                this.isCountryFilterNotEmpty.emit(true);
                this.isSubmitButtonEnabled.emit(false);
            }
        }
        else {
            this.nullSelections.emit(true);
        }
    }

    /**
     * Emit Menu Closed
     * @param flag - true if dropdown was close by user/ false if dropdown was open by the user
     */
    public emitMenuClosed(flag: boolean): void {
        if (
            flag &&
            this.displayOptions &&
            this.displayOptions[0]?.selectedOptionsCount === 0 &&
            this.displayType === 'checkbox' &&
            !this.parentCheckBoxTobeDisabled
        ) {
            if(this.displayInput === "RequestAccess"){
                this.setAll(false, this.displayOptions[0]);
            }
            else{
                this.setAll(true, this.displayOptions[0]);
            }
        }
        this.filterData(this.typeAheadText);
        if(flag){
            this.virtualScroll?.scrollTo({ top: 1 });
        }
        this.ddMenuClosed.emit(flag);

        //clean the search text bar when the dropdown is open and restore all the possible options
        if(!flag){
          this.virtualScroll?.scrollTo({ top: this.scrollPosition });
          this.deleteSearchText();
          this.typeAheadText = '';
          this.filterData(this.typeAheadText);

          if(this.displayInput !== "RequestAccess" && this.copyOriginal[0]){
            if(this.copyOriginal[0].selectedOptionsCount != null && (this.copyOriginal[0].selectedOptionsCount == 0 || this.copyOriginal[0].selectedOptionsCount == this.originalSize)){
                this.arrayName === 'countries' ? this.selectedOption = 'All Countries' : this.selectedOption = 'All Clients'
                this.setAll(true, this.displayOptions[0]);
              }
          } 
        }

        //set select Option to All Countries if the user search for some country but did not select any option (all the option are available to be selected) and close the dropdown
        if(this.displayInput !== "RequestAccess" && this.copyOriginal[0]){
        if(flag && this.copyOriginal[0].selectedOptionsCount != null && (this.copyOriginal[0].selectedOptionsCount == 0 || this.copyOriginal[0].selectedOptionsCount == this.originalSize)){
          this.arrayName === 'countries' ? this.selectedOption = 'All Countries' : this.selectedOption = 'All Clients'
        }
        }
      }


    /**
     * Filter the dropdown list with the text introduced by the user on the search text box
     * @param inpVal - search text used to filter the original dropdown list
     * @returns
     */
    // typeahead search
    public filterData(inpVal: string): void {

        for (const ele of this.copyOriginal) {
            if (!ele.subtasks) {
                break;
            }

            const displayObj = this.displayOptions.find(
                (obj) => obj.name === ele.name
            );
            ele.allComplete = displayObj.allComplete;
            if (displayObj && displayObj.subtasks) {
                displayObj.subtasks.forEach((subEle) => {
                    const originalSubEle = ele.subtasks.find(
                        (obj) => obj.name === subEle.name
                    );
                    if (originalSubEle) {
                        originalSubEle[this.flagKey] = subEle[this.flagKey];
                    }
                });
            }
        };



        // to avoid performance problems on the load of the dropdown list
        if (this.originalSize != this.displayOptions.length) {
            this.displayOptions = this.copyOriginal;
            if (this.displayType !== 'regular') {
                this.setSelectedValue(this.displayOptions);
            }
        }

        if (!inpVal.length) {
            this.parentCheckBoxTobeDisabled = false;
            return;
        }

        //to be improve to use event action
        else{
          var button = document.getElementsByName("search-delete-icon")[0] as HTMLInputElement;
          button.hidden = false
        }


        // UF-2016 start
        if (this.displayType === 'checkbox') {
            if (this.arrayName === 'countries') {
                if (inpVal.length && inpVal.length > 0) {
                    this.parentCheckBoxTobeDisabled = true;
                    if (this.selectedOption === 'All Countries') {
                        this.setAll(false, this.displayOptions[0]);
                    }
                }
                else {
                    this.parentCheckBoxTobeDisabled = false;
                }
            }
        } else {
            this.parentCheckBoxTobeDisabled = false;
        }
        // UF-2016 end
        if (this.displayType === 'regular') {
            this.displayOptions = this.displayOptions.filter((ele) =>
                ele[this.displayKey].toLowerCase().includes(inpVal.toLocaleLowerCase())
            );
        }  else if (this.parentCheckBoxTobeDisabled){
            this.displayOptions = this.displayOptions.map((element) => {
                return {
                    ...element,
                    [this.childKey]: element[this.childKey].filter((subElement) =>
                        subElement[this.displayChildKey]
                            .toLowerCase()
                            .includes(inpVal.toLocaleLowerCase())
                    ),
                };
            });
        } else {
            this.displayOptions = this.displayOptions.map((element) => {
                return {
                    ...element,
                    [this.childKey]: element[this.childKey].filter((subElement) =>
                        subElement[this.displayChildKey]
                            .toLowerCase()
                            .includes(inpVal.toLocaleLowerCase())
                    ),
                };
            });
        }
    }

    @HostListener('document:keydown', ['$event'])
    public closeDDOptions(kbEvnt: KeyboardEvent): void {
        if (kbEvnt.key === 'Escape') {
            this.trigger.closeMenu();
        }
    }

    /**
     * Delete the text present on the search text box inside of the dropdown (to be improve to use event action)
     */
    public deleteSearchText(){
      var input = document.getElementsByClassName("search-input ng-valid ng-dirty ng-touched")[0] as HTMLInputElement;
      var button = document.getElementsByName("search-delete-icon")[0] as HTMLInputElement;

      if(input != null){
        input.value = '';
      }

      if(button != null){
        button.hidden = true
      }
    }
}
