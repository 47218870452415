import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FILTER_DEFAULTS,
  FILTER_NAME_LIST,
  NIELSENIQ,
  PERMISSION_VIEW_ALL_CLIENTS,
  REQUEST_ACCESS,
  SNACK_BAR_TYPE_ERROR,
  SNACK_BAR_TYPE_SUCCESS,
} from '../constant';
import { CheckboxArray } from '../models/checkbox-array/checkbox-array';
import {
  DropDownModel,
  FilterModel,
  FilterOps,
  RequestAccessSearchModel,
} from '../models/request-access-filter/request-access-filter-model';
import { MessageBarService } from '../services/mesasgeBar/message-bar.service';
import * as _ from 'lodash';
import { UserInfoService } from '../services/user/user-info.service';
import { UserInfo } from '../models/user/user-info';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { PermissionService } from '../services/permissions/permission.service';
import { RequestProjectService } from '../services/request-projects/request-project.service';
import { MatDrawer } from '@angular/material/sidenav';
export class DisplayFilters {
  public name: string;
  public toolTipStr: string;
  public data: any;
}
@Component({
  selector: 'niq-request-access-search-filters',
  templateUrl: './request-access-search-filters.component.html',
  styleUrls: ['./request-access-search-filters.component.scss']
})
export class RequestAccessSearchFiltersComponent implements OnInit {

  @Output() public projectList = new EventEmitter();
  @Input() public lazyLoad: any;
  @Input() public fieldClick: any;
  @ViewChild('drawer') drawer: MatDrawer;
  @Output() searchButtonClicked = new EventEmitter<void>();
  @Input() searchSubmitted: boolean;
  @Input() searchQuery: string = '';
  @Input() selectedProjectCountForRequest = 0;
  @Input() isFullFailureAlertVisible: boolean;
  @Input() selectAllCheckbox: boolean;
  @Input() allDataLoaded: boolean;
  @Input() spinner: boolean;
  @Input() gridVal:any[] = [];
  @Input() errorstatus: string = '';
  @Input() message: string = '';
  @Input() showMargin: boolean;
  @Input() isFooterAbsolute: boolean;
  
  @Input() searchApplied: boolean;
  @Input() gridData = [];
  @Output() searchResetChange = new EventEmitter<boolean>();
  @Output() searchQueryChange = new EventEmitter<any>();
  @Output() clearEvent = new EventEmitter<any>();
  @Output() dataEvent = new EventEmitter<any>();
  @Output() responseEvent = new EventEmitter<any>();
  @Output() public searchText = new EventEmitter();
  receivedResponse: any;
  public filterToggle = false;
  public projectCount: number;
  public savedFilterCount: number;
  public openFilter: boolean = false;
  public optionsHover: boolean = false;
  public filterNameError: string = '';
  public isProjectsNull: boolean = false;
  public isSolutionsNull: boolean = false;
  public isCountriesNull: boolean = false;
  public menuClosed: boolean = true;
  public filterDirty: boolean = false;
  public isDesc: boolean = false;
  public filterClient: DisplayFilters = new DisplayFilters();
  public filterProjects: DisplayFilters = new DisplayFilters();
  public filterSolutionTypes: DisplayFilters = new DisplayFilters();
  public filterCountries: DisplayFilters = new DisplayFilters();
  public selectedOption: string = '';
  public filterhoverstate: boolean = false;
  public RequestAccess: string = REQUEST_ACCESS;
  public RequestAccessClient: string = REQUEST_ACCESS;
  public clients: Array<DropDownModel> = [];
  public solutionTypes: Array<CheckboxArray> = [];
  public countries: Array<CheckboxArray> = [];
  public searchPhase: string = '';

  public clientsCopy: Array<DropDownModel> = [];
  public sortByCopy: Array<DropDownModel> = [];
  public lookupsFilterCopy: Array<DropDownModel> = [];
  public solutionTypesCopy: Array<CheckboxArray> = [];
  public statusesCopy: Array<CheckboxArray> = [];
  public countriesCopy: Array<CheckboxArray> = [];

  public selectedFilters: FilterModel;
  public searchModel: RequestAccessSearchModel = new RequestAccessSearchModel();
  public limit = 35;
  public offset = 0;
  public previousSelectedFilter: FilterModel;
  public userInfo: UserInfo;
  public isNotInitialSelection = false;
  public clientViewPermissions = true;
  public sortByApplied = false;
  public selectedSavedFilter = '';
  public newSavedFilterName = '';
  public searchTooltipText = "Enter key words, numbers, and email addresses. Separate by comma for multi-search";
  
  public userFilterAction: string = '';
  public searchProjects: boolean;
  public isFooterAbsoluteApproval: boolean;
  public tabGroup: any;
  public showMarginApproval: boolean;
  public searchAllowed: boolean;
  public productfilterNotEmpty: boolean;
  public countryfilterNotEmpty: boolean;
  public clientfilterNotEmpty: boolean;
  public countryFilterDirty: boolean;
  public productFilterDirty: boolean;
  public previousSelectedClientFilter: string;
  public clientFilterDirty: boolean;
  public newQuery: boolean;
  public countryPrefernceCount: number;
  public productPrefernceCount: number;
  public selectedDropdown: string;

  constructor(
      private _requestProjectService: RequestProjectService,
      private _toaster: MessageBarService,
      private _userInfoService: UserInfoService,
      private _analyticsService: AnalyticsService,
      private _permissions: PermissionService
  ) {
  }

  ngOnInit(): void {
    this.spinner = true;
    this.searchAllowed = false;
    this._userInfoService.get().subscribe((data: any) => {
      this.userInfo = data;
  });
  this._permissions.get().subscribe((data) => {
      this.clientViewPermissions = data.permissions.includes(
          PERMISSION_VIEW_ALL_CLIENTS
      );
  });
  if(this.spinner){
    this.getFilteredDatas(this.searchModel);
  }
 }
  
  public ngOnChanges(): void {
    if (this.lazyLoad && this.limit !== 0) {
        this.lazyLoad = null;
        this.sortByApplied = true;
        this.limit = 35;
        this.offset = this.offset + this.limit;
        this.searchModel.searchPhrase = this.searchPhase = this.searchQuery;
    } else if (this.fieldClick) {
        this.sortByApplied = true;
        this.offset = 0;
        this.limit = 35;
        this.searchModel.searchPhrase = this.searchPhase = this.searchQuery;
    }
  }
  
  public setSearchText(evnt: string): void {
    if (evnt !== this.searchModel.searchPhrase) {
        this.offset = 0;
        this.limit = 35;
        this.searchModel.searchPhrase = evnt;
        this.searchModel.filters = this.getFilterModel();
    }
  }

  public onNewFilterChange(selectedFilter: DropDownModel): void {
    this.searchModel.filters = new FilterModel();
    this.searchModel.filters.names = [];
    this.searchModel.filters.names.push(selectedFilter);

    this.newSavedFilterName = selectedFilter.name;
    this.offset = 0;
    this.limit = 35;
    this.sortByApplied = false;
}
 
  public getFilteredDatas(searchObj: RequestAccessSearchModel, type?: string): void {
    this._requestProjectService.getProjects(searchObj, this.limit, this.offset).subscribe(
        (data) => {
            if(searchObj.filters==null || searchObj.filters.clientsFilter==null || searchObj.filters.clientsFilter.length==0){
              this.RequestAccessClient = REQUEST_ACCESS
            }else{
              this.RequestAccessClient = ""
            }
            this.projectList.emit(data.data);
            this.selectedFilters = data.filters;
            this.searchPhase = data.searchPhrase
                      ? data.searchPhrase
                      : this.searchModel.searchPhrase;
            this.countryPrefernceCount = this.selectedFilters.countriesFilter.filter(country => country.isUserPreference === true).length;
            this.productPrefernceCount = this.selectedFilters.solutionsFilter.filter(product => product.isUserPreference === true).length;
            if (!this.sortByApplied) {
                this.setDropDownValues(data.filters);
            }
  
            if (!data.data || data.data.length === 0) {
                this.limit = 0;
            }
            if (type === 'applied') {
               if(this.newQuery){
                  this._toaster.openSnackBar(
                    SNACK_BAR_TYPE_SUCCESS,
                    `Search run successfully`
              );
               }
               else {
                this._toaster.openSnackBar(
                  SNACK_BAR_TYPE_SUCCESS,
                  `Filters ${type} successfully`
            );
               }
            }
            this.newQuery = false;
            if (type === 'reset') {
              this._toaster.openSnackBar(
                SNACK_BAR_TYPE_SUCCESS,
                `Search and filters reset successfully`
              );
            }
  
            if (!this.sortByApplied) {
                this.previousSelectedFilter = this.getFilterModel();
            }
            if (typeof data === 'object' && this.searchAllowed) {
              // Convert the object to an array
              this.gridVal = Object.values(data);
              this.dataEvent.emit(this.gridVal);
              this.responseEvent.emit(searchObj);
              const newData = this.gridVal[1];
              this.gridData = [...this.gridData, ...newData];
              newData.forEach((project) => {
                project.conceptData = [];
                project.isConceptDataLoaded = false;
              });
              if (newData.length < this.limit) {
                this.allDataLoaded = true;
              }
            }
            this.spinner = false;
        },
        () => {
            this._toaster.openSnackBar(
                SNACK_BAR_TYPE_ERROR,
                'Internal Error. Try again later.'
            );
            this.gridData = [];
            this.spinner = false;
            this.errorstatus = 'error';
            this.message = "Something went wrong. Please try again.";
            this.isFullFailureAlertVisible = true;
            this.spinner = false;
        },
        
    );
  }
  

  public getOrganizationId(clientsFilter: DropDownModel[]): string {
    if (clientsFilter.length > 1) {
        if (this.userInfo.isInternalUser) {
            return clientsFilter.find((client) => client.name === NIELSENIQ)
                .identifier;
        }
    }
    return clientsFilter[0].identifier;
  }
  
  public setDropDownValues(filters: FilterModel): void {
    this.clients = filters.clientsFilter;
  
    this.searchQuery = this.searchPhase = this.searchModel.searchPhrase;
    this.countries = this.mapDropDownObj(
        filters.countriesFilter,
        'Select Countries'
    );
  
    const fiteredSolutions = filters.solutionsFilter.filter((sol) => {
        if(sol.identifier === 'IS') {
          return this.userInfo?.featureFlags.includes('INNOVATION_SCREENER');
        }
        else if (sol.identifier === 'DD') {
          return this.userInfo?.featureFlags.includes('STUDIO_DESIGN_PRODUCT');
        }
        else {
          return true;
        }
    });
    this.solutionTypes = this.mapDropDownObj(fiteredSolutions, 'Select Product');
  
    // copy of dropdowns
    this.clientsCopy = JSON.parse(JSON.stringify(this.clients));
    this.countriesCopy = JSON.parse(JSON.stringify(this.countries));
    this.solutionTypesCopy = JSON.parse(JSON.stringify(this.solutionTypes));
  
    this.setInitialFilterValues();
  }
  
  public mapDropDownObj(obj: DropDownModel[], mainStr: string): any[] {
    const multiSelectOptions: any = {};
    multiSelectOptions.tooltipText = '';
    multiSelectOptions.name = mainStr;
    multiSelectOptions.selectedOptionsCount = obj.filter(
        (ele) => ele.isUserPreference
    ).length;
    multiSelectOptions.allComplete = multiSelectOptions.isUserPreference =
        multiSelectOptions.selectedOptionsCount === obj.length ? true : false;
    multiSelectOptions.singleSelectedOptionObject =
        multiSelectOptions.selectedOptionsCount === 1
            ? obj.find((ele) => ele.isUserPreference)
            : '';
    multiSelectOptions.subtasks = obj;
  
    if (!multiSelectOptions.allComplete) {
        obj.forEach((ele) => {
            if (ele.isUserPreference) {
                multiSelectOptions.tooltipText = multiSelectOptions.tooltipText
                    ? multiSelectOptions.tooltipText + ', ' + ele.name
                    : ele.name;
            }
        });
    }
  
    return [multiSelectOptions];
  }

  
  // to set the selected filter option in the drop down
  public setInitialFilterValues(): void {
    this.isNotInitialSelection = false;
    this.setSelectedFilterOption(this.clients, 'clients');
    this.setSelectedFilterOption(this.solutionTypes, 'types');
    this.setSelectedFilterOption(this.countries, 'countries');
  }
  
  public setFilterObjects(obj: any, filterOption: any): DisplayFilters {
    const mapObject: DisplayFilters = new DisplayFilters();
    if (obj[0].allComplete) {
        mapObject.name =
            mapObject.toolTipStr = `All ${FILTER_NAME_LIST[filterOption]}`;
    } else {
        mapObject.name =
            obj[0].selectedOptionsCount > 1
                ? `${obj[0].selectedOptionsCount} ${FILTER_NAME_LIST[filterOption]}`
                : obj[0].singleSelectedOptionObject.name;
        mapObject.toolTipStr = obj[0].tooltipText;
    }
    mapObject.data = obj[0];
  
    return mapObject;
  }
  
  public setSelectedFilterOption(event: any, filterOption: any): void {
    if (filterOption === 'clients') {
        this.filterClient.name = this.filterClient.toolTipStr =
        event[event.findIndex((ele) => ele.isUserPreference)]?.name;
        (typeof this.filterClient.name === 'undefined') ? this.clientFilterDirty = false : this.clientFilterDirty = true;
        if(typeof this.filterClient.name === 'undefined'){
          this.clientfilterNotEmpty = false
        }
        else if(this.filterClient.name !== this.previousSelectedClientFilter){
          this.clientfilterNotEmpty = true
        }
        this.selectedDropdown = ""
        this.filterClient.data = event;
        this.previousSelectedClientFilter = this.filterClient.name;
    } else if (filterOption === 'types') {
        this.filterSolutionTypes = this.setFilterObjects(event, filterOption);
    } else if (filterOption === 'countries') {
        this.filterCountries = this.setFilterObjects(event, filterOption);
    }
  
    if (this.openFilter && filterOption === 'lookupsFilter') {
        this.isNotInitialSelection = true;
    }
    if (this.openFilter) {
        this.isNotInitialSelection = true;
    } else if (filterOption === 'lookupsFilter' && this.openFilter) {
        this.isNotInitialSelection = true;
    }
  }
  
  public getFilterModel(): FilterModel {
    const filterData: FilterModel = new FilterModel();
    filterData.clientsFilter = this.filterClient.data.filter(
        (ele) => ele.isUserPreference
    );
    filterData.clientsFilter =
        filterData.clientsFilter[0]?.name !== 'All Clients'
            ? filterData.clientsFilter
            : [];
  
    filterData.countriesFilter = this.filterCountries.data.allComplete
        ? []
        : this.filterCountries.data.subtasks.filter(
            (ele) => ele.isUserPreference
        );
  
    filterData.solutionsFilter = this.filterSolutionTypes.data.allComplete
        ? []
        : this.filterSolutionTypes.data.subtasks.filter(
            (ele) => ele.isUserPreference
        );
    return filterData;
  }

  public resetFilter(value: boolean): void {
    this.spinner = true;
    this.offset = 0;
    this.limit = 35;
    this.searchModel.searchPhrase = this.searchQuery = "";
    this.searchModel.filters = new FilterModel();
    this.searchAllowed = false;
    this.searchSubmitted = false;
    if(value){
      this.getFilteredDatas(this.searchModel, 'reset');
    }
    else{
      this.getFilteredDatas(this.searchModel);
      this.drawer.toggle();
      this.openFilter = false;
    }
    this.searchResetChange.emit(false);
}
  
  public editFilters(): void {
    this.userFilterAction = FilterOps.edit;
  }
  
  public cancelFilter(): void {
    this.clients = this.clientsCopy;
    this.countries = this.countriesCopy;
    this.solutionTypes = this.solutionTypesCopy;
    this.setInitialFilterValues();
  }

  onSearchButtonClick() {
    this.offset = 0;
    this.limit = 35;
    this.spinner = true;
    this.showMargin = false;
    this.searchSubmitted = true;
    this.searchAllowed = true;
    this.productfilterNotEmpty = this.countryfilterNotEmpty = this.clientfilterNotEmpty = false;
    this.selectedFilters = this.getFilterModel();
    this.searchModel.filters = this.selectedFilters;
    this.selectedDropdown = ""
    this.searchModel.searchPhrase = this.searchPhase = this.searchQuery;
    this.getFilteredDatas(this.searchModel, 'applied');
    
    var countryArray = this.filterCountries.toolTipStr.split(', ');
    var countryCount = countryArray.length;
    if(countryCount<=10){
      if(this.filterClient.toolTipStr===undefined){
        this.filterClient.toolTipStr = 'Select Client'
      }
      if(this.filterSolutionTypes.toolTipStr===""){
        this.filterSolutionTypes.toolTipStr = 'Select Products'
      }
      if(this.filterCountries.toolTipStr===""){
        this.filterCountries.toolTipStr = "Select Countries"
      }
      if(this.filterClient.toolTipStr === 'Select Client' && this.filterSolutionTypes.toolTipStr === 'Select Products' && this.filterCountries.toolTipStr === "Select Countries" && this.searchQuery!==''){
        this._analyticsService.track('uf-ProjAcc-ReqAccT-Srch-KywrdsOnly-run', {
          reqProjectAccSearchText: this.searchQuery,
        });
      }
      if((this.filterClient.toolTipStr !== 'Select Client' || this.filterSolutionTypes.toolTipStr !== 'Select Products' || this.filterCountries.toolTipStr !== "Select Countries") && this.searchQuery===''){
        this._analyticsService.track('uf-ProjAcc-ReqAccT-Srch-FiltOnly-run', {
          reqProjectAccClientFilter: this.filterClient.toolTipStr,
          reqProjectAccProductFilter: this.filterSolutionTypes.toolTipStr,
          reqProjectAccCountryFilterCount: countryCount,
          reqProjectAccCountryFilter: this.filterCountries.toolTipStr,
        });
      }
      if((this.filterClient.toolTipStr !== 'Select Client' || this.filterSolutionTypes.toolTipStr !== 'Select Products' || this.filterCountries.toolTipStr !== "Select Countries") && this.searchQuery!==''){
        this._analyticsService.track('uf-ProjAcc-ReqAccT-Srch-Kywrds+Filtr-run', {
          reqProjectAccSearchText: this.searchQuery,
          reqProjectAccClientFilter: this.filterClient.toolTipStr,
          reqProjectAccProductFilter: this.filterSolutionTypes.toolTipStr,
          reqProjectAccCountryFilterCount: countryCount,
          reqProjectAccCountryFilter: this.filterCountries.toolTipStr,
        });
      }
    }
    else{
      if(this.filterClient.toolTipStr===undefined){
        this.filterClient.toolTipStr = 'Select Client'
      }
      if(this.filterSolutionTypes.toolTipStr===""){
        this.filterSolutionTypes.toolTipStr = 'Select Products'
      }
      if(this.filterCountries.toolTipStr===""){
        this.filterCountries.toolTipStr = "Select Countries"
      }
      if(this.filterClient.toolTipStr === 'Select Client' && this.filterSolutionTypes.toolTipStr === 'Select Products' && this.filterCountries.toolTipStr === "Select Countries" && this.searchQuery!==''){
        this._analyticsService.track('uf-ProjAcc-ReqAccT-Srch-KywrdsOnly-run', {
          reqProjectAccSearchText: this.searchQuery,
        });
      }
      if((this.filterClient.toolTipStr !== 'Select Client' || this.filterSolutionTypes.toolTipStr !== 'Select Products' || this.filterCountries.toolTipStr !== "Select Countries") && this.searchQuery===''){
        this._analyticsService.track('uf-ProjAcc-ReqAccT-Srch-FiltOnly-run', {
          reqProjectAccClientFilter: this.filterClient.toolTipStr,
          reqProjectAccProductFilter: this.filterSolutionTypes.toolTipStr,
          reqProjectAccCountryFilterCount: countryCount,
        });
      }
      if((this.filterClient.toolTipStr !== 'Select Client' || this.filterSolutionTypes.toolTipStr !== 'Select Products' || this.filterCountries.toolTipStr !== "Select Countries") && this.searchQuery!==''){
        this._analyticsService.track('uf-ProjAcc-ReqAccT-Srch-Kywrds+Filtr-run', {
          reqProjectAccSearchText: this.searchQuery,
          reqProjectAccClientFilter: this.filterClient.toolTipStr,
          reqProjectAccProductFilter: this.filterSolutionTypes.toolTipStr,
          reqProjectAccCountryFilterCount: countryCount,
        });
      }
    }
      
  }

  onInputChange() {
    this.searchSubmitted = false;
    this.searchModel.searchPhrase = this.searchQuery;
    this.searchAllowed = true;
    this.newQuery = true;
    this._analyticsService.track(`uf-ProjAcc-ReqAccT-SrchBox-Edit`, {
      reqProjectAccSearchText: this.searchQuery,
    });
  }

  public trackFilterDropDownAction(filterOption: string): void {
    this._analyticsService.track(`uf-ProjAcc-ReqAccT-${filterOption}Fltr-clicked`);
  }

  public trackFilterAction(filterAction: string): void {
    this._analyticsService.track(`uf-ProjAcc-ReqAccT-KebabMen-clickedTo${filterAction}`);
  }

  clear() {
    this.searchQuery = '';
    this.searchApplied = false;
    if(this.productFilterDirty){
      this.productfilterNotEmpty = true;
    }
    if(this.countryFilterDirty){
      this.countryfilterNotEmpty = true;
    }
    if(this.clientFilterDirty){
      this.clientfilterNotEmpty = true;
    }
  }

  isProductFilterDirty(value: boolean){
    this.productfilterNotEmpty = value;
  }

  isCountryFilterDirty(value: boolean){
    this.countryfilterNotEmpty = value;
  }

  isCountryFilterNotEmpty(value: boolean){
    this.selectedDropdown = "country"
    this.countryFilterDirty = value;
  }

  isProductFilterNotEmpty(value: boolean){
    this.selectedDropdown = "product"
    this.productFilterDirty = value;
  }

  isSearchButtonDisabled(): boolean {
    return ( !this.productfilterNotEmpty && !this.countryfilterNotEmpty && !this.clientfilterNotEmpty &&  (!this.searchQuery || !this.searchQuery.trim() || this.searchSubmitted));}

  isProductCheckboxFilterDirty(value: boolean){
    this.productfilterNotEmpty = value;
  }

  isCountryCheckboxFilterDirty(value: boolean){
    this.countryfilterNotEmpty = value;
  }

  closeDropdown(event: Event): void {
    event.stopPropagation();
  }

  @HostListener('document:keydown', ['$event'])
    public closeFilter(kbEvnt: KeyboardEvent): void {
        if (kbEvnt.key === 'Escape' && this.menuClosed) {
          kbEvnt.stopPropagation();
        }
    }


  isSubmitButtonEnabled(value: boolean){

    // To record the last selected solution filter
    const userPreferenceSolutions = this.selectedFilters.solutionsFilter.filter(solution => solution.isUserPreference === true);
    const userPreferenceSolutionNames = userPreferenceSolutions.map(solution => solution.name);
    const resultSolutionString = userPreferenceSolutionNames.join(', ');

    // To record the last selected country filter
    const userPreferenceCountries = this.selectedFilters.countriesFilter.filter(country => country.isUserPreference === true);
    const userPreferenceCountryNames =userPreferenceCountries.map(country => country.name);
    const resultCountryString = userPreferenceCountryNames.join(', ');
    
    if((!this.productFilterDirty && !this.countryFilterDirty && !this.clientFilterDirty)){
      this.productfilterNotEmpty = false;
      this.countryfilterNotEmpty = false;
      if(this.searchQuery && (resultSolutionString.trim() || resultCountryString.trim())){
        this.searchSubmitted = false;
      }
      if(!this.searchQuery){
        this.searchSubmitted = true;
      }
    }
    if(this.productFilterDirty && this.filterSolutionTypes.toolTipStr!== resultSolutionString && this.selectedDropdown === "product"){
      this.productfilterNotEmpty = true;
      
    }
    if(!this.productFilterDirty && (this.filterSolutionTypes.toolTipStr.trim() !== resultSolutionString.trim()) && this.selectedDropdown === "product" && (this.countryFilterDirty || this.clientFilterDirty)){
      if(this.filterSolutionTypes.toolTipStr.trim()==="Select Products" && !resultSolutionString.trim()){
        this.productfilterNotEmpty = false;
      }else{
        this.productfilterNotEmpty = true;
      }
    }
    if(this.countryFilterDirty && this.filterCountries.toolTipStr.trim() !== resultCountryString.trim() && this.selectedDropdown === "country"){
        this.countryfilterNotEmpty = true;
    }
    if(!this.countryFilterDirty && this.filterCountries.toolTipStr.trim() !== resultCountryString.trim() && this.selectedDropdown === "country" && (this.productFilterDirty || this.clientFilterDirty)){
      if(this.filterCountries.toolTipStr.trim()==="Select Countries" && !resultCountryString.trim()){
        this.countryfilterNotEmpty = false;
      }else{
        this.countryfilterNotEmpty = true;
      }
    }
    }
  }
