<niq-spinner [display]="spinner"></niq-spinner>
<div class="search-bar">
  <span class="sif sif-search custom-search-icon"></span>
  <div class="search-input-wrapper">
      <input mat-input #searchInput="ngModel" type="text" class="search-inputs" [(ngModel)]="searchQuery"
          placeholder="Search for projects and concepts" [matTooltip]="searchTooltipText"
          [matTooltipClass]="['custom-tooltip-text-bar', 'pre-tooltip']" (input)="onInputChange()"
          (keyup.enter)="onSearchButtonClick()">
      <button [hidden]="!(searchQuery)"
          (click)="clear()" name="search-delete-icons">
          <span class="sif sif-delete"></span>
      </button>
  </div>
  <mat-drawer-container class="example-container" autosize style="border-right: none; overflow: hidden;">
    <mat-drawer #drawer class="example-sidenav" mode="side" style="border-right: none; overflow: hidden;" [disableClose]="true">
      <div class="apply-filter-section filter-section" *ngIf="openFilter">
        <div class="apply-filter-options align-items-center">
          <div class="dropdown-bar align-items-center">
            <div class="dropdown-input client-dropdown" *ngIf="clientViewPermissions">
              <niq-drop-down [displayInput]="RequestAccessClient" [displayType]="'regular'" [displayOptions]="clients" [arrayName]="'clients'"
                [flagKey]="'isUserPreference'" [displayKey]="'name'" [addRadioIndent]="false"
                (userSelections)="setSelectedFilterOption($event, 'clients')" (ddMenuClosed)="menuClosed = $event" (click)="trackFilterDropDownAction('Client')">
              </niq-drop-down>
            </div>
            <div class="dropdown-input product-dropdown">
              <niq-drop-down [displayInput]="RequestAccess" [displayType]="'checkbox'" [displayOptions]="solutionTypes" [arrayName]="'types'"
                [flagKey]="'isUserPreference'" [displayKey]="'name'" [displayChildKey]="'name'" [childKey]="'subtasks'"
                (userSelections)="setSelectedFilterOption($event, 'types')" (nullSelections)="isSolutionsNull = $event"
                (ddMenuClosed)="menuClosed = $event" (isProductFilterDirty)="isProductFilterDirty($event)" (isProductCheckboxFilterDirty)="isProductCheckboxFilterDirty($event)" (isProductFilterNotEmpty)="isProductFilterNotEmpty($event)" (isSubmitButtonEnabled)="isSubmitButtonEnabled($event)" (click)="trackFilterDropDownAction('Product')">
              </niq-drop-down>
            </div>
            <div class="dropdown-input country-dropdown">
              <niq-drop-down [displayInput]="RequestAccess" [displayType]="'checkbox'" [displayOptions]="countries" [arrayName]="'countries'"
                [flagKey]="'isUserPreference'" [displayKey]="'name'" [displayChildKey]="'name'" [childKey]="'subtasks'"
                (userSelections)="setSelectedFilterOption($event, 'countries')" (nullSelections)="isCountriesNull = $event"
                (ddMenuClosed)="menuClosed = $event" (isCountryFilterDirty)="isCountryFilterDirty($event)" (isCountryCheckboxFilterDirty)="isCountryCheckboxFilterDirty($event)" (isCountryFilterNotEmpty)="isCountryFilterNotEmpty($event)" (isSubmitButtonEnabled)="isSubmitButtonEnabled($event)" (click)="trackFilterDropDownAction('Country')">
              </niq-drop-down>
            </div>
          </div>
          <div class="controller-btn">
            <button mat-button mat-flat-button color="secondary" class="mat-flat-button mat-secondary hover-btn"
                [disabled]="isSearchButtonDisabled()" (click)="onSearchButtonClick()">
                <span>SEARCH</span>
            </button>
            <button [hidden]="!productFilterDirty && !countryFilterDirty && !clientFilterDirty" (click)="resetFilter(true);"
                  matTooltip="Reset search and filters" matTooltipClass="custom-tooltip" class="action-btn reset-btn"
                  [disabled]="isProjectsNull || isSolutionsNull || isCountriesNull">
                  <span class="sif sif-reset"></span>
            </button>
            <button type="button"
                (click)="openFilter = false; drawer.toggle(); trackFilterAction('Close')"
                [disabled]="productFilterDirty || countryFilterDirty || clientFilterDirty" [matTooltip]="productFilterDirty || countryFilterDirty || clientFilterDirty ? 'Filters can be hidden when reset' : 'Close filters'"
                [matTooltipClass]="['custom-tooltip']" [ngClass]="{'custom-class': !productfilterNotEmpty && !countryfilterNotEmpty && !clientfilterNotEmpty}">
                <span class="sif sif-ellipsis-horizontal"></span>
            </button>

          </div>
        </div>
      </div>  
    </mat-drawer>
  
    <div class="example-sidenav-content">
        <div class="filter-container" *ngIf="!openFilter" >
          <div class="align-items-center filter-section" mat-form-field>
            <div class="flex-search-filter">
            
              <div class="filter-display-container" (mouseenter)="optionsHover = true" (mouseleave)="optionsHover = false">
                <mat-menu #menu="matMenu">
                </mat-menu>
                <div class="filterWrapper" (click)="openFilter = true; editFilters()" style="cursor: pointer;z-index: 99;">
                  <div class="filterClientDropdown" [matTooltip]="filterClient.toolTipStr" matTooltipClass="custom-tooltip" *ngIf="clientViewPermissions && filterhoverstate">
                    <span>{{ filterClient.name }}</span>
                  </div>
                  <div class="filterSolutionTypesDropdown"  [matTooltip]="filterSolutionTypes.toolTipStr" matTooltipClass="custom-tooltip" *ngIf="filterhoverstate">
                    <span>{{ filterSolutionTypes.name }}</span>
                  </div>
                  <div class="filterCountriesDropdown" [matTooltip]="filterCountries.toolTipStr" matTooltipClass="custom-tooltip" *ngIf="filterhoverstate">
                    <span>{{ filterCountries.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="controller-btn">
              <button mat-button mat-flat-button color="secondary" class="mat-flat-button mat-secondary hover-btn"
                  [disabled]="isSearchButtonDisabled()" (click)="onSearchButtonClick()">
                  <span>SEARCH</span>
              </button>
              <button type="button" (click)="openFilter = true; drawer.toggle(); trackFilterAction('Open')" [matTooltip]="productFilterDirty || countryFilterDirty || clientFilterDirty ? 'Filters can be hidden when reset' : 'Open filters'" [matTooltipClass]="['custom-tooltip']">
                <span class="sif sif-ellipsis-horizontal"></span>
              </button>
            </div>
          </div>
        </div>
    </div>  
  </mat-drawer-container>          
</div>