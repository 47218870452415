import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { Collaborator } from '../../models/collaborators/collaborator';

@Injectable({
    providedIn: 'root',
})
export class CollaboratorService {
    private _url: string;

    constructor(private _http: HttpClient, private _cs: AppConfigService) {
        this._url = this._cs?.config?.projectservice.url;
    }

    /**
   * Returns the list of collaborators
   *
   * @param link
   * @returns {Observable<Array<Collaborator>}
   * @memberof CollaboratorService
   */
    public get(link: string): Observable<Array<Collaborator>> {
        return this._http.get<Array<Collaborator>>(`${this._url}${link}`);
    }
}
