import { OverlayRef } from '@angular/cdk/overlay';
import {
    Component,
    DoCheck,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from '@angular/material/legacy-progress-spinner';
import { OverlayService } from './services/overlay.service';

@Component({
    selector: 'niq-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, DoCheck {
    @Input() public color?: ThemePalette = 'primary';
    @Input() public diameter = 100;
    @Input() public mode?: ProgressSpinnerMode = 'indeterminate';
    @Input() public strokeWidth?: number;
    @Input() public value = 50;
    @Input() public backdropEnabled = true;
    @Input() public positionGloballyCenter = true;
    @Input() public display: boolean;

    @ViewChild('progressSpinnerRef', { read: TemplateRef, static: true })
    public progressSpinnerRef: TemplateRef<any>;
    private _progressSpinnerOverlayConfig: any;
    private _overlayRef: OverlayRef;
    constructor(
        private _vcRef: ViewContainerRef,
        private _overlayService: OverlayService
    ) { }

    public ngOnInit(): void {
        // Config for Overlay Service
        this._progressSpinnerOverlayConfig = {
            hasBackdrop: this.backdropEnabled,
        };
        if (this.positionGloballyCenter) {
            this._progressSpinnerOverlayConfig.positionStrategy = this._overlayService.positionGloballyCenter();
        }
        // Create Overlay for progress spinner
        this._overlayRef = this._overlayService.createOverlay(
            this._progressSpinnerOverlayConfig
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.progressSpinnerRef;
    }

    public ngDoCheck(): void {
        // Based on status of displayProgressSpinner attach/detach overlay to progress spinner template
        if (this.display && !this._overlayRef.hasAttached()) {
            this._overlayService.attachTemplatePortal(
                this._overlayRef,
                this.progressSpinnerRef,
                this._vcRef
            );
        } else if (!this.display && this._overlayRef.hasAttached()) {
            this._overlayRef.detach();
        }
    }
}
