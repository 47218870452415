<div class="app-container">
  <div class="position-sticky">
    <niq-solution-type *ngIf="showProjectType" (showProjectSetup)="projectSetupShowHide($event)" [gridView]="gridView"
        [gridViewId]="gridViewId"  (selectedSolution)="setSolution($event)" (gridViewUpdated)="updateViewSize($event)"></niq-solution-type>
    <niq-project-setup *ngIf="showProjectSetup" (closeScreen)="showCreateButton($event)"
      (previousScreen)="showPreviousScreen($event)" [isWorkbenchVisible]="isWorkbenchVisible"
      [solution]="selectedSolution">
    </niq-project-setup>
  </div>
  <mat-drawer-container hasBackdrop="false">
    <mat-drawer #drawer mode="over">
      <button mat-menu-item class="mat-btn" [ngClass]="{ 'mat-btn-active': true }">
        <span class="sif sif-home" (click)="navigateToHomeUsingIcon($event)"></span> 
        <span class="mat-btn-text" (click)="navigateToHome($event)">{{"project.toolbar.name" | translate}}</span>     
      </button>
      <button mat-menu-item *ngFor="let app of additionalApps" class="mat-btn">
        <span class="sif {{ app.icon }}" (click)="navigateToAppUsingIcon($event, app)"></span>
        <span class="mat-btn-text" (click)="navigateToApp($event, app)">{{ app.appName }}</span>
      </button>
    </mat-drawer>
    <mat-drawer-content>
      <div class="alert d-flex error" role="alert" *ngIf="isApprovalAlertVisible && !userInfo.roles.includes('ROLE_SYSTEM_ADMINISTRATOR') && !userInfo.roles.includes('ROLE_All Project Access') && userInfo.roles.includes('ROLE_Nielsen')  && requestApprovalData && requestApprovalData.length > 0 && isNotificationBarEnabled">
        <div class="icon d-flex align-items-center justify-content-center">
          <mat-icon class="sif sif-warning"></mat-icon>
        </div>
        <div class="message w-100">{{ message }}</div>
        <a class="close-icon d-flex align-items-center justify-content-center" (click)="navigateToApprovalTab()">VIEW ACCESS REQUEST</a>
        <div class="close-icon d-flex align-items-center justify-content-center" matTooltip="Dismiss the notification until a new Request comes in"
        matTooltipClass="custom-tooltip" (click)="closeAlert()">
          <mat-icon class="sif sif-close"></mat-icon>
        </div>
      </div>
      <div class="home-container" [class]="!showCreateProjectBtn ? 'dynamic-padding' : ''">
        <div class="tool-bar" [hidden]="!showCreateProjectBtn">
          <div class="tool-bar-container align-items-center">
            <div class="tool-bar-left">
              <button mat-icon-button *ngIf="showMenu" (click)="trackNavigationOptions(); drawer.toggle()"
                name="flyout-menu" [ngClass]="{
                  'menu-enabled': isHighlighted,
                  'menu-disabled': !isHighlighted
                }">
                <span class="sif sif-hamburger hamburger-icon" id="flyout-menu" matTooltip="Additional Applications"
                  matTooltipClass="custom-tooltip"></span>
              </button>
              <span class="sif sif-home home-icon"></span>
              <label class="header-label">{{
                "project.toolbar.name" | translate
                }}</label>
            </div>

            <div class="align-items-center" mat-form-field>
              <div class="flex">
                <button class="access-request-btn mat-flat-button"
                  (click)="navigateToRequestAccess()" matTooltip="Gain access to projects not currently available to you" matTooltipClass="center-tooltip" *ngIf="userInfo.isInternalUser">
                  <span class="sif sif-import"></span>{{ "project.Request_Access.button" | translate }}
                </button>
                <button class="create-project-btn mat-flat-button mat-primary"  style="margin-left: 4px;" color="primary"
                  (click)="showProjectTypeHandler($event)">
                  <span class="sif sif-add"></span>{{ "project.create_project.button" | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="tool-bar second-toolbar" [hidden]="!showCreateProjectBtn">
            <div class="tool-bar-container align-items-center">
              <niq-search-filters class="filter-section" (isLoading)="isLoading = $event"
                (resetOffset)="offset = $event" (projectList)="projectsList = $event" [lazyLoad]="lazyLoadProjects"
                (limitExceeded)="limit = $event" [isAnalysisStatusVisible]="isAnalysisStatusVisible"
                [fieldClick]="fieldClick" (sortOrder)="sortOrder = $event" (favProjCount)="favProjCount = $event">
              </niq-search-filters>
            </div>
          </div>
        </div>

        <div class="project-content">
          <niq-project-details [isLoading]="isLoading" [projectsList]="projectsList" [resetOffset]="offset"
            [limitExceeded]="limit" (lazyLoad)="lazyLoadProjects = $event" [isWorkbenchVisible]="isWorkbenchVisible"
            (fieldClick)="fieldClick = $event" [sortOrder]="sortOrder" [favTotalCount]="favProjCount">
          </niq-project-details>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  <!-- <button class="back-to-top" matTooltip="Back to top" matTooltipClass="custom-tooltip" (click)="window.scrollTo(0, 0)">
    <img src="../../../assets/images/back-to-top.png"/>
  </button> -->
</div>
