import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectModel } from '../../models/project/project-model';
import { AppConfigService } from '../../../core/config/app-config.service';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    /**
     * String to hold the url.
     *
     * @type     {string}
     * @memberof ProjectService
     */
    private _url: string;

    /**
     * Constructor.
     *
     * @param HttpClient
     * @memberof ProjectService
     */
    constructor(private _http: HttpClient, private _cs: AppConfigService) {
        const resourceUri = '/api/v1/projects';
        this._url = `${this._cs?.config?.projectservice.url}${resourceUri}`;
    }

    /**
     * Returns the list of projects.
     *
     * @param none
     * @returns httpresponse
     * @memberof ProjectService
     */
    public get(limit: number, offset: number): Observable<any> {
        return this._http.get(`${this._url}?limit=${limit}&offset=${offset}`, {
            observe: 'response',
        });
    }

    /**
     * Saves a project.
     *
     * @param ProjectModel
     * @returns {Observable<ProjectModel>} The project observable.
     * @memberof ProjectService
     */
    public save(project: ProjectModel, isDuplicate = false): Observable<ProjectModel> {
        const headers = new HttpHeaders();
        const params = new HttpParams()
            .append('isDuplicate', isDuplicate.toString());
        return this._http.post<ProjectModel>(this._url, project, { headers, params });
    }

    /**
     * Update a project.
     *
     * @param ProjectModel
     * @returns {Observable<ProjectModel>} The project observable.
     * @memberof ProjectService
     */
    public update(project: ProjectModel): Observable<ProjectModel> {
        return this._http.put<ProjectModel>(`${this._url}/${project.id}`, project);
    }

    /**
     * Delete a project.
     *
     * @param ProjectModel
     * @returns {Observable<ProjectModel>} The project observable.
     * @memberof ProjectService
     */
    public delete(project: ProjectModel): Observable<ProjectModel> {
        return this._http.delete<ProjectModel>(`${this._url}/${project.id}`);
    }

    /**
     * Patch the project information.
     *
     * @param ProjectModel
     * @returns {Observable<ProjectModel>} The project observable.
     * @memberof ProjectService
     */
    public patch(project: ProjectModel): Observable<ProjectModel> {
        return this._http.patch<ProjectModel>(`${this._url}/${project.id}`, {
            workbenchId: project.workbenchId,
        });
    }

    // save and update the favorite flag of a project
    public saveFavorite(projectId: string): Observable<any> {
        return this._http.put<any>(
            `${this._cs?.config?.projectservice.url}/api/v1/favorites/${projectId}`,
            projectId
        );
    }
}
