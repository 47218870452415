import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocaleModel } from '../../models/locale/locale-model';
import { AppConfigService } from '../../../core/config/app-config.service';
import { concatMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { addLocales } from '../../store/actions/locales.actions';
import { selectLocalesState } from '../../store/selectors/locales.selectors';

@Injectable({
    providedIn: 'root',
})
export class LocaleService {
    /**
     * String to hold the url.
     *
     * @type     {string}
     * @memberof LocaleService
     */
    private _url: string;

    /**
     * Constructor.
     *
     * @param HttpClient
     * @param AppConfigService
     * @memberof LocaleService
     */
    constructor(
        private _http: HttpClient,
        private _cs: AppConfigService,
        private _store: Store
    ) {
        const resourceUri = '/api/v1/locales';
        this._url = `${this._cs?.config?.projectservice.url}${resourceUri}`;
    }

    /**
     * Returns the list of locales.
     *
     * @param none
     * @returns { Array<Locale> }
     * @memberof LocaleService
     */
    public get(sortBy: string): Observable<Array<LocaleModel>> {
        let locales$: Observable<Array<LocaleModel>> = this.fetchFromStore();
        locales$.pipe(take(1)).subscribe((locales) => {
            if (!locales) {
                locales$ = this.fetchFromAPI(sortBy);
            }
        });
        return locales$;
    }

    // Store
    // fetching from API
    public fetchFromAPI(sortBy: string): Observable<Array<LocaleModel>> {
        return this._http
            .get<Array<LocaleModel>>(`${this._url}?sortBy=${sortBy}`)
            .pipe(
                concatMap((data) => {
                    this.loadOnStore(data);
                    return this.fetchFromStore();
                })
            );
    }

    // Loading in store
    public loadOnStore(locales: Array<LocaleModel>): void {
        this._store.dispatch(addLocales({ locales }));
    }

    // fetchig from store
    public fetchFromStore(): Observable<Array<LocaleModel>> {
        return this._store.pipe(select(selectLocalesState));
    }
}
