import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { SNACK_BAR_TYPE_ERROR } from '../../constant';
import { MessageBarService } from '../../services/mesasgeBar/message-bar.service';
import { ProjectService } from '../../services/projects/project.service';

@Component({
    selector: 'niq-rename-project',
    templateUrl: './rename-project.component.html',
    styleUrls: ['./rename-project.component.scss'],
})
export class RenameProjectComponent implements OnInit {
    /**
     * Placeholder text for WorkbenchId field.
     *
     * @type     {string}
     * @memberof DuplicateProjectComponent
     */
    public workbenchIdPlaceHolder: string = this.translate.instant(
        'project.create_project.workbench_id_place_holder'
    );

    /**
     * Placeholder text for project name field.
     *
     * @type     {string}
     * @memberof DuplicateProjectComponent
     */
    public projectNamePlaceHolder: string = this.translate.instant(
        'project.create_project.project_name_place_holder'
    );

    public labelName: string = this.translate.instant('project.form_label.name');
    public labelWorkbench: string = this.translate.instant(
        'project.form_label.workbenchId'
    );
    public projectName = '';
    public projectDetails: any;
    public isPrjNameValid: boolean = true;
    public prjNameErrorMessage: string = '';
    public displaySpinner = false;
    public isFormDirty = false;
    public isCRMLinked = false;

    constructor(
        public dialogRef: MatDialogRef<RenameProjectComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public translate: TranslateService,
        private _projService: ProjectService,
        private _messageBar: MessageBarService
    ) { }

    public ngOnInit(): void {
        this.projectDetails = JSON.parse(JSON.stringify(this.data.project));
        this.projectName = this.projectDetails.name;
        this.projectDetails.status = this.projectDetails.status.toUpperCase();
        this.isCRMLinked =
            this.projectDetails.crmId !== undefined &&
            this.projectDetails.crmId !== null;
    }

    public setRenameData(objValue: string, objKey: string): void {
        this.projectDetails[objKey] = objValue;
    }

    public renameProject(): void {
        this.displaySpinner = true;
        if (
            this.projectDetails.name.trim().length > 0 &&
            this.projectDetails.name !== this.data.project.name
        ) {
            this._projService.update(this.projectDetails).subscribe(
                (data) => {
                    this.displaySpinner = false;
                    if (data && data.id) {
                        this.dialogRef.close(data);
                    }
                },
                (error) => {
                    this.displaySpinner = false;
                    const err = JSON.parse(JSON.stringify(error.error));
                    if (err.error.status === 500 || error.status === 500) {
                        this._messageBar.openSnackBar(
                            SNACK_BAR_TYPE_ERROR,
                            'Project rename failed.  Please contact system administrator.'
                        );
                    } else {
                        this.prjNameErrorMessage = err.error.details[0];
                    }
                }
            );
        } else if (
            this.projectDetails.workbenchId !== this.data.project.workbenchId
        ) {
            this._projService.patch(this.projectDetails).subscribe(
                (data) => {
                    this.displaySpinner = false;
                    if (data && data.id) {
                        this.dialogRef.close(data);
                    }
                },
                (error) => {
                    this.displaySpinner = false;
                    const err = JSON.parse(JSON.stringify(error.error));
                    if (err.error.status === 500 || error.status === 500) {
                        this._messageBar.openSnackBar(
                            SNACK_BAR_TYPE_ERROR,
                            'Project workbench id updation failed.  Please contact system administrator.'
                        );
                    } else {
                        this.prjNameErrorMessage = err.error.details[0];
                    }
                }
            );
        } else {
            this.displaySpinner = false;
        }
    }
}
