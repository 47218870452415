<niq-spinner-small *ngIf="spinner"></niq-spinner-small>
<niq-empty-page [icon]="emptyConst.icon" [leadText]="emptyConst.lead_text" [supportText]="emptyConst.support_text"
  [pageSize]="'small'" *ngIf="!spinner && concepts.length === 0"></niq-empty-page>

<div class="concept-heading" *ngIf="concepts.length > 0">
  <span class="sif sif-concept"></span>
  <h5 translate>{{ "project.table_content.concepts.name" }}</h5>
  <span>[{{ concepts?.length }}]</span>
</div>
<div class="concept-body" *ngIf="concepts.length > 0">
  <div *ngFor="let concept of concepts">
    <mat-card class="concept-details">
      <div class="thumbnail-size">
        <img [src]="getThumbnailUrl(concept.thumbnailPath)"
          onerror="this.src='../../../assets/images/concepts-placeholder.png'" loading="lazy" />
      </div>
      <p class="text-overflow-ellipsis" [matTooltip]="concept.name" matTooltipClass="custom-tooltip">{{ concept.name }}
      </p>
    </mat-card>
  </div>
</div>
