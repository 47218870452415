import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2, StaticProvider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_INPUT_DATE_PICKER_CONTROL_VALUE_ACCESSOR: StaticProvider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OnlyNumberDirective),
    multi: true
};

@Directive({
    selector: '[niqOnlyNumber]',
    providers: [CUSTOM_INPUT_DATE_PICKER_CONTROL_VALUE_ACCESSOR]
})
export class OnlyNumberDirective implements ControlValueAccessor {
    private _onChange: (val: string) => void;
    private _onTouched: () => void;
    private _value: string;

    @Input('niqOnlyNumber') public enable: boolean;

    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer2
    ) {
    }

    @HostListener('input', ['$event.target.value'])
    public onInputChange(value: string): void {
        const filteredValue: string = this.enable ? filterValue(value) : value;
        this._updateTextInput(filteredValue, this._value !== filteredValue);
    }

    @HostListener('blur')
    public onBlur(): void {
        this._onTouched();
    }

    private _updateTextInput(value: string, propagateChange: boolean): void {
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
        if (propagateChange) {
            this._onChange(value);
        }
        this._value = value;
    }

    // ControlValueAccessor Interface
    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    }

    public writeValue(value: any): void {
        value = value ? String(value) : '';
        this._updateTextInput(value, false);
    }
}

function filterValue(value): string {
    return value.replace(/[^0-9]*/g, '');
}
