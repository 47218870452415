import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import {
    FILTER_DEFAULTS,
    FILTER_NAME_LIST,
    NIELSENIQ,
    PERMISSION_VIEW_ALL_CLIENTS,
    SNACK_BAR_TYPE_ERROR,
    SNACK_BAR_TYPE_SUCCESS,
} from '../constant';
import { CheckboxArray } from '../models/checkbox-array/checkbox-array';
import {
    Action,
    DropDownModel,
    FilterModel,
    FilterOps,
    SearchModel,
} from '../models/filter/filter-model';
import { MessageBarService } from '../services/mesasgeBar/message-bar.service';
import { SearchFiltersService } from '../services/search-filters/search-filters.service';
import * as _ from 'lodash';
import { UserInfoService } from '../services/user/user-info.service';
import { UserInfo } from '../models/user/user-info';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { PermissionService } from '../services/permissions/permission.service';

export class DisplayFilters {
    public name: string;
    public toolTipStr: string;
    public data: any;
}
@Component({
    selector: 'niq-search-filters',
    templateUrl: './search-filters.component.html',
    styleUrls: ['./search-filters.component.scss'],
})
export class SearchFiltersComponent implements OnInit, OnChanges {
    @Output() public isLoading = new EventEmitter();
    @Output() public projectList = new EventEmitter();
    @Output() public resetOffset = new EventEmitter();
    @Output() public limitExceeded = new EventEmitter();
    @Output() public sortOrder = new EventEmitter();
    @Output() public favProjCount = new EventEmitter();

    @Input() public lazyLoad: any;
    @Input() public isAnalysisStatusVisible: boolean;
    @Input() public fieldClick: any;

    public filterToggle = false;
    public projectCount: number;
    public savedFilterCount: number;
    public openFilter: boolean = false;
    public optionsHover: boolean = false;
    public filterNameError: string = '';
    public isProjectsNull: boolean = false;
    public isSolutionsNull: boolean = false;
    public isStatusesNull: boolean = false;
    public isCountriesNull: boolean = false;
    public menuClosed: boolean = true;
    public filterDirty: boolean = false;
    public isDesc: boolean = false;
    public filterClient: DisplayFilters = new DisplayFilters();
    public filterProjects: DisplayFilters = new DisplayFilters();
    public filterSolutionTypes: DisplayFilters = new DisplayFilters();
    public filterStatuses: DisplayFilters = new DisplayFilters();
    public filterCountries: DisplayFilters = new DisplayFilters();
    public filterLookups: DisplayFilters = new DisplayFilters();
    public filterSort: DisplayFilters = new DisplayFilters();
    public filterName: DisplayFilters = new DisplayFilters();
    public selectedOption: string = '';

    public clients: Array<DropDownModel> = [];
    public sortBy: Array<DropDownModel> = [];
    public lookupsFilter: Array<DropDownModel> = [];
    public solutionTypes: Array<CheckboxArray> = [];
    public statuses: Array<CheckboxArray> = [];
    public countries: Array<CheckboxArray> = [];
    public nameFilter: Array<DropDownModel> = [];
    public searchPhase: string = '';

    public clientsCopy: Array<DropDownModel> = [];
    public sortByCopy: Array<DropDownModel> = [];
    public lookupsFilterCopy: Array<DropDownModel> = [];
    public solutionTypesCopy: Array<CheckboxArray> = [];
    public statusesCopy: Array<CheckboxArray> = [];
    public countriesCopy: Array<CheckboxArray> = [];
    public nameFilterCopy: Array<DropDownModel> = [];

    public channel = new BroadcastChannel('savedFilters');
    public newChanges = false;
    public selectedFilters: FilterModel;
    public searchModel: SearchModel = new SearchModel();
    public filterNameList: {} = FILTER_NAME_LIST;
    public limit = 15;
    public offset = 0;
    public previousSelectedFilter: FilterModel;
    public userInfo: UserInfo;
    public filterDefault = FILTER_DEFAULTS;
    public isNotInitialSelection = false;
    public clientViewPermissions = true;
    public sortByApplied = false;
    public selectedSavedFilter = '';
    public newSavedFilterName = '';
    public searchTooltipText = "Enter key words, numbers, and email addresses. Separate by comma for multi-search";
    public newSavedFilterNameCopy: string = '';
    public userFilterAction: string = '';

    constructor(
        private _filtersService: SearchFiltersService,
        private _toaster: MessageBarService,
        private _userInfoService: UserInfoService,
        private _analyticsService: AnalyticsService,
        private _permissions: PermissionService
    ) {
        this.channel.addEventListener('message', () => {
            this.isLoading.emit(true);
            setTimeout(() => {
                if (this.searchModel.filters && this.searchModel.filters.names) {
                    this.searchModel = new SearchModel();
                }
                this.limit!==0 ? this.getFilteredData(this.searchModel):''
            }, 2000);
        });
    }

    public ngOnInit(): void {
        this._userInfoService.get().subscribe((data: any) => {
            this.userInfo = data;
        });
        this._permissions.get().subscribe((data) => {
            this.clientViewPermissions = data.permissions.includes(
                PERMISSION_VIEW_ALL_CLIENTS
            );
        });
        this.searchModel.action = Action.read;
        this.searchModel.searchPhrase = this.searchPhase = localStorage.getItem(
            this.userInfo.userId
        )
            ? localStorage.getItem(this.userInfo.userId)
            : '';

        this.getFilteredData(this.searchModel);
    }

    public ngOnChanges(): void {
        if (this.lazyLoad && this.limit !== 0) {
            this.lazyLoad = null;
            this.sortByApplied = true;
            this.limit = 15;
            this.offset = this.offset + this.limit;
            this.searchModel.filters = this.setSortingDetails();
            this.searchModel.action = Action.read;
            this.getFilteredData(this.searchModel);
        } else if (this.fieldClick) {
            this.sortByApplied = true;
            this.isLoading.emit(true);
            this.offset = 0;
            this.limit = 15;
            this.searchModel.action = Action.update;
            this.searchModel.filters = this.setSortingDetails();
            this.getFilteredData(this.searchModel);
        }
    }

    public setSearchText(evnt: string): void {
        if (evnt !== this.searchModel.searchPhrase) {
            this.isLoading.emit(true);
            this.offset = 0;
            this.limit = 15;
            this.resetOffset.emit(this.offset);
            this.searchModel.searchPhrase = evnt;
            this.searchModel.action = Action.read;
            this.searchModel.filters = this.getFilterModel();
            localStorage.setItem(this.userInfo.userId, this.searchModel.searchPhrase);
            this.getFilteredData(this.searchModel);
            window.scroll(0, 0);
            this._analyticsService.track('Triggered Search Event', {
                searchText: evnt,
            });
        }
    }

    public getFilteredData(searchObj: SearchModel, type?: string): void {
        this._filtersService.get(searchObj, this.limit, this.offset).subscribe(
            (data) => {
                this.isLoading.emit(false);
                this.resetOffset.emit(this.offset);
                this.projectList.emit(data.data);
                this.sortOrder.emit({
                    name: data.filters.sortByFilter,
                    order: data.filters.isDesc,
                });
                this.favProjCount.emit(data.meta.favoritesCount);
                this.projectCount = data.meta.count;
                this.savedFilterCount = data.filters?.names?.length;
                this.selectedFilters = data.filters;
                this.searchPhase = data.searchPhrase
                    ? data.searchPhrase
                    : this.searchModel.searchPhrase;
                if (!this.sortByApplied) {
                    this.setDropDownValues(data.filters);
                }

                if (!data.data || data.data.length === 0) {
                    this.limit = 0;
                }
                this.limitExceeded.emit(this.limit);
                if (type) {
                    this._toaster.openSnackBar(
                        SNACK_BAR_TYPE_SUCCESS,
                        `View ${type} successfully.`
                    );
                }

                if (!this.sortByApplied) {
                    this.previousSelectedFilter = this.getFilterModel();
                    this.isFilterDirty();
                }
                this._analyticsService.initializeWithIdentifier(
                    this.userInfo,
                    this.getOrganizationId(data.filters.clientsFilter)
                );
                this.isLoading.emit(false);
            },
            () => {
                this.isLoading.emit(false);
                this._toaster.openSnackBar(
                    SNACK_BAR_TYPE_ERROR,
                    'Internal Error. Try again later.'
                );
            }
        );
    }

    public getOrganizationId(clientsFilter: DropDownModel[]): string {
        if (clientsFilter.length > 1) {
            if (this.userInfo.isInternalUser) {
                return clientsFilter.find((client) => client.name === NIELSENIQ)
                    .identifier;
            }
        }
        return clientsFilter[0].identifier;
    }

    public setDropDownValues(filters: FilterModel): void {
        this.clients = filters.clientsFilter;
        const allClient =
            this.clients.filter((ele) => ele.isUserPreference)?.length ===
            this.clients.length;

        this.clients.unshift({
            name: 'All Clients',
            identifier: '',
            isUserPreference: allClient,
        });

        this.statuses = this.mapDropDownObj(filters.statusesFilter, 'All Statuses');

        this.countries = this.mapDropDownObj(
            filters.countriesFilter,
            'All Countries'
        );

        const fiteredSolutions = filters.solutionsFilter.filter((sol) => {
            if(sol.identifier === 'IS') {
                return this.userInfo?.featureFlags.includes('INNOVATION_SCREENER');
              }
              else if (sol.identifier === 'DD') {
                return this.userInfo?.featureFlags.includes('STUDIO_DESIGN_PRODUCT');
              }
              else {
                return true;
              }
        });
        this.solutionTypes = this.mapDropDownObj(fiteredSolutions, 'All Products');
        this.nameFilter = filters.names;

        this.lookupsFilter = filters.lookupsFilter;
        this.sortBy = filters.sortByFilter;
        this.isDesc = filters.isDesc;

        // find the selected saved filter and save to selectedSavedFilter
        filters.names.forEach((ele) => {
            if (ele.isUserPreference) {
                this.selectedSavedFilter = ele.identifier;
            }
        });

        // copy of dropdowns
        this.clientsCopy = JSON.parse(JSON.stringify(this.clients));
        this.statusesCopy = JSON.parse(JSON.stringify(this.statuses));
        this.countriesCopy = JSON.parse(JSON.stringify(this.countries));
        this.solutionTypesCopy = JSON.parse(JSON.stringify(this.solutionTypes));
        this.sortByCopy = JSON.parse(JSON.stringify(this.sortBy));
        this.lookupsFilterCopy = JSON.parse(JSON.stringify(this.lookupsFilter));
        this.nameFilterCopy = JSON.parse(JSON.stringify(this.nameFilter));

        this.setInitialFilterValues();
    }

    public mapDropDownObj(obj: DropDownModel[], mainStr: string): any[] {
        const multiSelectOptions: any = {};
        multiSelectOptions.tooltipText = '';
        multiSelectOptions.name = mainStr;
        multiSelectOptions.selectedOptionsCount = obj.filter(
            (ele) => ele.isUserPreference
        ).length;
        multiSelectOptions.allComplete = multiSelectOptions.isUserPreference =
            multiSelectOptions.selectedOptionsCount === obj.length ? true : false;
        multiSelectOptions.singleSelectedOptionObject =
            multiSelectOptions.selectedOptionsCount === 1
                ? obj.find((ele) => ele.isUserPreference)
                : '';
        multiSelectOptions.subtasks = obj;

        if (!multiSelectOptions.allComplete) {
            obj.forEach((ele) => {
                if (ele.isUserPreference) {
                    multiSelectOptions.tooltipText = multiSelectOptions.tooltipText
                        ? multiSelectOptions.tooltipText + ', ' + ele.name
                        : ele.name;
                }
            });
        }

        return [multiSelectOptions];
    }

    // to set the selected filter option in the drop down
    public setInitialFilterValues(): void {
        this.isNotInitialSelection = false;
        this.setSelectedFilterOption(this.clients, 'clients');
        this.setSelectedFilterOption(this.solutionTypes, 'types');
        this.setSelectedFilterOption(this.statuses, 'statuses');
        this.setSelectedFilterOption(this.countries, 'countries');
        this.setSelectedFilterOption(this.lookupsFilter, 'lookupsFilter');
        this.setSelectedFilterOption(this.nameFilter, 'name');
        this.selectedSavedFilter = this.nameFilter.find(
            (ele) => ele.isUserPreference
        ).name;
    }

    public setFilterObjects(obj: any, filterOption: any): DisplayFilters {
        const mapObject: DisplayFilters = new DisplayFilters();
        if (obj[0].allComplete) {
            mapObject.name =
                mapObject.toolTipStr = `All ${FILTER_NAME_LIST[filterOption]}`;
        } else {
            mapObject.name =
                obj[0].selectedOptionsCount > 1
                    ? `${obj[0].selectedOptionsCount} ${FILTER_NAME_LIST[filterOption]}`
                    : obj[0].singleSelectedOptionObject.name;
            mapObject.toolTipStr = obj[0].tooltipText;
        }
        mapObject.data = obj[0];

        return mapObject;
    }

    public setSelectedFilterOption(event: any, filterOption: any): void {
        if (filterOption === 'clients') {
            this.filterClient.name = this.filterClient.toolTipStr =
                event[event.findIndex((ele) => ele.isUserPreference)].name;
            this.filterClient.data = event;
        } else if (filterOption === 'projects') {
            this.filterProjects = this.setFilterObjects(event, filterOption);
        } else if (filterOption === 'types') {
            this.filterSolutionTypes = this.setFilterObjects(event, filterOption);
        } else if (filterOption === 'statuses') {
            this.filterStatuses = this.setFilterObjects(event, filterOption);
        } else if (filterOption === 'countries') {
            this.filterCountries = this.setFilterObjects(event, filterOption);
        } else if (filterOption === 'lookupsFilter') {
            this.filterLookups.name = this.filterLookups.toolTipStr =
                event[event.findIndex((ele) => ele.isUserPreference)].name;
            this.filterLookups.data = event;
        } else if (filterOption === 'name') {
            this.filterName.name = this.filterName.toolTipStr =
                event[event.findIndex((ele) => ele.isUserPreference)].name;
            this.filterName.data = event;
        }

        if (this.openFilter && filterOption === 'lookupsFilter') {
            this.isNotInitialSelection = true;
        }
        if (this.openFilter) {
            this.isNotInitialSelection = true;
        } else if (filterOption === 'lookupsFilter' && this.openFilter) {
            this.isNotInitialSelection = true;
        }
    }

    public onNewFilterChange(selectedFilter: DropDownModel): void {
        this.searchModel.action = Action.read;
        this.searchModel.filters = new FilterModel();
        this.searchModel.filters.names = [];
        this.searchModel.filters.names.push(selectedFilter);

        this.newSavedFilterName = selectedFilter.name;

        this.isLoading.emit(true);
        this.offset = 0;
        this.limit = 15;
        this.sortByApplied = false;

        this.channel.postMessage('newChanges');
        this.getFilteredData(this.searchModel);
    }

    public getFilterModel(): FilterModel {
        const filterData: FilterModel = new FilterModel();

        filterData.clientsFilter = this.filterClient.data.filter(
            (ele) => ele.isUserPreference
        );
        filterData.clientsFilter =
            filterData.clientsFilter[0].name !== 'All Clients'
                ? filterData.clientsFilter
                : [];

            filterData.countriesFilter = this.filterCountries.data.allComplete
            ? []
            : this.filterCountries.data.subtasks.filter(
                (ele) => ele.isUserPreference
            );

        filterData.solutionsFilter = this.filterSolutionTypes.data.allComplete
            ? []
            : this.filterSolutionTypes.data.subtasks.filter(
                (ele) => ele.isUserPreference
            );

        filterData.statusesFilter = this.filterStatuses.data.allComplete
            ? []
            : this.filterStatuses.data.subtasks.filter((ele) => ele.isUserPreference);

        filterData.lookupsFilter = this.filterLookups.data.allComplete
            ? []
            : this.filterLookups.data.filter((ele) => ele.isUserPreference);

        filterData.names = this.filterName.data.filter(
            (ele) => ele.isUserPreference
        );

        filterData.sortByFilter =
            this.fieldClick != null ? this.fieldClick.field : this.sortBy;

        filterData.isDesc =
            this.fieldClick != null
                ? this.fieldClick.type === 'DESC'
                    ? true
                    : false
                : this.isDesc;
        return filterData;
    }

    public setSortingDetails(): FilterModel {
        this.previousSelectedFilter.sortByFilter =
            this.fieldClick != null
                ? this.fieldClick.field
                : this.previousSelectedFilter.sortByFilter;

        this.previousSelectedFilter.isDesc =
            this.fieldClick != null
                ? this.fieldClick.type === 'DESC'
                    ? true
                    : false
                : this.previousSelectedFilter.isDesc;

        return this.previousSelectedFilter;
    }

    public applyFilters(apply: boolean = false): void {
        this.sortByApplied = false;

        if (apply) {
            this.selectedFilters = this.getFilterModel();
            /**
         * The below code change is a hack done in order to get the results for Analysis state as well
         * even though the user has just selected `Locked` status, this is because Non-nielsen users will
         * not see the `Analysis' status of a project and is masked as Locked for these users.
         * More info can be found in UF-831 bug.
         */
            if (!this.isAnalysisStatusVisible && this.selectedFilters) {
                const onlyLockSelected =
                    this.selectedFilters.statusesFilter.length === 1 &&
                    this.selectedFilters.statusesFilter[0].name === 'Locked';
                if (onlyLockSelected) {
                    this.selectedFilters.statusesFilter.push({
                        name: 'Analysis',
                        isUserPreference: true,
                        identifier: 'ANALYSIS',
                    });
                }
            }
            const originalSavedFilter = this.nameFilter.find(
                (ele) => ele.isUserPreference
            ).name;
            this.searchModel.filters = this.selectedFilters;

            if (
                !_.isEqual(this.selectedFilters, this.previousSelectedFilter) ||
                originalSavedFilter !== this.newSavedFilterName
            ) {
                if (this.newSavedFilterName.trim() !== '') {
                    this.selectedFilters.names[0].name = this.newSavedFilterName;
                    this.selectedFilters.names[0].identifier = this.newSavedFilterName;
                }
                this.isLoading.emit(true);
                this.offset = 0;
                this.limit = 15;
                this.resetOffset.emit(this.offset);
                this.searchModel.action =
                    this.userFilterAction === FilterOps.add
                        ? Action.create
                        : Action.update;
                this.getFilteredData(this.searchModel, 'saved');
                window.scrollTo(0, 0);
                this.channel.postMessage('newChanges');
            }
            this._analyticsService.track('Clicked APPLY Filters');
        } else {
            this.cancelFilter();
            this.filterNameError = '';
        }
    }

    public isFilterDirty(): void {
        this.selectedFilters = this.getFilterModel();
        if (
            this.filterDefault.clientsFilter.length !==
            this.selectedFilters.clientsFilter.length ||
            this.filterDefault.countriesFilter.length !==
            this.selectedFilters.countriesFilter.length ||
            this.filterDefault.solutionsFilter.length !==
            this.selectedFilters.solutionsFilter.length ||
            this.filterDefault.statusesFilter.length !==
            this.selectedFilters.statusesFilter.length ||
            this.filterDefault.lookupsFilter[0].name !==
            this.selectedFilters.lookupsFilter[0].name
        ) {
            this.filterDirty = true;
        } else {
            this.filterDirty = false;
        }
    }

    public actionAnalytics(action): void {
        this.sortByApplied = false;
        this._analyticsService.track('Clicked ' + action + ' Filters');
        this.searchModel.action = action;
    }

    public addFilters(): void {
        this.actionAnalytics(Action.create);
        this.setInitialFilterValues();
        this.userFilterAction = FilterOps.add;
        this.newSavedFilterName = this.selectedSavedFilter + ' (1)';
        this.newSavedFilterNameCopy = JSON.parse(
            JSON.stringify(this.newSavedFilterName)
        );
        this.setFilterName(this.newSavedFilterName);
    }

    public editFilters(): void {
        this.userFilterAction = FilterOps.edit;
        this.actionAnalytics(Action.update);
        this.newSavedFilterName = JSON.parse(
            JSON.stringify(this.selectedSavedFilter)
        );
        this.newSavedFilterNameCopy = JSON.parse(
            JSON.stringify(this.newSavedFilterName)
        );
    }

    public deleteFilters(): void {
        this.actionAnalytics(Action.delete);
        this.userFilterAction = FilterOps.delete;

        const filterIndex = this.filterName.data.findIndex(
            (ele) => ele.isUserPreference
        );

        let selectedFilter = new DropDownModel();
        selectedFilter = this.filterName.data[filterIndex];

        this.isLoading.emit(true);
        this.offset = 0;
        this.limit = 15;

        this.searchModel.filters = new FilterModel();
        this.searchModel.filters.names = [selectedFilter];
        this.getFilteredData(this.searchModel, 'deleted');
        this.channel.postMessage('deletedFilter');
    }

    public resetFilter(): void {
        this.actionAnalytics(Action.reset);
        this.isLoading.emit(true);
        this.offset = 0;
        this.limit = 15;

        this.searchModel.filters = new FilterModel();
        this.searchModel.filters.names = this.filterName.data.filter(
            (ele) => ele.isUserPreference
        );

        this.getFilteredData(this.searchModel, 'reset');
        this.channel.postMessage('resetFilters');
        window.scrollTo(0, 0);
    }

    public trackFilterDropDownAction(filterOption: string): void {
        this._analyticsService.track(`Selected ${filterOption} Filter`);
    }

    public setFilterName(obj: any): void {
        const value = obj.trim();
        this.newSavedFilterName = value;

        if (!value) {
            this.filterNameError = 'Name is empty';
        } else if (value.length > 25) {
            this.filterNameError = 'Name has over 25 Characters';
        } else {
            this.filterNameError = '';
        }

        const duplicateName = this.nameFilter.find(
            (ele) => ele.name.toUpperCase() === this.newSavedFilterName.toUpperCase()
        );
        if (duplicateName) {
            if (
                (this.userFilterAction === FilterOps.edit &&
                    !duplicateName.isUserPreference) ||
                this.userFilterAction === FilterOps.add
            ) {
                this.filterNameError = 'View name already exist!';
                return;
            }
        }
    }

    public cancelFilter(): void {
        this.clients = this.clientsCopy;
        this.statuses = this.statusesCopy;
        this.countries = this.countriesCopy;
        this.solutionTypes = this.solutionTypesCopy;
        this.lookupsFilter = this.lookupsFilterCopy;
        this.nameFilter = this.nameFilterCopy;
        this.setInitialFilterValues();
    }

    @HostListener('document:keydown', ['$event'])
    public closeFilter(kbEvnt: KeyboardEvent): void {
        if (kbEvnt.key === 'Escape' && this.menuClosed) {
            this.cancelFilter();
            this.openFilter = false;
        }
    }
}
