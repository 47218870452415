import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SolutionModel } from '../../models/solution/solution-model';

@Component({
    selector: 'niq-project-info-modal',
    templateUrl: './project-info-modal.component.html',
    styleUrls: ['./project-info-modal.component.scss'],
})
export class ProjectInfoModalComponent implements OnInit {
    public  slides = [];
    /**
   * Constructor.
   *
   * @param    MatDialogRef
   * @param    MAT_DIALOG_DATA
   * @memberof ProjectInfoModalComponent
   */
    constructor(
    public dialogRef: MatDialogRef<ProjectInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SolutionModel
    ) {}

    /**
   * Initiliazes the Component.
   *
   * @returns  {void}
   * @memberof ProjectInfoModalComponent
   */
    public ngOnInit(): void {
        this.slides = this.data.slides;
    }
}
