import { AppConfigService } from './app-config.service';

/**
 * Initializes AppConfigService.
 *
 * @param appConfigService
 */
export function configFactory(appConfigService: AppConfigService): any {
    return () => appConfigService.load();
}
