import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { concatMap, Observable, take } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { addImageBlob } from '../../store/actions/image.actions';
import { selectImageBlob } from '../../store/selectors/image.selectors';

@Injectable({
    providedIn: 'root',
})
export class SolutionImagesService {
    /**
     * String to hold the url.
     *
     * @type     {string}
     * @memberof SolutionImagesService
     */
    private _url: string;

    constructor(
        private _http: HttpClient,
        private _cs: AppConfigService,
        private _store: Store
    ) {
        const resourceUri = '/api/v1/solutionImages';
        this._url = `${this._cs?.config?.projectservice.url}${resourceUri}`;
    }

    /**
     * Returns solution image.
     * @param imageName of the image
     * @returns httpresponse
     * @memberof SolutionImagesService
     */
    public get(imageName: string, type: string): Observable<Blob> {
        let imageName$: Observable<any> = this._fetchImageFromStore();
        imageName$.pipe(take(1)).subscribe((data) => {
            if (!data || !data[imageName]) {
                imageName$ = this._fetchImageFromAPI(imageName, type);
            }
        });
        return imageName$;
    }

    private _fetchImageFromAPI(imageName: string, type: string): Observable<Blob> {
        return this._http
            .get(`${this._url}`, {
                params: { imageName, type },
                responseType: 'blob',
            })
            .pipe(
                concatMap((result) => {
                    this.loadImageOnStore(imageName, result);
                    return this._fetchImageFromStore();
                })
            );
    }

    public loadImageOnStore(imageName: string, result: Blob): void {
        const imgBlobStr = result;
        this._store.dispatch(
            addImageBlob({ imageBlob: { [imageName]: imgBlobStr } })
        );
    }

    private _fetchImageFromStore(): Observable<any> {
        return this._store.pipe(select(selectImageBlob)).pipe();
    }
}
