import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { Title } from '@angular/platform-browser';
@Component({
    selector: 'niq-panel-home',
    templateUrl: './panel-home.component.html',
    styleUrls: ['./panel-home.component.scss'],
})
export class PanelHomeComponent {
    /**
   * Constructor.
   *
   * @param    MatDialog
   * @memberof HomeComponent
   */
    constructor(
        public dialog: MatDialog,
        private _analyticsService: AnalyticsService,
        private _titleService: Title
    ) {
        this._titleService.setTitle("NIQ Studio - Projects");
    }

    public trackNavigationOptions(): void {
        this._analyticsService.trackDisplay(true, 'Navigation Options');
    }

    public navigateToHome($event): void {
        this._analyticsService.trackButtonClick($event);
    }
}