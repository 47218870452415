import { Component } from '@angular/core';
import { AppConfigService } from './core/config/app-config.service';
import { OnLoadService } from './platform/services/on-load.service';
import { UserInfoService } from './platform/services/user/user-info.service';

@Component({
    selector: 'niq-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    public title = 'project-ui';

    // for user details and brand-bar functionality
    public userInfo: any;
    public navigationServiceURL: string;
    public logoutServiceURL: string;

    constructor(
    private _onLoadService: OnLoadService,
    private _configService: AppConfigService,
    private _userInfoService: UserInfoService
    ) {
        localStorage.removeItem('gridView');
        this._userInfoService.get().subscribe((data: any) => {
            this.userInfo = data;
        });
        this.logoutServiceURL = `${this._configService.config?.authProxy.url}/logout`;
        this.navigationServiceURL = `${this._configService.config?.studioProxy.url}/platformservices/userNavigation`;

        this._onLoadService.initialData();
    }
}
