import { Action, createReducer, on } from '@ngrx/store';
import { addLocales } from '../actions/locales.actions';
import { LocalesState } from '../states/localesState.state';

/**
 * Reducer for all locales actions.
 */
const localesReducer = createReducer(
    null,
    on(addLocales, (state, { locales }) => {
        const newLocales = [...locales];
        return newLocales;
    })
);

/**
 * Report `NgRx` reducer.
 *
 * @export
 * @param {UserInfo} state
 * @param {UserInfoActions} action
 * @returns {UserInfo}
 */
export function reducer(
    state: LocalesState | undefined,
    action: Action
): LocalesState {
    return localesReducer(state, action);
}
