<niq-spinner [display]="spinner"></niq-spinner>
<h2 mat-dialog-title>
  {{'project.delete_project.text' | translate}}
  <span mat-button class="close-icon" [mat-dialog-close]="true">
    <span class="sif sif-close"></span>
  </span>
</h2>
<mat-dialog-content>
  <p>{{'project.delete_project.header' | translate}}</p>
</mat-dialog-content>
<mat-dialog-actions class="align-items-right" (keydown)="$event.key === 'Escape' ? $event.stopPropagation() ? '' : dialogRef.close(): ''">
  <button class="mat-secondary" mat-button mat-dialog-close>Cancel</button>
  <button class="mat-flat-button mat-primary" mat-raised-button color="primary"
    cdkFocusInitial (click)="deleteProject()">Delete</button>
</mat-dialog-actions>
