import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as platformConstants from '../constant';
import { FieldingModel } from '../models/fielding/fielding-model';
import { FieldingDataService } from '../services/fieldings/fielding-data.service';
import { MessageBarService } from '../services/mesasgeBar/message-bar.service';

@Component({
    selector: 'niq-fieldings',
    templateUrl: './fieldings.component.html',
    styleUrls: ['./fieldings.component.scss'],
})
export class FieldingsComponent implements OnInit, OnDestroy {

    @Input() public link: string;

    public fetchFielding: boolean = false;
    public fieldingData: Array<FieldingModel> = [];
    public isfieldingError: boolean = false;
    public sub: Subscription;

    /**
     * fielding chart/doughnuts color constants.
     *
     * @type      {Object}
     * @memberof  FieldingsComponent
     */
    public fieldingColorConst: any = platformConstants.FIELDING_SPINNER_COLOR;

    /**
     * doughnut chart base color.
     *
     * @type      {string}
     * @memberof  FieldingsComponent
     */
    public donutChartBaseColor: string;

    /**
     * doughnut chart color.
     *
     * @type      {string}
     * @memberof  FieldingsComponent
     */
    public donutChartColor: string;

    /**
     * formatted value of the incidence.
     *
     * @type      {number}
     * @memberof  FieldingsComponent
     */
    public formattedIncidence: number;

    /**
     * Empty state constant messages holder.
     *
     * @type      {any}
     * @memberof  FieldingsComponent
     */
    public emptyConst: any = platformConstants.EMPTY_STATES.fieldings;

    /**
     * Error state constant messages holder.
     *
     * @type      {any}
     * @memberof  FieldingsComponent
     */
    public fieldingErrorConst: any = platformConstants.ERROR_STATES.fieldings;

    /**
     * Constructor.
     *
     */
    constructor(
        private _fieldingDataService: FieldingDataService,
        private _messageBar: MessageBarService
    ) { }

    /**
     * @method get()
     * @returns {object}
     *
     * setting the style - color, of mat-spinner (pie chart of incidence).
     */
    public get incidenceColor(): object {
        return {
            color: this.fieldingColorConst.published.incidence,
        };
    }

    /**
     * @method get()
     * @returns {object}
     *
     * setting the style - color, of mat-spinner (pie chart of incidence).
     */
    public get incidenceColorComplete(): object {
        return {
            color: this.fieldingColorConst.completed.incidence,
        };
    }
    /**
     * Defining and initializing the objects
     *
     * @OnInit - angular Lifecyclehook
     */
    public ngOnInit(): void {
        this.getFielding();
    }

    public getFielding(): void {
        this.fetchFielding = true;

        this.sub = this._fieldingDataService.get(this.link).subscribe(
            (data: any) => {
                this.fetchFielding = false;
                if (data && data.length > 0) {
                    data.forEach((fieldingObj: any) =>
                        this.fieldingData.push(fieldingObj)
                    );
                }
            },
            () => {
                this.fetchFielding = false;
                this.isfieldingError = true;
                this._messageBar.openSnackBar(
                    platformConstants.SNACK_BAR_TYPE_ERROR,
                    'There was an error loading fielding data for the project.'
                );
            }
        );
    }

    /**
     * @method setSpinnerColor()
     * @param  {FieldingModel}
     *
     * based on the fielding details, setting the colors of the doughnuts chart
     */
    public setChartColor(obj: FieldingModel): void {
        const status =
            obj.status.toLowerCase() !== platformConstants.FIELDING_STATUS_COMPLETED
                ? platformConstants.FIELDING_STATUS_PUBLISHED
                : platformConstants.FIELDING_STATUS_COMPLETED;
        const incidence =
            obj.starts !== 0 ? (obj.completes / obj.starts) * 100 : 0.0;
        this.formattedIncidence = Math.round(incidence * 10) / 10;
        obj.completePercentage = (obj.completes / obj.targetCompletes) * 100;
        obj.completePercentage =
            obj.completePercentage > 100
                ? obj.completePercentage - 100
                : obj.completePercentage;

        if (obj.completes > obj.targetCompletes) {
            this.donutChartBaseColor = this.fieldingColorConst[status].total;
            this.donutChartColor = this.fieldingColorConst[status].more;
        } else {
            this.donutChartBaseColor = this.donutChartColor =
                this.fieldingColorConst[status].total;
        }
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
