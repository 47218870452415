<section class="section-container">
  <span class="list-section">
    <ul>
      <li *ngFor="let item of list">
        <div class="break-line">
          <mat-checkbox [class]="item.disabled ? 'mat-checkbox-border-disabled' : ''" [disabled]="item.disabled"
            [(ngModel)]="item.allComplete" [indeterminate]="partialComplete(item)" [checked]="item.selected"
            (change)="setAll($event.checked, item); selectedList('main', item)" #parent>
            {{item[parentDisplayKey]}} {{ item[additionalInfo] ? "(" + item[additionalInfo] + ")" : ''}}
          </mat-checkbox>
        </div>
        <span class="sub-list-section"
          [hidden]="!(item.allComplete || partialComplete(item)) && (expandable ? !(item.selectedOptionsCount > 0)  : false)">
          <ul>
            <li *ngFor="let subItem of item[childKey]" class="break-line">
              <mat-checkbox [(ngModel)]="subItem.selected"
                (ngModelChange)="updateAllComplete(item); selectedList('sub', item, subItem)"
                [disabled]="subItem.isDisableDefault" [class]="subItem.isDisableDefault ? 'mat-checkbox-border-disabled' : ''">
                {{subItem.isRecommended ? subItem[childDisplayKey] + ' *'  : subItem[childDisplayKey]}}
              </mat-checkbox>
              <div class="extra-details" *ngIf="subItem[additionalInfo]">
                <span></span>
                <label class="mat-checkbox-layout"><span>({{ subItem[additionalInfo]}})</span></label>
              </div>
            </li>
          </ul>
        </span>
      </li>
    </ul>
  </span>
</section>
