import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../../core/config/app-config.service';
import { RequestAccessSearchModel } from '../../models/request-access-filter/request-access-filter-model';

@Injectable({
  providedIn: 'root'
})
export class RequestProjectService {
    private _url: string;

  constructor(private http: HttpClient, private _cs: AppConfigService) {
    const resourceUri = '/api/v1/requestAccess';
    this._url = `${this._cs?.config?.projectservice.url}${resourceUri}`;
  }

  public getProjects(
    searchObj: RequestAccessSearchModel, limit: number, offset: number): Observable<RequestAccessSearchModel> {
    return this.http.post<RequestAccessSearchModel>(`${this._url+'/search'}?limit=${limit}&offset=${offset}`,
        searchObj
  );
}

  public fetchConceptData(conceptUrl: string): Observable<any> {
    return this.http.get(`${this._cs?.config?.projectservice.url}${conceptUrl}`);
  }

  public requestAccess(selectedItems: any[]): Observable<any> {
    // Extract the projectIds for the ones which are selected
    const projectIds = selectedItems.map(item => parseInt(item.referenceId, 10));

    // Request payload with the updated data for PATCH
    const updatedData = {
      projectIds: projectIds
    };

    return this.http.patch(this._url, updatedData);
  }

  public getPendingRequestData(): Observable<any> {
    return this.http.get<any>(`${this._url}/pendingRequest`, {
        observe: 'response',
    });
  }

  //same as getPendingRequestData
  public getApprovalRequestData(): Observable<any> {
    return this.http.get<any>(`${this._url}/approvalRequest`, {
        observe: 'response',
    });
  }

  public approveRequest(selectedItems: any[]): Observable<any> {
    
    const updatedData = [];
    selectedItems.forEach(item => updatedData.push({
      projectId: item.referenceId,
      requestedByUserId: item.requestedByUserId,
      requestedByUser : item.requestedByUser
    }))
     
    return this.http.post(this._url, updatedData);
  }

  // Updating the logged in projectUser's dismissalDate 
  public updateProjectUser() {
    return this.http.patch<any>(`${this._url}/updateProjectUsers`, null);
  }

   //To get the current logged in projectUser with dismissalDateTime
   public getProjectUser(): Observable<any> {
    return this.http.get<any>(`${this._cs?.config?.projectservice.url}/api/v1/projectUsers/projectUser`, {
        observe: 'response',
    });
  }
}
