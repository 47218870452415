import { Injectable } from '@angular/core';
import { UserInfo } from '../models/user/user-info';
import { LocaleService } from './locales/locale.service';
import { PermissionService } from './permissions/permission.service';
import { SolutionService } from './solutions/solution.service';
import { UserInfoService } from './user/user-info.service';

@Injectable({
    providedIn: 'root',
})
export class OnLoadService {
    private _userInfo: UserInfo;
    private _permissions: Array<string>;
    constructor(
        private _userInfoService: UserInfoService,
        private _globalPermissionService: PermissionService,
        private _solutionService: SolutionService,
        private _localeService: LocaleService
    ) { }

    public initialData(): void {
        this._userInfoService.get().subscribe((data: any) => {
            this._userInfo = data;
        });

        this._globalPermissionService.get().subscribe((data: any) => {
            this._permissions = data.permissions;
            if (this._permissions.indexOf('READ_SOLUTIONS') > -1) {
                this._solutionService.get(false).subscribe();
            }

            if (this._permissions.indexOf('READ_LOCALES') > -1) {
                this._localeService.get('countryKey').subscribe();
            }
        });
    }
}
