<div class="solution-type-container bg-color">
  <div class="imaginarytoolbar">
   <div class="info-container">
     <p class="info-text" translate>{{ 'project.create_project.approach' }}</p>
     <div class="info-right">
       <p translate>{{ 'project.create_project.cant_decide' }} <label><a target="_blank" [href]="getHelpUrl()"
             translate>{{ 'project.create_project.help' }}</a></label>
       </p>
       <button class="cards-button" mat-icon-button [class]="gridView ? '' : 'active'" (click)="saveCardView(false)" matTooltip="{{'project.create_project.small_view' | translate}}">
         <span mat-icon class="sif sif-grid"></span>
       </button>
       <button class="cards-button" mat-icon-button [class]="gridView ? 'active' : ''" (click)="saveCardView(true)" matTooltip="{{'project.create_project.large_view' | translate}}">
         <span mat-icon class="sif sif-big-card"></span>
       </button>
       <div class="separator"></div>
       <button class="mat-button" mat-icon-button aria-label="Info" (click)="close()">
         <span class="sif sif-close"></span>
       </button>
     </div>
   </div>
   </div> 
   <div class="solution-type-list" *ngIf="!gridView">
     <div class="solution-type-item" *ngFor="let solution of solutions">
       <mat-card class="mat-card" [class]="solution.name.replaceAll(' ','-') | lowercase"
         (click)="cardClickHandler(solution)">
         <div class="card-content">
           <img src="assets/images/card-img/{{solution.name.replaceAll(' ', '')}}.svg" />
           <h4>{{ solution.name }}</h4>
           <!-- <div class="get-started-cont">
             <button class="mat-button" mat-icon-button aria-label="Info"
               (click)="openCarousalDialog(solution); $event.stopPropagation()" [hidden]="!solution.slides.length">
               <span class="sif sif-info"></span>
             </button>
           </div> -->
         </div>
       </mat-card>
     </div>
   </div>
   <div class="card-view-container" *ngIf="gridView">
     <div class="slide-btn mat-icon-button left-btn" (click)="scrollLeft()">
       <span class="sif sif-chevron-w" [style.color]="disableSlideBtn === 'left' ? '#234794' : ''" [class.boundary]="disableSlideBtn === 'left'"></span>
     </div>
 
     <div class="slide-container" #widgetsContent>
       <div class="card-view" *ngFor="let solution of solutions; let i = index">
         <mat-card class="card-details" [ngClass]="{'flip': mousein && i === hoverIndex }"
           [class]="solution.name.replaceAll(' ','-') | lowercase" (mouseover)="mousein = true; hoverIndex = i"
           (mouseleave)="mousein = false; showBenefitIcon = false">
           <div class="card-content" *ngIf="!mousein || i !== hoverIndex">
             <h4>{{ solution.name }}</h4>
             <img src="assets/images/card-img/{{solution.name.replaceAll(' ', '')}}.svg" />
           </div>
           <div class="card-flip-content" *ngIf="mousein && i === hoverIndex">
             <div class="header">
               <h4>Benefits</h4>
               <div>
                 <button class="cardflipicons" mat-icon-button (click)="openCarousalDialog(solution); $event.stopPropagation()"
                   [hidden]="!solution.slides.length" matTooltip="{{'project.tooltip.benefitsslideshow' | translate}}">
                   <span class="sif sif-column-view" style="color: grey;"></span>
                 </button>
                 <button class="cardflipicons" mat-icon-button
                   (click)="getImage(solution.benefits[0].icon, 0, i); showBenefitIcon = !showBenefitIcon" matTooltip="{{'project.tooltip.benefitsimage' | translate}}">
                   <span class="sif sif-results" style="color: grey;"></span>
                 </button>
               </div>
             </div>
             <div class="body">
               <div *ngIf="!showBenefitIcon">
                 <h4>{{ solution.benefits[0].title }}</h4>
                 <p>{{ solution.benefits[0].description }}</p>
               </div>
               <div class="benefit-img-container" *ngIf="showBenefitIcon">
                 <div class="img-container">
                   <mat-progress-spinner class="loading img-responsive" [mode]="'indeterminate'" diameter="50"
                     *ngIf="spinner">
                   </mat-progress-spinner>
                   <img class="img-responsive" [src]="svgImages[solution.benefits[0].icon]" *ngIf="!spinner" />
                 </div>
               </div>
             </div>
             <div class="footer">
               <button mat-button (click)="cardClickHandler(solution)">create</button>
             </div>
           </div>
         </mat-card>
       </div>
     </div>
 
     <div class="slide-btn mat-icon-button right-btn" (click)="scrollRight()">
       <span class="sif sif-chevron-e" [style.color]="disableSlideBtn === 'right' ? '#234794' : ''" [class.boundary]="disableSlideBtn === 'right'"></span>
     </div>
   </div>
 </div>
 