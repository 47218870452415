<div class="empty-page-container-large flex align-items-center" *ngIf="pageSize === 'large'">
  <div class="info-block">
    <div class="circle">
      <span class="sif sif-{{ icon }}"></span>
    </div>

    <div class="lead-text">
      <h3>{{ leadText | uppercase }}</h3>
    </div>

    <div class="support-text">
      <p>{{ supportText }}</p>
      <p *ngIf="editText">Click '<span class="sif sif-edit"></span>' to get started.</p>
    </div>
  </div>
</div>
<div class="empty-page-container-small flex align-items-center"  *ngIf="pageSize === 'small'">
  <div class="circle" [class]="type === 'error' ? 'error' : ''">
    <span class="sif sif-{{ icon }}"></span>
  </div>
  <div class="info-block">
    <div class="lead-text" [class]="type === 'error' ? 'error' : ''">
      <h3>{{ leadText | uppercase }}</h3>
    </div>

    <div class="support-text">
      <p>{{ supportText }}</p>
      <p *ngIf="editText">Click '<span class="sif sif-edit"></span>' to get started.</p>
    </div>
  </div>
</div>
