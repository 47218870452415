// angular modules
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

// project dependencies
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MaterialModule } from './material.module';

// project defined components and modules
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StoreModule } from '@ngrx/store';
import { CoreModule } from '../core/core.module';
import { CarouselComponent } from '../platform/carousel/carousel.component';
import { BenefitIconsModalComponent } from '../platform/modals/benefit-icons-modal/benefit-icons-modal.component';
import { ConfirmationModalComponent } from '../platform/modals/confirmation-modal/confirmation-modal.component';
import { ProjectInfoModalComponent } from '../platform/modals/project-info-modal/project-info-modal.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { BlukShareHomeComponent } from './bluk-share-home/bluk-share-home.component';
import { CheckboxTreeComponent } from './common-components/checkbox-tree/checkbox-tree.component';
import { DetailedMessageBarComponent } from './common-components/detailed-message-bar/detailed-message-bar.component';
import { DropDownComponent } from './common-components/drop-down/drop-down.component';
import { EmptyPageComponent } from './common-components/empty-page/empty-page.component';
import { InputBoxComponent } from './common-components/input-box/input-box.component';
import { MessageBarComponent } from './common-components/message-bar/message-bar.component';
import { MultilineInputBoxComponent } from './common-components/multiline-input-box/multiline-input-box.component';
import { SearchInputComponent } from './common-components/search-input/search-input.component';
import { SpinnerSmallComponent } from './common-components/spinner-small/spinner-small.component';
import { ConceptsComponent } from './concepts/concepts.component';
import { RequestProjectAccessComponent } from './request-project-access/request-project-access.component';
import { DeliveredBadgeComponent } from './custom-components/delivered-badge/delivered-badge.component';
import { DonutChartComponent } from './custom-components/donut-chart/donut-chart.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { EditSolutionComponent } from './edit-solution/edit-solution.component';
import { FieldingsComponent } from './fieldings/fieldings.component';
import { HomeComponent } from './home/home.component';
import { ListSolutionsComponent } from './list-solutions/list-solutions.component';
import { DeleteProjectComponent } from './modals/delete-project/delete-project.component';
import { DuplicateProjectComponent } from './modals/duplicate-project/duplicate-project.component';
import { LanguageModalComponent } from './modals/language-modal/language-modal.component';
import { RenameProjectComponent } from './modals/rename-project/rename-project.component';
import { PanelHomeComponent } from './panel-home/panel-home.component';
import { PanelProjectDetailsComponent } from './panel-project-details/panel-project-details.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectSetupComponent } from './project-setup/project-setup.component';
import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { SkuListComponent } from './sku-list/sku-list.component';
import { SolutionBenefitsComponent } from './solution-benefits/solution-benefits.component';
import { SolutionTypeComponent } from './solution-type/solution-type.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { metaReducers, reducers } from './store/reducers';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RequestAccessSearchFiltersComponent } from './request-access-search-filters/request-access-search-filters.component';
import { DesignOptionsListComponent } from './design-options-list/design-options-list.component';
import { SRBootstarpPortalComponent } from './srbootstarp-portal/srbootstarp-portal.component';

@NgModule({
    declarations: [
        HomeComponent,
        PanelHomeComponent,
        DonutChartComponent,
        ProjectDetailsComponent,
        PanelProjectDetailsComponent,
        DeliveredBadgeComponent,
        ConceptsComponent,
        RequestProjectAccessComponent,
        FieldingsComponent,
        ProjectSetupComponent,
        SolutionBenefitsComponent,
        SolutionTypeComponent,
        OnlyNumberDirective,
        DuplicateProjectComponent,
        BenefitIconsModalComponent,
        ConfirmationModalComponent,
        ProjectInfoModalComponent,
        InputBoxComponent,
        CheckboxTreeComponent,
        MessageBarComponent,
        RenameProjectComponent,
        DeleteProjectComponent,
        LanguageModalComponent,
        CarouselComponent,
        SearchFiltersComponent,
        DropDownComponent,
        SkuListComponent,
        EmptyPageComponent,
        SpinnerSmallComponent,
        SearchInputComponent,
        SolutionsComponent,
        MultilineInputBoxComponent,
        DetailedMessageBarComponent,
        EditSolutionComponent,
        ListSolutionsComponent,
        BlukShareHomeComponent,
        RequestAccessSearchFiltersComponent,
        DesignOptionsListComponent,
        SRBootstarpPortalComponent,
    ],
    
    imports: [
        CommonModule,
        RouterModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        MaterialModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule.forRoot(),
        TranslateModule,
        InfiniteScrollModule,
        SpinnerModule,
        DragDropModule,
        ScrollingModule,
        MatTabsModule,
    ],
    providers: [],
})
export class PlatformModule {}
