<h2 mat-dialog-title>
  {{header}}
  <span mat-button class="close-icon" [mat-dialog-close]="true">
    <span class="sif sif-close"></span>
  </span>

</h2>
<mat-dialog-content (keydown)="$event.key === 'Escape' ? $event.stopPropagation() ? '' : dialogRef.close(): ''">
  <div class="language-container">
    <span class="error"
      *ngIf="this.maxLocales && this.selectedCountries.length && this.disableSave">{{'project.select_languages.maxlimit_languages'
      | translate: {limit: this.maxLocales} }}</span>

    <div>
      <label class="grey-font">{{body.fielding_countries}}</label>

      <div class="locale-search-box">
        <niq-search-input (typeAheadText)="filterData($event)" [appendPlaceholder]="'countries'" #searchText>
        </niq-search-input>
        <span class="info" *ngIf="localeObjects.length === 0"> {{ 'project.select_languages.no_search_info' | translate
          }} </span>
      </div>

      <niq-checkbox-tree [list]="localeObjects" [parentDisplayKey]="'name'" [expandable]="true" [childKey]="'languages'"
        [childDisplayKey]="'name'" [additionalInfo]="'code'" (onlySelectedList)="selectLocales($event)"
        [selectedArrayObj]="selectedCountries">
      </niq-checkbox-tree>
    </div>

    <p class="note">{{'project.select_languages.extra_info' | translate}}</p>
    <div class="primary-language-container">
      <label class="grey-font-primary"><span class="sif sif-language-primary"></span>{{body.primary_language}}</label>
      <mat-select [(value)]="primaryLocale" (selectionChange)="changePrimaryLocale($event)">
        <mat-option [value]="locale" *ngFor="let locale of primaryLocaleOptions"> {{ getLanguage(locale)}} </mat-option>
      </mat-select>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="align-items-right">
  <button class="mat-flat-button cancel-btn" mat-button mat-dialog-close (click)="close()">{{'project.generic.cancel' |
    translate
    }}</button>
  <button class="mat-flat-button submit-btn" mat-raised-button (click)="save()"
    [disabled]="disableSave">{{footer}}</button>
</mat-dialog-actions>
