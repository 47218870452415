<niq-spinner-small *ngIf="fetchFielding"></niq-spinner-small>
<niq-empty-page [icon]="isfieldingError ? fieldingErrorConst.icon : emptyConst.icon"
  [leadText]="isfieldingError ? fieldingErrorConst.lead_text : emptyConst.lead_text"
  [supportText]="isfieldingError ? fieldingErrorConst.support_text : emptyConst.support_text" [pageSize]="'small'"
  *ngIf="!fetchFielding && (fieldingData.length === 0 || isfieldingError)" [type]="isfieldingError ? 'error' : ''">
</niq-empty-page>
<div class="fielding-heading" *ngIf="fieldingData.length > 0">
  <span class="sif sif-audience"></span>
  <h5 translate>{{ "project.table_content.fieldings.name" }}</h5>
</div>
<div class="fielding-body" *ngIf="fieldingData.length > 0">
  <div class="fielding-details" *ngFor="let obj of fieldingData">
    <p class="fielding-details-label">
      {{ "project.table_content.fieldings.survey_name" | translate }}:
      {{ obj.surveyName }}
    </p>
    <div class="fielding-result">
      <div class="spinner-chart">
        <span>{{ setChartColor(obj) }}</span>
        <niq-donut-chart class="fielding-spinner" [diameter]="150" [mode]="'determinate'"
          [value]="obj.completePercentage" [baseProgressSpinnerColor]="donutChartBaseColor"
          [progressSpinnerColor]="donutChartColor" [strokeWidth]="25"
          [noOpacity]="obj.completes > obj.targetCompletes ? true : false">
        </niq-donut-chart>
        <div class="fielding-spinner">
          <mat-progress-spinner diameter="72" mode="determinate" [value]="formattedIncidence" strokeWidth="42.7"
            [ngStyle]="
            obj.status.toLowerCase() !== 'completed'
              ? incidenceColor
              : incidenceColorComplete
          ">
          </mat-progress-spinner>
        </div>
      </div>
      <div class="results-font-style" [class]="obj.status.toLowerCase() !== 'completed' ? '' : 'complete'">
        <p [class]="
            obj.completes > obj.targetCompletes
              ? 'fielding-total-exceed'
              : 'fielding-total-less'
          ">
          <span [class]="
              obj.completes > obj.targetCompletes
                ? 'fielding-completed-more'
                : 'fielding-completed-less'
            ">
            {{ obj.completes }}
          </span>
          / {{ obj.targetCompletes }}
          {{ "project.table_content.fieldings.completes" | translate }}
        </p>
        <p [class]="
            obj.status.toLowerCase() !== 'completed'
              ? 'incidence'
              : 'complete-incidence'
          ">
          {{ formattedIncidence }} %
          {{ "project.table_content.fieldings.conversion" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
