import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './platform/home/home.component';
import { SolutionsComponent } from './platform/solutions/solutions.component';
import { EditSolutionComponent } from './platform/edit-solution/edit-solution.component';
import { ListSolutionsComponent } from './platform/list-solutions/list-solutions.component';
import { PanelHomeComponent } from './platform/panel-home/panel-home.component';
import { RequestProjectAccessComponent } from './platform/request-project-access/request-project-access.component';
import { AuthGuardService } from './platform/guards/auth.guard';
import {
    AccessForbiddenComponent,
    PageNotFoundComponent,
} from '@basesstudio/ngx-components';
import { BlukShareHomeComponent } from './platform/bluk-share-home/bluk-share-home.component';
import { SRBootstarpPortalComponent } from '@platform/srbootstarp-portal/srbootstarp-portal.component';

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: [
                'ROLE_SYSTEM_ADMINISTRATOR',
                'ROLE_Limited_Access',
                'ROLE_Subscriber',
                'ROLE_Project_Lead',
                'ROLE_Trial',
                'ROLE_API_USER',
                'ROLE_Nielsen',
                'ROLE_Nielsen+Analytics',
                'ROLE_Nielsen+Finance',
                'ROLE_CONCEPT_ARCHIVE',
            ],
        },
    },
    {
        path: 'solutions',
        component: SolutionsComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: ['ROLE_SYSTEM_ADMINISTRATOR'],
        },
        children: [
            {
                path: '',
                component: ListSolutionsComponent,
            },
            {
                path: ':id',
                component: EditSolutionComponent,
            },
        ],
    },
    {
        path: 'panelHome',
        component: PanelHomeComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: ['ROLE_SYSTEM_ADMINISTRATOR', 'ROLE_Panel'],
        },
    },
    {
        path: 'bulkshare',
        component: BlukShareHomeComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: ['ROLE_SYSTEM_ADMINISTRATOR'],
        },
    },
    {
        path: 'request',
        component: RequestProjectAccessComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: [
                'ROLE_SYSTEM_ADMINISTRATOR',
                'ROLE_Limited_Access',
                'ROLE_Project_Lead',
                'ROLE_Trial',
                'ROLE_API_USER',
                'ROLE_Nielsen',
                'ROLE_Nielsen+Analytics',
                'ROLE_Nielsen+Finance',
                'ROLE_CONCEPT_ARCHIVE',
            ],
        },
    },
    {
        path: 'srbootstrap',
        component: SRBootstarpPortalComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: [
                'ROLE_SYSTEM_ADMINISTRATOR',
                'ROLE_Limited_Access',
                'ROLE_Project_Lead',
                'ROLE_Trial',
                'ROLE_API_USER',
                'ROLE_Nielsen',
                'ROLE_Nielsen+Analytics',
                'ROLE_Nielsen+Finance',
                'ROLE_CONCEPT_ARCHIVE',
            ],
        },
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    { path: 'forbidden', component: AccessForbiddenComponent },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash : true })],
    exports: [RouterModule],
    providers: [AuthGuardService],
})
export class AppRoutingModule {}
