import { ProjectModel } from '../project/project-model';

export class SearchModel {
    public meta: any;
    public filters: FilterModel;
    public searchPhrase: string;
    public action: Action;
    public data: ProjectModel[];

    constructor() {
        this.filters = null;
        this.searchPhrase = '';
        this.data = [];
    }
}

export enum Action {
  read = 'read',
  create = 'create',
  update = 'update',
  delete = 'delete',
  reset = 'reset',
}

export enum FilterOps {
  add = 'add',
  delete = 'delete',
  edit = 'edit',
}

export class DropDownModel {
    public name: string;
    public identifier: string;
    public isUserPreference: boolean;
}

export class FilterModel {
    public clientsFilter: DropDownModel[];
    public solutionsFilter: DropDownModel[];
    public statusesFilter: DropDownModel[];
    public countriesFilter: DropDownModel[];
    public lookupsFilter: DropDownModel[];
    public names: DropDownModel[];
    public sortByFilter: any;
    public isDesc: boolean;
}
