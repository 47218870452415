<div class="message-summary">
  <span>
    {{ summary }}
  </span>
  <a class="show-hide-btn" aria-expanded="false" (click)="showDetails = !showDetails">
      <span class="span-hide-show" *ngIf="showDetails" (click)="collapseButton()">
          {{"project.generic.hideDetails" | translate}}
      </span>
    <span class="span-hide-show" *ngIf="!showDetails" (click)="expandButton()">
          {{"project.generic.showDetails" | translate}}
      </span>
  </a>
  <div class="dismiss">
    <button mat-icon-button (click)="snackBarRef.dismiss()">
      <span class="sif sif-close"></span>
    </button>
  </div>
</div>
<div class="message-details collapsible" [@collapse]="buttonCollapsed">
  <ul class="alert-list message-details" *ngIf="showDetails">
    <li *ngFor="let msg of messages">{{ msg }}</li>
  </ul>
</div>
