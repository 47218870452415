import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import * as platformConstants from '../../constant';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
    name: string;
    code?: string;
    selected: boolean;
    languages?: Locale[];
}
@Component({
    selector: 'niq-language-modal',
    templateUrl: './language-modal.component.html',
    styleUrls: ['./language-modal.component.scss'],
})
export class LanguageModalComponent implements OnInit {
    /**
     * List of countries and languages to be populated.
     *
     * @type     {Array<any>}
     * @memberof LanguageModalComponent
     */
    public localeObjects: Array<any> = [];

    /**
     * Max number of locales applied to a solution
     *
     * @type     {any}
     * @memberof LanguageModalComponent
     */
    public maxLocales: any;

    /**
     * Max number of locales applied to a solution
     *
     * @type     {any}
     * @memberof LanguageModalComponent
     */
    public disableSave: boolean = false;

    /**
     * Max number of locales applied to a solution
     *
     * @type     {number}
     * @memberof LanguageModalComponent
     */
    public selectedLocaleCount: number;
    /**
     * List of selected countries.
     *
     * @type     {Array<any>}
     * @memberof LanguageModalComponent
     */
    public selectedCountries: Array<any> = [];

    /**
     * Primary Language of the project.
     *
     * @type     {Array<string>}
     * @memberof LanguageModalComponent
     */
    public primaryLocale: string = '';

    /**
     * Default Primary locale of the project.
     *
     * @type     {Array<string>}
     * @memberof LanguageModalComponent
     */
    public defaultPrimaryLocale: string = platformConstants.PROJECT_DEFAULT_LOCALE;

    /**
     * Default Primary Language of the project.
     *
     * @type     {Array<string>}
     * @memberof LanguageModalComponent
     */
    public defaultPrimaryLanguage: string = platformConstants.DEFAULT_PRIMARY_LANG_COUNTRY;

    /**
     * List of selected locales.
     *
     * @type     {Array<string>}
     * @memberof LanguageModalComponent
     */
    public selectedLocaleObjects: Array<any> = [];

    /**
     * Header text to be displayed in the modal.
     *
     * @type     {string}
     * @default  ""
     * @memberof LanguageModalComponent
     */
    public header: string = '';

    /**
     * Body text to be displayed in the modal.
     *
     * @type     {string}
     * @default  ""
     * @memberof LanguageModalComponent
     */
    public body: any;

    /**
     * Footer button name to be displayed in the modal.
     *
     * @type     {string}
     * @default  ""
     * @memberof LanguageModalComponent
     */
    public footer: string = '';
    /**
     * Which holds list of locales selected by user and english if non-english country selected.
     */
    public primaryLocaleOptions: Array<any> = [];
    public filteredLocales: Array<any> = [];
    public copyOriginal: Array<any> = [];

    /**
     * Constructor.
     *
     * @param MatDialog
     * @memberof LanguageModalComponent
     */
    constructor(
        public dialogRef: MatDialogRef<LanguageModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public translate: TranslateService
    ) { }

    /**
     * Invokes after Component initialization.
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public ngOnInit(): void {
        this.setModalData();
        const groupByCountry = this.groupLocalesByCountry(this.body.localeObjects);

        // to sort the locales based on isRecommended flag
        Object.entries(groupByCountry)?.forEach(([, value]) => {
            const languagesProxy: any = value;

            languagesProxy.sort((x: any, y: any) => {
                return x.isRecommended === y.isRecommended
                    ? 0
                    : x.isRecommended
                        ? -1
                        : 1;
            });
        });
        this.selectedCountries = this.data.body.selectedLocaleObjects.length
            ? JSON.parse(JSON.stringify(this.data.body.selectedLocaleObjects))
            : [];
        this._setLocales(groupByCountry, this.selectedCountries);
        this.updatePrimaryLocaleOptions();
        this.toggleSave();
    }

    /**
     * Sets the modal data received from the invoked component.
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public setModalData(): void {
        this.header = this.data.header;
        this.body = this.data.body;
        this.footer = this.data.footer;
        this.maxLocales = this.data.body.maxLocales;
        this.primaryLocale = this.data.body.primaryLocale
            ? this.data.body.primaryLocale
            : this.defaultPrimaryLocale;
    }

    /**
     * Constructs the locales object as required by the Checkbox tree component.
     *
     * @param    {Map<any, any>}
     * @memberof LanguageModalComponent
     */
    private _setLocales(
        groupByCountry: object,
        selectedCountries: Array<any>
    ): void {
        Object.entries(groupByCountry)?.forEach(([key, value]) => {
            // Even though value is an array , it shows error while performing array ops.
            const languagesProxy: any = value;
            const countryCode = key.split('-')[1];
            const countryName = key.split('-')[0];
            const countriesLength = selectedCountries.length;
            const isDisabled = !selectedCountries.some(
                (country) => country.code === countryCode
            );
            const selectedCountry = selectedCountries.find(
                (country) => country.code === countryCode
            );
            if (selectedCountry) {
                const selectedLanguages = selectedCountry.languages.filter(
                    (language: any) => language.selected === true
                );

                languagesProxy.forEach((lang: any) => {
                    const languageName = lang.name;
                    if (
                        selectedLanguages.find(
                            (selectedLang: any) => selectedLang.name === languageName
                        )
                    ) {
                        lang.selected = true;
                    }
                });
            }

            this.localeObjects.push({
                name: countryName,
                code: key.split('-')[1],
                allComplete: languagesProxy.every(
                    (lang: any) => lang.selected === true
                ),
                selected: false,
                disabled: countriesLength > 0 && !this.maxLocales ? isDisabled : false,
                languages: languagesProxy,
                primaryLocale: this.getPrimaryLocale(languagesProxy, countryCode),
            });

            this.copyOriginal = JSON.parse(JSON.stringify(this.localeObjects));
        });
    }

    /**
     * Returns primary locale based on country name and languages.
     * If the country doesn't have their specific English then en_US
     * is returned else en_{countryCode} (ex: en_CA) is returned.
     *
     * @param    {string}
     * @memberof LanguageModalComponent
     */
    public getPrimaryLocale(languages: Array<any>, countryCode: string): string {
        return languages.some(
            (language: any) =>
                language.name === platformConstants.DEFAULT_PRIMARY_LANG
        )
            ? `${platformConstants.PROJECT_DEFAULT_LANG_CODE}_${countryCode}`
            : platformConstants.PROJECT_DEFAULT_LOCALE;
    }

    /**
     * Group the list of locales by country based on country name and country code.
     *
     * @param    {Array<any>}
     * @memberof LanguageModalComponent
     */
    public groupLocalesByCountry(locales: Array<any>): object {
        return locales?.reduce((accmulator: any, current: any) => {
            if (!accmulator[`${current.country}-${current.countryCode}`]) {
                accmulator[`${current.country}-${current.countryCode}`] = [];
            }
            accmulator[`${current.country}-${current.countryCode}`].push({
                name: current.language.split('(')[0].trim(),
                selected: false,
                locale: current.locale,
                isRecommended: current.isRecommended,
            });
            return accmulator;
        }, {});
    }

    /**
     * Handler for Save action on the modal.
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public save(): void {
        this.setSelectedLocales();
        this.dialogRef.close(this.selectedLocaleObjects);
    }

    /**
     * Handler for cancel action on the modal.
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public close(): void {
        this.dialogRef.close('cancel');
    }

    /**
     * Returns language(English (United States)) given locale(en_US).
     *
     * @param    {locale}
     * @memberof LanguageModalComponent
     */
    public getLanguage(locale: string): string {
        const localeObject = this.body.localeObjects.find(
            (localeObj: any) => localeObj.locale === locale
        );
        return localeObject.language ? localeObject.language : '';
    }

    /**
     * Returns if the selected country's language is equal to the defaultPrimaryLanguage.
     *
     * @param    {locale}
     * @memberof LanguageModalComponent
     */
    public isEnglishUS(language: string, countryName: string): boolean {
        return language && countryName
            ? `${language} (${countryName})` === this.defaultPrimaryLanguage
            : false;
    }

    /**
     * Sets the locales selected by the user on save.
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public setSelectedLocales(): void {
        this.selectedCountries.forEach((country) => {
            const languages = country.languages.filter((language: any) => {
                if (language.selected) {
                    return language;
                }
            });
            const localesList = languages.map((languageObj: any) => {
                return languageObj.locale;
            });
            this.selectedLocaleObjects.push({
                locales: localesList,
                name: country.name,
                code: country.code,
                languages: country.languages,
                count: country.selectedOptionsCount
                    ? country.selectedOptionsCount
                    : languages.length,
                primaryLocale: this.primaryLocale,
            });
        });
    }

    /**
     * Handles change to Primary Language field.
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public changePrimaryLocale(option: any): void {
        this.primaryLocale = option.value;
        const isLocaleCountExceeded = this.selectedLocaleCount > this.maxLocales;
        const maxCountries = this.selectedCountries.length !== 1;
        this.disableSave = isLocaleCountExceeded && maxCountries;
    }

    /**
     * Returns the locale count based on the countries.
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public getLocaleCount(options: any): number {
        return options.reduce((result, current) => {
            return (
                result +
                current.languages.filter((language: any) => language.selected === true)
                    .length
            );
        }, 0);
    }

    /**
     * Handles click event generated on selecting languages.
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public selectLocales(selectedOptions: any): void {
        this.selectedCountries = selectedOptions;
        const selectedCountryCount = this.selectedCountries.length;
        const primLocaleEng = this.selectedCountries[
            selectedCountryCount - 1
        ]?.languages.findIndex(
            (ele) =>
                ele.locale.split('_')[0] === platformConstants.PROJECT_DEFAULT_LANG_CODE
        );
        this.primaryLocale =
            this.selectedCountries.length > 0 && primLocaleEng > -1
                ? this.selectedCountries[selectedCountryCount - 1].primaryLocale
                : this.defaultPrimaryLocale;
        this.updateCountryPrimaryLocale();
        this.updatePrimaryLocaleOptions();
        this.toggleSave();
        if (!this.maxLocales) {
            this.disableLocales(this.localeObjects, selectedCountryCount);
        }
    }

    /**
     * Updates primary locale to en_US for an English speaking country if
     * user un selects the country specific English langage(ex: en_CA).
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public updateCountryPrimaryLocale(): void {
        const enLocale = this.primaryLocaleOptions.find(
            (eachOption) =>
                eachOption.split('_')[0] === platformConstants.PROJECT_DEFAULT_LANG_CODE
        );
        this.primaryLocale = enLocale
            ? enLocale
            : platformConstants.PROJECT_DEFAULT_LOCALE;
    }

    /**
     * Disables Locale Objects as per the validation placed per each solution(maxLocales).
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public disableLocales(
        locales: Array<any>,
        selectedCountryCount: number
    ): void {
        if (selectedCountryCount === 0) {
            locales.map((localeObject) => (localeObject.disabled = false));
        } else {
            locales.map((localeObject) => {
                if (
                    !this.selectedCountries.some(
                        (country) => country.name === localeObject.name
                    )
                ) {
                    localeObject.disabled = true;
                }
            });
        }
    }
    /**
     * Flips the save button to be enabled/disabled.
     *
     * @param    {void}
     * @memberof LanguageModalComponent
     */
    public toggleSave(): void {
        this.selectedLocaleCount = this.primaryLocaleOptions.length;
        if (
            this.maxLocales &&
            this.selectedCountries.length > 0 &&
            this.selectedLocaleCount <= this.maxLocales
        ) {
            this.disableSave = false;
        } else if (!this.maxLocales && this.selectedCountries.length > 0) {
            this.disableSave = false;
        } else {
            this.disableSave = true;
        }
    }

    /**
     * Updates primary locale options.
     */
    public updatePrimaryLocaleOptions(): void {
        this.primaryLocaleOptions = [];
        const locales = [];
        let existingPrimaryLocale;
        this.selectedCountries.forEach((country) => {
            country.languages.forEach((language) => {
                if (language.selected) {
                    locales.push(language.locale);
                }
            });
            existingPrimaryLocale = country.primaryLocale;
        });
        // English United states (en_US) will be added, in the case where english locale has not been selected by user.
        const englishLocales = this.filterEnglishLocales(locales);
        if (existingPrimaryLocale && locales.includes(existingPrimaryLocale)) {
            this.primaryLocale = existingPrimaryLocale;
        } else {
            this.primaryLocale = englishLocales.length
                ? englishLocales[0]
                : platformConstants.PROJECT_DEFAULT_LOCALE;
        }
        this.primaryLocaleOptions.push(this.primaryLocale);
        locales.forEach((eachLocale) => {
            if (!this.primaryLocaleOptions.includes(eachLocale)) {
                this.primaryLocaleOptions.push(eachLocale);
            }
        });
        // Add english if not present.
        const enLocalesInPrimaryLocales = this.filterEnglishLocales(
            this.primaryLocaleOptions
        );
        if (!enLocalesInPrimaryLocales.length) {
            this.primaryLocaleOptions.push(platformConstants.PROJECT_DEFAULT_LOCALE);
        }
    }

    /**
     * Filters english locales.
     */
    public filterEnglishLocales(locales: Array<any> = []): Array<any> {
        return locales.filter((selectedLocale) => {
            return (
                selectedLocale.split('_')[0] ===
                platformConstants.PROJECT_DEFAULT_LANG_CODE
            );
        });
    }

    public filterData(inpVal: string): void {
        this.copyOriginal.forEach((ele) => {
            if (ele.languages) {
                const displayObj = this.localeObjects.find(
                    (obj) => obj.name === ele.name
                );
                if (displayObj) {
                    ele.allComplete = displayObj.allComplete;
                    if (displayObj.languages) {
                        displayObj.languages.forEach((subEle) => {
                            const originalSubEle = ele.languages.find(
                                (obj) => obj.name === subEle.name
                            );
                            if (originalSubEle) {
                                originalSubEle.selected = subEle.selected;
                            }
                        });
                    }
                }
            }
        });
        this.localeObjects = JSON.parse(JSON.stringify(this.copyOriginal));
        if (!this.maxLocales) {
            this.disableLocales(this.localeObjects, this.selectedCountries.length);
        }

        if (!inpVal.length) {
            return;
        }

        this.localeObjects = this.localeObjects.filter((element) =>
            element.name.toLowerCase().includes(inpVal.toLocaleLowerCase())
        );
    }
}
