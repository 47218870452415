import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
    selector: 'niq-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Output() public searchText = new EventEmitter();
  @Output() public typeAheadText = new EventEmitter();
  @Input() public searchInputValue = '';
  @Input() public appendPlaceholder = '';
  @Input() displayInput: string;
  constructor(private _analyticsService: AnalyticsService,) { }

  public searchHandler(event): void {
      event.target.focus();
      if (event.relatedTarget?.name !== 'search-delete-icon') {
          this.searchText.emit(this.searchInputValue);
      } else {
          this.searchInputValue = '';
          this.typeAheadText.emit(this.searchInputValue);
          if(this.displayInput==='REQUEST_ACCESS'){
            if(this.appendPlaceholder==='clients'){
                this._analyticsService.track(`uf-ProjAcc-ReqAccT-ClientFltr-Trash-clicked`);
            }
            if(this.appendPlaceholder==='countries'){
                this._analyticsService.track(`uf-ProjAcc-ReqAccT-CountryFltr-Trash-clicked`);
            }
       }
      }
  }

  public typeAheadHandler(): void {
      this.typeAheadText.emit(this.searchInputValue);
  }

  public enterHandler(event: any): void {
      if (event.keyCode === 13) {
          this.searchText.emit(this.searchInputValue);
      }
  }

  public clear(): void {
      this.searchInputValue = '';
      this.searchText.emit(this.searchInputValue);
      this.typeAheadText.emit(this.searchInputValue);
  }
}
