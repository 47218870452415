import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'niq-input-box',
    templateUrl: './input-box.component.html',
    styleUrls: ['./input-box.component.scss'],
})
export class InputBoxComponent {
  /**
   * Property to set expected pattern for validation.
   *
   * @default /^[a-zA-Z0-9\s.\-_'!\u00E4\u00F6\u00FC\u00DF\u00C4\u00D6\u00DC]+$/
   * @type     {RegExp}
   * @memberof InputBoxComponent
   */
  @Input() public pattern = new RegExp(
      /^[a-zA-Z0-9\s.\-_'!\u00E4\u00F6\u00FC\u00DF\u00C4\u00D6\u00DC]+$/
  );

  /**
   * Property to set default value if any.
   *
   * @default  ''
   * @type     {string}
   * @memberof InputBoxComponent
   */
  @Input() public inputBoxValue: string = '';

  /**
   * Property to assign a class to the input.
   *
   * @default  ''
   * @type     {string}
   * @memberof InputBoxComponent
   */
  @Input() public inputClass: string = 'input-class';

  /**
   * Property to know the type of input.
   *
   * @default  ''
   * @type     {string}
   * @memberof InputBoxComponent
   * expected values: 'number' | 'text'
   */
  @Input() public inputType: string;

  /**
   * Property to set the placeholder of input element.
   *
   * @default  ''
   * @type     {string}
   * @memberof InputBoxComponent
   */
  @Input() public inputPlaceholder: string;

  /**
   * Property to set the maxlength of input element.
   *
   * @default  0
   * @type     {number}
   * @memberof InputBoxComponent
   */
  @Input() public maxLength: number;

  /**
   * Property to have/not a label of input element.
   *
   * @default  true
   * @type     {boolean}
   * @memberof InputBoxComponent
   */
  @Input() public hasLabel: boolean = true;

  /**
   * Property to set the label of input element.
   *
   * @default  ''
   * @type     {string}
   * @memberof InputBoxComponent
   */
  @Input() public displayLabel: string;

  /**
   * Property to set the name for the input.
   *
   * @default  ''
   * @type     {string}
   * @memberof InputBoxComponent
   */
  @Input() public fieldName: string = 'Project name';

  @Input() public fieldRequiredError: string = this.translate.instant(
      'project.validations.exceeded_max_length'
  );

  /**
   * Property to make/not the input data mandatory.
   *
   * @default  false
   * @type     {boolean}
   * @memberof InputBoxComponent
   */
  @Input() public isRequired: boolean;

  /**
   * Property to make/not the input to be non-editable.
   *
   * @default  false
   * @type     {boolean}
   * @memberof InputBoxComponent
   */
  @Input() public isReadOnly: boolean;

  /**
   * Property to set width of the input element in percentage.
   *
   * @default  100%
   * @type     {number}
   * @memberof InputBoxComponent
   */
  @Input() public width: number = 100;

  @Input() public errorResponse: string = '';
  @Input() public countAlignBottom: boolean = false;

  @Input() public projDuplicationInfSectionClass: string = '';

  /**
   * Property to have the user response of input element.
   *
   * @default  ''
   * @type     {any}
   * @memberof InputBoxComponent
   */
  @Output() public inputBoxUpdatedValue: any = new EventEmitter();

  /**
   * Property to sent the valid state of input -- only for project name.
   *
   * @default  ''
   * @type     {any}
   * @memberof InputBoxComponent
   */
  @Output() public isInputValid: any = new EventEmitter();

  /**
   * Property to sent the dirty state of input
   *
   * @default  ''
   * @type     {any}
   * @memberof InputBoxComponent
   */
  @Output() public isFormDirty: any = new EventEmitter();

  /**
   * Property to store the state of the input element based on user response.
   *
   * @default  'default'
   * @type     {string}
   * @memberof InputBoxComponent
   *
   * values: 'default' | 'warning' | 'error'
   */
  public state: string = 'default';

  /**
   * Property to store the percentage of the input element based on user response(maxlength).
   *
   * @default  0
   * @type     {number}
   * @memberof InputBoxComponent
   */
  public percentage: number = 0;
  public isNameValid: boolean = true;
  public isNameValidPattern: boolean = true;

  public get dynamicWidth(): object {
      return {
          width: this.width + '%',
      };
  }

  /**
   * Constructor.
   */
  constructor(public translate: TranslateService) { }

  /**
   * @method changeBorderColor
   * @param event
   * @type  {any}
   *
   * calculation to change the border color of the input element.
   */
  public changeBorderColor(event: any): void {
      this.percentage = (event.target.value.length / this.maxLength) * 100;
      this.state =
      this.percentage >= 80
          ? this.percentage <= 100
              ? 'warning'
              : 'error'
          : 'default';
  }

  /**
   * @method emitValue
   * @param inputVal
   * @type  {string}
   *
   * sends the value of the input element.
   */
  public emitValue(inputVal: string): void {
      this.validation(inputVal);
      this.inputBoxUpdatedValue.emit(inputVal?.trim(), this.isNameValid);
  }

  public validation(inputValue: string): void {
      this.isNameValidPattern = inputValue ? this.pattern.test(inputValue) : true;
      this.isNameValid =
      this.isNameValidPattern &&
      inputValue.trim().length > 0 &&
      inputValue.length <= this.maxLength;
      this.isInputValid.emit(this.isNameValid);
  }

  public formDirtyStatus(obj: boolean): void {
      this.isFormDirty.emit(obj);
  }
}
