import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { SKUList } from '../../models/sku-list/skulist';

@Injectable({
    providedIn: 'root',
})
export class SkulistService {
    constructor(private _http: HttpClient, private _cs: AppConfigService) {}

    public get(skuListLinkPath: string): any {
        const skuListsUrl = `${this._cs.config?.projectservice.url}${skuListLinkPath}`;
        return this._http.get<SKUList[]>(`${skuListsUrl}`);
    }
}
