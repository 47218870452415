<div class="search-text-container align-items-center">
  <input mat-input #searchInput="ngModel" type="text" class="search-input" [(ngModel)]="searchInputValue"
    [placeholder]="appendPlaceholder ? 'Search for ' + appendPlaceholder: 'project.toolbar.search_placeholder' | translate"
    (change)="searchHandler($event)" (input)="typeAheadHandler()" (keypress)="enterHandler($event)">
  <button [hidden]="
      !(searchInput && searchInput.value ? searchInput.value.length : 0)
    " (click)="clear()" name="search-delete-icon">
    <span class="sif sif-delete"></span>
  </button>
</div>
