import { skipWhile, switchMap, take } from 'rxjs/operators';
import { HeartBeatService } from '@basesstudio/ngx-components';
import { interval, of } from 'rxjs';
import { AppConfigService } from './app-config.service';

/**
 * Heartbeat service implementation.
 */
export class HeartBeatServiceImpl implements HeartBeatService {
    constructor(private _appConfigService: AppConfigService) {}

    /**
   * Returns hearbeat url promise.
   *
   * @returns heartbeat url
   */
    public getHeartBeatURL(): string | Promise<string> {
        const promise = interval(1000)
            .pipe(
                skipWhile(() => !this._appConfigService.config),
                take(1),
                switchMap(() => of(this._appConfigService.config.heartbeat.url))
            )
            .toPromise();
        return promise;
    }
}
