import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ProjectInfoModalComponent } from '../../platform/modals/project-info-modal/project-info-modal.component';
import * as platformConstants from '../constant';
import { SolutionModel } from '../models/solution/solution-model';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { SolutionImagesService } from '../services/solution-images/solution-images.service';
import { SolutionService } from '../services/solutions/solution.service';
import { UserInfoService } from '../services/user/user-info.service';

@Component({
    selector: 'niq-solution-type',
    templateUrl: './solution-type.component.html',
    styleUrls: ['./solution-type.component.scss'],
})
export class SolutionTypeComponent implements OnInit {
    @Input() public gridView: boolean;
    @Input() public gridViewId: string = "";
    @Output() public showProjectSetup = new EventEmitter<boolean>();
    @Output() public selectedSolution = new EventEmitter<any>();
    @Output() public gridViewUpdated = new EventEmitter<boolean>();
    @ViewChild('widgetsContent', { read: ElementRef })
    public widgetsContent: ElementRef<any>;

    /**
     * Flag to enable/disable spinner
     *
     * @type      {number}
     * @memberof  SolutionTypeComponent
     */
    public spinner: boolean = false;

    /**
     * solutions array object.
     *
     * @type      {Array<SolutionModel>}
     * @memberof  SolutionTypeComponent
     */
    public solutions: Array<SolutionModel> = [];

    /**
     * Holds user information
     */
    public userInfo: any = {};

    public mousein: boolean = false;
    public hoverIndex: number = null;
    public disableSlideBtn: string = 'left';
    public showBenefitIcon: boolean = false;
    public imageBlob: Blob;
    public svgImages: any = {};

    /**
     * Constructor.
     *
     * @param MatDialog
     * @param TranslateService
     * @memberof SolutionTypeComponent
     */
    constructor(
        public dialog: MatDialog,
        public translate: TranslateService,
        private _solutionService: SolutionService,
        private _analyticsService: AnalyticsService,
        private _userInfoService: UserInfoService,
        private _solutionImageService: SolutionImagesService,
        private _sanitizer: DomSanitizer
    ) { }

    public ngOnInit(): void {
        this.spinner = true;
        this._userInfoService.get().subscribe((data: any) => {
            this.userInfo = data;
        });

        this._solutionService.get().subscribe((data: SolutionModel[]) => {
            this.spinner = false;
            if (data) {
                const filteredSolutions = data.filter((sol) => {
                    if (sol.code === 'IS') {
                        return this.userInfo?.featureFlags.includes('INNOVATION_SCREENER');
                    } 
                    else if (sol.code === 'DD') {
                        return this.userInfo?.featureFlags.includes('STUDIO_DESIGN_PRODUCT');
                    }
                    else {
                        return true;
                    }
                });
                this.solutions = this.solutions.concat(filteredSolutions);
            }
        });
    }

    /**
     * Handles the click event of the solution card.
     *
     * @returns  {void}
     * @memberof SolutionTypeComponent
     */
    public cardClickHandler(solution: any): void {
        this._analyticsService.track('Solution Title Click', {
            solutionType: solution.name,
        });

        this.showProjectSetup.emit(true);
        this.selectedSolution.emit(solution);
    }

    /**
     * Closes the project setup window.
     *
     * @returns  {void}
     * @memberof SolutionTypeComponent
     */
    public close(): void {
        this.showProjectSetup.emit(false);
        this._analyticsService.trackDisplay(false, 'Project Setup Window');
    }

    /**
     * Returns the standard help url.
     *
     * As its a constant across local, Dev & PROD environments, hardcoded the
     * url directly instead of reading it from the config.
     *
     * @returns  {string}
     * @memberof SolutionTypeComponent
     */
    public getHelpUrl(): string {
        return `https://nielseniq.com/global/en/solutions/innovate-your-products/`;
    }

    /**
     * Opens the dialog to view benfits and images of a solution.
     *
     * @returns  {void}
     * @memberof SolutionTypeComponent
     */
    public openCarousalDialog(solution: any): void {
        this.dialog.open(ProjectInfoModalComponent, {
            maxWidth: platformConstants.MODAL_WIDTH,
            data: solution,
        });
        this._analyticsService.trackDisplay(true, 'Solution Benefits Slideshow', {
            solutionType: solution.name,
        });
    }

    public saveCardView(isBigCardView: boolean): void {
        this.gridViewUpdated.emit(isBigCardView);
    }

    public scrollLeft(): void {
        this.widgetsContent.nativeElement.scrollLeft -= 335;
        if (this.widgetsContent.nativeElement.scrollLeft === 0) {
            this.disableSlideBtn = 'left';
        } else {
            this.disableSlideBtn = '';
        }
    }

    public scrollRight(): void {
        const container = this.widgetsContent.nativeElement;
        const scrollStep = 345;
        
        container.scrollLeft += scrollStep;
        const sum = container.scrollLeft + container.offsetWidth;
        if (container.scrollWidth - sum <= 10) {
          this.disableSlideBtn = 'right';
        } else {
          this.disableSlideBtn = '';
        }
    }

    // code to show the benefits icons
    public getImage(bnftIcon: string, ind: number, solutionInd: number): any {
        this.spinner = true;
        this._solutionImageService
            .get(bnftIcon, 'benefits')
            .subscribe((data: any) => {
                this.spinner = false;
                this.imageBlob = data[this.solutions[solutionInd].benefits[ind].icon];
                this.createImageFromBlob(ind, solutionInd);
            });
    }

    public createImageFromBlob(ind: number, solutionInd: number): void {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                const preview = this._sanitizer.bypassSecurityTrustUrl(
                    reader.result.toString()
                );
                this.svgImages[this.solutions[solutionInd].benefits[ind].icon] =
                    preview;
            },
            false
        );
        if (this.imageBlob) {
            reader.readAsDataURL(this.imageBlob);
        }
    }
}
