import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { AppState } from '../states/app.state';

import * as fromUserInfo from './user-info.reducer';
import * as fromLocales from './locales.reducer';
import * as fromSolutions from './solutions.reducer';
import * as fromPermissions from './permissions.reducer';
import * as fromImageBlob from './image.reducer';

/**
 * `ActionReducerMap` for all reducers for the `State`.
 *
 * @export
 * @property {ActionReducerMap<AppState>} reducers
 */
export const reducers: ActionReducerMap<AppState> = {
    userInfo: fromUserInfo.reducer,
    locales: fromLocales.reducer,
    solutions: fromSolutions.reducer,
    permissions: fromPermissions.reducer,
    imageBlob: fromImageBlob.reducer,
};

/**
 * Exports `NgRx` logger object.
 *
 * @export
 * @param {ActionReducer<AppState>} reducer
 * @returns {ActionReducer<AppState>}
 */
export function logger(
    reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
    return (state: AppState, action: any) => {
        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 *
 * @export
 * @property
 */
export const metaReducers: MetaReducer<AppState>[] = !environment.production
    ? [logger]
    : [];
