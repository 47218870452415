import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { ConceptModel } from '../../models/concept/concept-model';

@Injectable({
    providedIn: 'root',
})
export class ConceptService {
    /**
   * Constructor.
   *
   * @param HttpClient
   * @param AppConfigService
   * @memberof ConceptService
   */
    constructor(private _http: HttpClient, private _cs: AppConfigService) { }

    /**
   * Returns a list of Concepts.
   *
   * @param conceptsLinkPath
   * @memberof ConceptService
   */
    public get(conceptsLinkPath: string): Observable<ConceptModel[]> {
        const conceptsUrl = `${this._cs.config?.projectservice.url}${conceptsLinkPath}`;
        return this._http.get<ConceptModel[]>(`${conceptsUrl}`);
    }

    /**
   * Returns studio thumbnail url for concept image.
   *
   * @param thumbnailPath
   * @memberof ConceptService
   */
    public getThumbnailUrl(thumbnailPath: string): string {
        return `${this._cs.config?.studioProxy.url}${thumbnailPath}`;
    }
}
