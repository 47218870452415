import { Component, Input } from '@angular/core';
import { InputBoxComponent } from '../input-box/input-box.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'niq-multiline-input-box',
    templateUrl: './multiline-input-box.component.html',
    styleUrls: ['./multiline-input-box.component.scss']
})
export class MultilineInputBoxComponent extends InputBoxComponent {

  /**
   * Property to set expected pattern for validation. By default, these special
   * characters are allowed: "',/><{}[]|\~!@#$%^&*()=-_.äöüÄÖÜß
   *
   * @default /^[a-zA-Z0-9\s,.\/<>{}\[\]\\|~@#$%^&*()\-=_'"!\u00E4\u00F6\u00FC\u00DF\u00C4\u00D6\u00DC]+$/
   * @type     {RegExp}
   * @memberof InputBoxComponent
   */
  @Input() public pattern = new RegExp(
      /^[a-zA-Z0-9\s,.\/<>{}\[\]\\|~@#$%^&*()\-=_'"!\u00E4\u00F6\u00FC\u00DF\u00C4\u00D6\u00DC]+$/
  );

  /**
   * Constructor.
   */
  constructor(
    public translate: TranslateService
  ) {
      super(translate);
  }

}
