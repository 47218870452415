import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { concatMap, Observable, take } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { addPermissions } from '../../store/actions/permissions.actions';
import { selectPermissionsState } from '../../store/selectors/permissions.selectors';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    /**
     * String to hold the url.
     *
     * @type {string}
     * @memberof PermissionService
     */
    private _url: string;

    /**
     * Constructor.
     *
     * @param HttpClient
     * @param AppConfigService
     * @memberof PermissionService
     */
    constructor(
        private _http: HttpClient,
        private _cs: AppConfigService,
        private _store: Store
    ) {
        const resourceUri = '/api/v1/permissions';
        this._url = `${this._cs?.config?.projectservice.url}${resourceUri}`;
    }
    /**
     * Returns the list of permissions based on user roles (these are identified in the backend itself).
     *
     * @param none
     * @returns httpresponse
     * @memberof PermissionService
     */
    public get(): Observable<any> {
        let permissions$: Observable<any> =
            this._fetchPermissionsFromStore();
        permissions$.pipe(take(1)).subscribe((user) => {
            if (!user) {
                permissions$ = this._fetchPermissionsFromAPI();
            }
        });
        return permissions$;
    }

    private _fetchPermissionsFromAPI(): Observable<any> {
        return this._http.get<any>(`${this._url}`).pipe(
            concatMap((result) => {
                this.loadPermissionsOnStore(result);
                return this._fetchPermissionsFromStore();
            })
        );
    }

    public loadPermissionsOnStore(permissions: any): void {
        this._store.dispatch(addPermissions({ permissions }));
    }

    private _fetchPermissionsFromStore(): Observable<any> {
        return this._store.pipe(select(selectPermissionsState));
    }
}
