<niq-spinner-small *ngIf="spinner"></niq-spinner-small>

<niq-empty-page
  [icon]="emptyConst.icon"
  [leadText]="emptyConst.lead_text"
  [supportText]="emptyConst.support_text"
  [pageSize]="'small'"
  *ngIf="!spinner && skuLists.length === 0"
></niq-empty-page>

<div class="sku-heading" *ngIf="skuLists.length > 0">
  <span class="sif sif-season"></span>
  <h5 translate>{{ "project.table_content.designOptionslist.name" }}</h5>
  <span>[{{ skuLists?.length }}]</span>
</div>

<div class="sku-body" *ngIf="skuLists.length > 0">
  <div *ngFor="let skuList of skuLists">
    <mat-card class="sku-details">
      <h2>{{ skuList.name }}</h2>
      <p>Design count  : {{ skuList.skuCount }}</p>
      <p *ngIf="skuList.doeStatus">DOE status : {{ skuList.doeStatus }}</p>
    </mat-card>
  </div>
</div>