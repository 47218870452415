<niq-spinner [display]="spinner"></niq-spinner>
<div class="table-container">
  <mat-table #solutionsTable [dataSource]="solutions" cdkDropList [cdkDropListData]="solutions"
    (cdkDropListDropped)="drop($event)">
    <ng-container matColumnDef="drag">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-65'"> </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'w-65'">
        <button class="mat-button" mat-icon-button aria-label="Info" [hidden]="element.id !== highlightedSolutionID">
          <span class="sif sif-drag-handler"></span>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-150'"> {{ "solution.properties.name" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'w-150'"> {{element.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="titleOne">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-250'"> {{ "solution.properties.titleOne" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'w-250'"> <span class="text-overflow-ellipsis"
          [matTooltip]="element.benefits[0].title" matTooltipClass="custom-tooltip"> {{element.benefits[0].title}}
        </span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="descriptionOne">
      <mat-header-cell *matHeaderCellDef> <span>{{ "solution.properties.descriptionOne" | translate }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> <span class="text-overflow-ellipsis"
          [matTooltip]="element.benefits[0].description" matTooltipClass="custom-tooltip">
          {{element.benefits[0].description}} </span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="titleOneIcon">
      <mat-header-cell *matHeaderCellDef class="align-items-center" [ngClass]="'w-110'"> {{
        "solution.properties.benefitOnePreview" | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element" class="align-items-center" [ngClass]="'w-110'">
        <button class="mat-button" mat-icon-button aria-label="Info" (click)="openBenefitDialog(element, 0)"
          [hidden]="!element.benefits[0].icon.length">
          <span class="sif sif-eye-enabled" *ngIf="element.benefits[0].icon.length"></span>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="titleTwo">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-250'"> {{ "solution.properties.titleTwo" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'w-250'"> <span class="text-overflow-ellipsis"
          [matTooltip]="element.benefits[1].title" matTooltipClass="custom-tooltip"> {{element.benefits[1].title}}
        </span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="descriptionTwo">
      <mat-header-cell *matHeaderCellDef> {{ "solution.properties.descriptionTwo" | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"><span class="text-overflow-ellipsis"
          [matTooltip]="element.benefits[1].description" matTooltipClass="custom-tooltip">
          {{element.benefits[1].description}} </span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="titleTwoIcon">
      <mat-header-cell *matHeaderCellDef class="align-items-center" [ngClass]="'w-110'"> {{
        "solution.properties.benefitTwoPreview" | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element" class="align-items-center" [ngClass]="'w-110'">
        <button class="mat-button" mat-icon-button aria-label="Info" (click)="openBenefitDialog(element, 1)"
          [hidden]="!element.benefits[1].icon.length">
          <span class="sif sif-eye-enabled" *ngIf="element.benefits[1].icon.length"></span>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="slides">
      <mat-header-cell *matHeaderCellDef class="align-items-center" [ngClass]="'w-110'"> {{
        "solution.properties.slideshow_images" | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element" class="align-items-center" [ngClass]="'w-110'">
        <button class="mat-button" mat-icon-button aria-label="Info" (click)="openCarousalDialog(element)"
          [disabled]="!element.slides.length">
          <span class="sif sif-eye-enabled" *ngIf="element.slides.length"></span>
          <span class="sif sif-eye-disabled" *ngIf="!element.slides.length"></span>
        </button>
        {{element.slides.length}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-65'"> </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'w-65'">
        <button class="mat-button" mat-icon-button aria-label="Info"
          matTooltip="{{'solution.edit.details' | translate}}" matTooltipClass="custom-tooltip" (click)="edit(element)"
          [hidden]="element.id !== highlightedSolutionID">
          <span class="sif sif-edit"></span>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'highlight': highlightedSolutionID === row.id}" cdkDragLockAxis="y" cdkDrag [cdkDragData]="row"
      (mouseover)="highlight(row, true)" (mouseleave)="highlight(row, false)"></mat-row>
  </mat-table>
</div>
