import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'niq-donut-chart',
    templateUrl: './donut-chart.component.html',
    styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements OnInit {
    /**
     * input from parent component.
     * base color of the base spinner.
     *
     * @type      {string}
     * @memberof  DonutChartComponent
     */
    @Input() public baseProgressSpinnerColor: string;

    /**
     * input from parent component.
     * base color of the spinner.
     *
     * @type      {string}
     * @memberof  DonutChartComponent
     */
    @Input() public progressSpinnerColor: string;

    /**
     * input from parent component.
     * spinner stroke width.
     *
     * @type      {number}
     * @memberof  DonutChartComponent
     */
    @Input() public strokeWidth: number;

    /**
     * input from parent component.
     * mode of the spinner.
     *
     * @values :
     * determinate (or) indeterminate
     *
     * @type      {string}
     * @memberof  DonutChartComponent
     */
    @Input() public mode: string;

    /**
     * input from parent component.
     * The diameter of the progress spinner (will set width and height of svg).
     *
     * @type      {number}
     * @memberof  DonutChartComponent
     */
    @Input() public diameter: number;

    /**
     * input from parent component.
     * Value of the progress circle.
     *
     * @type      {number}
     * @memberof  DonutChartComponent
     */
    @Input() public value: number;

    /**
     * input from parent component.
     * flag to have or not to have opacity set to the base spinner.
     *
     * @type      {boolean}
     * @memberof  DonutChartComponent
     */
    @Input() public noOpacity: boolean;

    // GENERIC SPINNER INPUTS - ENDS

    // PROJECT STATUS SPINNER INPUTS - STARTS
    /**
     * input from parent component.
     * project status.
     *
     * @type      {string}
     * @memberof  DonutChartComponent
     */
    @Input() public statusType: string;

    /**
     * input from parent component.
     * solution type.
     *
     * @type      {string}
     * @memberof  DonutChartComponent
     */
    @Input() public solutionType: string;

    /**
     * input from parent component.
     * projects delivered date.
     *
     * @type      {string}
     * @memberof  DonutChartComponent
     */
    @Input() public deliveredDate: string; // optional

    /**
     * projects status and forecast spinner icon.
     *
     * @type      {string}
     * @memberof  DonutChartComponent
     */
    @Input() public displayIcon: string = '';

    /**
     * Constructor.
     *
     */
    constructor() { }

    /**
     * getter method to set the style of base progress spinner dyanmically.
     *
     * @method get()
     * @returns {object}
     */
    public get baseProgressSpinnerStyle(): object {
        return {
            color: this.baseProgressSpinnerColor,
        };
    }

    /**
     * getter method to set the style of progress spinner dyanmically.
     *
     * @method get()
     * @returns {object}
     */
    public get progressSpinnerStyle(): object {
        return {
            color: this.progressSpinnerColor,
        };
    }

    /**
     * Defining and initializing the objects
     *
     * @OnInit - angular Lifecyclehook
     */
    public ngOnInit(): void {
        this.mode = 'determinate';
    }
}
