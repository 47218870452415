import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SolutionModel } from '../../models/solution/solution-model';
import { AppState } from '../states/app.state';

/**
 * Solutions feature selector.
 *
 * @export
 * @property
 */
export const selectSolutionsFeature = createFeatureSelector<
  AppState,
  SolutionModel[]
>('solutions');

/**
 * Solutions selector.
 *
 * @export
 * @property
 */
export const selectSolutionsState = createSelector(
    selectSolutionsFeature,
    (state: SolutionModel[]) => state
);
