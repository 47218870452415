import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SpinnerComponent } from './spinner.component';
export { SpinnerComponent } from './spinner.component';


@NgModule({
    declarations: [SpinnerComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ],
    exports: [SpinnerComponent]
})
export class SpinnerModule { }
