import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'niq-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss'],
})
export class SolutionsComponent {

    constructor(
    public translate: TranslateService,
    ) { }
}