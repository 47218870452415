import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../../core/config/app-config.service';
import { FieldingModel } from '../../models/fielding/fielding-model';

@Injectable({
    providedIn: 'root'
})
export class FieldingDataService {

    private _url: string;

    /**
   * Constructor.
   *
   * @param HttpClient
   * @param AppConfigService
   * @memberof FieldingDataService
   */
    constructor(private _http: HttpClient, private _cs: AppConfigService) {
    }

    /**
   * Returns the fielding info making an api call to project service.
   *
   * @param link
   * @returns {Observable<FieldingModel>}
   * @memberof FieldingService
   */
    public get(link: string): Observable<FieldingModel[]> {
        this._url = `${this._cs.config.projectservice.url}${link}`;
        return this._http.get<FieldingModel[]>(this._url);
    }
}
