import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { UserInfo } from '../../models/user/user-info';
import { addUserInfo } from '../../store/actions/user-info.actions';
import { selectUserInfo } from '../../store/selectors/user-info.selectors';

@Injectable({
    providedIn: 'root',
})
export class UserInfoService {

    constructor(
        private _http: HttpClient,
        private _cs: AppConfigService,
        private _store: Store
    ) { }

    /**
   * Returns the logged in user details.
   *
   * @param none
   * @returns Observable<UserInfo>
   * @memberof UserInfoService
   */

    public get(): Observable<UserInfo> {
        let user$: Observable<UserInfo> = this._fetchUserFromStore();
        user$.pipe(take(1)).subscribe((user) => {
            if (!user) {
                user$ = this._fetchUserFromAPI();
            }
        });
        return user$;
    }

    /**
   * Fetch user object from the API.
   *
   * @private
   * @returns {Observable<UserInfo>} The user observable
   * @memberof UserService
   */
    private _fetchUserFromAPI(): Observable<UserInfo> {
        const userInfoUrl = `${this._cs.config.authProxy.url}/api/userInfo`;
        return this._http.get<UserInfo>(`${userInfoUrl}`).pipe(
            concatMap((result) => {
                this.loadUserOnStore(result);
                return this._fetchUserFromStore();
            })
        );
    }

    /**
   * Loads user object into the store.
   *
   * @private
   * @param {UserInfo} userInfo The user object
   * @memberof UserService
   */
    public loadUserOnStore(userInfo: UserInfo): void {
        this._store.dispatch(addUserInfo({ userInfo }));
    }

    /**
   * Fetches user object from the store.
   *
   * @private
   * @returns {Observable<UserInfo>} The user observable.
   * @memberof UserService
   */
    private _fetchUserFromStore(): Observable<UserInfo> {
        return this._store.pipe(select(selectUserInfo));
    }
}
