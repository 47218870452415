import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlatformModule } from './platform/platform.module';
import { CoreModule, HEART_BEAT_TOKEN } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrandBarModule, LoggerType, NgxComponentsConfig, NgxComponentsModule } from '@basesstudio/ngx-components';

/**
 * NgxComponents configuration.
 */
const ngxConfig: NgxComponentsConfig = {
    moduleName: 'New Studio Home',
    heartbeat: {
        injectionToken: HEART_BEAT_TOKEN,
    },
    logger: {
        type: LoggerType.CONSOLE,
    },
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        NgxComponentsModule.forRoot(ngxConfig),
        BrowserModule,
        AppRoutingModule,
        PlatformModule,
        CoreModule,
        BrowserAnimationsModule,
        BrandBarModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
