import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
    MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'niq-message-bar',
    templateUrl: './message-bar.component.html',
    styleUrls: ['./message-bar.component.scss'],
})
export class MessageBarComponent implements OnInit {
    /**
   * Message text to be displayed
   *
   * @memberof MessageBarComponent
   */
    public message: string = '';

    /**
   * Type of the message
   *
   * @memberof MessageBarComponent
   */
    public type: string = 'success';
    public icon: string = 'checkmark';

    constructor(
    public snackBarRef: MatSnackBarRef<MessageBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {}

    /**
   * Angular life cycle hook.
   *
   * @memberof MessageBarComponent
   */
    public ngOnInit(): void {
        this.message = this.data.message;
        this.type = this.data.type;
        if (this.data.icon) {
            switch (this.type) {
                case 'success':
                    this.icon = 'checkmark';
                    break;
                case 'warn':
                    this.icon = 'warning';
                    break;
                case 'info':
                    this.icon = 'info-filled';
                    break;
                case 'error':
                    this.icon = 'warning';
                    break;
            }
        }
    }
}
