import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { AppConfigService } from '../../../core/config/app-config.service';
import { SolutionModel } from '../../models/solution/solution-model';
import { addSolutions } from '../../store/actions/solutions.actions';
import { selectSolutionsState } from '../../store/selectors/solutions.selectors';

@Injectable({
    providedIn: 'root'
})
export class SolutionService {
    /**
     * String to hold the url.
     *
     * @type     {string}
     * @memberof SolutionService
     */
    private _url: string;

    private _solutions: SolutionModel[];

    /**
     * Constructor.
     *
     * @param HttpClient
     * @memberof SolutionService
     */
    constructor(
        private _http: HttpClient,
        private _cs: AppConfigService,
        private _store: Store
    ) {
        const resourceUri = '/api/v1/solutions';
        this._url = `${this._cs?.config?.projectservice.url}${resourceUri}`;
    }

    /**
     * Returns the list of solutions.
     *
     * @param none
     * @returns httpresponse
     * @memberof SolutionService
     */
    public get(cache: boolean = true): Observable<Array<SolutionModel>> {
        let solutions$: Observable<Array<SolutionModel>> = this.fetchSolutionsFromStore();
        solutions$.pipe(take(1)).subscribe((solutions) => {
            if (!cache || !solutions) {
                solutions$ = this.fetchFromAPI();
            }
        });
        return solutions$;
    }

    /**
     * Update a solution.
     *
     * @param SolutionModel
     * @returns {Observable<ProjectModel>} The solution observable.
     * @memberof SolutionService
     */
    public update(solution: SolutionModel): Observable<SolutionModel> {
        return this._http.put<SolutionModel>(`${this._url}/${solution.id}`, solution);
    }

    // Store fetching
    public fetchFromAPI(): Observable<Array<SolutionModel>> {
        return this._http
            .get<Array<SolutionModel>>(this._url, { observe: 'response' })
            .pipe(
                concatMap((data) => {
                    this._solutions = data.body
                        .filter((x) => x.active)
                        .sort((x, y) => x.displayOrder - y.displayOrder);
                    this.loadSolutionsOnStore(this._solutions);
                    return this.fetchSolutionsFromStore();
                })
            );
    }

    // Loading in store
    public loadSolutionsOnStore(solutions: Array<SolutionModel>): void {
        this._store.dispatch(addSolutions({ solutions }));
    }

    // fetchig from store
    public fetchSolutionsFromStore(): Observable<Array<SolutionModel>> {
        return this._store.pipe(select(selectSolutionsState));
    }

    public uploadBenefitIcon(file: File, solution: SolutionModel, benefitIconIndex: number): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('benefitIconIndex', benefitIconIndex + '');
        return this._http.post(`${this._url}/${solution.id}`, formData);
    }

    public uploadSlideshowImages(files, solution: SolutionModel): Observable<any> {
        const formData: FormData = new FormData();
        for (const file of files) {
            formData.append('files[]', file);
        }
        return this._http.post(`${this._url}/${solution.id}`, formData);
    }
}
