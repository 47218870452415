<niq-spinner [display]="spinner"></niq-spinner>
<div class="project-grid-container" *ngIf="!spinner || (projects && projects.length > 0)">
  <niq-empty-page [icon]="emptyConst.icon" [leadText]="emptyConst.lead_text" [supportText]="emptyConst.support_text"
    [pageSize]="'large'" *ngIf="!projects || projects.length === 0"></niq-empty-page>
  <div class="grid-header" *ngIf="projects && projects.length > 0">
    <mat-grid-list cols="24" rowHeight="50px">
      <mat-grid-tile *ngFor="let tile of gridTitle" [colspan]="tile.cols" [rowspan]="tile.rows"
        [class]="tile.className">{{ tile.text | translate }}</mat-grid-tile>
    </mat-grid-list>
  </div>
  <div class="grid-container" *ngIf="projects && projects.length > 0">
    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScroll()">
      <mat-accordion [togglePosition]="'before'" *ngFor="let project of projects"
        (click)="showHideProjectDetails(project)" multi="false">
        <mat-expansion-panel [expanded]="project.isExpanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ project.name }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <mat-card>
              <div [class]="fieldingData.length > 2 ? 'scroll-added' : ''">
                <mat-grid-list cols="8" rowHeight="50px" class="grid-header">
                  <mat-grid-tile colspan="2" class="left">{{"project.panel_home.fieldings" | translate}}</mat-grid-tile>
                  <mat-grid-tile colspan="1">{{"project.panel_home.completes" | translate}}</mat-grid-tile>
                  <mat-grid-tile colspan="1">{{"project.panel_home.starts" | translate}}</mat-grid-tile>
                  <mat-grid-tile colspan="1">{{"project.panel_home.incidence" | translate}}</mat-grid-tile>
                  <mat-grid-tile colspan="1">{{"project.panel_home.totalnSize" | translate}}</mat-grid-tile>
                  <mat-grid-tile colspan="1">{{"project.panel_home.fieldStart" | translate}}</mat-grid-tile>
                  <mat-grid-tile colspan="1">{{"project.panel_home.fieldEnd" | translate}}</mat-grid-tile>
                </mat-grid-list>
              </div>
              <niq-spinner-small *ngIf="fetchFielding"></niq-spinner-small>
              <niq-empty-page [icon]="isfieldingError ? fieldingErrorConst.icon : fieldingEmptyConst.icon"
                [leadText]="isfieldingError ? fieldingErrorConst.lead_text : fieldingEmptyConst.lead_text"
                [supportText]="isfieldingError ? fieldingErrorConst.support_text : fieldingEmptyConst.support_text"
                [pageSize]="'small'" *ngIf="(isfieldingError || !fetchFielding) && fieldingData.length === 0"
                [type]="isfieldingError ? 'error' : ''">
              </niq-empty-page>
              <div class="panel-fielding-container" *ngIf="fieldingData.length > 0">
                <mat-grid-list cols="8" rowHeight="50px" *ngFor="let fielding of fieldingData">
                  <mat-grid-tile colspan="2" class="left"><a [href]="navigate(project)" (click)="trackFieldingNav(project)">{{ fielding.surveyName }}</a>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1"> {{ fielding.completes }} </mat-grid-tile>
                  <mat-grid-tile colspan="1"> {{ fielding.starts }} </mat-grid-tile>
                  <mat-grid-tile colspan="1"> {{ incidence | number: "1.1-1" }} % </mat-grid-tile>
                  <mat-grid-tile colspan="1"> {{ fielding.targetCompletes }} </mat-grid-tile>
                  <mat-grid-tile colspan="1"> {{ fielding.startDate }} </mat-grid-tile>
                  <mat-grid-tile colspan="1"> {{ fielding.endDate }} </mat-grid-tile>
                </mat-grid-list>
              </div>
            </mat-card>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
