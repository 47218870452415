import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'niq-checkbox-tree',
    templateUrl: './checkbox-tree.component.html',
    styleUrls: ['./checkbox-tree.component.scss'],
})
export class CheckboxTreeComponent implements OnInit {
  /**
   * Property to display the list.
   *
   * @default  []
   * @type     {Array<any>}
   * @memberof CheckboxTreeComponent
   */
  @Input() public list: Array<any> = [];

  /**
   * Property to know the parent object key.
   *
   * @default  ""
   * @type     {string}
   * @memberof CheckboxTreeComponent
   */
  @Input() public parentDisplayKey: string;

  /**
   * Property to know the child list object key.
   *
   * @default  ""
   * @type     {string}
   * @memberof CheckboxTreeComponent
   */
  @Input() public childDisplayKey: string;

  /**
   * Property to know the child object key.
   *
   * @default  ""
   * @type     {string}
   * @memberof CheckboxTreeComponent
   */
  @Input() public childKey: string;

  /**
   * Property to know whether or not to keep the child list expanded.
   *
   * @default  false
   * @type     {boolean}
   * @memberof CheckboxTreeComponent
   */
  @Input() public expandable: boolean;

  /**
   * Property to know whether or not to keep the child list expanded.
   *
   * @default  ''
   * @type     {string}
   * @memberof CheckboxTreeComponent
   */
  @Input() public additionalInfo: string;

  /**
   * Property to send the updated array list.
   *
   * @default  false
   * @type     {boolean}
   * @memberof CheckboxTreeComponent
   */
  @Output() public updatedList: any = new EventEmitter();

  /**
   * Property to send the updated array list.
   *
   * @default  false
   * @type     {boolean}
   * @memberof CheckboxTreeComponent
   */
  @Output() public onlySelectedList: any = new EventEmitter();
  @Input() public selectedArrayObj = [];

  /**
   * Constructor.
   *
   * @memberof CheckboxTreeComponent
   */
  constructor() {}

  /**
   * Defining and initializing the objects
   *
   * @OnInit - angular Lifecyclehook
   */
  public ngOnInit(): void {
      this.list.forEach((ele) => {
          ele.selectedOptionsCount = ele[this.childKey].filter(
              (t: any) => t.selected
          ).length;
      });
      this.updatedList.emit(this.list);
  }

  /**
   * @method updateAllComplete
   * @param parentObj
   * @type  {any}
   * @returns {void}
   *
   * method to mark all parent node based on child node selections.
   */
  public updateAllComplete(parentObj: any): void {
      parentObj.selectedOptionsCount = parentObj[this.childKey].filter(
          (t: any) => t.selected
      ).length;
      parentObj.allComplete =
      parentObj[this.childKey] !== null &&
      parentObj[this.childKey].every((t: any) => t.selected);
      parentObj.checkState =
      parentObj.selectedOptionsCount === parentObj[this.childKey].length
          ? 2
          : parentObj.selectedOptionsCount === 0
              ? 1
              : 3;
      parentObj.selected =
      parentObj.selectedOptionsCount === parentObj[this.childKey].length
          ? true
          : parentObj.selectedOptionsCount === 0
              ? false
              : true;
      this.updatedList.emit(this.list);
  }

  /**
   * @method partialComplete
   * @param parentObj
   * @type  {any}
   * @returns {boolean}
   *
   * method to know whether or not all the child node of a parent node is selected.
   */
  public partialComplete(parentObj: any): boolean {
      if (!parentObj[this.childKey] || parentObj[this.childKey] === null) {
          return false;
      }
      return (
          parentObj[this.childKey].filter((t: any) => t.selected).length > 0 &&
      !parentObj.allComplete
      );
  }

  /**
   * @method partialComplete
   * @param {selected, parentObj}
   * @type  {boolean, any}
   * @returns {void}
   *
   * method to mark all child node of a parent node.
   */
  public setAll(selected: boolean, parentObj: any): void {
      if (!parentObj[this.childKey] || parentObj[this.childKey] === null) {
          return;
      }

      let checkState =
      parentObj.selectedOptionsCount === parentObj[this.childKey].length
          ? 2
          : parentObj.selectedOptionsCount === 0
              ? 1
              : 3;
      selected = parentObj.selected =
      parentObj.selectedOptionsCount === parentObj[this.childKey].length
          ? false
          : true;
      let flag = false;

      parentObj[this.childKey].forEach((t: any) => {
          t.selected = t.isDisableDefault ? t.isDisableDefault : selected;
          if (
              t.isRecommended === false &&
        (!parentObj.checkState ||
          parentObj.checkState === 2 ||
          checkState === 1)
          ) {
              t.selected = t.isRecommended;
              flag = true;
          }
      });
      parentObj.checkState = checkState = flag ? 3 : checkState;
      parentObj.selectedOptionsCount = parentObj[this.childKey].filter(
          (t: any) => t.selected
      ).length;
      parentObj.allComplete =
      parentObj.selectedOptionsCount === parentObj[this.childKey].length
          ? true
          : false;
      this.updatedList.emit(this.list);
  }

  public selectedList(treeBranch: string, treeObj: any, subTreeObj: any = []): void {
      const ind = this.selectedArrayObj.findIndex(
          (ele) => ele.code === treeObj.code
      );
      if (treeBranch === 'main') {
          if (ind < 0) {
              this.selectedArrayObj.push(JSON.parse(JSON.stringify(treeObj)));
              this.selectedArrayObj[0][this.childKey] = this.selectedArrayObj[0][
                  this.childKey
              ].filter((t) => t.selected);
          } else if (ind >= 0 && treeObj.selectedOptionsCount > 0) {
              this.selectedArrayObj.splice(ind, 1);
              this.selectedArrayObj.push(JSON.parse(JSON.stringify(treeObj)));
              this.selectedArrayObj[ind][this.childKey] = this.selectedArrayObj[ind][
                  this.childKey
              ].filter((t) => t.selected);
          } else {
              this.selectedArrayObj.splice(ind, 1);
          }
      } else if (treeBranch === 'sub') {
          if (ind < 0) {
              this.selectedArrayObj.push(JSON.parse(JSON.stringify(treeObj)));
              this.selectedArrayObj[0][this.childKey].splice(
                  0,
                  this.selectedArrayObj[0][this.childKey].length,
                  subTreeObj
              );
          } else {
              if (treeObj.selectedOptionsCount > 0) {
                  const subInd = this.selectedArrayObj[ind][this.childKey].findIndex(
                      (ele) => ele.locale === subTreeObj.locale
                  );
                  this.selectedArrayObj[ind][this.childKey][subInd] = subTreeObj;
                  this.selectedArrayObj[ind].selectedOptionsCount =
            treeObj.selectedOptionsCount;
                  if (subInd < 0) {
                      this.selectedArrayObj[ind][this.childKey].push(subTreeObj);
                  } else {
                      if (!this.selectedArrayObj[ind][this.childKey][subInd].selected) {
                          this.selectedArrayObj[ind][this.childKey].splice(subInd, 1);
                      }
                  }

                  const latestObj = this.selectedArrayObj.splice(ind, 1);
                  this.selectedArrayObj.push(latestObj[0]);
              } else {
                  this.selectedArrayObj.splice(ind, 1);
              }
          }
      }
      this.onlySelectedList.emit(this.selectedArrayObj);
  }
}
