import {animate,animateChild,group,query,state,style,transition,trigger} from '@angular/animations';
import { RequestProjectModel } from '../models/request-project/request-projects-model';
import { TranslateService } from '@ngx-translate/core';
import * as platformConstants from '../constant';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Title } from '@angular/platform-browser';
import {Component,EventEmitter,HostListener,Input,OnChanges,ViewChild,OnInit,Output,} from '@angular/core';
import {FILTER_DEFAULTS,FILTER_NAME_LIST,NIELSENIQ,PERMISSION_VIEW_ALL_CLIENTS,SNACK_BAR_TYPE_ERROR,SNACK_BAR_TYPE_SUCCESS,} from '../constant';
import { CheckboxArray } from '../models/checkbox-array/checkbox-array';
import {Action,DropDownModel,FilterModel,FilterOps,RequestAccessSearchModel,} from '../models/request-access-filter/request-access-filter-model';
import { MessageBarService } from '../services/mesasgeBar/message-bar.service';
import * as _ from 'lodash';
import { UserInfoService } from '../services/user/user-info.service';
import { UserInfo } from '../models/user/user-info';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { PermissionService } from '../services/permissions/permission.service';
import { RequestProjectService } from '../services/request-projects/request-project.service';
import { RequestAccessSearchFiltersComponent } from '@platform/request-access-search-filters/request-access-search-filters.component';
export class DisplayFilters {
  public name: string;
  public toolTipStr: string;
  public data: any;
}

/**
 * Model for Grid Header.
 *
 * @export
 * @interface GridHeader
 */
export interface GridHeader {
  /**
   * dynamic scss classname.
   *
   * @type      {string}
   * @memberof  GridHeader
   */
  className: string;

  /**
   * column size.
   *
   * @type      {number}
   * @memberof  GridHeader
   */
  cols: number;

  /**
   * row size.
   *
   * @type      {number}
   * @memberof  GridHeader
   */
  rows: number;

  /**
   * Header name.
   *
   * @type      {string}
   * @memberof  GridHeader
   */
  text: string;
  dbPropertyName: string;
  tooltip?: string;
}

@Component({
  selector: 'niq-request-project-access',
  templateUrl: './request-project-access.component.html',
    styleUrls: ['./request-project-access.component.scss'],
    animations: [
    trigger('bodyExpansion', [
        transition(':enter', [
            style({ height: '0px', minHeight: '0' }),
            animate('500ms ease-in', style({ height: '*', visibility: 'visible' })),
        ]),
        transition(':leave', [
            animate('500ms ease-out', style({ height: '0px', minHeight: '0' })),
        ]),
    ]),
    trigger('backgroudAnimation', [
        state('1', style({ backgroundPositionY: '0px' })),
        state('0', style({ backgroundPositionY: '*' })),
        transition('* => 1', [
            group([
                animate('500ms linear', style({ backgroundPositionY: '0px' })),
                query('@bodyExpansion', animateChild()),
            ]),
        ]),
        transition('1 => 0', [
            group([
                animate('500ms linear', style({ backgroundPositionY: '*' })),
                query('@bodyExpansion', animateChild()),
            ]),
        ]),
    ])
],
})

export class RequestProjectAccessComponent implements OnInit {

  @Output() public projectList = new EventEmitter();
  @Input() public lazyLoad: any;
  @Input() public isAnalysisStatusVisible: boolean;
  @Input() public fieldClick: any;

  public filterToggle = false;
  public savedFilterCount: number;
  public openFilter: boolean = false;
  public optionsHover: boolean = false;
  public filterNameError: string = '';
  public isProjectsNull: boolean = false;
  public isSolutionsNull: boolean = false;
  public isStatusesNull: boolean = false;
  public isCountriesNull: boolean = false;
  public menuClosed: boolean = true;
  public filterDirty: boolean = false;
  public searchProjects: boolean = false;
  public isDesc: boolean = false;
  public filterClient: DisplayFilters = new DisplayFilters();
  public filterProjects: DisplayFilters = new DisplayFilters();
  public filterSolutionTypes: DisplayFilters = new DisplayFilters();
  public filterCountries: DisplayFilters = new DisplayFilters();
  public selectedOption: string = '';

  public clients: Array<DropDownModel> = [];
  public solutionTypes: Array<CheckboxArray> = [];
  public countries: Array<CheckboxArray> = [];
  public searchPhase: string = '';

  public clientsCopy: Array<DropDownModel> = [];
  public sortByCopy: Array<DropDownModel> = [];
  public lookupsFilterCopy: Array<DropDownModel> = [];
  public solutionTypesCopy: Array<CheckboxArray> = [];
  public statusesCopy: Array<CheckboxArray> = [];
  public countriesCopy: Array<CheckboxArray> = [];
  public nameFilterCopy: Array<DropDownModel> = [];

  public channel = new BroadcastChannel('savedFilters');
  public newChanges = false;
  public selectedFilters: FilterModel;
  public searchModel: RequestAccessSearchModel = new RequestAccessSearchModel();
  public filterNameList: {} = FILTER_NAME_LIST;
  public previousSelectedFilter: FilterModel;
  public userInfo: UserInfo;
  public filterDefault = FILTER_DEFAULTS;
  public isNotInitialSelection = false;
  public clientViewPermissions = true;
  public sortByApplied = false;
  public selectedSavedFilter = '';
  public newSavedFilterName = '';
  public searchTooltipText = "Enter key words, numbers, and email addresses. Separate by comma for multi-search";
  public newSavedFilterNameCopy: string = '';
  public userFilterAction: string = '';
  @Input() message: string;
  @Input() successmessage: string;
  @Input() successstatus: string;
  @Input() errorstatus: string;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild(RequestAccessSearchFiltersComponent, { static: false }) filtersComponent: RequestAccessSearchFiltersComponent;
  public requestprojects: Array<RequestProjectModel> = [];
  public gridTitle: Array<GridHeader> = [];
  public emptyConst = platformConstants.EMPTY_STATES.request_access_search;
  public pendingRequestEmptyState = platformConstants.EMPTY_STATES.pending_request_empty;
  public emptySearchConst = platformConstants.EMPTY_STATES.request_access_empty;
  public approvalRequestEmptyState = platformConstants.EMPTY_STATES.approval_request_empty;
  public searchApplied = false;
  searchQuery: string;
  searchText: string[];
  gridData: RequestProjectModel[] = [];
  pendingRequestData: RequestProjectModel[] = [];
  requestApprovalData: RequestProjectModel[] = [];
  requestAccessSearchObj: RequestAccessSearchModel;
  gridVal: any[] = [];
  public spinner = false;
  public allDataLoaded: boolean = false;
  public selectAllChecked: boolean = false;
  public countriesHover: boolean = false;
  public offset: number = 0;
  public limit: number = 35;
  public projectCount: number = 35;
  public selectAllCheckbox: boolean = false;
  public selectAllCheckboxApproval: boolean = false;
  public searchSubmitted: boolean = false;
  public selectedProjectCountForRequest = 0;
  public selectedProjectCountForApproval = 0;
  public maxSelectionLimit = 10;
  item: RequestProjectModel;
  isFullFailureAlertVisible: boolean = false;
  isAlreadyApproveException: boolean = false;
  isPartialFailureAlertVisible: boolean = false;
  isSuccessAlertVisible: boolean = false;
  isFooterAbsolute: boolean = false;
  isFooterAbsoluteApproval: boolean = false;
  showMargin: boolean = false;
  showMarginApproval: boolean = false;
  loadedApprovalProjectCount = 0;
  columns : string[] = ['checkbox','chevron','project-name','project-number','product','country','client','date-created'];
  pendingRequestColumns : string[] = ['chevron','project-name','project-number','product','country','client','date-created','date-requested'];
  approvalRequestColumns : string[] = ['checkbox','chevron','project-name','project-number','product','country','client','date-requested','requested-by'];
  searchValue: string;
  receivedData: any;
  receivedResponse: any;

  constructor(public _analyticsService: AnalyticsService,
      public translate: TranslateService,
      public requestProjectService: RequestProjectService,
      private route: ActivatedRoute,
      private _titleService: Title,
      public router: Router,
      private _toaster: MessageBarService,
      private _userInfoService: UserInfoService,
      private _permissions: PermissionService) {
      this._titleService.setTitle("NIQ Studio - Project Access");
  }

  onTabChange(event: any) {
    const activeTabIndex = event.index;
    localStorage.setItem('selectedTabIndex', activeTabIndex.toString());
    switch (activeTabIndex) {
      case 0:
      this.router.navigate(['/request']);
      this.isSuccessAlertVisible = false;
      this.isPartialFailureAlertVisible = false;
      this.isAlreadyApproveException = false;
      this.isFooterAbsoluteApproval = false;
      this._analyticsService.track('uf-ProjAcc-ReqAccT-clicked');
      break;
      case 1:
        this.router.navigate(['/request']);
        this.fetchPendingRequestsTabData();
        this._analyticsService.track('uf-ProjAcc-PendReqT-clicked');
        this.isAlreadyApproveException = false;
        this.isFooterAbsoluteApproval = false;
        break;
      case 2:
        this.isSuccessAlertVisible = false;
        this.isPartialFailureAlertVisible = false;
        this.isFooterAbsoluteApproval = false;
        this.fetchApprovalsTabData();
        this._analyticsService.track('uf-ProjAcc-ApprovT-clicked');
        break;
      default:
        break;
    }
    this.isFullFailureAlertVisible = false;
    this.pendingRequestData = [];
    if (activeTabIndex === 0) {
      this.spinner = true;
      setTimeout(() => {
        this.spinner = false;
      }, 500); 
    }
    this.requestApprovalData = [];
  }

  fetchPendingRequestsTabData() {
    this.spinner = true;
    this.requestProjectService.getPendingRequestData().subscribe({
      next: (data: any) => {
        if (typeof data === 'object') {
          this.gridVal = Object.values(data);
          const newData = this.gridVal[6];
          this.pendingRequestData = [...this.pendingRequestData, ...newData];
          newData.forEach((project) => {
            project.conceptData = [];
            project.isConceptDataLoaded = false;
          });
          if (newData.length < this.limit) {
            this.allDataLoaded = true;
          }
        } else {
          this.pendingRequestData = [];
        }
        this.spinner = false;
      },
      error: (error: any) => {
        console.error(error);
        this.pendingRequestData = [];
        this.spinner = false;
      }
    });
  }
  
  fetchApprovalsTabData() {
    this.spinner = true;
    this.showMarginApproval = false;
    this.selectAllCheckboxApproval= false;
    this.selectedProjectCountForApproval = 0;
    this.requestProjectService.getApprovalRequestData().subscribe({
      next: (data: any) => {
        if (typeof data === 'object') {
           this.gridVal = Object.values(data);
           const newData = this.gridVal[6];
          
           this.requestApprovalData = [...this.requestApprovalData, ...newData.slice(0, this.projectCount)];
           this.loadedApprovalProjectCount = this.projectCount;
           newData.forEach((project) => {
             project.conceptData = [];
             project.isConceptDataLoaded = false;
           });
           if (newData.length < this.limit) {
             this.allDataLoaded = true;
           }
         } else {
           this.requestApprovalData = [];
         }
         this.spinner = false;
         this.router.navigate(['/request']);
      },
      error: (error: any) => {
        console.error(error);
        this.requestApprovalData = [];
        this.spinner = false;
      }
    });
  
  }

  checkboxselectionLimitReachedRequest() {
    const selectedItems = this.gridData.filter(item => item.isSelected);
    this.selectedProjectCountForRequest = selectedItems.length;
  }

  checkboxselectionLimitReachedApproval() {
    const selectedItems = this.requestApprovalData.filter(item => item.isSelected);
    this.selectedProjectCountForApproval = selectedItems.length;
  }

  handleGridData(data: any) {
    this.receivedData = data;
    this.searchApplied = true;
    this.spinner = true;
    this.getProjectDetails(true);
  }

  handleFilterModel(response: any) {
    this.receivedResponse = response;
  }

  searchReset(value: boolean)
  {
    this.gridData = [];
    this.searchQuery = '';
    this.searchApplied = value;
  }

  requestAccess() {
    // Filter the selected items from the gridData based on isSelected property
    this.spinner = true;
    const selectedItems = this.gridData.filter(item => item.isSelected);
      if(this.gridData.every(item => item.isSelected))
          {
            this._analyticsService.track('uf-ProjAcc-ReqAccT-AllChckbx-selected', {
              requestProjectCount: selectedItems.length,
            });
          }
      else{
            this._analyticsService.track('uf-ProjAcc-ReqAccT-IndivProjChckbx-selected', {
              requestProjectCount: selectedItems.length,
          });
          }
      this.requestProjectService.requestAccess(selectedItems).subscribe(
      response => {
        
        if (response.successfulRequestProjects.length === 0) {
          this.errorstatus = 'error';
          this.message = "Something went wrong. Please try again.";
          this.isFullFailureAlertVisible = true;
          this.spinner = false;
        }
        else if (response.failRequestProjects.length > 0 && response.successfulRequestProjects.length > 0) {
          this.tabGroup.selectedIndex = 1;
          this.errorstatus = 'error';
          this.message = "There was a problem requesting access with one or more projects. Please try again.";
          this.isPartialFailureAlertVisible = true;
          this.successstatus = 'success';
          this.successmessage = "Request sent, pending owner's approval.";
          this.isSuccessAlertVisible = true;
          const hideSuccessAlertTimeout = 2500; // Adjust the time in milliseconds
          setTimeout(() => {
            this.isSuccessAlertVisible = false;
          }, hideSuccessAlertTimeout);
          const successfulProjectIds = [];
          for (const project of response.successfulRequestProjects) {
            const item = this.gridData.find(item => (item.referenceId === project.currentProjectId));
            if (item) {
              item.isSelected = false;
              successfulProjectIds.push({projectId: item.referenceId});
            }
          }
          this.gridData = this.gridData.filter(item => !successfulProjectIds.find(iterator => (iterator.projectId === item.referenceId)));
          this.spinner = false;
        }
        else if (response.failRequestProjects.length === 0 && response.successfulRequestProjects.length > 0)
        {
          this.tabGroup.selectedIndex = 1;
          this.successstatus = 'success';
          this.successmessage = "Request sent, pending owner's approval.";
          this.isSuccessAlertVisible = true;
          const hideSuccessAlertTimeout = 2500; // Adjust the time in milliseconds
          setTimeout(() => {
            this.isSuccessAlertVisible = false;
          }, hideSuccessAlertTimeout);
          this.gridData = [];
          this.clearSearchQuery();
          this.searchApplied = false;
          this.spinner = false;
          this.filtersComponent.resetFilter(false);
        }
      },
      error => {
        // Handle the error
        console.error("Error updating projects:", error);
        this.errorstatus = 'error';
        this.message = "Something went wrong. Please try again.";
        this.isFullFailureAlertVisible = true;
        this.spinner = false;
      }
    );
    this._analyticsService.track('uf-ProjAcc-ReqAccT-ReqB-clicked');
  }

  trackCancelRequestAccess() {
    this._analyticsService.track('uf-ProjAcc-ReqAccT-CancB-clicked');
  }

  trackCancelApproveRequest() {
    this._analyticsService.track('uf-ProjAcc-ApprovT-CancB-clicked');
  }

  approveRequest() {
    this.spinner = true;
    this.isPartialFailureAlertVisible = false;
    this.isFullFailureAlertVisible = false;
    this.isAlreadyApproveException = false;
    this._analyticsService.track('uf-ProjAcc-ApprovT-ApprovB-clicked');
    // Filter the selected items from the requestApprovalData based on isSelected property
    const selectedItems = this.requestApprovalData.filter(item => item.isSelected);
    if(this.requestApprovalData.every(item => item.isSelected))
          {
            this._analyticsService.track('uf-ProjAcc-ApprovT-AllChckbx-selected', {
              approveProjectCount: selectedItems.length,
          });
          }
    else{
            this._analyticsService.track('uf-ProjAcc-ApprovT-IndivProjChckbx-selected', {
              approveProjectCount: selectedItems.length,
          });
          }
    this.requestProjectService.approveRequest(selectedItems).subscribe(
      (response) => {
        
        if (response.successfulRequestProjects.length === 0) {
          const resourceNotFoundProject = response.failRequestProjects.find(project => project.failReason === "ResourceNotFoundException");
          if (resourceNotFoundProject){
            this.isFullFailureAlertVisible = false;
            this.errorstatus = 'approvalWarning';
            this.message = "Something changed. Refresh the page to see the project list updated.";
            this.isAlreadyApproveException = true;
            this.spinner = false;
            
          }
          else{
            this.errorstatus = 'error';
            this.message = "Something went wrong. Please try again.";
            this.isFullFailureAlertVisible = true;
            this.spinner = false;
          }
           
        }
        else if (response.failRequestProjects.length > 0 && response.successfulRequestProjects.length > 0) {
          this.errorstatus = 'error';
          this.message = "There was a problem granting access to one or more projects. Please try again.";
          this.isPartialFailureAlertVisible = true;
          this.isAlreadyApproveException = false;
          this.successstatus = 'success';
          this.successmessage = "Access Successfully Approved";
          this.isSuccessAlertVisible = true;
          const hideSuccessAlertTimeout = 2500; // Adjust the time in milliseconds
          setTimeout(() => {
            this.isSuccessAlertVisible = false;
          }, hideSuccessAlertTimeout);
          const successfulProjectIds = [];
          for (const project of response.successfulRequestProjects) {
            const item = this.requestApprovalData.find(item => (item.referenceId === project.currentProjectId && item.requestedByUserId === project.requestedByUserId));
            if (item) {
              item.isSelected = false;
              successfulProjectIds.push({projectId: item.referenceId, requestedByUserId: item.requestedByUserId});
            }
          }
          this.requestApprovalData = this.requestApprovalData.filter(item => !successfulProjectIds.find(iterator => (iterator.projectId === item.referenceId && iterator.requestedByUserId === item.requestedByUserId)));
          this.spinner = false; 
        }
        else if (response.failRequestProjects.length === 0 && response.successfulRequestProjects.length > 0)
        {
          this.successstatus = 'success';
          this.successmessage = "Access Successfully Approved";
          this.isSuccessAlertVisible = true;
          const hideSuccessAlertTimeout = 2500; // Adjust the time in milliseconds
          setTimeout(() => {
            this.isSuccessAlertVisible = false;
          }, hideSuccessAlertTimeout);
          const successfulProjectIds = [];
          for (const project of response.successfulRequestProjects) {
            const item = this.requestApprovalData.find(item => (item.referenceId === project.currentProjectId && item.requestedByUserId === project.requestedByUserId));
            if (item) {
              successfulProjectIds.push({projectId: item.referenceId, requestedByUserId: item.requestedByUserId});
            }
          }
          this.requestApprovalData = this.requestApprovalData.filter(item => !successfulProjectIds.find(iterator => (iterator.projectId === item.referenceId && iterator.requestedByUserId === item.requestedByUserId)));
          
          this.spinner = false;        
        }
      },
      error => {
        
        if(error.error.failRequestProjects[0].failReason === "ResourceNotFoundException") { 
         this.isFullFailureAlertVisible = false;
         this.errorstatus = 'approvalWarning';
         this.message = "Something changed. Refresh the page to see the project list updated.";
         this.isAlreadyApproveException = true;
         this.spinner = false;
         
        }
        else {
          this.errorstatus = 'error';
          this.message = "Something went wrong. Please try again.";
          this.isFullFailureAlertVisible = true;
          this.isAlreadyApproveException = false;
          this.spinner = false;
        }
      }
    );
  }

  toggleSelectAll() {
    for (let item of this.gridData) {
      item.isSelected = this.selectAllCheckbox;
    }
  }

  //**unselect all checkboxes from request tab*/
  unselectAll(){
    for (let item of this.gridData) {
      item.isSelected = false;
    }
    this.selectedProjectCountForRequest = 0;
    this._analyticsService.track('uf-ProjAcc-ReqAccT-Reset-clicked');
  }
  
  //**unselect all checkboxes from approval tab*/
  unselectAllForApprove(){
    for (let item of this.requestApprovalData) {
      item.isSelected = false;
    }
    this.selectedProjectCountForApproval = 0;
    this._analyticsService.track('uf-ProjAcc-ApprovT-Reset-clicked');
  }

  toggleSelectAllForApprove() {
    for (let item of this.requestApprovalData) {
      item.isSelected = this.selectAllCheckboxApproval;
    }
  }


  partialComplete(): boolean {
    const selectedCount = this.gridData.filter(item => item.isSelected).length;
    return selectedCount > 0 && selectedCount < this.gridData.length;
  }

  partialCompleteForApprove(): boolean {
    const selectedCount = this.requestApprovalData.filter(item => item.isSelected).length;
    return selectedCount > 0 && selectedCount < this.requestApprovalData.length;
  }

  isAllOptionsSelected(): boolean {
    return this.gridData.every(item => item.isSelected);
  }

  isAllOptionsSelectedApproval(): boolean {
    return this.requestApprovalData.every(item => item.isSelected);
  }


  public isButtonDisabled(): boolean {
    if (!this.gridData || this.gridData.length === 0) {
      return true;
    }
    return this.gridData.every(item => !item.isSelected) || this.selectedProjectCountForRequest > this.maxSelectionLimit;
  }

  public isApproveButtonDisabled(): boolean {
    if (!this.requestApprovalData || this.requestApprovalData.length === 0) {
      return true;
    }
    return this.requestApprovalData.every(item => !item.isSelected) || this.selectedProjectCountForApproval > this.maxSelectionLimit;
  }

  public showHideProjectDetails(item: any): void {
    if (!item) {
      return;
    }
    item.isExpanded = !item.isExpanded;
    if (!item.isConceptDataLoaded && item.isExpanded) {
      const conceptDataPromises: Promise<any>[] = [];
      item.links.forEach((link: any) => {
        if (link.rel === 'concepts_api' || link.rel === 'skuLists' || link.rel === 'designOptions') {
          const promise = this.requestProjectService.fetchConceptData(link.href).toPromise();
          conceptDataPromises.push(promise);
        }
      });
  
      Promise.all(conceptDataPromises).then((conceptDataArray: any[]) => {
        item.conceptData = conceptDataArray
          .map((conceptData: any) => conceptData.map((concept: any) => concept.name))
          .reduce((acc: any[], curr: any[]) => acc.concat(curr), []);
  
        item.isConceptDataLoaded = true;
      });
    }
    if (item.isExpanded){
      if(this.tabGroup.selectedIndex===0) {
        this._analyticsService.track('uf-ProjAcc-ReqAccT-ConcCaret-expanded');     
      } 
      else if (this.tabGroup.selectedIndex===1) {
        this._analyticsService.track('uf-ProjAcc-PendReqT-ConcCaret-expanded');
      }
      else if (this.tabGroup.selectedIndex===2) {
        this._analyticsService.track('uf-ProjAcc-ApprovT-ConcCaret-expanded');
      }
    }
    else if (!item.isExpanded) {
      if(this.tabGroup.selectedIndex===0) {
      this._analyticsService.track('uf-ProjAcc-ReqAccT-ConcCaret-collapsed'); 
      }
      else if(this.tabGroup.selectedIndex===1) {
        this._analyticsService.track('uf-ProjAcc-PendReqT-ConcCaret-collapsed');
      }
      else if(this.tabGroup.selectedIndex===2) {
        this._analyticsService.track('uf-ProjAcc-ApprovT-ConcCaret-collapsed');
      }      
    }
  }

  getProjectDetails(refreshModel: boolean = false) {
    this.selectedProjectCountForRequest = 0;
    this.isFullFailureAlertVisible = false;
    this.selectAllCheckbox = false;
    if (refreshModel) {
      this.gridData = [];
      this.offset = 0;
      this.limit = 35;
      this.allDataLoaded = false;
    }
    
    this.gridVal = this.receivedData;
    const newData = this.gridVal[1];
              this.gridData = [...this.gridData, ...newData];
              newData.forEach((project) => {
                project.conceptData = [];
                project.isConceptDataLoaded = false;
              });
              if (newData.length < this.limit) {
                this.allDataLoaded = true;
              }
    this.spinner = false;
    }

clearSearchQuery() {
  this.searchQuery = '';
}

onInputChange() {
  this.searchSubmitted = false;
}

closeAlert(){
  this.isSuccessAlertVisible = false;  
}

closeAlertAlreadyApprovalWarning() {
  this.isAlreadyApproveException = false;
  this._analyticsService.track('uf-hm-ApprovT-SthChangedPrompt-dismissed');

}

getExpandTooltipText(item: any): string {
  let message;
  item.links.some(link => {
    switch(link.rel){
      case 'concepts_api':
        message = 'Show concepts list';
        return true;
      case 'skuLists':
        message = 'Show SKU lists';
        return true;
      case 'designOptions':
        message = 'Show Design Options lists'
        return true;
    }
  });
  return message;
}

getCollapsedTooltipText(item: any): string {
  let message;
  item.links.some(link => {
    switch(link.rel){
      case 'concepts_api':
        message = 'Hide concepts list';
        return true;
      case 'skuLists':
        message = 'Hide SKU lists';
        return true;
      case 'designOptions':
        message = 'Hide Design Options lists'
        return true;
    }
  });
  return message;
}

getNoDataMessage(item: any): string {
  let message;
  item.links.some(link => {
    switch(link.rel){
      case 'concepts_api':
        message = 'No concepts available';
        return true;
      case 'skuLists':
        message = 'No SKU lists available';
        return true;
      case 'designOptions':
        message = 'No Design Options available'
        return true;
    }
  });
  return message;
}

onScroll() {
    if(this.searchApplied){
    if (this.allDataLoaded) {
      return;
    }
    this.spinner = true;
    const scrollPosition = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const isScrolledToEnd = scrollPosition + windowHeight >= documentHeight - 2000;
    if (isScrolledToEnd || scrollPosition + windowHeight === documentHeight) {
      this.isFooterAbsolute = true;
      this.isFooterAbsoluteApproval = true;
      if(this.tabGroup.selectedIndex === 0){
      this.showMargin = true;
      }
      else if(this.tabGroup.selectedIndex === 2){
        this.showMarginApproval = true;
      }
      this.offset += this.limit;
      this.FetchProjectOnScroll();
    }
    else{
      this.isFooterAbsoluteApproval = false;
      this.isFooterAbsolute = false;
    }
  }
  
}

FetchProjectOnScroll(){
  this.requestProjectService.getProjects(this.receivedResponse, this.limit, this.offset).subscribe(
    (data) => {
        this.projectList.emit(data.data);
        this.projectCount = data.meta.count;
        this.selectedFilters = data.filters;
        this.searchPhase = data.searchPhrase
                  ? data.searchPhrase
                  : this.searchModel.searchPhrase;

        if (!data.data || data.data.length === 0) {
            this.limit = 0;
        }

        if (typeof data === 'object') {
          // Convert the object to an array
          this.gridVal = Object.values(data);
          const newData = this.gridVal[1];
          this.gridData = [...this.gridData, ...newData];
          newData.forEach((project) => {
            project.conceptData = [];
            project.isConceptDataLoaded = false;
          });
          if (newData.length < this.limit) {
            this.allDataLoaded = true;
          }
        }
        this.searchApplied = true;
        this.spinner = false;
    },
    () => {
        this._toaster.openSnackBar(
            SNACK_BAR_TYPE_ERROR,
            'Internal Error. Try again later.'
        );
        this.gridData = [];
        this.spinner = false;
        this.errorstatus = 'error';
        this.message = "Something went wrong. Please try again.";
        this.isFullFailureAlertVisible = true;
    },
    
);
}

onScrollApproval() {
  const scrollPosition = window.pageYOffset;
  const windowHeight = window.innerHeight;
  const documentHeight = document.documentElement.scrollHeight;
  const isScrolledToEnd = scrollPosition + windowHeight >= documentHeight - 3000;

  if (isScrolledToEnd || scrollPosition + windowHeight === documentHeight) {
    // Load the next set of 35 items
    const newData = this.gridVal[6].slice(this.loadedApprovalProjectCount, this.loadedApprovalProjectCount + this.projectCount);
    this.loadedApprovalProjectCount += this.projectCount;

    if (newData.length > 0) {
      this.requestApprovalData = [...this.requestApprovalData, ...newData];
    } else {
      this.allDataLoaded = true; // Assuming all data has been loaded from the server
    }

    this.spinner = false;
    this.isFooterAbsoluteApproval = true;
    this.showMarginApproval = true;
  } else {
    this.isFooterAbsoluteApproval = false;
    this.isFooterAbsolute = false;
  }
}

clear() {
  this.clearSearchQuery();
  this.searchApplied = false;
  this.gridData = [];
}
  
ngOnInit(): void {
}

ngAfterViewInit(): void {
  this.route.queryParams.subscribe((params) => {
    const selectedIndexParam = Number(params['selectedtab']);
    if (selectedIndexParam===0) {
      localStorage.setItem('selectedTabIndex', "0");
      this.tabGroup.selectedIndex = 0;
      this.router.navigate(['/request']);
    }
    else if (selectedIndexParam===2) {
      localStorage.setItem('selectedTabIndex', "2");
      this.tabGroup.selectedIndex = 2;
    }
    else if((selectedIndexParam!==0)){
      const storedTabIndex = localStorage.getItem('selectedTabIndex');
      if (storedTabIndex !== null) {
      this.tabGroup.selectedIndex = parseInt(storedTabIndex, 10);
      }
    }
  });
}

ngOnDestroy(): void {
  localStorage.setItem('selectedTabIndex', "0");
}

handleClearEvent() {
  this.clear();
}

}
