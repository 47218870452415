<div class="home-container" >
  <div class="tool-bar" >
    <div class="tool-bar-container align-items-center">
      <div class="tool-bar-left">
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="navigateToHome($event)">
            <span class="sif sif-home"></span>
            <span>{{"project.toolbar.name" | translate}}</span>
          </button>
        </mat-menu>
        <label class="header-label">{{
          "project.toolbar.name" | translate
          }}</label>
      </div>
    </div>
  </div>
  <div>
    <niq-panel-project-details ></niq-panel-project-details>
  </div>
</div>
