import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SolutionService } from '../services/solutions/solution.service';
import { SolutionModel } from '../models/solution/solution-model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BenefitIconsModalComponent } from '../modals/benefit-icons-modal/benefit-icons-modal.component';
import { ProjectInfoModalComponent } from '../modals/project-info-modal/project-info-modal.component';
import * as platformConstants from '../constant';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'niq-list-solutions',
    templateUrl: './list-solutions.component.html',
    styleUrls: ['./list-solutions.component.scss'],
})
export class ListSolutionsComponent implements OnInit {
    public titleOneLabel: string = this.translate.instant(
        'solution.properties.titleOne'
    );
    public descriptionOneLabel: string = this.translate.instant(
        'solution.properties.descriptionOne'
    );
    public titleTwoLabel: string = this.translate.instant(
        'solution.properties.titleTwo'
    );
    public descriptionTwoLabel: string = this.translate.instant(
        'solution.properties.descriptionTwo'
    );
    public displayedColumns = [
        'drag',
        'name',
        'titleOne',
        'descriptionOne',
        'titleOneIcon',
        'titleTwo',
        'descriptionTwo',
        'titleTwoIcon',
        'slides',
        'edit',
    ];
    @ViewChild(MatTable) public solutionsTable!: MatTable<any>;

    /**
     * Hidden input element reference.
     *
     * @type {ElementRef}
     * @memberof ReportFilesComponent
     */
    @ViewChild('uploaderInput0') public uploaderInput0: ElementRef;

    /**
     * Hidden input element reference.
     *
     * @type {ElementRef}
     * @memberof ReportFilesComponent
     */
    @ViewChild('uploaderInputForSlideshow') public uploaderInputForSlideshow: ElementRef;

    /**
     * Hidden input element reference.
     *
     * @type {ElementRef}
     * @memberof ReportFilesComponent
     */
    @ViewChild('uploaderInput1') public uploaderInput1: ElementRef;

    public uploaderInputForBenefit = [];

    /**
     * Flag to enable/disable spinner
     *
     * @type      {number}
     * @memberof  SolutionsComponent
     */
    public spinner: boolean = false;

    /**
     * solutions array object.
     *
     * @type      {Array<SolutionModel>}
     * @memberof  SolutionsComponent
     */
    public solutions: Array<SolutionModel> = [];

    public highlightedSolutionID: string;
    public placeHolderImage: string = platformConstants.DEFAULT_IMAGE_NAME;

    constructor(
        private _router: Router,
        public dialog: MatDialog,
        public translate: TranslateService,
        private _service: SolutionService
    ) { }

    public ngOnInit(): void {
        this.spinner = true;
        this._service.get(false).subscribe((data: any) => {
            this.spinner = false;
            if (data) {
                this.solutions.length = 0;
                this.solutions = this.solutions.concat(data);
            }
        });
    }

    public highlight(solution: SolutionModel, val: boolean): void {
        if (val) {
            this.highlightedSolutionID = solution.id;
        } else {
            this.highlightedSolutionID = '';
        }
    }

    /**
     * Opens the dialog to view benefit images of a solution.
     *
     * @returns  {void}
     * @memberof SolutionTypeComponent
     */
    public openBenefitDialog(solution: SolutionModel, benefitIndex: number): void {
        this.dialog.open(BenefitIconsModalComponent, {
            maxWidth: platformConstants.BENEFIT_MODAL_WIDTH,
            data: { solution: solution, benefit: solution.benefits[benefitIndex] },
        });
    }

    /**
     * Opens the edit of a solution.
     *
     * @returns  {void}
     * @memberof SolutionsComponent
     */
    public edit(solution: SolutionModel): void {
        this._router.navigateByUrl('solutions/' + solution.id);
    }

    /**
     * Opens the dialog to view benefits and images of a solution.
     *
     * @returns  {void}
     * @memberof SolutionsComponent
     */
    public openCarousalDialog(solution: SolutionModel): void {
        this.dialog.open(ProjectInfoModalComponent, {
            maxWidth: platformConstants.MODAL_WIDTH,
            data: solution,
        });
    }

    public drop(event: CdkDragDrop<SolutionModel[]>): void {
        let solutionMoved: SolutionModel;
        if (event.currentIndex === event.previousIndex) {
            return;
        }
        this.spinner = true;
        moveItemInArray(this.solutions, event.previousIndex, event.currentIndex);
        solutionMoved = { ...this.solutions[event.currentIndex] };
        if (event.currentIndex === 0) {
            solutionMoved.displayOrder = 0;
        } else {
            solutionMoved.displayOrder =
                this.solutions[event.currentIndex - 1].displayOrder + 1;
        }
        this._service.update(solutionMoved).subscribe(() => {
            this.ngOnInit();
            this.solutionsTable.renderRows();
            this.spinner = false;
        });
    }
}
