<niq-spinner [display]="spinner"></niq-spinner>
<div class="tool-bar-area">
    <div class="tool-bar-left">
        <a routerLink="/home" matTooltip="Go back to my projects" matTooltipClass="custom-tooltip"><span
                class="sif sif-arrow-w"></span></a>
        <label class="header-label">
            REQUEST PROJECT ACCESS
        </label>
    </div>
</div>
<hr class="toolbar-divider-line">

<div class="main-area">

    <div class="alert success-box d-flex" role="alert" [ngClass]="successstatus === 'success' ? 'success': 'error'"
        *ngIf="isSuccessAlertVisible">
        <div class="icon d-flex align-items-center justify-content-center">
            <mat-icon [ngClass]="successstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
        </div>
        <div class="message w-100">{{ successmessage }}</div>
        <div class="close-icon d-flex align-items-center justify-content-center" (click)="closeAlert()">
            <mat-icon class="sif sif-close"></mat-icon>
        </div>
    </div>

    <mat-tab-group #tabGroup class="custom-tab-group" (selectedTabChange)="onTabChange($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <div matTooltip="Search for and request access to Projects you are not a Collaborator on"
                    matTooltipClass="center-tooltip">REQUEST ACCESS</div>
            </ng-template>
                <niq-request-access-search-filters class="filter-section" (spinner)="spinner = $event"
                (resetOffset)="offset = $event" (clearEvent)="handleClearEvent()" (limitExceeded)="limit = $event" (dataEvent)="handleGridData($event)" (responseEvent)="handleFilterModel($event)"
                (searchResetChange)="searchReset($event)">
                </niq-request-access-search-filters>
            
            <hr class="divider-line">

            <div class="request-project-access-container" *ngIf="gridData && gridData.length > 0">
                <div class="blank-area"></div>
                <div class="prompt-area">
                    <div class="alert align-items-center" role="alert"
                        [ngClass]="errorstatus === 'success' ? 'success': 'error'" *ngIf="isFullFailureAlertVisible">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="errorstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ message }}</div>
                    </div>
                </div>
                <div class="table-area" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500"
                    (scrolled)="onScroll()" [scrollWindow]="false">
                    <mat-table [dataSource]="gridData" class="mat-elevation-z8">
                        <ng-container matColumnDef="checkbox">
                            <th mat-header-cell *matHeaderCellDef>
                              <!-- <mat-checkbox class="custom-checkbox checkbox-container" [(ngModel)]="selectAllCheckbox"
                                (change)="toggleSelectAll()" [checked]="isAllOptionsSelected()"
                                [indeterminate]="partialComplete()" style="padding-left: 2px;"></mat-checkbox>    #Commented out as per the UF-2174 requirement, selectAll to be disabled-->
                            </th>
                            <td mat-cell *matCellDef="let item">
                           <mat-checkbox class="custom-checkbox checkbox-container" [(ngModel)]="item.isSelected"(ngModelChange)="checkboxselectionLimitReachedRequest()" style="padding-left: 2px;"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="chevron">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let item" [ngStyle]="{ cursor: 'pointer' }"
                                (click)="showHideProjectDetails(item); $event.stopPropagation()">
                                <span class="chevron" *ngIf="!item.isExpanded">
                                    <span class="sif sif-chevron-e custom-chevron"
                                        [matTooltip]="getExpandTooltipText(item)" [matTooltipClass]="['custom-tooltip']"></span>
                                </span>
                                <span class="chevron" *ngIf="item.isExpanded">
                                    <span class="sif sif-chevron-s custom-chevron"
                                        [matTooltip]="getCollapsedTooltipText(item)" [matTooltipClass]="['custom-tooltip']"></span>
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="project-name">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Project Name</span> </th>
                            <td mat-cell *matCellDef="let item">
                                <span class="bold-text" style="cursor: text;">
                                    {{ item.name }}
                                </span>
                                <div *ngIf="item.isExpanded">
                                    <span class="shifted-list" style="padding-left: 12.1%;">
                                        <div *ngFor="let concept of item?.conceptData" style="margin-bottom: 10px;">
                                            <span style="cursor: text;">{{ concept }}
                                                <br></span>
                                        </div>
                                        <div class="no-data"
                                            *ngIf="item.isConceptDataLoaded && (!item?.conceptData || item?.conceptData.length === 0)"
                                            style="margin-bottom: 10px;">
                                            <span style="cursor: text;">{{ getNoDataMessage(item) }}</span>
                                        </div>
                                    </span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="project-number">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">BASES Project Number</span> </th>
                            <td mat-cell *matCellDef="let item">
                                <span
                                [matTooltip]="item.workbenchId === null ? 'No Project Number Assigned' : null"
                                [matTooltipClass]="['custom-tooltip-text-rqstaccess']"
                                class="tooltip-container" style="cursor: text;"
                              > {{item.workbenchId ? item.workbenchId : '--'}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="product">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Product</span> </th>
                            <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.solutionName}}</span></td>
                        </ng-container>
                        <ng-container matColumnDef="country">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Country</span> </th>
                            <td mat-cell *matCellDef="let item" (mouseenter)="countriesHover = item?.countries.fielding.length > 1" (mouseleave)="countriesHover = false">
                                <div [matTooltip]="item?.countries.fielding.length > 1 ? item?.countries.fielding: '' " matTooltipClass="custom-tooltip">
                                    <span style="cursor: text;">{{
                                        item?.countries.fielding.length > 1
                                        ? item?.countries.fielding[0] +
                                        "...(" +
                                        item.countries.fielding.length +
                                        ")"
                                        : item?.countries.fielding[0]
                                        }}</span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="client">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Client</span> </th>
                            <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.client}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="date-created">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Date Created</span> </th>
                            <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.dateCreated | date: 'MMM d, yyyy'}}</span> </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let item; columns: columns"></tr>
                    </mat-table>

                </div>
                <div class="footer-area">
                    <hr class="footer-divider-line">
                    <div style="display: flex; align-items: center; margin-top: 8px; margin-bottom: 8px;">
                        <div class="submit-button">
                            <button class="get-started-btn mat-flat-button submit-btn" mat-raised-button type="submit"
                                [disabled]="isButtonDisabled()" (click)="requestAccess()" [matTooltip]="selectedProjectCountForRequest > this.maxSelectionLimit ? 'Limit of 10 projects per request' : null"
                                [matTooltipClass]="['custom-tooltip']">
                                {{ "project.Request_Access.button" | translate | uppercase}}
                            </button>
                        </div>
                        <div class="cancel-button">
                            <button routerLink="/home" mat-button class="mat-flat-button cancel-btn"
                                (click)="trackCancelRequestAccess()">
                                {{ "project.create_project.cancel" | translate | uppercase }}
                            </button>
                          
                        </div>
                        <div class="count-request" *ngIf="selectedProjectCountForRequest > 0" >
                            Projects Selected: {{selectedProjectCountForRequest}}
                        </div> 
                        <div *ngIf="selectedProjectCountForRequest > 0" matTooltip="Clear all selections" matTooltipClass="center-tooltip">
                            <button  (click)="unselectAll()" class="resetButton">
                            {{"Reset"}}
                        </button>
                        </div> 
                    </div>

                </div>
                <div class="extra-area"></div>
            </div>

            <div class="request-access-empty-container" *ngIf="!(gridData && gridData.length > 0)">
                <div class="blank-area"></div>
                <div class="main-area">
                    <div  *ngIf="!(gridData && gridData.length > 0)">
                        <niq-empty-page [icon]="emptyConst.icon" [leadText]="emptyConst.lead_text"
                            [supportText]="emptyConst.support_text" [pageSize]="'large'"
                            *ngIf="searchApplied && gridData.length===0 && !spinner"></niq-empty-page>
                        <niq-empty-page [icon]="emptySearchConst.icon" [leadText]="emptySearchConst.lead_text"
                            [supportText]="emptySearchConst.support_text" [pageSize]="'large'"
                            *ngIf="!searchApplied && !spinner"></niq-empty-page>
                    </div>
                </div>
            </div>

        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div matTooltip="View your requested Projects waiting approval" matTooltipClass="center-tooltip">PENDING
                    REQUESTS</div>
            </ng-template>

            <div class="pending-request-container">
                <div class="blank-area"></div>
                <div class="prompt-area">
                    <div class="alert d-flex" role="alert"
                        [ngClass]="errorstatus === 'success' ? 'success': 'tab2error'"
                        *ngIf="isPartialFailureAlertVisible">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="errorstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ message }}</div>
                    </div>
                </div>
                <div class="table-area" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500"
                    (scrolled)="onScroll()" [scrollWindow]="false">
                    <div *ngIf="pendingRequestData && pendingRequestData.length > 0">
                        <mat-table [dataSource]="pendingRequestData" class="mat-elevation-z8">
                            <ng-container matColumnDef="chevron">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let item" [ngStyle]="{ cursor: 'pointer' }"
                                    (click)="showHideProjectDetails(item); $event.stopPropagation()">
                                    <span class="chevron" *ngIf="!item.isExpanded">
                                        <span class="sif sif-chevron-e custom-chevron"
                                            [matTooltip]="getExpandTooltipText(item)" [matTooltipClass]="['custom-tooltip']"></span>
                                    </span>
                                    <span class="chevron" *ngIf="item.isExpanded">
                                        <span class="sif sif-chevron-s custom-chevron"
                                            [matTooltip]="getCollapsedTooltipText(item)" [matTooltipClass]="['custom-tooltip']"></span>
                                    </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="project-name">
                                <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Project Name</span> </th>
                                <td mat-cell *matCellDef="let item">
                                    <span class="bold-text" style="cursor: text;">
                                        {{ item.name }}
                                    </span>
                                    <div *ngIf="item.isExpanded">
                                        <span class="shifted-list" style="padding-left: 12.1%;">
                                            <div *ngFor="let concept of item?.conceptData" style="margin-bottom: 10px;">
                                                <span style="cursor: text;">{{ concept }}
                                                    <br></span>
                                            </div>
                                            <div class="no-data"
                                                *ngIf="item.isConceptDataLoaded && (!item?.conceptData || item?.conceptData.length === 0)"
                                                style="margin-bottom: 10px;">
                                                <span style="cursor: text;">{{ getNoDataMessage(item) }}</span>
                                            </div>
                                        </span>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="project-number">
                                <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">BASES Project Number</span> </th>
                                <td mat-cell *matCellDef="let item">
                                    <span
                                    [matTooltip]="item.workbenchId === null ? 'No Project Number Assigned' : null"
                                    [matTooltipClass]="['custom-tooltip-text-rqstaccess']"
                                    class="tooltip-container" style="cursor: text;"
                                  >{{item.workbenchId ? item.workbenchId : '--'}}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="product">
                                <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Product</span> </th>
                                <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.solutionName}}</span></td>
                            </ng-container>
                            <ng-container matColumnDef="country">
                                <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Country</span> </th>
                                <td mat-cell *matCellDef="let item">
                                    <div [matTooltip]="item?.countries.fielding.length > 1 ? item?.countries.fielding: '' " matTooltipClass="custom-tooltip">
                                        <span style="cursor: text;">{{
                                            item?.countries.fielding.length > 1
                                            ? item?.countries.fielding[0] +
                                            "...(" +
                                            item.countries.fielding.length +
                                            ")"
                                            : item?.countries.fielding[0]
                                            }}</span>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="client">
                                <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Client</span> </th>
                                <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.client}}</span> </td>
                            </ng-container>
                            <ng-container matColumnDef="date-created">
                                <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Date Created</span> </th>
                                <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.dateCreated | date: 'MMM d, yyyy'}}</span> </td>
                            </ng-container>
                            <ng-container matColumnDef="date-requested">
                                <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Date Requested</span> </th>
                                <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.pendingRequestCreationDate | date: 'MMM d, yyyy'}}</span> </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="pendingRequestColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let item; columns: pendingRequestColumns"></tr>
                        </mat-table>
                    </div>

                    <div *ngIf="!(pendingRequestData && pendingRequestData.length > 0)">
                        <niq-empty-page [icon]="pendingRequestEmptyState.icon"
                            [leadText]="pendingRequestEmptyState.lead_text"
                            [supportText]="pendingRequestEmptyState.support_text" [pageSize]="'large'"
                            *ngIf="pendingRequestData.length===0 && !spinner"></niq-empty-page>
                    </div>
                </div>
                <div class="extra-area"></div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div matTooltip="Grant access to Projects someone else requested" matTooltipClass="center-tooltip">
                    APPROVALS</div>
            </ng-template>

            <div class="approval-request-container" *ngIf="requestApprovalData && requestApprovalData.length > 0">
                <div class="blank-area"></div>
                <div class="prompt-area">
                    <div class="alert d-flex" role="alert"
                        [ngClass]="errorstatus === 'success' ? 'success': 'approvalError'"
                        *ngIf="isFullFailureAlertVisible">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="errorstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ message }}</div>
                    </div>
                    <div class="alert d-flex" role="alert"
                        [ngClass]="errorstatus === 'approvalWarning' ? 'approvalWarning': 'approvalError'"
                        *ngIf="isAlreadyApproveException">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="errorstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ message }}</div>
                        <div class="close-icon-warning d-flex align-items-center justify-content-center"
                            (click)="closeAlertAlreadyApprovalWarning()">
                            <mat-icon class="sif sif-close"></mat-icon>
                        </div>
                    </div>
                    <div class="alert success-box d-flex" role="alert"
                        [ngClass]="successstatus === 'success' ? 'success': 'error'" *ngIf="isSuccessAlertVisible">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="successstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ successmessage }}</div>
                    </div>
                    <div class="alert d-flex" role="alert"
                        [ngClass]="errorstatus === 'success' ? 'success': 'tab2error'"
                        *ngIf="isPartialFailureAlertVisible">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="errorstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ message }}</div>
                    </div>
                </div>
                <div class="table-area" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500"
                    (scrolled)="onScrollApproval()" [scrollWindow]="false">
                    <mat-table [dataSource]="requestApprovalData" class="mat-elevation-z8">
                        <ng-container matColumnDef="checkbox">
                            <th mat-header-cell *matHeaderCellDef>
                                <!-- <mat-checkbox class="custom-checkbox checkbox-container"
                                    [(ngModel)]="selectAllCheckboxApproval" (change)="toggleSelectAllForApprove()"
                                    [checked]="isAllOptionsSelectedApproval()"
                                    [indeterminate]="partialCompleteForApprove()" style="padding-left: 2px;"></mat-checkbox> #Commented out as per the UF-2180 requirement, selectAll to be disabled-->
                            </th>
                            <td mat-cell *matCellDef="let item">
                                <mat-checkbox class="custom-checkbox checkbox-container"
                                    [(ngModel)]="item.isSelected" (ngModelChange)="checkboxselectionLimitReachedApproval()" style="padding-left: 2px;"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="chevron">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let item" [ngStyle]="{ cursor: 'pointer' }"
                                (click)="showHideProjectDetails(item); $event.stopPropagation()">
                                <span class="chevron" *ngIf="!item.isExpanded">
                                    <span class="sif sif-chevron-e custom-chevron"
                                        [matTooltip]="getExpandTooltipText(item)" [matTooltipClass]="['custom-tooltip']"></span>
                                </span>
                                <span class="chevron" *ngIf="item.isExpanded">
                                    <span class="sif sif-chevron-s custom-chevron"
                                        [matTooltip]="getCollapsedTooltipText(item)" [matTooltipClass]="['custom-tooltip']"></span>
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="project-name">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Project Name</span> </th>
                            <td mat-cell *matCellDef="let item">
                                <span class="bold-text" style="cursor: text;">
                                    {{ item.name }}
                                </span>
                                <div *ngIf="item.isExpanded">
                                    <span class="shifted-list" style="padding-left: 12.1%;">
                                        <div *ngFor="let concept of item?.conceptData" style="margin-bottom: 10px;">
                                            <span style="cursor: text;">{{ concept }}
                                                <br></span>
                                        </div>
                                        <div class="no-data"
                                            *ngIf="item.isConceptDataLoaded && (!item?.conceptData || item?.conceptData.length === 0)"
                                            style="margin-bottom: 10px;">
                                            <span style="cursor: text;">{{ getNoDataMessage(item) }}</span>
                                        </div>
                                    </span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="project-number">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">BASES Project Number</span> </th>
                            <td mat-cell *matCellDef="let item"> 
                                <span
                                [matTooltip]="item.workbenchId === null ? 'No Project Number Assigned' : null"
                                [matTooltipClass]="['custom-tooltip-text-rqstaccess']"
                                class="tooltip-container" style="cursor: text;"
                              > {{item.workbenchId ? item.workbenchId : '--'}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="product">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Product</span> </th>
                            <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.solutionName}}</span></td>
                        </ng-container>
                        <ng-container matColumnDef="country">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Country</span> </th>
                            <td mat-cell *matCellDef="let item">
                                <div [matTooltip]="item?.countries.fielding.length > 1 ? item?.countries.fielding: '' " matTooltipClass="custom-tooltip">
                                    <span style="cursor: text;">{{
                                        item?.countries.fielding.length > 1
                                        ? item?.countries.fielding[0] +
                                        "...(" +
                                        item.countries.fielding.length +
                                        ")"
                                        : item?.countries.fielding[0]
                                        }}</span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="client">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Client</span> </th>
                            <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.client}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="date-requested">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Date Requested</span> </th>
                            <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.pendingRequestCreationDate | date: 'MMM d, yyyy'}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="requested-by">
                            <th mat-header-cell *matHeaderCellDef> <span style="cursor: text;">Requested By</span> </th>
                            <td mat-cell *matCellDef="let item"> <span style="cursor: text;">{{item.requestedByUser}}</span> </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="approvalRequestColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let item; columns: approvalRequestColumns"></tr>
                    </mat-table>
                </div>
                <div class="footer-area">

                    <hr class="footer-divider-line">
                    <div style="display: flex; align-items: center; margin-top: 8px; margin-bottom: 8px">
                        <div class="submit-button">
                            <button class="get-started-btn mat-flat-button submit-btn" mat-raised-button type="submit"
                                [disabled]="isApproveButtonDisabled()" (click)="approveRequest()" [matTooltip]="selectedProjectCountForApproval > this.maxSelectionLimit ? 'Limit of 10 projects per approval' : null"
                                [matTooltipClass]="['custom-tooltip']">
                                {{ "project.Approval_Request.button" | translate | uppercase}}
                            </button>
                        </div>
                        <div class="cancel-button">
                            <button routerLink="/home" mat-button class="mat-flat-button cancel-btn"
                                (click)="trackCancelApproveRequest()">
                                {{ "project.create_project.cancel" | translate | uppercase }}
                            </button>
                        </div>
                        <div class="count-request" *ngIf="selectedProjectCountForApproval > 0" >
                            Projects Selected: {{selectedProjectCountForApproval}}
                        </div> 
                        <div *ngIf="selectedProjectCountForApproval > 0" matTooltip="Clear all selections" matTooltipClass="center-tooltip">
                            <button  (click)="unselectAllForApprove()" class="resetButton">
                            {{"Reset"}}
                        </button>
                        </div> 
                    </div>
                </div>
                <div class="extra-area"></div>
            </div>

            <div class="approval-request-empty-comtainer"
                *ngIf="!(requestApprovalData && requestApprovalData.length > 0)">
                <div class="blank-area"></div>
                <div class="prompt-area">
                    <div class="alert d-flex" role="alert"
                        [ngClass]="errorstatus === 'success' ? 'success': 'approvalError'"
                        *ngIf="isFullFailureAlertVisible">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="errorstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ message }}</div>
                    </div>
                    <div class="alert d-flex" role="alert"
                        [ngClass]="errorstatus === 'approvalWarning' ? 'approvalWarning': 'approvalError'"
                        *ngIf="isAlreadyApproveException">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="errorstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ message }}</div>
                        <div class="close-icon-warning d-flex align-items-center justify-content-center"
                            (click)="closeAlertAlreadyApprovalWarning()">
                            <mat-icon class="sif sif-close"></mat-icon>
                        </div>
                    </div>
                    <div class="alert success-box d-flex" role="alert"
                        [ngClass]="successstatus === 'success' ? 'success': 'error'" *ngIf="isSuccessAlertVisible">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="successstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ successmessage }}</div>
                    </div>
                    <div class="alert d-flex" role="alert"
                        [ngClass]="errorstatus === 'success' ? 'success': 'tab2error'"
                        *ngIf="isPartialFailureAlertVisible">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <mat-icon
                                [ngClass]="errorstatus === 'success' ? 'sif sif-checkmark' : 'sif sif-warning'"></mat-icon>
                        </div>
                        <div class="message w-100">{{ message }}</div>
                    </div>
                </div>
                <div class="table-area">
                    <niq-empty-page class="approval-empty" [icon]="approvalRequestEmptyState.icon"
                        [leadText]="approvalRequestEmptyState.lead_text"
                        [supportText]="approvalRequestEmptyState.support_text" [pageSize]="'large'"
                        *ngIf="requestApprovalData.length===0 && !spinner"></niq-empty-page>
                </div>
                <div class="extra-area"></div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>