<div class="home-container">
  <div class="tool-bar">
    <div class="tool-bar-container align-items-center">
      <div class="tool-bar-left">
        <a routerLink="/home"><span class="sif sif-arrow-w"></span></a>
        <label class="header-label">{{
          "solution.toolbar.name" | translate
          }}</label>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
