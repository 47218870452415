import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserInfoService } from '../services/user/user-info.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService  {
    constructor(public router: Router, public userInfoService: UserInfoService) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const userInfo$ = this.userInfoService.get();
        return userInfo$.pipe(
            switchMap((userInfo) => {
                const roles = route.data.roles;
                const currentRoles = userInfo?.roles;
                const intersect = currentRoles.filter((value) => roles.includes(value));
                if (currentRoles.includes('ROLE_Panel') && intersect.length === 0) {
                    this.router.navigate(['panelHome']);
                    return of(false);
                }
                else if (roles && intersect.length === 0) {
                    this.router.navigate(['forbidden']);
                    return of(false);
                }
                return of(true);
            })
        );
    }
}
