<div class="project-name-section">
  <div>
    <label [hidden]="!hasLabel">{{ displayLabel }}</label>
    <div class="input-container" [ngStyle]="dynamicWidth">
      <textarea mat-input #inputObj="ngModel" cdkFocusInitial (input)="
          changeBorderColor($event);
          validation(inputObj.value);
          formDirtyStatus(inputObj.dirty);
          emitValue(inputObj.value);
        " (change)="inputBoxValue = inputBoxValue?.trim()" (focus)="changeBorderColor($event)"
                [(ngModel)]="inputBoxValue" (blur)="formDirtyStatus(inputObj.dirty); emitValue(inputObj.value)"
                [required]="isRequired" [niqOnlyNumber]="inputType === 'number'" [placeholder]="inputPlaceholder"
                [readOnly]="isReadOnly" [disabled]="isReadOnly"
                [class.required-error]="
          isRequired
            ? inputObj.invalid && (inputObj.dirty || inputObj.touched)
            : false
        " [class]="
          state !== 'default'
            ? state === 'warning'
              ? 'warning-box'
              : 'error-box'
            : 'default'
        "></textarea>
      <span>{{ inputObj && inputObj.value ? inputObj.value?.length : 0 }} /
        {{ maxLength }}</span>
    </div>
    <div class="error-block">
      <span [hidden]="percentage <= 80 || percentage > 100" class="warning">{{
        "project.validations.approach_max_character_limit" | translate }}
        {{ maxLength }}.</span>
      <span [hidden]="inputObj?.value?.length <= maxLength" class="error">
        {{
        "project.validations.remove_extra_characters"
          | translate: { maxlength: maxLength }
        }}</span>
      <span [hidden]="
          isRequired
            ? !(inputObj.invalid && (inputObj.dirty || inputObj.touched))
            : true
        " class="error">{{ fieldRequiredError }}</span>
      <span [hidden]="isNameValidPattern" class="error">{{
        "project.validations.name_error_msg" | translate: {field: fieldName, allowedSpecialCharacters: "\"',/><{}[]|\\~!@#$%^&*()=-_.äöüÄÖÜß"}
        }}</span>
      <span [hidden]="!errorResponse" class="error">{{errorResponse}}</span>
      <span></span>
    </div>
  </div>
</div>
