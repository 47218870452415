import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
    MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'niq-detailed-message-bar',
    templateUrl: './detailed-message-bar.component.html',
    styleUrls: ['./detailed-message-bar.component.scss'],
    animations: [
        trigger('collapse', [
            state('true', style({ height: AUTO_STYLE, visibility: 'visible' })),
            state('false', style({ height: '0', visibility: 'visible' })),
            transition('true => false', [animate('.5s')]),
            transition('false => true', [animate('.5s')]),
        ])]
})
export class DetailedMessageBarComponent implements OnInit {

    public buttonCollapsed = false;

    /**
   * List of Messages to be displayed for details
   *
   * @memberof DetailedMessageBarComponent
   */
    public messages: string[] = [];

    /**
   * Summary Message text to be displayed
   *
   * @memberof DetailedMessageBarComponent
   */
    public summary: string = '';

    /**
   * Show the details of the message?
   *
   * @memberof DetailedMessageBarComponent
   */
    public showDetails = false;

    /**
   * Type of the message
   *
   * @memberof DetailedMessageBarComponent
   */
    private _type: string = 'success';

    constructor(
    public snackBarRef: MatSnackBarRef<DetailedMessageBarComponent>,
    public translate: TranslateService,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {}

    /**
   * Angular life cycle hook.
   *
   * @memberof DetailedMessageBarComponent
   */
    public ngOnInit(): void {
        this.summary = this.data.summary;
        this.messages = this.data.messages;
        this._type = this.data.type;
    }

    public collapseButton(): void {
        this.buttonCollapsed = false;
    }
    public expandButton(): void {
        this.buttonCollapsed = true;
    }
}
