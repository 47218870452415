<niq-spinner [display]="spinner"></niq-spinner>
<div class="project-setup-container">
  <div class="info-container">
    <!-- <button class="mat-button back-btn" mat-icon-button aria-label="Back" (click)="openConfirmationDialog('back')">
      <span class="sif sif-arrow-w"></span>
    </button> -->
    <p class="info-text" translate>
      {{ "project.create_project.setup_project" }}
    </p>
    <button class="mat-button close-btn" mat-icon-button aria-label="Info" (click)="openConfirmationDialog('close')">
      <span class="sif sif-close"></span>
    </button>
  </div>
  <div class="flex">
    <div class="project-info-section">
      <h4 translate>{{ "project.create_project.enter_project_info" }}</h4>
      <niq-input-box [inputBoxValue]="projectName" [maxLength]="100" [hasLabel]="false" [isRequired]="true"
        [inputClass]="'custom-input'" (isInputValid)="isPrjNameValid = $event" (isFormDirty)="isDirty = $event"
        [errorResponse]="prjNameErrorMessage" [inputPlaceholder]="projectNamePlaceHolder" [countAlignBottom]="true"
        (inputBoxUpdatedValue)="setProjectData($event, 'projectName')">
      </niq-input-box>
      <niq-input-box *ngIf='isWorkbenchVisible' [inputBoxValue]="workbenchId" [maxLength]="10" [hasLabel]="false"
        [inputClass]="'custom-input'" [inputType]="'number'" [width]="70" [inputPlaceholder]="workbenchIdPlaceHolder"
        [countAlignBottom]="true" (isFormDirty)="isDirty = $event"
        (inputBoxUpdatedValue)="setProjectData($event, 'workbenchId')">
      </niq-input-box>
      <!-- <hr class="seperator" /> -->
      <h6 translate>
        {{ "project.create_project.country_lang" }} &nbsp;&nbsp;
        <span class="sif sif-edit" (click)="openLanguagesModal(selectedLocaleObjects)"
          *ngIf="selectedLocales.length > 0"></span>
      </h6>
      <div class="countries-languages-container" *ngIf="selectedLocales.length === 0">
        <p translate>{{ "project.create_project.click_lang_text" }}</p>
        <button class="mat-button mat-flat-button" mat-flat-button (click)="openLanguagesModal()">
          {{ "project.create_project.language_link" | translate }}
        </button>
      </div>
      <div class="countries-languages-container" *ngIf="selectedLocales.length > 0">
        <div class="countries-details">
          <h5>
            {{ "project.create_project.fielding_in" | translate }}
          </h5>
          <div class="fielding-locales">
            <p *ngFor="let locale of selectedLocaleObjects; let isLast = last">
              {{ locale.name }} ({{ locale.count }}){{ isLast ? "" : ", " }}
            </p>
          </div>
        </div>
        <div class="countries-details align-items-center">
          <h5>
            {{ "project.create_project.primary_language" | translate }}
          </h5>
          <p>{{ getLanguage(primaryLocale) }}</p>
        </div>
        <div class="countries-details align-items-center" *ngIf="referenceLocale">
          <h5>
            {{ "project.create_project.reference_language" | translate }}
          </h5>
          <p>{{ defaultReferenceLanguage }}</p>
        </div>
      </div>
      <!-- <hr class="seperator" /> -->

    </div>
    <div class="project-sidebar">
      <div class="benefits-header">
        <h4 *ngIf="!solution.name.includes('Other'); else OtherSolType" class="section-title">
          {{ solution.name }} {{ "project.create_project.benefits" | translate }}
        </h4>
        <ng-template #OtherSolType class="section-title">
        <h4 class="section-title">
          {{ solution.name }} {{ "project.create_project.solution_benefits" | translate }}
        </h4>
        </ng-template>
        <!-- <a class="sub-link" href="javascript:void(0)" (click)="openCarousalDialog()" [hidden]="!solution.slides.length">{{
          "project.create_project.slide_show_link" | translate }}</a> -->
          <button mat-icon-button (click)="openCarousalDialog();"
          [hidden]="!solution.slides.length" matTooltip="{{'project.tooltip.benefitsslideshow' | translate}}">
            <span class="sif sif-column-view"></span>
          </button>
      </div>

      <niq-solution-benefits [benefits]="solution.benefits" [noExpansion]="true"></niq-solution-benefits>
    </div>
  </div>
  <div class="footer">
    <button mat-button class="mat-flat-button cancel-btn" (click)="openConfirmationDialog('back')">{{
      "project.create_project.cancel" | translate |
      uppercase
      }} </button>
    <button class="get-started-btn mat-flat-button submit-btn" mat-raised-button type="submit" (click)="save()"
      [disabled]="
          !isPrjNameValid
            ? !isPrjNameValid
            : projectName.length === 0 ||
              projectName.length > 100 ||
              workbenchId?.length > 10 ||
              selectedLocales.length === 0
        ">
      {{ "project.create_project.get_started_button" | translate | uppercase}}
    </button>
  </div>
</div>
