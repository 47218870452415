<h2 mat-dialog-title>
  {{'project.rename_project.text' | translate}}
  <span mat-button class="close-icon" [mat-dialog-close]="true">
    <span class="sif sif-close"></span>
  </span>
</h2>
<niq-spinner-small mat-dialog-content *ngIf="displaySpinner" (keydown)="$event.key === 'Escape' ? $event.stopPropagation() ? '' : dialogRef.close(): ''"></niq-spinner-small>

<mat-dialog-content [hidden]="displaySpinner" (keydown)="$event.key === 'Escape' ? $event.stopPropagation() ? '' : dialogRef.close(): ''">
  <niq-input-box [inputBoxValue]="projectName" [maxLength]="100" [hasLabel]="true" [displayLabel]="labelName"
    [isRequired]="true" [inputPlaceholder]="projectNamePlaceHolder" (isInputValid)="isPrjNameValid = $event"
    [errorResponse]="prjNameErrorMessage" (isFormDirty)="isFormDirty = $event"
    (inputBoxUpdatedValue)="setRenameData($event, 'name')">
  </niq-input-box>
  <niq-input-box  *ngIf='this.data.isWorkbenchVisible' [inputBoxValue]="data.project.workbenchId" [maxLength]="10" [hasLabel]="true" [displayLabel]="labelWorkbench"
    [inputType]="'number'" [inputPlaceholder]="workbenchIdPlaceHolder" [width]="40" (isFormDirty)="isFormDirty = $event"
    (inputBoxUpdatedValue)="setRenameData($event, 'workbenchId')" [isReadOnly]="isCRMLinked">
  </niq-input-box>
</mat-dialog-content>
<mat-dialog-actions class="align-items-right">
  <button class="mat-secondary" mat-button mat-dialog-close>Cancel</button>
  <button class="mat-flat-button mat-primary" mat-raised-button color="primary" cdkFocusInitial
    [disabled]="!isFormDirty || (!isPrjNameValid ? !isPrjNameValid : (projectDetails.name.length === 0 || projectDetails.name.length > 100 || projectDetails.workbenchId?.length > 10)) "
    (click)="renameProject()">Rename</button>
</mat-dialog-actions>
