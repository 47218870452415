<div class="carousel">
  <niq-spinner-small *ngIf="spinner"></niq-spinner-small>

  <ul class="list-ul">
    <li class="list-li" *ngFor="let slide of imageSlides;let i=index">
      <ng-container *ngIf="i === currentIndex">
        <img class="carousel-image" [src]="slide">
      </ng-container>
    </li>
  </ul>
  <div>
    <button mat-icon-button class="next mat-tertiary" (click)="next()" [disabled]="slides.length === 1">
      <span class="sif sif-chevron-e"></span>
    </button>
    <button mat-icon-button class="pre mat-tertiary" (click)="pre()" [disabled]="slides.length === 1">
      <span class="sif sif-chevron-w"></span>
    </button>
  </div>
</div>
<div class="selectors">
  <div class="selector" [ngClass]="i === currentIndex ? 'active' : ''" *ngFor="let slide of slides;let i=index"
    (click)="setSlide(i)"></div>
</div>
