import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../states/app.state';

export const selectImageBlobFeature = createFeatureSelector<AppState, any>(
    'imageBlob'
);

export const selectImageBlob = createSelector(
    selectImageBlobFeature,
    (state: any) => state
);
