import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { ConfirmationModalComponent } from '../../platform/modals/confirmation-modal/confirmation-modal.component';
import { ProjectInfoModalComponent } from '../../platform/modals/project-info-modal/project-info-modal.component';
import * as projectConst from '../constant';
import { LanguageModalComponent } from '../modals/language-modal/language-modal.component';
import { LocaleModel } from '../models/locale/locale-model';
import { ProjectModel } from '../models/project/project-model';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { LocaleService } from '../services/locales/locale.service';
import { MessageBarService } from '../services/mesasgeBar/message-bar.service';
import { ProjectService } from '../services/projects/project.service';

@Component({
    selector: 'niq-project-setup',
    templateUrl: './project-setup.component.html',
    styleUrls: ['./project-setup.component.scss'],
})
export class ProjectSetupComponent implements OnInit {
    @Output() public closeScreen = new EventEmitter<boolean>();
    @Output() public previousScreen = new EventEmitter<boolean>();
    @Output() public newProject = new EventEmitter<ProjectModel>();

    /**
     * Solution Object.
     *
     * @type     {any}
     * @default  ""
     * @memberof ProjectSetupComponent
     */
    @Input() public solution: any = {};
    @Input() public isWorkbenchVisible: boolean;
    /**
     * Placeholder text for WorkbenchId field.
     * WorkbenchId is displayed as "BASES Project Number" -- Changes added as per UF-1217
     *
     * @type     {string}
     * @memberof ProjectSetupComponent
     */
    public workbenchIdPlaceHolder: string = this.translate.instant(
        'project.create_project.workbench_id_place_holder'
    );

    /**
     * Placeholder text for project name field.
     *
     * @type     {string}
     * @memberof ProjectSetupComponent
     */
    public projectNamePlaceHolder: string = this.translate.instant(
        'project.create_project.project_name_place_holder'
    );

    /**
     * Primary Language.
     *
     * @type     {string}
     * @default  ""
     * @memberof ProjectSetupComponent
     */
    public primaryLocale: string = '';

    /**
     * List of all the locale objects.
     *
     * @type     {Array<LocaleModel>}
     * @default  ""
     * @memberof ProjectSetupComponent
     */
    public localeObjects: Array<LocaleModel> = [];

    /**
     * List of all selected locales as Strings.
     *
     * @type     {Array<string>}
     * @default  ""
     * @memberof ProjectSetupComponent
     */
    public selectedLocales: Array<string> = [];

    /**
     * List of selected locale objects.
     *
     * @type     {Array<string>}
     * @default  ""
     * @memberof ProjectSetupComponent
     */
    public selectedLocaleObjects: Array<any> = [];

    /**
     * The project model.
     *
     * @type     {ProjectModel}
     * @memberof ProjectSetupComponent
     */
    public project: ProjectModel;

    /**
     * Name of the project.
     *
     * @type     {string}
     * @default  ""
     * @memberof ProjectSetupComponent
     */
    public projectName: string = '';

    /**
     * WorkbenchId associated with the project.
     *
     * @type     {string}
     * @memberof ProjectSetupComponent
     */
    public workbenchId: string = '';

    /**
     * Reference locale associated with the project.
     *
     * @type     {string}
     * @memberof ProjectSetupComponent
     */
    public referenceLocale: string = '';

    /**
     * Default constant value for reference  language.
     *
     * @type     {string}
     * @memberof ProjectSetupComponent
     */
    public defaultReferenceLanguage: string = projectConst.DEFAULT_PRIMARY_LANG_COUNTRY;

    /**
     * Boolean flag to hold if projectName is Valid.
     *
     * @type     {boolean}
     * @memberof ProjectSetupComponent
     */
    public isPrjNameValid: boolean = true;
    public isDirty: boolean = false;
    public prjNameErrorMessage: string = '';
    public spinner: boolean = false;
    /**
     * Constructor.
     *
     * @param ProjectService
     * @param MatDialog
     * @param TranslateService
     * @param LocaleService
     * @memberof ProjectSetupComponent
     */
    constructor(
        private _service: ProjectService,
        public dialog: MatDialog,
        public translate: TranslateService,
        private _localeService: LocaleService,
        private _configService: AppConfigService,
        private _messageBar: MessageBarService,
        private _analyticsService: AnalyticsService
    ) { }

    /**
     * Initializes the Component.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public ngOnInit(): void {
        const solutionName = this.solution?.name;
        if (solutionName) {
            this.setProjectName(solutionName);
            this.getLocales();
        }
    }

    /**
     * Gets the list of locales
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public getLocales(): void {
        this._localeService
            .get('countryKey')
            .subscribe((data: Array<LocaleModel>) => {
                this.localeObjects = data;
            });
    }

    /**
     * Assigns defualt project name based on selected solution name.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public setProjectName(solutionName: string): void {
        this.projectName = `${solutionName}-${formatDate(
            new Date(),
            projectConst.PROJECT_DATE_FORMAT,
            projectConst.PROJECT_DEFAULT_LANG_CODE
        )}`;
    }

    /**
     * Set the project model data.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public setProjectData(value: string, key: string): void {
        if (key === 'projectName') {
            this.projectName = value;
        } else {
            this.workbenchId = value;
        }
    }

    /**
     * Saves the project data.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public save(): void {
        this.spinner = true;
        const projectObject = this.getProjectModel();
        this._service.save(projectObject).subscribe(
            (project) => {
                const inputsLinkPath = project.links.find(
                    (link) => link.rel === 'studio_inputs'
                ).href;
                window.location.href = `${this._configService.config.studio.url}${inputsLinkPath}`;
            },
            (error) => {
                this.spinner = false;
                const err = JSON.parse(JSON.stringify(error.error));
                if (err.error.status === 500 || error.status === 500) {
                    this._messageBar.openSnackBar(
                        projectConst.SNACK_BAR_TYPE_ERROR,
                        'Project creation failed.  Please contact system administrator.'
                    );
                } else {
                    this.prjNameErrorMessage = err.error.details[0];
                }
            }
        );
        this._analyticsService.track(
            `Clicked ${this.translate.instant(
                'project.create_project.get_started_button'
            )}`
        );
    }

    /**
     * Returns the project model.
     *
     * @returns  {ProjectModel}
     * @memberof ProjectSetupComponent
     */
    public getProjectModel(): ProjectModel {
        return {
            name: this.projectName,
            solutionCode: this.solution.code,
            workbenchId: this.workbenchId ? Number(this.workbenchId) : null,
            primaryLocale: this.primaryLocale,
            fieldingLocales: this.selectedLocales,
            referenceLocale: this.referenceLocale,
        };
    }

    /**
     * Returns en_US if `en` is not present in any of fielding or primar locale.
     *
     * @returns  {string}
     * @memberof ProjectSetupComponent
     */
    public getReferenceLocale(
        primaryLocale: string,
        fieldingLocales: Array<string>
    ): string {
        if (fieldingLocales) {
            const isEnLocalePresent = fieldingLocales.find((locale) =>
                locale.startsWith(projectConst.PROJECT_DEFAULT_LANG_CODE)
            );
            return isEnLocalePresent ||
                this.primaryLocale === projectConst.ENGLISH_US_LOCALE
                ? ''
                : projectConst.ENGLISH_US_LOCALE;
        }
    }

    /**
     * Close the project setup window.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public close(): void {
        this.closeScreen.emit(false);
    }

    /**
     * Opens the dialog to view benfits and images of a solution.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public openCarousalDialog(): void {
        this.dialog.open(ProjectInfoModalComponent, {
            maxWidth: projectConst.MODAL_WIDTH,
            data: this.solution,
        });
        this._analyticsService.trackDisplay(true, 'Solution Benefits Slideshow', {
            solutionType: this.solution.name,
        });
    }

    /**
     * Opens the dialog to choose languages.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public openLanguagesModal(selectedOptions: any = []): void {
        if (this.localeObjects.length === 0) {
            this.getLocales();
        }

        const data = this.getLanguageModalData(selectedOptions);
        const dialog = this.dialog.open(LanguageModalComponent, {
            position: { top: '115px' },
            data: data.data,
        });

        dialog.afterClosed().subscribe((localesInfo) => {
            if (localesInfo === 'cancel') {
                return;
            }

            if (localesInfo && localesInfo.length > 0) {
                this.isDirty = true;
                this.selectedLocaleObjects = localesInfo.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                this.primaryLocale = localesInfo[0].primaryLocale;
                this.selectedLocales = localesInfo
                    .map((localeInfo: any) => localeInfo.locales)
                    .flat();
                this.referenceLocale = this.getReferenceLocale(
                    this.primaryLocale,
                    this.selectedLocales
                );
            }
        });
    }

    /**
     * Returns language given a locale.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public getLanguage(locale: string): string {
        const localeObject = this.localeObjects.find(
            (localeObj) => localeObj.locale === locale
        );
        return localeObject.language;
    }

    /**
     * Constructs data required to be displayed by language modal.
     *
     * @returns  {any}
     * @memberof ProjectSetupComponent
     */
    public getLanguageModalData(selectedOptions: Array<any>): any {
        const maxLocales = this.solution.maxLocales;
        const maxLocalesForModal = maxLocales === 9999 ? '' : maxLocales;
        return {
            data: {
                header: this.translate.instant(
                    'project.select_languages.manage_languages'
                ),
                body: {
                    fielding_countries: this.translate.instant(
                        'project.select_languages.fielding_countries_languages'
                    ),
                    primary_language: this.translate.instant(
                        'project.select_languages.primary_language'
                    ),
                    localeObjects: this.localeObjects,
                    selectedLocaleObjects: selectedOptions ? selectedOptions : [],
                    primaryLocale: this.primaryLocale,
                    maxLocales: maxLocalesForModal,
                },
                footer: this.translate.instant('project.generic.save'),
            },
        };
    }

    /**
     * Opens Confirmation dialog.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public openConfirmationDialog(actions: string): void {
        if (!this.isDirty) {
            if (actions === 'back') {
                this.previousScreen.emit(true);
                this._analyticsService.track('Project Creation Hero Back', {
                    solutionType: this.solution.name,
                });
            } else {
                this.close();
                this._analyticsService.track('Project Creation Hero Close', {
                    solutionType: this.solution.name,
                });
            }
            return;
        }

        const modalData = this.getConfirmationModalData();
        const dialogRef = this.dialog.open(ConfirmationModalComponent, modalData);

        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                if (actions === 'back') {
                    this.previousScreen.emit(true);
                    this._analyticsService.track('Project Creation Hero Back', {
                        solutionType: this.solution.name,
                    });
                } else {
                    this.close();
                    this._analyticsService.track('Project Creation Hero Close', {
                        solutionType: this.solution.name,
                    });
                }
            }
        });
    }

    /**
     * Constructs data required to be displayed by confirmation modal.
     *
     * @returns  {any}
     * @memberof ProjectSetupComponent
     */
    public getConfirmationModalData(): any {
        return {
            data: {
                header: this.translate.instant('project.confirmation_modal.header'),
                body: this.translate.instant('project.confirmation_modal.body'),
                footer: this.translate.instant('project.confirmation_modal.footer'),
            },
        };
    }
}
