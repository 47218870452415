import { Component } from '@angular/core';

@Component({
    selector: 'niq-spinner-small',
    templateUrl: './spinner-small.component.html',
    styleUrls: ['./spinner-small.component.scss']
})
export class SpinnerSmallComponent {
    constructor() { }
}
