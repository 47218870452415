<div class="flex">
  <div class="icon" [class]="type" [hidden]="!data.icon">
    <button mat-icon-button>
      <span class="sif sif-{{ icon }}"></span>
    </button>
  </div>
  <div class="data">
    <p>{{ message }}</p>
  </div>
  <div class="dismiss">
    <button mat-icon-button (click)="snackBarRef.dismiss()">
      <span class="sif sif-close"></span>
    </button>
  </div>
</div>
