import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'niq-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
    /**
   * Header text to be displayed in the modal.
   *
   * @type     {string}
   * @default  ""
   * @memberof ConfirmationModalComponent
   */
    public header: string = '';

    /**
   * Body text to be displayed in the modal.
   *
   * @type     {string}
   * @default  ""
   * @memberof ConfirmationModalComponent
   */
    public body: string = '';

    /**
   * Footer button name to be displayed in the modal.
   *
   * @type     {string}
   * @default  ""
   * @memberof ConfirmationModalComponent
   */
    public footer: string = '';

    /**
   * Holds the event emitted up on button click.
   *
   * @type     {boolean}
   * @memberof ConfirmationModalComponent
   */
    public confim: EventEmitter<boolean> = new EventEmitter();

    /**
   * Constructor.
   *
   * @param    MatDialogRef
   * @param    MAT_DIALOG_DATA
   * @memberof ConfirmationModalComponent
   */
    constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    /**
   * Initilizes the Component and sets the values for header, body & footer.
   *
   * @param    MatDialogRef
   * @param    MAT_DIALOG_DATA
   * @memberof ConfirmationModalComponent
   */
    public ngOnInit(): void {
        this.header = this.data.header;
        this.body = this.data.body;
        this.footer = this.data.footer;
    }

    /**
   * Handles click event generated on Confirmation.
   *
   * @param    {void}
   * @memberof ConfirmationModalComponent
   */
    public onConfirm(): void {
        this.dialogRef.close(true);
    }
}
