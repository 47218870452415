import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { AppConfigService } from '../../../core/config/app-config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BootstrapPortalService {
  private _url: string;
  requestId: string
  constructor( private _http: HttpClient,
    private _cs: AppConfigService,
    
  ) { 
    const resourceUri = '/api/v1/manumation';
        this._url = `${this._cs?.config?.projectservice.url}${resourceUri}`;
  }

  public uploadConcept(files: File[], selectedCategory: string): Observable<any> {
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('category', selectedCategory);
    return this._http.post(`${this._url}`, formData);
  }

  public getResponses(): Observable<any> {
    return this._http.get(`${this._url}`);
  }

  public downloadResult(requestId: string): Observable<HttpResponse<Blob>> {
    const url = `${this._url}/result/${requestId}`;
    this.requestId=requestId;
    return this._http.get(url, { responseType: 'blob', observe: 'response' });
  }

  public getFileNameFromHttpResponse(response: HttpResponse<Blob>): string {
    const contentDisposition = response.headers.get('Content-Disposition');
    const matches = /filename="([^"]*)"/.exec(contentDisposition);
    return matches && matches[1] ? matches[1] : `${this.requestId}.zip`;
  }
}
