export class SolutionModel {
    /**
   * Solution object identifier.
   *
   * @type      {string}
   * @memberof  SolutionModel
   */
    public id?: string;

    /**
   * Code of the solution.
   *
   * @type      {string}
   * @memberof  SolutionModel
   */
    public code: string;

    /**
   * Is the solution active?
   *
   * @type      {boolean}
   * @memberof  SolutionModel
   */
    public active: boolean;

    /**
   * Studio solution reference id.
   *
   * @type      {number}
   * @memberof  SolutionModel
   */
    public referenceId?: number;

    /**
   * Name of the solution
   *
   * @type      {string}
   * @memberof  SolutionModel
   */
    public name: string;

    /**
   * Display Name of the solution
   *
   * @type      {string}
   * @memberof  SolutionModel
   */
    public description?: string;

    /**
   * Display Position of the solution.
   *
   * @type      {number}
   * @memberof  SolutionModel
   */
    public displayOrder: number;

    /**
   * Icon of the solution
   *
   * @type      {string}
   * @memberof  SolutionModel
   */
    public icon: string;

    public benefits: { icon: string; title: string; description: string }[];

    /**
   * Maximum number of locales the solution is allowed to have.
   *
   * @type      {string}
   * @memberof  SolutionModel
   */
    public maxLocales: number;

    public slides: string[];

    public hasAnalytics: boolean;
    public maxCountries: number;
}
