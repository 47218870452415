<div [ngClass]= "displayInput === 'RequestAccess' || 'RequestAccessClient' ? 'dropdown-container-rqstAccess' : 'dropdown-container'">
  <div class="select-box" [matMenuTriggerFor]="menu" (menuOpened)="toggle = true; emitMenuClosed(false)"
    (menuClosed)="toggle = false; emitMenuClosed(true)">
    <input [value]="selectedOption" [matTooltip]="
        displayType === 'checkbox'
          ? displayOptions[0].tooltipText
          : selectedOption
      " matTooltipClass="custom-tooltip" readonly />
    <span class="chevron" *ngIf="!toggle">
      <span class="sif sif-chevron-s"></span>
    </span>
    <span class="chevron" *ngIf="toggle">
      <span class="sif sif-chevron-n"></span>
    </span>
  </div>
  <mat-menu #menu="matMenu" class="drop-down-mat-menu">
    <div class="list" (click)="$event.stopPropagation()" (keydown.esc)="$event.stopPropagation(); closeDDOptions($event)">
      <div class="type-ahead-search"
        *ngIf="displayType === 'checkbox' ? copyOriginal[0][childKey].length > 10 : copyOriginal.length > 10">
        <div *ngIf="displayInput === 'RequestAccess'">
          <niq-search-input (typeAheadText)="typeAheadText = $event; filterData($event)" [appendPlaceholder]="arrayName" [displayInput]="RequestAccess" #searchText>
          </niq-search-input>
        </div>
        <div *ngIf="displayInput !== 'RequestAccess'">
          <niq-search-input (typeAheadText)="typeAheadText = $event; filterData($event)" [appendPlaceholder]="arrayName" #searchText>
          </niq-search-input>
        </div>
      </div>
      <p *ngIf="(parentCheckBoxTobeDisabled && displayOptions[0][childKey].length === 0)" class="error-msg">{{ "project.select_languages.no_search_info" | translate }}</p>      
      <p *ngIf="displayOptions.length === 0 " class="error-msg">{{ "project.validations.filter_error_msg" | translate }}</p>            
      <!-- code for radio type dropdown options -->
      <mat-radio-group aria-labelledby="radio-group-label" [(ngModel)]="selectedOption"
        [class]="{'add-indent': addRadioIndent}" [hidden]="displayType === 'checkbox'"
        *ngIf="displayOptions.length > 0">
        <cdk-virtual-scroll-viewport itemSize="10" class = "list"  [style.height]="displayOptions.length <= 9  ? displayOptions.length * 36 + 'px': '350px'">
        <li mat-menu-item role="menuitemradio" *cdkVirtualFor="let option of displayOptions" (click)="setValue(displayOptions, option)"
          [class]="{'selection': selectedOption === option[displayKey]}" class="li">
          <mat-radio-button [value]="option[displayKey]" [checked]="option[displayKey] === selectedOption">
            <span style="visibility: 'hidden'; margin-left: '-8px'"></span>{{
            option[displayKey]
            }}</mat-radio-button>
        </li>
      </cdk-virtual-scroll-viewport>
      </mat-radio-group>
      <!-- code for checkbox type dropdown options -->
      <div [hidden]="displayType !== 'checkbox'" *ngFor="let item of displayOptions">
        <div [hidden] = "parentCheckBoxTobeDisabled" *ngIf="(displayInput !== 'RequestAccess')">
          <li mat-menu-item role="menuitemcheckbox" [class]="item.allComplete ? 'selection' : ''" class="parent-option checkbox-options">
            <mat-checkbox [checked]="item.allComplete" [indeterminate]="partialComplete(item)"
              (change)="setAll($event.checked, item)" #parent>
              <span style="visibility: 'hidden'; margin-left: '-8px'"></span>{{ item[displayKey] }}
            </mat-checkbox>
          </li>
        </div>
        <div [ngClass]= "parentCheckBoxTobeDisabled ? 'sub-list-parent-list-hidden' : 'sub-list'" *ngIf="(displayInput !== 'RequestAccess')">
          <li mat-menu-item role="menuitemcheckbox" *ngFor="let subItem of item[childKey]" [class]="subItem[flagKey] ? 'selection' : ''" class="checkbox-options">
            <mat-checkbox [(ngModel)]="subItem[flagKey]" (ngModelChange)="updateAllComplete(item)">
              <span style="visibility: 'hidden'; margin-left: '-8px'"></span>{{ subItem[displayChildKey] }}
            </mat-checkbox>
          </li>
        </div>
        <div [ngClass]= "parentCheckBoxTobeDisabled ?  'sub-list-parent-list-hidden' : 'sub-list-parent-list-hidden'" *ngIf="(displayInput === 'RequestAccess')">
          <li mat-menu-item role="menuitemcheckbox" *ngFor="let subItem of item[childKey]" [class]="subItem[flagKey] ? 'selection' : ''" class="checkbox-options">
            <mat-checkbox [(ngModel)]="subItem[flagKey]" (ngModelChange)="updateAllComplete(item)">
              <span style="visibility: 'hidden'; margin-left: '-8px'"></span>{{ subItem[displayChildKey] }}
            </mat-checkbox>
          </li>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
