import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SolutionImagesService } from '../services/solution-images/solution-images.service';

@Component({
    selector: 'niq-solution-benefits',
    templateUrl: './solution-benefits.component.html',
    styleUrls: ['./solution-benefits.component.scss'],
})
export class SolutionBenefitsComponent implements OnInit {
  /**
   * Solution Object.
   *
   * @type     {any}
   * @default  ""
   * @memberof SolutionBenefitsComponent
   */
  @Input() public benefits: any[] = [];
  @Input() public noExpansion: boolean = false;
  public imageBlob: Blob;
  public svgImages: any = {};
  public spinner = true;

  /**
   * Constructor.
   *
   * @param ProjectService
   * @param MatDialog
   * @param TranslateService
   * @param LocaleService
   * @memberof SolutionBenefitsComponent
   */
  constructor(
    public translate: TranslateService,
    private _solutionImageService: SolutionImagesService,
    private _sanitizer: DomSanitizer
  ) {}

  /**
   * Initializes the Component.
   *
   * @returns  {void}
   * @memberof SolutionBenefitsComponent
   */
  public ngOnInit(): void {
      this.benefits.forEach((ele, i) => {
          this.getImage(ele.icon, i);
      });
  }

  public getImage(bnftIcon: string, ind: number): any {
      this._solutionImageService
          .get(bnftIcon, 'benefits')
          .subscribe((data: any) => {
              this.spinner = false;
              this.imageBlob = data[this.benefits[ind].icon];
              this.createImageFromBlob(ind);
          });
  }

  public createImageFromBlob(ind: number): void {
      const reader = new FileReader();
      reader.addEventListener(
          'load',
          () => {
              const preview = this._sanitizer.bypassSecurityTrustUrl(
                  reader.result.toString()
              );
              this.svgImages[this.benefits[ind].icon] = preview;
          },
          false
      );
      if (this.imageBlob) {
          reader.readAsDataURL(this.imageBlob);
      }
  }
}
