import { Action, createReducer, on } from '@ngrx/store';
import { addSolutions } from '../actions/solutions.actions';
import { SolutionsState } from '../states/solutionsState.state';

/**
 * Reducer for all solutions actions.
 */
const solutionsReducer = createReducer(
    null,
    on(addSolutions, (state, { solutions }) => {
        const newSolutions = [...solutions];
        return newSolutions;
    })
);

/**
 * Report `NgRx` reducer.
 *
 * @export
 * @param {UserInfo} state
 * @param {UserInfoActions} action
 * @returns {UserInfo}
 */
export function reducer(state: SolutionsState | undefined, action: Action): SolutionsState {
    return solutionsReducer(state, action);
}
