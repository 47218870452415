import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MessageBarComponent } from '../../common-components/message-bar/message-bar.component';
import { DetailedMessageBarComponent } from '../../common-components/detailed-message-bar/detailed-message-bar.component';

@Injectable({
    providedIn: 'root',
})
export class MessageBarService {
    constructor(private _messageBar: MatSnackBar) { }

    /**
   * Returns the list of projects.
   *
   * @param type
   * @param message
   * @memberof MessageBarService
   */
    public openSnackBar(type: string, message: string): void {
        this._messageBar.openFromComponent(MessageBarComponent, {
            data: { type, message },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: [type],
            duration: 2500
        });
    }

    /**
   * Returns the list of projects.
   *
   * @param type
   * @param message
   * @memberof MessageBarService
   */
    public openDetailedSnackBar(type: string, summary: string, messages: string[]): void {
        this._messageBar.openFromComponent(DetailedMessageBarComponent, {
            data: { type, summary, messages },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: [type, 'width-500']
        });
    }

    public openSnackBarWithIcon(type: string, message: string): void {
        this._messageBar.openFromComponent(MessageBarComponent, {
            data: { type: type, message: message, icon: true },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: [type],
            duration: 5000
        });
    }
}
