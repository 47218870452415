<niq-spinner [display]="spinner"></niq-spinner>
<div class="container">
  <form (ngSubmit)="onSubmit()" #submissionForm="ngForm" class="sticky-form">
    <div class="form-group">
      <label for="category" class="inline-label">Select a category</label>
      <div class="select-wrapper">
        <select id="category" class="form-control inline-select" [(ngModel)]="selectedCategory" name="category" (change)="onCategoryChange()" required>
          <option value="" disabled selected>Select from list</option>
          <option *ngFor="let category of categories" [value]="category">{{ category }}</option>
        </select>
      </div>
    </div> 

    <div class="form-group file-upload-group">
      <label for="file-upload" class="inline-label">Upload a file:</label>
      <span class="file-count">{{ files.length }} files selected</span>
      <input type="file" id="file-upload" (change)="onFileChange($event)" multiple hidden #fileInput />
      <button type="button" mat-flat-button color="secondary" small (click)="triggerFileUpload($event)">Browse...</button>
    </div>
    <button type="submit" class="btn btn-primary" [disabled]="!canSubmit">Submit</button>
    <hr class="bold-line" />
  </form>

  <div class="table-container" infinite-scroll (scrolled)="onScroll()" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300">
    <table class="table">
      <thead>
        <tr>
          <th>Workspace Name</th>
          <th>Category</th>
          <th>Concept Name</th>
          <th>Status</th>
          <th>Date Submitted</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let request of displayedRequests">
          <td>{{ request.name }}</td>
          <td>{{ request.category }}</td>
          <td>{{ request.conceptName }}</td>
          <td>{{ request.status }}</td>
          <td>{{ request.dateSubmitted | date: 'MMM d, yyyy' }}</td>
          <td>
            <span 
              class="sif sif-12 sif-download download-icon" 
              [ngClass]="{'disabled': request.status != 'RESULT_READY'}" 
              (click)="onDownload(request.conceptId)">
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>