<h2 mat-dialog-title>
  {{ data?.name }} {{ "project.create_project.benefits" | translate }}
  <button mat-button class="close-icon" [mat-dialog-close]="true">
    <span class="sif sif-close"></span>
  </button>
</h2>
<mat-dialog-content (keydown)="$event.key === 'Escape' ? $event.stopPropagation() ? '' : dialogRef.close(): ''">
  <h6 class="sub-heading">{{ data?.benefits[0].title }}</h6>
  <p>{{ data?.benefits[0].description }}</p>
  <div class="carousel-container">
    <niq-carousel [slides]="slides" [slideDuration]="3"></niq-carousel>
  </div>
</mat-dialog-content>
