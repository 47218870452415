import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../services/projects/project.service';

@Component({
    selector: 'niq-delete-project',
    templateUrl: './delete-project.component.html',
    styleUrls: ['./delete-project.component.scss'],
})
export class DeleteProjectComponent {
    public spinner: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<DeleteProjectComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public translate: TranslateService,
        private _projService: ProjectService
    ) { }

    public deleteProject(): void {
        this.spinner = true;
        this._projService.delete(this.data).subscribe(
            (data) => {
                if (!data) {
                    this.dialogRef.close('deleted');
                    this.spinner = false;
                }
            },
            (error) => {
                this.spinner = false;
                const err = JSON.parse(JSON.stringify(error.error));
                this.dialogRef.close({ error: err.error.details[0] });
            }
        );
    }
}
