import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { AppConfigService } from './config/app-config.service';
import {
    APP_INITIALIZER,
    InjectionToken,
    ModuleWithProviders,
    NgModule,
} from '@angular/core';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { configFactory } from './config/app-config.initializer';
import { translationLoaderFactory } from './i18n/translation.loader';
import { translationFactory } from './i18n/translation.initializer';

import { HeartBeatServiceImpl } from '../core/config/heart-beat.service';
import { HeartBeatService } from '@basesstudio/ngx-components';

export const HEART_BEAT_TOKEN = new InjectionToken<HeartBeatService>(
    'HEART_BEAT_SERVICE'
);
@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translationLoaderFactory,
                deps: [HttpBackend],
            },
        }),
    ],
    providers: [
        {
            provide: HEART_BEAT_TOKEN,
            useClass: HeartBeatServiceImpl,
            deps: [AppConfigService],
        },
    ],
})

export class CoreModule {
    /**
   * `forRoot()` method returns a `ModuleWithProviders` object which provides
   * `root` modules with the shared service instances.
   *
   * @static
   * @returns {ModuleWithProviders}
   * @memberof CoreModule
   */
    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                AppConfigService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: configFactory,
                    deps: [AppConfigService],
                    multi: true,
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: translationFactory,
                    deps: [TranslateService],
                    multi: true,
                },
                { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
            ],
        };
    }
}
