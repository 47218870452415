import { AppConfigService } from '../config/app-config.service';
import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _router: Router, private _appConfigService: AppConfigService) {
    }

    private _canIntercept(requestUrl: string): boolean {
        return requestUrl.includes('/api') || requestUrl.includes('/platformservices') || requestUrl.includes('/heartbeat');
    }

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if (!this._canIntercept(request.url)) {
            return next.handle(request);
        }

        let headersObject = {};
        const defaultHeadersObject = {
            'X-Requested-With': 'XMLHttpRequest',
        };

        if (request.url.indexOf('/api') >= 0 || request.url.indexOf('/heartbeat') >= 0) {
            headersObject = {
                ...defaultHeadersObject,
                targetUrl: window.location.href,
                'Access-Control-Expose-Headers': ['targetUrl'],
            };
        }

        const currentRequestHeaders = request.headers.keys().reduce((acc, cur) => {
            acc[cur] = request.headers.get(cur);
            return acc;
        }, {});

        const custRequest = request.clone({
            withCredentials: true,
            // Merging existing request headers with new headers
            headers: new HttpHeaders({
                ...currentRequestHeaders,
                ...headersObject,
            }),
        });

        return next.handle(custRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    const currentURL = encodeURI(window.location.href);
                    const casUrl = error.headers.get('casLoginUrl');
                    window.location.href = `${casUrl}&service=${currentURL}`;
                    return of(error as any);
                } else if (error.status === 403) {
                    this._router.navigateByUrl('/forbidden');
                }
                return throwError(error);
            })
        );
    }
}
