import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SolutionModel } from '../../models/solution/solution-model';

@Component({
    selector: 'niq-benefit-icons-modal',
    templateUrl: './benefit-icons-modal.component.html',
    styleUrls: ['./benefit-icons-modal.component.scss'],
})
export class BenefitIconsModalComponent {

    /**
   * Constructor.
   *
   * @param    MatDialogRef
   * @param    MAT_DIALOG_DATA
   * @memberof BenefitIconsModalComponent
   */
    constructor(
    public dialogRef: MatDialogRef<BenefitIconsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { solution: SolutionModel, benefit: any }
    ) { }
}
