import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserInfo } from '../../models/user/user-info';
import { AppState } from '../states/app.state';

/**
 * UserInfo feature selector.
 *
 * @export
 * @property
 */
export const selectUserInfoFeature = createFeatureSelector<AppState, UserInfo>(
    'userInfo'
);

/**
 * UserInfo selector.
 *
 * @export
 * @property
 */
export const selectUserInfo = createSelector(
    selectUserInfoFeature,
    (state: UserInfo) => state
);
