import { Injectable, NgZone } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { AppConfigService } from '../../../core/config/app-config.service';
import { environment } from '../../../../environments/environment';
import { UserInfoService } from '../user/user-info.service';

declare let pendo: any;

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private _token: string;
    private _pendoAPIKey: string;
    private _prodPrefix = 'prod';
    private _userInfo: any = {};
    private _isInitialized = false;

    /**
     * Constructor.
     *
     * @param _ngZone
     * @param AppConfigService
     * @memberof ConceptService
     */
    constructor(
        private _cs: AppConfigService,
        private _ngZone: NgZone,
        private _userInfoService: UserInfoService
    ) {
        this._pendoAPIKey = this._cs.config?.useranalytics.pendoAPIKey;
        this._userInfoService.get().subscribe((data: any) => {
            this._userInfo = data;
            if (
                this._pendoAPIKey &&
                this._userInfo.featureFlags.indexOf('PENDO') >= 0
            ) {
                (window as any).initializePendoWithKey(this._pendoAPIKey);
            }
        });
        this._token = this._cs.config?.useranalytics.token;
        if (this._token) {
            mixpanel.init(this._token);
            this.setProfileInMixPanel();
        }
    }

    public setProfileInMixPanel(): void {
        const identifier = this.getIdentifier();
        const userId = identifier.userId;
        const domain = identifier.domain;
        mixpanel.identify(userId);
        mixpanel.people.set({
            $first_name: userId,
            domain: domain,
        });
    }

    public getIdentifier(): any {
        const userName = this._userInfo.username;
        const userId = this._userInfo.userId;
        let domain = 'Unknown';
        if (userName) {
            const userInfo = userName.split('@');
            if (userInfo.length === 2) {
                domain = userInfo[1];
            }
        }
        return { userId, domain };
    }

    public trackButtonClick(event, details?: {}): void {
        const innerName = event.target.innerText;
        if(innerName==='PROJECTS'){
            this.track(
                `uf-hm-SMenu-Proj-txt-clicked`,
            details ? { ...event, ...details } : {});
        }else if(innerName==='KEY MEASURES ANALYZER'){
            this.track(
                `uf-hm-SMenu-KMA-txt-clicked`,
                details ? { ...event, ...details } : {});
        }else if(innerName==='CONCEPT ARCHIVE'){
            this.track(
                `uf-hm-SMenu-ConArch-txt-clicked`,
                details ? { ...event, ...details } : {});
        }
        else{
            this.track(
                `Clicked ${event.target.innerText}`,
                details ? { ...event, ...details } : {}
            );  
        }      
    }

    public trackButtonClickUsingIcon(event, details?: {}): void {
        const adjacentSpan = event.target.nextElementSibling;
        const innerName = adjacentSpan.textContent;
        if(innerName==='PROJECTS'){
            this.track(
                `uf-hm-SMenu-Proj-icon-clicked`,
            details ? { ...event, ...details } : {});
        }else if(innerName==='KEY MEASURES ANALYZER'){
            this.track(
                `uf-hm-SMenu-KMA-icon-clicked`,
                details ? { ...event, ...details } : {});
        }else if(innerName==='CONCEPT ARCHIVE'){
            this.track(
                `uf-hm-SMenu-ConArch-icon-clicked`,
                details ? { ...event, ...details } : {});
        }
        else{
            this.track(
                `Clicked ${event.target.innerText}`,
                details ? { ...event, ...details } : {}
            );  
        }
    }

    public trackDisplay(display: boolean, title: string, details?: {}): void {
        this.track(`${display ? 'Displayed' : 'Closed'} ${title}`, details);
    }

    public track(action: string, details?: {}): void {
        details = Object.assign(
            {
                distinct_id: this._userInfo.userId,
                userId: this._userInfo.userId,
                isInternalUser: this._userInfo.isInternalUser,
                urlVisited: window.location.href.split('/')[3],
            },
            details
        );
        if (this._token) {
            this._ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    mixpanel.track(action, details || {});
                });
            });
        }
    }

    public initializeWithIdentifier(userInfo: any, organizationId: string): void {
        if (!this._pendoAPIKey || this._isInitialized) {
            return;
        }
        const userId = userInfo.userId;
        const envIdentifier = window.location.host.split('.')[0];
        const prefix = environment.production
            ? `${this._prodPrefix}-${envIdentifier}-`
            : `development-${envIdentifier}-`;

        pendo.initialize({
            visitor: {
                id: `${prefix}${userId}`, // Required if user is logged in
            },
            account: {
                // You can add any additional account level key-values here
                id: `${prefix}${organizationId}`,
            },
        });
        this._isInitialized = true;
    }
}
