import { Action, createReducer, on } from '@ngrx/store';
import { UserInfo } from '../../models/user/user-info';
import { addUserInfo } from '../actions/user-info.actions';

/**
 * Reducer for all concept actions.
 */
const userInfoReducer = createReducer(
    null,
    on(addUserInfo, (state, { userInfo }) => {
        const newUserInfo = { ...userInfo };
        return newUserInfo;
    })
);

/**
 * Report `NgRx` reducer.
 *
 * @export
 * @param {UserInfo} state
 * @param {UserInfoActions} action
 * @returns {UserInfo}
 */
export function reducer(
    state: UserInfo | undefined,
    action: Action
): UserInfo {
    return userInfoReducer(state, action);
}
