import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { SNACK_BAR_TYPE_ERROR, SNACK_BAR_TYPE_INFO } from '../constant';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { BulkshareService } from '../services/bulkshare/bulkshare.service';
import { MessageBarService } from '../services/mesasgeBar/message-bar.service';

export class BulkShare {
    public userAccount: string;
    public sharedWith: string;
    public lastShared: string;
    public status: string;
    public id: string;
}
const ELEMENT_DATA: BulkShare[] = [];
@Component({
    selector: 'niq-bluk-share-home',
    templateUrl: './bluk-share-home.component.html',
    styleUrls: ['./bluk-share-home.component.scss'],
})
export class BlukShareHomeComponent implements OnInit {
    public displayedColumns: string[] = [
        'userAccount',
        'sharedWith',
        'lastShared',
        'status',
    ];
    public dataSource = new MatTableDataSource<BulkShare>(ELEMENT_DATA);

    public emailPattern = '[a-z0-9._%+-]+@nielseniq.com';
    public bulkshareForm = new UntypedFormGroup({
        toUser: new UntypedFormControl('', [Validators.required]),
        fromUser: new UntypedFormControl('', [Validators.required]),
    });
    public errorMessage: string;

    public placeholderFrom: string = this.translate.instant(
        'project.bulkshare.placeholder_from'
    );

    public placeholderTo: string = this.translate.instant(
        'project.bulkshare.placeholder_to'
    );

    constructor(
        public translate: TranslateService,
        public dialog: MatDialog,
        private _bulkshareService: BulkshareService,
        private _msgService: MessageBarService,
        private _analyticsService: AnalyticsService
    ) { }

    public ngOnInit(): void {
        this.getJobList();
    }

    public validateEmail(value): boolean {
        const regExp = new RegExp(this.emailPattern);
        if (regExp.test(value.toLowerCase())) {
            return false;
        } else {
            return true;
        }
    }

    public share(): void {
        this.errorMessage = '';
        const data = this.bulkshareForm.value;
        data.toUser = data.toUser.toLowerCase();
        data.fromUser = data.fromUser.toLowerCase();
        if (data.toUser === data.fromUser) {
            this.errorMessage = this.translate.instant(
                'project.bulkshare.error_messages.same_email'
            );
            return;
        }
        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                header: this.translate.instant(
                    'project.bulkshare.confirm_modal.header'
                ),
                body: this.translate.instant('project.bulkshare.confirm_modal.body', {
                    formData_to: `${data.toUser}`,
                }),
                footer: this.translate.instant(
                    'project.bulkshare.confirm_modal.footer'
                ),
            },
        });

        confirmRef.afterClosed().subscribe((confirmed) => {
            if (!confirmed) {
                // dialog gets closed and user stays in the same page
            } else {
                // call the bulkshare api to proceed the action
                this._bulkshareService.share(data).subscribe(
                    (res) => {
                        this.bulkshareForm.reset();
                        this._msgService.openSnackBar(SNACK_BAR_TYPE_INFO, res.message);
                        this.getJobList();
                    },
                    (error) => {
                        this._msgService.openSnackBar(
                            SNACK_BAR_TYPE_ERROR,
                            error.error.error.details[0]
                                ? error.error.error.details[0]
                                : error.error.error.code
                        );
                    }
                );
            }
        });
    }

    public getJobList(): void {
        this._bulkshareService.getJobRecords().subscribe(
            (data) => {
                const tempObj = [];
                data = data.slice(0, 5);
                data?.forEach((jobDtls: any) => {
                    const jobData = new BulkShare();
                    jobData.id = jobDtls.id;
                    jobData.userAccount = jobDtls.fromUser;
                    jobData.sharedWith = jobDtls.toUser;
                    jobData.lastShared = jobDtls.dateCreated;
                    jobData.status =
                        jobDtls.status === 'IN_PROGRESS' ? 'IN PROGRESS' : jobDtls.status;

                    tempObj.push(jobData);
                });
                this.dataSource.data = tempObj;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    public navigateToHome($event): void {
        this._analyticsService.trackButtonClick($event);
    }
}
