import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SolutionModel } from '../models/solution/solution-model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from '../services/solutions/solution.service';
import { MessageBarService } from '../services/mesasgeBar/message-bar.service';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'niq-edit-solution',
    templateUrl: './edit-solution.component.html',
    styleUrls: ['./edit-solution.component.scss']
})
export class EditSolutionComponent implements OnInit {

    public titleOneLabel: string = this.translate.instant(
        'solution.properties.titleOne'
    );
    public descriptionOneLabel: string = this.translate.instant(
        'solution.properties.descriptionOne'
    );
    public titleTwoLabel: string = this.translate.instant(
        'solution.properties.titleTwo'
    );
    public descriptionTwoLabel: string = this.translate.instant(
        'solution.properties.descriptionTwo'
    );
    public isFormDirty: boolean;
    public isTitleOneValid: boolean;
    public isDescriptionOneValid: boolean;
    public isTitleTwoValid: boolean;
    public isDescriptionTwoValid: boolean;

    /**
     * Hidden input element reference.
     *
     * @type {ElementRef}
     * @memberof ReportFilesComponent
     */
    @ViewChild('uploaderInput0') public uploaderInput0: ElementRef;

    /**
     * Hidden input element reference.
     *
     * @type {ElementRef}
     * @memberof ReportFilesComponent
     */
    @ViewChild('uploaderInputForSlideshow') public uploaderInputForSlideshow: ElementRef;

    /**
     * Hidden input element reference.
     *
     * @type {ElementRef}
     * @memberof ReportFilesComponent
     */
    @ViewChild('uploaderInput1') public uploaderInput1: ElementRef;

    public uploaderInputForBenefit = [];
    /**
     * Solution being edited
     *
     * @type      {SolutionModel}
     * @memberof  SolutionsComponent
     */
    public solutionUnderEdit: SolutionModel;

    /**
     * Flag to enable/disable spinner
     *
     * @type      {number}
     * @memberof  SolutionsComponent
     */
    public spinner: boolean = false;

    /**
     * solutions array object.
     *
     * @type      {Array<SolutionModel>}
     * @memberof  SolutionsComponent
     */
    public solutions: Array<SolutionModel> = [];

    public placeHolderImage: string = 'place-holder.png';

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        public dialog: MatDialog,
        public translate: TranslateService,
        private _service: SolutionService,
        private _toaster: MessageBarService,
    ) { }

    public ngOnInit(): void {
        this.spinner = true;
        this.setInitialSaveState();
        this._service.get(false).subscribe((data: any) => {
            const sId = this._route.snapshot.paramMap.get('id');
            const solution = data.find(s => s.id === sId);
            this.setInitialSaveState();
            this.solutionUnderEdit = { ...solution };
            this.solutionUnderEdit.benefits = [
                { ...this.solutionUnderEdit.benefits[0] },
                { ...this.solutionUnderEdit.benefits[1] },
            ];
            this.spinner = false;
            if (data) {
                this.solutions.length = 0;
                this.solutions = this.solutions.concat(data);
            }
        });
    }

    public setInitialSaveState(): void {
        this.isFormDirty = false;
        this.isTitleOneValid = true;
        this.isDescriptionOneValid = true;
        this.isTitleTwoValid = true;
        this.isDescriptionTwoValid = true;
    }

    public setTitle(value: string, index: number): void {
        this.solutionUnderEdit.benefits[index].title = value;
    }

    public setDescription(value: string, index: number): void {
        this.solutionUnderEdit.benefits[index].description = value;
    }

    /**
     * Triggers click event on the hidden input element.
     *
     * @param {Event} evt
     */
    public selectFilesToUploadForSlideshow(evt: Event): void {
        evt.preventDefault();
        const input = this.uploaderInputForSlideshow.nativeElement;
        input.value = '';
        input.click();
    }

    /**
     * Triggers click event on the hidden input element.
     *
     * @param {Event} evt
     */
    public selectFilesToUploadForBenefit(evt: Event, index: number): void {
        evt.preventDefault();
        this.uploaderInputForBenefit = [this.uploaderInput0, this.uploaderInput1];
        const input = this.uploaderInputForBenefit[index].nativeElement;
        input.value = '';
        input.click();
    }

    public uploadBenefitIcon(index: number): void {
        this.spinner = true;
        const acceptedImageFileType = new RegExp('(.)(jpg|png|svg)$', 'i');
        this.uploaderInputForBenefit = [this.uploaderInput0, this.uploaderInput1];
        const input = this.uploaderInputForBenefit[index].nativeElement;
        const inputFiles: { [key: string]: File } = input.files;
        const file: File = inputFiles[0];
        if (file.size > 2097152) {
            this._toaster.openSnackBar('error', 'Maximum file size is 2 MB.');
            this.spinner = false;
        } else if (!acceptedImageFileType.test(file.name)) {
            this._toaster.openSnackBar('error', file.name + ' file type is invalid');
            this.spinner = false;
        } else {
            this._service
                .uploadBenefitIcon(file, this.solutionUnderEdit, index)
                .subscribe(() => {
                    this.solutionUnderEdit.benefits[index].icon = file.name;
                    // TODO: update solution store
                    this._toaster.openSnackBar(
                        'success',
                        this.translate.instant('solution.edit.image.uploaded')
                    );
                    this.spinner = false;
                });
        }
    }

    public removeBenefitIcon(index: number): void {
        this.solutionUnderEdit.benefits[index].icon = this.placeHolderImage;
        this.saveSolution(this.translate.instant('solution.edit.image.deleted'));
    }

    public uploadSlideshowImages(): void {
        this.spinner = true;
        const slides = this.solutionUnderEdit.slides;
        const acceptedImageFileType = new RegExp('(.)(jpg|png)$', 'i');
        const input = this.uploaderInputForSlideshow.nativeElement;
        const inputFiles: File[] = input.files;
        const goodFiles: File[] = [];
        const messages: string[] = [];
        if (inputFiles.length + slides.length > 10) {
            this._toaster.openSnackBar('error', 'File limit of 10 exceeded');
            this.spinner = false;
            return;
        }
        for (let idx = 0; inputFiles.length > idx; idx++) {
            const file = inputFiles[idx];
            if (file.size > 2097152) {
                messages.push(file.name + ' - File cannot be larger than 2 MB');
            } else if (!acceptedImageFileType.test(file.name)) {
                messages.push(file.name + ' - file type is invalid');
            } else if (slides.indexOf(file.name) >= 0) {
                messages.push(file.name + ' - File with that name already exists');
            } else {
                goodFiles.push(file);
            }
        }
        if (goodFiles.length > 0) {
            this._service
                .uploadSlideshowImages(goodFiles, this.solutionUnderEdit)
                .subscribe(() => {
                    this.solutionUnderEdit.slides = [
                        ...this.solutionUnderEdit.slides,
                        ...goodFiles.map((x) => x.name),
                    ].sort();
                    if (messages.length) {
                        const summary = this.translate.instant(
                            'solution.edit.image.upload.failedSome',
                            {
                                numberOfGoodFiles: goodFiles.length,
                                totalNumberOfFiles: inputFiles.length,
                                numberOfFailingFiles: messages.length,
                            }
                        );
                        this._toaster.openDetailedSnackBar('warn', summary, messages);
                    } else {
                        this._toaster.openSnackBar(
                            'success',
                            this.translate.instant('solution.edit.image.uploaded')
                        );
                    }
                    this.spinner = false;
                });
        } else {
            this.spinner = false;
            this._toaster.openDetailedSnackBar(
                'error',
                this.translate.instant('solution.edit.image.upload.failedAll'),
                messages
            );
        }
    }

    public removeSlideshowImages(): void {
        this.solutionUnderEdit.slides = [];
        this.saveSolution(this.translate.instant('solution.edit.image.deleted'));
    }

    public cancelEdit(): void {
        // eslint-disable-next-line one-var
        const unmodifiedSolution = this.solutions.find((solution) => solution.id === this.solutionUnderEdit.id),
            unmodifiedBenefitZero = unmodifiedSolution.benefits[0],
            unmodifiedBenefitOne = unmodifiedSolution.benefits[1],
            modifiedTitleOne =
                unmodifiedBenefitZero.title !==
                this.solutionUnderEdit.benefits[0].title,
            modifiedTitleTwo =
                unmodifiedBenefitOne.title !== this.solutionUnderEdit.benefits[1].title,
            modifiedDescriptionZero =
                unmodifiedBenefitZero.description !==
                this.solutionUnderEdit.benefits[0].description,
            modifiedDescriptionOne =
                unmodifiedBenefitOne.description !==
                this.solutionUnderEdit.benefits[1].description,
            modified =
                modifiedTitleOne ||
                modifiedTitleTwo ||
                modifiedDescriptionOne ||
                modifiedDescriptionZero;
        if (modified) {
            this.openConfirmationDialog('back');
        } else {
            this.setInitialSaveState();
            this._router.navigateByUrl('solutions');
        }
    }

    public saveSolution(
        successMessage: string = 'Solution changes made successfully'
    ): void {
        this.spinner = true;
        this._service.update(this.solutionUnderEdit).subscribe(() => {
            this.ngOnInit();
            this._toaster.openSnackBar('success', successMessage);
            this.spinner = false;
        });
    }
    /**
     * Opens Confirmation dialog.
     *
     * @returns  {void}
     * @memberof ProjectSetupComponent
     */
    public openConfirmationDialog(actions: string): void {
        const modalData = this.getConfirmationModalData();
        const dialogRef = this.dialog.open(ConfirmationModalComponent, modalData);

        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                if (actions === 'back') {
                    this.setInitialSaveState();
                    this._router.navigateByUrl('solutions');
                }
            }
        });
    }

    /**
     * Constructs data required to be displayed by confirmation modal.
     *
     * @returns  {any}
     * @memberof ProjectSetupComponent
     */
    public getConfirmationModalData(): any {
        return {
            data: {
                header: this.translate.instant('project.confirmation_modal.header'),
                body: this.translate.instant('project.confirmation_modal.body'),
                footer: this.translate.instant('project.confirmation_modal.footer'),
            },
        };
    }
}
