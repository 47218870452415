import { Action, createReducer, on } from '@ngrx/store';
import { addPermissions } from '../actions/permissions.actions';

/**
 * Reducer for all concept actions.
 */
const permissionsReducer = createReducer(
    null,
    on(addPermissions, (state, permissions) => {
        const newPermissions = permissions;
        return newPermissions;
    })
);

export function reducer(state: any | undefined, action: Action): any {
    return permissionsReducer(state, action);
}
