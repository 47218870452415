import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend } from '@angular/common/http';

/**
 * Adds multi file ngx-translate support.
 *
 * @param {HttpBackend} httpBackend
 */
export function translationLoaderFactory(httpBackend: HttpBackend): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(httpBackend, [
        { prefix: './assets/i18n/ngx-components/', suffix: '.json' },
        { prefix: './assets/i18n/platform/', suffix: '.json' }
    ]);
}
