import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/config/app-config.service';

@Injectable({
    providedIn: 'root',
})
export class BulkshareService {
    private _url: string = '';
    constructor(private _http: HttpClient, private _cs: AppConfigService) {
        const resourceUri = '/api/v1/bulkShare';
        this._url = `${this._cs?.config?.projectservice.url}${resourceUri}`;
    }

    public share(data: any): Observable<any> {
        return this._http.post<any>(this._url, data);
    }

    public getJobRecords(): Observable<any> {
        return this._http.get<any>(this._url);
    }
}
