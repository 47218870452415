<mat-accordion [togglePosition]="'before'" multi="true" class="solution-benefits-container" *ngIf="!noExpansion">
  <mat-expansion-panel class="mat-expansion-panel" [expanded]="true" *ngFor="let benefit of benefits">
    <mat-expansion-panel-header class="mat-expansion-panel-header">
      <mat-panel-title>
        {{ benefit.title }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex">
      <div class="product-image">
        <mat-progress-spinner class="loading" [mode]="'indeterminate'" diameter="50" *ngIf="spinner">
        </mat-progress-spinner>
        <img class="img-responsive" [src]="svgImages[benefit.icon]" />
      </div>
      <div class="product-description">
        {{ benefit.description }}
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="no-expansion-container" *ngIf="noExpansion">
  <div class="container-wrapper" *ngFor="let benefit of benefits">
    <div class="img-container">
      <mat-progress-spinner class="loading" [mode]="'indeterminate'" diameter="50" *ngIf="spinner">
      </mat-progress-spinner>
      <img class="img-responsive" [src]="svgImages[benefit.icon]" *ngIf="!spinner" />
    </div>
    <div class="details-container">
      <h4>{{ benefit.title }}</h4>
      <p>{{ benefit.description }}</p>
    </div>
  </div>
</div>
