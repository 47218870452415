import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocaleModel } from '../../models/locale/locale-model';
import { AppState } from '../states/app.state';

/**
 * Solutions feature selector.
 *
 * @export
 * @property
 */
export const selectLocalesFeature = createFeatureSelector<
  AppState,
  LocaleModel[]
>('locales');

/**
 * Solutions selector.
 *
 * @export
 * @property
 */
export const selectLocalesState = createSelector(
    selectLocalesFeature,
    (state: LocaleModel[]) => state
);
