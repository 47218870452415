import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './app-config.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {

    /**
   * Configuration object for the app.
   *
   * @static
   * @type {AppConfig}
   * @memberof AppConfigService
   */
    public config: AppConfig;

    /**
   * Constructor for initializing the service.
   *
   * @param _http
   */
    constructor(private _http: HttpClient) {}

    /**
   * Loads the config file and returns a void promise.
   *
   * @returns {Promise<void>}
   */
    public load(): Promise<void> {
        const filename = environment.name === 'default' ? `config.json` : `config.${environment.name}.json`;
        const path = `assets/config/${filename}`;
        return new Promise<void>((resolve, reject) => {
            this._http.get<AppConfig>(path).toPromise().then((appConfig: AppConfig) => {
                this.config = appConfig;
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${path}': ${JSON.stringify(response)}`);
            });
        });
    }
}
