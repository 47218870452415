import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BootstrapPortalService } from '@platform/services/bootstrap-portal/bootstrap-portal.service';
import { MessageBarService } from '@platform/services/mesasgeBar/message-bar.service';
import { saveAs } from 'file-saver';

interface Request {
  category: string;
  concepts: any;
  status: string;
  dateSubmitted: Date;
  workspaceId: any
  name: string;
}

@Component({
  selector: 'niq-srbootstarp-portal',
  templateUrl: './srbootstarp-portal.component.html',
  styleUrls: ['./srbootstarp-portal.component.scss'],
})
export class SRBootstarpPortalComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  categories = ['Cookies', 'Household Cleaner', 'Laundry Care', 'Oral Care', 'Vitamins and Supplements'];
  selectedCategory = '';
  files: File[] = [];
  canSubmit = false;
  requests: Request[] = [];
  workId: string = 'Not assigned';
  ConceptName: string;
  status: boolean = false;
  isProcessing: boolean = false;
  spinner: boolean = false;
  responses: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateItemsPerPage();
  }

  constructor(private bootstrapPortalService: BootstrapPortalService,
    private _toaster: MessageBarService,
    private _http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.fetchResponses();
  }

  onCategoryChange() {
    this.updateSubmitState();
  }

  onFileChange(event: any) {
    this.files = Array.from(event.target.files);
    if(this.files.length > 50){
      this._toaster.openSnackBar('error','Cannot upload more than 50 concepts at once')
    }
    this.updateSubmitState();
  }

  updateSubmitState() {
    this.canSubmit = this.selectedCategory !== '' && this.files.length > 0 && this.files.length <= 50 && !this.isProcessing;
  }

  onSubmit() {
    if (!this.canSubmit) return;
    this.spinner=true;
    this.canSubmit=false;
    this.isProcessing=true;
    this.bootstrapPortalService.uploadConcept(this.files, this.selectedCategory).subscribe(
      (response) => {
        this.fetchResponses();
        this.workId = response.workspace.name;
        this.status = true;
        this.selectedCategory=response.workspace.category;
        this.addRequests(response.concepts);
        this._toaster.openSnackBar('success', 'Submitted Successfully - You may close this window');
        this.resetForm();
        this.isProcessing=false;
        this.spinner=false;
      },
      (error) => {
        this.status = false;
        this._toaster.openSnackBar('error', 'Something went wrong! Please try again');
        this.addRequest();
        this.resetForm();
        this.isProcessing=false;
        this.spinner=false;
      }
    );
  }
 
  addRequest() {
    this.files.forEach((file)=>{
      const newRequest: Request = {
        workspaceId: this.workId,
        category: this.selectedCategory,
        concepts: file.name,
        status: this.getStatus(),
        dateSubmitted: new Date(),
        name: ''
      };
      this.requests.push(newRequest);
    });
    
  }

  addRequests(concepts: any[]) {
    concepts.forEach((concept) => {
      const newRequest: Request = {
        workspaceId: this.workId,
        category: this.selectedCategory,
        concepts: concept.name,
        status: this.getStatus(),
        dateSubmitted: new Date(),
        name: ''
      };
      this.requests.push(newRequest);
    });
  }

  getStatus() {
    return this.status ? 'Submitted Successfully' : 'Failed';
  }

  resetForm() {
    this.selectedCategory = '';
    this.files = [];
    this.canSubmit = false;
    this.ConceptName = '';
    this.workId='Not assigned'
    this.resetFileInput();
  }

  resetFileInput() {
    this.fileInput.nativeElement.value = '';
  }

  triggerFileUpload(event: Event) {
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  displayedRequests: any[] = [];
  itemsPerPage: number = 30;
  currentPage: number = 0;

  fetchResponses(): void {
    this.bootstrapPortalService.getResponses().subscribe(
      (data: any[]) => {
        this.responses = data;
        this.requests = this.processRequests(data);
        this.currentPage=0;
        this.displayedRequests=[];
        this.loadMoreRequests();
      },
      (error) => {
        this._toaster.openSnackBar('error', 'Failed to fetch responses');
      }
    );
  }
  processRequests(data: any[]): any[] {
    const processedRequests = [];
    data.forEach((item) => {
      item.concepts.forEach((concept) => {
        processedRequests.push({
          id: item.id,
          name: item.name,
          category: item.category,
          conceptName: concept.name,
          status: concept.status,
          conceptId: concept.id,
          dateSubmitted: new Date(item.dateCreated),
        });
      });
    });
    return processedRequests;
  }
  loadMoreRequests(): void {
    const nextRequests = this.requests.slice(this.currentPage * this.itemsPerPage, (this.currentPage + 1) * this.itemsPerPage);
    this.displayedRequests = [...this.displayedRequests, ...nextRequests];
    this.currentPage++;
    this.spinner= false;
  }
  onScroll(): void {
    this.spinner= true;
    this.loadMoreRequests();
  }
  updateItemsPerPage(): void {
    const windowWidth = window.innerWidth;
    if (windowWidth < 600) {
      this.itemsPerPage = 30; // Smaller screen
    } else if (windowWidth < 900) {
      this.itemsPerPage = 50; // Medium screen
    } else {
      this.itemsPerPage = 60; // Larger screens
    }
    this.currentPage = 0;
    this.displayedRequests = []; 
    this.loadMoreRequests(); 
  }

  onDownload(requestId: string): void {
    this.bootstrapPortalService.downloadResult(requestId).subscribe(
      (response: HttpResponse<Blob>) => {
        const fileName = this.bootstrapPortalService.getFileNameFromHttpResponse(response);
        saveAs(response.body, fileName);
      }
    );
  }
}