import {
    animate,
    animateChild,
    group,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import * as platformConstants from '../constant';
import { UNITED_STATES } from '../constant';
import { DeleteProjectComponent } from '../modals/delete-project/delete-project.component';
import { DuplicateProjectComponent } from '../modals/duplicate-project/duplicate-project.component';
import { RenameProjectComponent } from '../modals/rename-project/rename-project.component';
import { FieldingModel } from '../models/fielding/fielding-model';
import { ProjectModel } from '../models/project/project-model';
import { SolutionModel } from '../models/solution/solution-model';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { MessageBarService } from '../services/mesasgeBar/message-bar.service';
import { PermissionService } from '../services/permissions/permission.service';
import { ProjectService } from '../services/projects/project.service';
import { SolutionService } from '../services/solutions/solution.service';
import { UserInfoService } from '../services/user/user-info.service';
import { MatMenuTrigger } from '@angular/material/menu';

/**
 * Model for Grid Header.
 *
 * @export
 * @interface GridHeader
 */
export interface GridHeader {
    /**
     * dynamic scss classname.
     *
     * @type      {string}
     * @memberof  GridHeader
     */
    className: string;

    /**
     * column size.
     *
     * @type      {number}
     * @memberof  GridHeader
     */
    cols: number;

    /**
     * row size.
     *
     * @type      {number}
     * @memberof  GridHeader
     */
    rows: number;

    /**
     * Header name.
     *
     * @type      {string}
     * @memberof  GridHeader
     */
    text: string;
    dbPropertyName: string;
    tooltip?: string;
}

@Component({
    selector: 'niq-project-details',
    templateUrl: './project-details.component.html',
    styleUrls: ['./project-details.component.scss'],
    animations: [
        trigger('bodyExpansion', [
            transition(':enter', [
                style({ height: '0px', minHeight: '0' }),
                animate('500ms ease-in', style({ height: '*', visibility: 'visible' })),
            ]),
            transition(':leave', [
                animate('500ms ease-out', style({ height: '0px', minHeight: '0' })),
            ]),
        ]),
        trigger('backgroudAnimation', [
            state('1', style({ backgroundPositionY: '0px' })),
            state('0', style({ backgroundPositionY: '*' })),
            transition('* => 1', [
                group([
                    animate('500ms linear', style({ backgroundPositionY: '0px' })),
                    query('@bodyExpansion', animateChild()),
                ]),
            ]),
            transition('1 => 0', [
                group([
                    animate('500ms linear', style({ backgroundPositionY: '*' })),
                    query('@bodyExpansion', animateChild()),
                ]),
            ]),
        ]),
    ],
})
export class ProjectDetailsComponent implements OnInit, OnChanges {
    /**
     * Flag to enable/disable spinner
     *
     * @type      {number}
     * @memberof  ProjectDetailsComponent
     */
    @Input() public isLoading: boolean = false;

    @Input() public projectsList: Array<ProjectModel> = [];
    @Input() public resetOffset: number;
    @Input() public limitExceeded: number;
    @Input() public isWorkbenchVisible: boolean;
    @Input() public sortOrder: { name: string; order: boolean };
    @Input() public favTotalCount: number = 0;

    @Output() public lazyLoad = new EventEmitter();
    @Output() public fieldClick = new EventEmitter();
    @ViewChildren('clickMenuTrigger') clickMenuTrigger: QueryList<MatMenuTrigger>;
    @HostListener('window:scroll')
    scrollEventDone() {
        this.clickMenuTrigger.forEach(element => {
            element.closeMenu();
        });;
      }
      
    /**
     * Boolean flag to show or hide hover.
     *
     * @type      {boolean}
     * @memberof  ProjectDetailsComponent
     */
    public countriesHover: boolean = false;

    /**
     * gird header details array object.
     *
     * @type      {Array<GridHeader>}
     * @memberof  ProjectDetailsComponent
     */
    public gridTitle: Array<GridHeader> = [];

    /**
     * fielding details.
     *
     * @type      {Array<ProjectModel>}
     * @memberof  ProjectDetailsComponent
     */
    public fieldingData: Array<FieldingModel> = [];

    /**
     * project status panel backdrop color constant.
     *
     * @type      {Object}
     * @memberof  ProjectDetailsComponent
     */
    public solutions: any = platformConstants.SOLUTIONS;

    /**
     * variable to set the project status grid color dynamically.
     *
     * @type      {string}
     * @memberof  ProjectDetailsComponent
     */
    public projectGridColor: string;

    /**
     * flag to know the ellipseIcon (card more option) is clicked.
     *
     * @type      {boolean}
     * @memberof  ProjectDetailsComponent
     */
    public ellipseIconClicked: boolean;

    /**
     * Flag to know if all scroll reached the end.
     *
     * @type      {boolean}
     * @memberof  ProjectDetailsComponent
     */
    public finishedScroll: boolean = false;

    /**
     * Flag to either fetch fielding details or not.
     *
     * @type      {boolean}
     * @memberof  ProjectDetailsComponent
     */
    public fetchFielding: boolean = false;

    /**
     * project details array object.
     *
     * @type      {Array<ProjectModel>}
     * @memberof  ProjectDetailsComponent
     */
    public projects: Array<ProjectModel> = [];

    /**
     * Flag which toggles if there is an error from fds to fetch the fielding details.
     *
     * @type      {boolean}
     * @memberof  ProjectDetailsComponent
     */
    public isfieldingError: boolean = false;

    public globalPermissionList: Array<string> = [];
    public emptyConst = platformConstants.EMPTY_STATES.filter_search;
    public statusConfig = platformConstants.PROJECT_STATUSES;
    public projectStatusBGColor = platformConstants.PROJECT_STATUS_BG_COLOR;
    public spinner = false;
    public analyticsViewSolutions = [];
    public solutionsList: Array<SolutionModel> = [];
    public sortingOrder: string;
    public isDesc: boolean = false;
    public fieldClicked: any;
    public favCount: number = 0;
    public userInfo: any;

    /**
     * Constructor.
     *
     * @param ProjectService
     * @param MatDialog
     * @param AnalyticsService
     * @param TranslateService
     */
    constructor(
        private _dialogRef: MatDialog,
        private _analyticsService: AnalyticsService,
        public translate: TranslateService,
        private _messageBar: MessageBarService,
        private _configService: AppConfigService,
        private _globalPermissions: PermissionService,
        private _solutionService: SolutionService,
        private _projectService: ProjectService,
        private _userInfoService: UserInfoService
    ) { }

    /**
     * getter method to set the style of project status panel dyanmically.
     *
     * @method get()
     * @returns {object}
     */
    public get backgroundColorCF(): object {
        return {
            background: this.projectGridColor ? this.projectGridColor : '',
        };
    }

    /**
     * Defining and initializing the objects
     *
     * @OnInit - angular Lifecyclehook
     */
    public ngOnInit(): void {
        // initializing the grid header details.
        this.gridTitle = [
            {
                text: '',
                cols: 1,
                rows: 1,
                className: 'grid-title',
                dbPropertyName: '',
            },
            {
                text: 'project.table_header.project_status',
                cols: 2,
                rows: 1,
                className: 'grid-title',
                dbPropertyName: 'statusOrder',
                tooltip: 'Sort by status',
            },
            {
                text: '',
                cols: 1,
                rows: 1,
                className: 'grid-title',
                dbPropertyName: '',
            },
            {
                text: 'project.table_header.project_name',
                cols: 8,
                rows: 1,
                className: 'grid-title header-title-left-align',
                dbPropertyName: 'name',
                tooltip: 'Sort by name',
            },
            {
                text: 'project.table_header.solution_type',
                cols: 3,
                rows: 1,
                className: 'grid-title header-title-left-align',
                dbPropertyName: 'solutionName',
                tooltip: 'Sort by Product',
            },
            {
                text: 'project.table_header.country',
                cols: 2,
                rows: 1,
                className: 'grid-title header-title-left-align',
                dbPropertyName: 'fieldingCountry',
                tooltip: 'Sort by country',
            },
            {
                text: 'project.table_header.dateUpdated',
                cols: 2,
                rows: 1,
                className: 'grid-title header-title-left-align',
                dbPropertyName: 'lastUpdated',
                tooltip: 'Sort by date updated',
            },
            {
                text: 'project.table_header.dateCreated',
                cols: 2,
                rows: 1,
                className: 'grid-title header-title-left-align',
                dbPropertyName: 'dateCreated',
                tooltip: 'Sort by date created',
            },
        ];
        this.spinner = true;
        this._globalPermissions.get().subscribe((data) => {
            this.globalPermissionList = data.permissions;
        });
        this._solutionService.get(false).subscribe((data: any) => {
            if (data) {
                this.solutionsList.length = 0;
                this.solutionsList = this.solutionsList.concat(data);
                this.populateAnalyticsViewSolutions();
            }
        });
        this._userInfoService.get().subscribe((data: any) => {
            this.userInfo = data;
        });
        this._analyticsService.track('Visit Project Service Home Page', {});
    }

    /**
     * @method  ngOnChanges()
     *
     * when a new project is created, the new project is added to the home project list.
     */
    public ngOnChanges(): void {
        if (this.resetOffset === 0) {
            this.projects.length = 0;
        }
        if (this.projectsList && this.projectsList.length > 0) {
            this.mapProjectObject(this.projectsList);
            this.projects = this.projects.concat(this.projectsList);
        }

        if (this.sortOrder) {
            this.sortingOrder = this.fieldClicked = this.sortOrder.name;
            this.isDesc = this.sortOrder.order === null ? true : this.sortOrder.order;
        } else {
            this.isDesc = false;
        }

        this.favCount = this.favTotalCount;
        this.spinner = this.isLoading;
    }

    /**
     * @method  onScroll()
     *
     * Helper method to map the project obj. to cater UI styling.
     * Ex: Adds extra space to countries like India,Belgium => India, Belgium
     */
    public mapProjectObject(data: any): void {
        data.map((ele) => {
            this.mapProjectCountryName(ele);
            this.mapLinks(ele);
            this.mapPrjPermissions(ele);
            this.mapDeleteProjectToolTip(ele);
        });
    }

    /**
     * Its a utility method to compute country name based product type.
     * @param project of type ProjectModel
     */
    public mapProjectCountryName(project: ProjectModel): void {
        const maxCountries = this.solutionsList.find((solution) => {
            return project.solutionCode === solution.code;
        })?.maxCountries;
        if (project.countries.fielding.length > maxCountries) {
            project.countries.fielding = project.countries.fielding.filter(
                (fc) => fc !== UNITED_STATES
            );
        }
        project.countries.fieldingStr = project.countries.fielding
            .toString()
            .trim('"')
            .replace(/,/g, ', ');
    }

    public mapDeleteProjectToolTip(project: ProjectModel): void {
        // if internal user i.e non client user
        if(this.userInfo.isInternalUser) {
            // Case D Project is post field (has at least one Live survey generated and Fielding is completed/backed up)
            // Case C Project is in field (has at least one Live survey generated, with it fielding)
            if (project.fieldingIds?.length > 0) {
                    project.deleteProjectToolTip = this.translate.instant(
                        'project.tooltip.respondent_data_cannot_delete'
                    );
            }
            // Case B Project is locked, at least one Draft survey is generated but there is no Live survey generated
            else if (project.surveyIds?.length > 0) {
                project.deleteProjectToolTip = this.translate.instant(
                    'project.tooltip.draft_survey_can_not_delete'
                );
            }
            //  Case A Project is locked, no draft survey generated
            else if (project?.status?.toUpperCase() !== 'INPUTS') {
                project.deleteProjectToolTip = this.translate.instant(
                    'project.tooltip.locked_project_can_not_delete'
                );
            } else {
                project.deleteProjectToolTip = this.translate.instant(
                    'project.delete_project.text'
                );
            }
        }
        // for client user
        else {
            if (project?.status?.toUpperCase() !== 'INPUTS')  {
                project.deleteProjectToolTip = this.translate.instant(
                    'project.tooltip.locked_project_can_not_delete'
                );
            }
            else if(project.surveyIds?.length > 0 || project.fieldingIds?.length > 0) {
                project.deleteProjectToolTip = this.translate.instant(
                    'project.tooltip.project_cannot_deleted'
                );
            }
            else {
                project.deleteProjectToolTip = this.translate.instant(
                    'project.delete_project.text'
                );
            }
        }
    }

    /**
     * @method  mapLinks()
     *
     * Helper method to map the `rel` field to transient fields in the UI so that it could be used in the
     * templates or else where directly.
     */
    public mapLinks(project: ProjectModel): void {
        project.links.forEach((link) => {
            switch (link.rel) {
                case 'concepts_api':
                    project.conceptsLink = link.href;
                    break;
                case 'skuLists':
                    project.skuListsLink = link.href;
                    break;
                case 'designOptions':
                    project.designOptionsLink = link.href;
                    break;
                case 'fieldings_api':
                    project.fieldingsLink = link.href;
                    break;
                case 'collaborators_api':
                    project.collaboratorsLink = link.href;
                    break;
                case 'studio_inputs':
                    project.inputsLink = link.href;
                    break;
                case 'studio_surveys':
                    project.surveyLink = link.href;
                    break;
                case 'studio_analytics':
                    project.analyticsLink = link.href;
                    break;
                case 'studio_collaborators':
                    project.studioCollabsLink = link.href;
                    break;
                case 'studio_project_administration':
                    project.studioAdministrationLink = link.href;
                    break;
                case 'studio_results':
                    project.resultsLink = link.href;
                    break;
                case 'studio_reports':
                    project.reportsLink = link.href;
                    break;
            }
        });
    }

    public mapPrjPermissions(project: ProjectModel): void {
        project.canViewFielding = this.globalPermissionList.includes(
            platformConstants.PERMISSION_READ_FIELDING
        );

        project.canViewSurvey = this.globalPermissionList.includes(
            platformConstants.PERMISSION_READ_FIELDING
        );

        project.canViewAnalytics =
            this.globalPermissionList.includes(
                platformConstants.PERMISSION_READ_ANALYTICS
            ) && this.analyticsViewSolutions.includes(project.solutionCode);

        project.canViewLpoSimulator =
            project.solutionCode === platformConstants.SOLUTION_CODE_LPO &&
            this.globalPermissionList.includes(
                platformConstants.PERMISSION_READ_LPO_SIMULATOR
            );

        project.canViewStdProjAdministrator = this.globalPermissionList.includes(
            platformConstants.PERMISSION_NAV_STD_ADMINISTRATION
        );

        if (project.permissions.length === 0) {
            project.canDuplicate =
                project.canRename =
                project.canDelete =
                this.globalPermissionList.includes(
                    platformConstants.PERMISSION_VIEW_ALL_PROJECTS
                );
            return;
        }

        project.permissions.forEach((permission) => {
            switch (permission) {
                case 'CREATE_PROJECT':
                    project.canDuplicate = true;
                    break;
                case 'UPDATE_PROJECT':
                    project.canRename = true;
                    break;
                case 'DELETE_PROJECT':
                    project.canDelete = true;
                    break;
            }
        });
    }
    /**
     * @method  hasAnalytics()
     * @param  {solutionCode}
     *
     * Method returns true when the given solution has Analytics enabled otherwise false
     */
    public populateAnalyticsViewSolutions(): void {
        this.solutionsList.forEach((solution) => {
            if (solution.hasAnalytics) {
                this.analyticsViewSolutions.push(solution.code);
            }
        });
    }
    /**
     * @method  onScroll()
     *
     * Method gets invoked when user reaches towards the end of the page to pull more projects.
     */
    public onScroll(): void {
        if (this.limitExceeded === 0) {
            return;
        }
        this.spinner = true;
        this.lazyLoad.emit(new Date());
        this._analyticsService.track('Infinite Scroll Pagination');
    }

    /**
     * @method showHideProjectDetails()
     * @param  {ProjectModel}
     *
     * to open and close the project status panel to view Concepts and Fielding Details
     */
    public showHideProjectDetails(project: ProjectModel): void {
        if (!project) {
            return;
        }
        this.projects.forEach((ele) => {
            if (ele.id === project.id) {
                ele.isExpanded = !ele.isExpanded;
                if (ele.isExpanded) {
                    window.scroll(0, document.getElementById(project.id).offsetTop - 500);
                }
                this._analyticsService.trackDisplay(
                    ele.isExpanded,
                    this.translate.instant('project.table_header.project_status'),
                    {
                        projectId: project.id,
                    }
                );
            } else {
                ele.isExpanded = false;
            }
        });
        this.projectGridColor = this.projectStatusBGColor.bgColor;
    }

    /**
     * @method sortByField()
     * @param  {texto}
     *
     * see name
     */
    public sortByField(field: string): void {
        // mixpanel code - start
        const analyticsMessage = {
            statusOrder: 'Project Status Sort',
            name: 'Project Name Sort',
            solutionName: 'Project Solution Type Sort',
            fieldingCountry: 'Project Fielding Country Sort',
            lastUpdated: 'Project Update Sort',
            dateCreated: 'Project Created Sort',
        };
        this._analyticsService.track(analyticsMessage[field]);
        // end
        this.fieldClicked = JSON.parse(JSON.stringify(field));

        if (field) {
            this.isDesc = !this.isDesc;

            if (this.sortingOrder === field) {
                if (this.isDesc) {
                    this.fieldClick.emit({ field: field, type: 'DESC' });
                } else {
                    this.fieldClick.emit({ field: field, type: 'ASC' });
                }
            } else {
                this.sortingOrder = field;
                this.fieldClick.emit({ field: field, type: 'ASC' });
            }
        }
    }

    /**
     * @method onEllipsisClick()
     * @param  {ProjectModel}
     *
     */
    public onEllipsisClick(project: ProjectModel): void {
        this._analyticsService.trackDisplay(true, 'Project Options', {
            projectId: project.id,
        });
        this.ellipseIconClicked = true;
        this.showPinIcon(project, true);
    }

    /**
     * @method showPinIcon()
     * @param  {ProjectModel}
     *
     * hide and show the pin and ellipse icon on hover.
     */
    public showPinIcon(project: ProjectModel, val: boolean): void {
        project.showHidePinEllipseIcon = this.ellipseIconClicked ? true : val;
    }

    /**
     * @method openDuplicateProject()
     * @param  {ProjectModel}
     *
     * opening the dialog to see the duplicateProjectComponent.
     */
    public openDuplicateProject(project: ProjectModel): void {
        const dupProject: ProjectModel = JSON.parse(JSON.stringify(project));
        dupProject.id = null;
        dupProject.duplicateId = project.id;
        dupProject.workbenchId = null;
        dupProject.collaborators = [];

        const ref = this._dialogRef.open(DuplicateProjectComponent, {
            data: {
                duplicateProject: dupProject,
                isWorkbenchVisible: this.isWorkbenchVisible,
            },
        });

        ref.afterClosed().subscribe((data) => {
            if (data && data.id) {
                this._messageBar.openSnackBar(
                    platformConstants.SNACK_BAR_TYPE_SUCCESS,
                    'Project duplicated successfully.'
                );
                this.mapLinks(data);
                if (data.inputsLink) {
                    window.location.href = `${this._configService.config.studio.url}${data.inputsLink}`;
                }
            }
        });
        this._analyticsService.track('Duplicate Project', {
            projectId: project.id,
        });
    }

    /**
     * @method renameProject()
     * @param  {ProjectModel}
     *
     * gets invoked when trying to rename a project.
     */
    public renameProject(project: ProjectModel): void {
        const ref = this._dialogRef.open(RenameProjectComponent, {
            data: { project: project, isWorkbenchVisible: this.isWorkbenchVisible },
            minWidth: '55vw',
        });

        ref.afterClosed().subscribe((data) => {
            if (data && data.id) {
                this._messageBar.openSnackBar(
                    platformConstants.SNACK_BAR_TYPE_SUCCESS,
                    'Project renamed successfully.'
                );
                this.projects.forEach((element) => {
                    if (element.id === data.id) {
                        element.name = data.name;
                        element.workbenchId = data.workbenchId;
                    }
                });
            }
        });
        this._analyticsService.track('Rename Project', {
            projectId: project.id,
        });
    }

    /**
     * @method navigate()
     * @param  {ProjectModel}
     *
     * Navigates the user to Studio inputs page.
     */
    public navigate(project: ProjectModel): string {
        const inputsPath = project.inputsLink;
        const resultsPath = project.resultsLink;
        const reportsPath = project.reportsLink;

        if(project.status.toUpperCase() === 'INPUTS') {
            return `${this._configService.config.studio.url}${inputsPath}`;
        }else if(project.status.toUpperCase() === 'LOCKED'){
            return this.userInfo.isInternalUser ? `${this._configService.config.studio.url}${resultsPath}` : `${this._configService.config.studio.url}${inputsPath}`;
        }else{
            return `${this._configService.config.studio.url}${reportsPath}`;
        }
    }

    /**
     * @method renameProject()
     * @param  {ProjectModel}
     *
     * gets invoked when trying to delete a project.
     */
    public deleteProject(project: ProjectModel): void {
        if (project.surveyIds?.length > 0 || project.status === 'INPUTS') {
            return;
        }

        const ref = this._dialogRef.open(DeleteProjectComponent, {
            data: project,
            maxWidth: '30vw',
        });

        ref.afterClosed().subscribe((data) => {
            if (!data || data === true) {
                return;
            }

            if (data && data.error) {
                this._messageBar.openSnackBar(
                    platformConstants.SNACK_BAR_TYPE_ERROR,
                    data.error
                );
                return;
            }

            const index = this.projects.indexOf(project);
            this.projects.splice(index, 1);
            this._messageBar.openSnackBar(
                platformConstants.SNACK_BAR_TYPE_SUCCESS,
                'Deleted successfully.'
            );
        });
        this._analyticsService.track('Delete Project', {
            projectId: project.id,
        });
    }

    public getProjectDetailsForAnalytics(project: ProjectModel): {} {
        return { projectId: project.id };
    }

    /**
     * Redirects to studio's survey editor.
     * @param project
     */
    public redirectToSurveyEditor(project: ProjectModel): string {
        if (project?.surveyIds.length) {
            return `${this._configService.config.studio.url}${project.surveyLink}`;
        }
    }

    /**
     * Redirects to studio's fielding page.
     * @param project
     */
    public redirectToFieldingPage(project: ProjectModel): string {
        const fieldingIds = project.fieldingIds;
        if (fieldingIds) {
            return `${this._configService.config.studio.url}/#/projects/${project.referenceId}/fieldings/${fieldingIds[fieldingIds.length - 1]}`;
        }
    }

    public onProjectEditClick(project: ProjectModel): void{
        this._analyticsService.track('uf-hm-ProjCrd-Edit-clicked', {
            projectId: project.id,
    });
    }

    public redirectToProjectEditor(project: ProjectModel): string {
        const fieldingIds = project.fieldingIds;
        if (fieldingIds) {
            return `${this._configService.config.studio.url}/#/projects/${project.referenceId}/inputs`;
        }
    }

    /**
     * Redirects to studio's analytics page.
     * @param project
     */
    public redirectToAnalytics(project: ProjectModel): string {
        const fieldingIds = project.fieldingIds;
        if (fieldingIds) {
            return `${this._configService.config.studio.url}${project.analyticsLink}`;
        }
    }

    /**
     * Redirects to LPO Simulator page.
     * @param project
     */
    public redirectToLpoSimulator(project: ProjectModel): string {
        const fieldingIds = project.fieldingIds;
        if (fieldingIds) {
            return `${this._configService.config.lpoSimulator.url}/#/projects/${project.referenceId}`;
        }
    }

    public redirectTo(link: string): string {
        if (link) {
            return this._configService.config.studio.url + link;
        }
    }

    public trackBY(project: ProjectModel, linkClicked: string): void {
        switch (linkClicked) {
            case 'survey': {
                this._analyticsService.track('Clicked Survey Editor', {
                    projectId: project.id,
                    url: `/#/projects/${project.referenceId}/surveys`,
                });
                break;
            }
            case 'fielding': {
                this._analyticsService.track('Clicked Fielding Page', {
                    projectId: project.id,
                    url: `/#/projects/${project.referenceId}/fieldings/${project.fieldingIds[project.fieldingIds.length - 1]}`,
                });
                break;
            }
            case 'analytics': {
                this._analyticsService.track('Clicked Analytics', {
                    projectId: project.id,
                    url: `/#/projects/${project.referenceId}/analytics`,
                });
                break;
            }
            case 'lpo-simulator': {
                this._analyticsService.track('Clicked Simulator', {
                    projectId: project.id,
                    url: `/#/projects/${project.referenceId}`,
                });
                break;
            }
            case 'project-elements': {
                this._analyticsService.track('uf-hm-ProjCrd-Edit-clicked', {
                    projectId: project.id,
                });
                break;
            }
        }
    }



    public saveFavorite(project: ProjectModel): void {
        this._projectService.saveFavorite(project.id).subscribe(
            (data) => {
                if (data && data.id) {
                    project.isFavorite = !project.isFavorite;
                    this._messageBar.openSnackBar(
                        platformConstants.SNACK_BAR_TYPE_SUCCESS,
                        project.isFavorite
                            ? 'Successfully added to favorites.'
                            : 'Successfully removed from favorites.'
                    );

                    this.favCount = data.projectIds.length;

                    // mixpanel for making or unmarking favorites - start
                    this._analyticsService.track(
                        project.isFavorite
                            ? 'Marking project as favourite'
                            : 'Untagging the project - back to non-favourite'
                    );
                    // end
                }
            },
            (error) => {
                this._messageBar.openSnackBar(
                    platformConstants.SNACK_BAR_TYPE_ERROR,
                    error.error.error.details[0]
                );
            }
        );
    }
}
