import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EMPTY_STATES } from '../constant';
import { ConceptModel } from '../models/concept/concept-model';
import { ConceptService } from '../services/concepts/concept.service';

@Component({
    selector: 'niq-concepts',
    templateUrl: './concepts.component.html',
    styleUrls: ['./concepts.component.scss'],
})
export class ConceptsComponent implements OnInit, OnDestroy {
    @Input() public link: string;
    /**
     * List of concepts to be displayed
     *
     * @memberof ConceptsComponent
     */
    public concepts: ConceptModel[] = [];

    /**
     * boolean flag to show/hide spinner.
     *
     * @memberof ConceptsComponent
     */
    public spinner: boolean = false;

    /**
     * Empty state object for concepts.
     *
     * @memberof ConceptsComponent
     */
    public emptyConst = EMPTY_STATES.concepts;

    public sub: Subscription;

    /**
     * Constructor
     *
     * @memberof ConceptsComponent
     */
    constructor(private _conceptService: ConceptService) { }

    public ngOnInit(): void {
        this.getConpets();
    }

    /**
     * Retuns thumbnail url for the concept image.
     *
     * @memberof ConceptsComponent
     * @param {string}
     * @returns {string}
     */
    public getThumbnailUrl(thumbnailPath: string): string {
        return this._conceptService.getThumbnailUrl(thumbnailPath);
    }

    /**
     * Loads the list of concepts data.
     *
     * @memberof ConceptsComponent
     */
    public getConpets(): void {
        this.spinner = true;
        this.sub = this._conceptService.get(this.link).subscribe(
            (data) => {
                this.spinner = false;
                this.concepts = data;
            },
            (error) => {
                console.error(error);
            }
        );
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
