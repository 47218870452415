<niq-spinner [display]="spinner"></niq-spinner>
<div *ngIf="solutionUnderEdit" class="edit-solution-container">
  <div class="edit-solution-details">
    <label class="header-label edit-solution-title">{{"solution.edit.details" | translate}}
      - {{solutionUnderEdit.name}}</label>
    <niq-input-box inputbox [fieldName]="titleOneLabel" [inputBoxValue]="solutionUnderEdit.benefits[0].title"
      [maxLength]="50" [hasLabel]="true" [displayLabel]="titleOneLabel" [isRequired]="true" [inputPlaceholder]="''"
      [inputClass]="'title-input'" [errorResponse]="" (isFormDirty)="isFormDirty = $event"
      (isInputValid)="isTitleOneValid = $event"
      [fieldRequiredError]="'project.validations.field_required_error'|translate"
      (inputBoxUpdatedValue)="setTitle($event, 0)">
    </niq-input-box>
    <niq-multiline-input-box [fieldName]="descriptionOneLabel"
      [inputBoxValue]="solutionUnderEdit.benefits[0].description" [maxLength]="250" [hasLabel]="true"
      [displayLabel]="descriptionOneLabel" [isRequired]="true" [inputPlaceholder]="''" [errorResponse]=""
      (isFormDirty)="isFormDirty = $event" (isInputValid)="isDescriptionOneValid = $event"
      [fieldRequiredError]="'project.validations.field_required_error'|translate"
      (inputBoxUpdatedValue)="setDescription($event, 0)">
    </niq-multiline-input-box>
    <label class="header-label">{{ "solution.properties.titleOneIcon" | translate }}</label>
    <span class="hidden">
      <input #uploaderInput0 type="file" name="files[]" accept="image/*" (change)="uploadBenefitIcon(0)">
    </span>
    <button class="mat-button" mat-icon-button aria-label="Info" (click)="selectFilesToUploadForBenefit($event, 0)">
      <span class="sif sif-upload"></span>
    </button>
    <button class="mat-button" mat-icon-button aria-label="Info"
      [disabled]="solutionUnderEdit.benefits[0].icon === placeHolderImage" (click)="removeBenefitIcon(0)">
      <span class="sif sif-delete"></span>
    </button>
    <niq-input-box inputbox [fieldName]="titleTwoLabel" [inputBoxValue]="solutionUnderEdit.benefits[1].title"
      [maxLength]="50" [hasLabel]="true" [displayLabel]="titleTwoLabel" [isRequired]="true" [inputPlaceholder]="''"
      [inputClass]="'title-input'" [errorResponse]="" (isFormDirty)="isFormDirty = $event"
      (isInputValid)="isTitleTwoValid = $event"
      [fieldRequiredError]="'project.validations.field_required_error'|translate"
      (inputBoxUpdatedValue)="setTitle($event, 1)">
    </niq-input-box>
    <niq-multiline-input-box [fieldName]="descriptionTwoLabel"
      [inputBoxValue]="solutionUnderEdit.benefits[1].description" [maxLength]="250" [hasLabel]="true"
      [displayLabel]="descriptionTwoLabel" [isRequired]="true" [inputPlaceholder]="''" [errorResponse]=""
      (isFormDirty)="isFormDirty = $event" (isInputValid)="isDescriptionTwoValid = $event"
      [fieldRequiredError]="'project.validations.field_required_error'|translate"
      (inputBoxUpdatedValue)="setDescription($event, 1)">
    </niq-multiline-input-box>
    <label class="header-label">{{ "solution.properties.titleTwoIcon" | translate }}</label>
    <span class="hidden">
      <input #uploaderInput1 type="file" name="files[]" accept="image/*" (change)="uploadBenefitIcon(1)">
    </span>
    <button class="mat-button" mat-icon-button aria-label="Info" (click)="selectFilesToUploadForBenefit($event, 1)">
      <span class="sif sif-upload"></span>
    </button>
    <button class="mat-button" mat-icon-button aria-label="Info"
      [disabled]="solutionUnderEdit.benefits[1].icon === placeHolderImage" (click)="removeBenefitIcon(1)">
      <span class="sif sif-delete"></span>
    </button>
    <br>
    <label class="header-label">{{ "solution.properties.slideshow_images" | translate }}
      : {{solutionUnderEdit.slides.length}}
    </label>
    <span class="hidden">
      <input #uploaderInputForSlideshow type="file" multiple name="files[]" accept="image/*"
        (change)="uploadSlideshowImages()">
    </span>
    <button class="mat-button" mat-icon-button aria-label="Info" (click)="selectFilesToUploadForSlideshow($event)">
      <span class="sif sif-upload"></span>
    </button>
    <button class="mat-button" mat-icon-button aria-label="Info" [disabled]="solutionUnderEdit.slides.length === 0"
      (click)="removeSlideshowImages()">
      <span class="sif sif-delete"></span>
    </button>
  </div>
  <mat-dialog-actions class="align-items-right">
    <button class="mat-secondary" mat-button (click)="cancelEdit()">{{'project.generic.cancel' | translate}}</button>
    <button class="mat-flat-button mat-primary" mat-raised-button color="primary"
      [disabled]="!isFormDirty || !isTitleOneValid || !isTitleTwoValid || !isDescriptionOneValid || !isDescriptionTwoValid"
      cdkFocusInitial (click)="saveSolution()">{{'project.generic.save' | translate}}</button>
  </mat-dialog-actions>
</div>
