import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import * as platformConstants from '../constant';
import { ProjectModel } from '../models/project/project-model';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { CardviewService } from '../services/cardview/cardview.service';
import { PermissionService } from '../services/permissions/permission.service';
import { UserInfoService } from '../services/user/user-info.service';
import { RequestProjectService } from '../services/request-projects/request-project.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MessageBarService } from '@platform/services/mesasgeBar/message-bar.service';
@Component({
    selector: 'niq-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [{ provide: Window, useValue: window }],
})
export class HomeComponent implements OnInit {
    @Input() message: string;
    @Input() successmessage: string;
    @Input() successstatus: string;
    @Input() errorstatus: "error";
    @ViewChild('drawer') public flyoutMenu: MatDrawer;
    /**
     * Property to toggle the project creation button.
     *
     * @default  true
     * @type     {boolean}
     * @memberof HomeComponent
     */
    public showCreateProjectBtn: boolean = true;

    /**
     * Property to toggle the solution selection window.
     *
     * @default  false
     * @type     {boolean}
     * @memberof HomeComponent
     */
    public showProjectType: boolean = false;

    /**
     * Property to toggle the project setup window.
     *
     * @default  false
     * @type     {boolean}
     * @memberof HomeComponent
     */
    public showProjectSetup: boolean = false;

    /**
     * Name of the selected solution.
     *
     * @default  ""
     * @type     {any}
     * @memberof HomeComponent
     */
    public selectedSolution: any;

    /**
     * newly created project.
     *
     * @default  ""
     * @type     {any}
     * @memberof HomeComponent
     */
    public projectsList: ProjectModel[];
    public isLoading: boolean;
    public lazyLoadProjects: Date;

    public userInfo: any;
    public offset: number;
    public limit: number;
    public isWorkbenchVisible: boolean = true;
    public isAnalysisStatusVisible: boolean = true;

    public additionalApps = [];
    public isHighlighted: boolean;
    public showMenu: boolean;
    public fieldClick: string;
    public sortOrder: {};
    public favProjCount: number = 0;
    public gridView: boolean;
    public gridViewId: string;
    public gridVal: any[] = [];
    public requestApprovalData: any[];
    public isApprovalAlertVisible: boolean;
    public isApprovalAlert: boolean;
    public latestApprovalCreationDate: any;
    public projectUser: any;
    public isNotificationBarEnabled: boolean;


    /**
     * Constructor.
     *
     * @param    MatDialog
     * @memberof HomeComponent
     */
    constructor(
        public dialog: MatDialog,
        private _analyticsService: AnalyticsService,
        private _configService: AppConfigService,
        private _userInfoService: UserInfoService,
        private requestProjectService: RequestProjectService,
        public window: Window,
        private _cardViewService: CardviewService,
        private _permissionService: PermissionService,
        private _titleService: Title,
        private router: Router,
        private _messageBar: MessageBarService
    ) {
        this._userInfoService.get().subscribe((data: any) => {
            this.userInfo = data;
        });
        this._titleService.setTitle("NIQ Studio - Projects");
    }

    /**
     * Defining and initializing the objects
     *
     * @OnInit - angular Lifecyclehook
     */
    public ngOnInit(): void {
        this.isApprovalAlert = true;
        this.isApprovalAlertVisible = true;
        this.isNotificationBarEnabled = false;
        this.message = "Someone is requesting access to a Project you are a Collaborator on.";
        this._permissionService.get().subscribe((data: any) => {
            this.isWorkbenchVisible = data.permissions.includes(
                platformConstants.PERMISSION_VIEW_WORKBENCH_INFO
            );
            this.isAnalysisStatusVisible = data.permissions.includes(
                platformConstants.PERMISSION_VIEW_PROJECT_ANALYSIS_STATUS
            );
            this.additionalApps = this.filterAdditionalApps(
                this.userInfo?.roles,
                data.permissions
            );
        });

        this._cardViewService.getSavedView().subscribe((data: any) => {
            if (data) {
                this.gridViewId = data.id
                this.gridView = data.bigCardView;
            } else {
                this.gridView = true;
            }
        });

        if(this.userInfo.isInternalUser){
            this.requestProjectService.getApprovalRequestData().subscribe({
                next: (data: any) => {
                  {
                     this.gridVal = Object.values(data);
                     const newData = this.gridVal[6];                    
                     this.requestApprovalData = newData;                     
                     if(this.requestApprovalData.length > 0) {
                        this.latestApprovalCreationDate = this.requestApprovalData[0]?.pendingRequestCreationDate; 
                        this.requestProjectService.getProjectUser().subscribe({
                            next: (data: any) =>{
                                const val = Object.values(data);
                                this.projectUser = val[6];
                                if(this.projectUser?.dismissalDateTime != null && this.latestApprovalCreationDate != null && this.projectUser?.dismissalDateTime < this.latestApprovalCreationDate) {
                                    this.isNotificationBarEnabled = true;
                                } 
                            }
                        });
                     }                     
                   }
                }
              });
        }
    }

    /**
     * @method
     *
     * toggle between the solution type page and create button.
     */
    public showProjectTypeHandler($event): void {
        this._analyticsService.trackButtonClick($event);
        this.showCreateProjectBtn = !this.showCreateProjectBtn;
        this.showProjectType = !this.showProjectType;
    }

    /**
     * @method
     * @param projectSetup
     * @type  {boolean}
     *
     * show the project setup.
     */
    public projectSetupShowHide(projectSetup: boolean): void {
        this.showCreateProjectBtn = !projectSetup;
        this.showProjectSetup = projectSetup;
        this.showProjectType = !this.showProjectType;
    }

    /**
     * @method
     * @param solutionName
     * @type  {string}
     *
     * setting the solution name.
     */
    public setSolution(solution: any): void {
        this.selectedSolution = solution;
    }

    /**
     * @method
     * @param close
     * @type  {boolean}
     *
     * show the create button.
     */
    public showCreateButton(close: boolean): void {
        this.showCreateProjectBtn = !close;
        this.showProjectSetup = close;
        this.showProjectType = close;
    }

    /**
     * @method
     * @param close
     * @type  {boolean}
     *
     * show the solution type page.
     */
    public showPreviousScreen(previous: boolean): void {
        this.showCreateProjectBtn = !previous;
        this.showProjectSetup = !previous;
        this.showProjectType = previous;
    }

    public navigateToHome($event): void {
        this._analyticsService.trackButtonClick($event);
    }

    public navigateToHomeUsingIcon($event): void {
        this._analyticsService.trackButtonClickUsingIcon($event);
    }

    public navigateToRequestAccess(): void {
        this.router.navigateByUrl('/request?selectedtab=0');
        this._analyticsService.track('uf-hm-ReqB-clicked');
    }

    public navigateToApprovalTab(): void {
        this.router.navigateByUrl('/request?selectedtab=2');
        this._analyticsService.track('uf-hm-ViewAccReqPrompt-clicked');
    }

    public trackNavigationOptions(): void {
        this._analyticsService.trackDisplay(true, 'Additional Applications');
        this.isApprovalAlertVisible = false;

    }

    @HostListener('click', ['$event'])
    public onClick(event): void {
        if (
            event.target.name !== 'flyout-menu' &&
            event.target.id !== 'flyout-menu'
        ) {
            this.flyoutMenu.close();
            this.isHighlighted = false;
        } else if (
            event.target.name === 'flyout-menu' ||
            event.target.id === 'flyout-menu'
        ) { 
            if(this.isApprovalAlert)
            this.requestApprovalData.length !== 0 ? this.isApprovalAlertVisible = true : this.isApprovalAlertVisible = false;
            this.isHighlighted = !this.isHighlighted;
        }
    }

    /**
     * @method
     * @param event
     * @param appUrl
     * @type  {void}
     *
     * Helper function to navigate the user to the application clicked.
     */
    public navigateToApp($event, app): void {
        if (
            app.appName === platformConstants.CONCEPT_ARCHIVE &&
            !$event.ctrlKey &&
            !$event.shiftKey
        ) {
            window.open(app.appUrl, '_self');
        } else {
            window.open(app.appUrl, '_blank');
        }
        const buttonText = $event.target.innerText || $event.target.textContent;
        this._analyticsService.trackButtonClick($event, { buttonText, app });
    }
    
    public navigateToAppUsingIcon($event, app): void {
        if (
            app.appName === platformConstants.CONCEPT_ARCHIVE &&
            !$event.ctrlKey &&
            !$event.shiftKey
        ) {
            window.open(app.appUrl, '_self');
        } else {
            window.open(app.appUrl, '_blank');
        }
        const buttonText = $event.target.innerText || $event.target.textContent;
        this._analyticsService.trackButtonClickUsingIcon($event, { buttonText, app });
    }
    /**
     * @method
     * @param roles
     * @param permissions
     * @type  {Array<any>}
     *
     * Filters the flyout menu options based on user roles and permissions.
     */
    public filterAdditionalApps(roles: any, permissions: Array<any>): Array<any> {
        const apps: any = this._configService.config.additionalApps;
        const filteredApps = apps.filter(
            (app) =>
                roles.includes(app.permission) || permissions.includes(app.permission)
        );
        if (filteredApps.length > 0) {
            this.showMenu = true;
        }
        return filteredApps;
    }

    public updateViewSize(isBigCardView: boolean) {
        this._cardViewService.saveView(isBigCardView, this.gridViewId).subscribe((data: any) => {
            if (data) {
                this.gridView = data.bigCardView;
                this.gridViewId = data.id;
            } else {
                this.gridView = true;
            }
        });
    }

    closeAlert(){
        this.isApprovalAlertVisible = false;
        this.isApprovalAlert = false;
        this._analyticsService.track('uf-hm-ViewAccReqPrompt-dismissed');
        this.requestProjectService.updateProjectUser().subscribe(
            (response) => {                
            }, error => {                
            }
        );
    }
}
