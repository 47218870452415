import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../states/app.state';

export const selectPermissionsFeature = createFeatureSelector<
  AppState,
  any
>('permissions');

export const selectPermissionsState = createSelector(
    selectPermissionsFeature,
    (state: any) => state
);
