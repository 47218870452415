import {
    animate,
    animation,
    style,
    transition,
    trigger,
    useAnimation,
} from '@angular/animations';
import {
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { interval, NEVER, Observable, Subject, Subscription } from 'rxjs';
import { map, scan, startWith, switchMap } from 'rxjs/operators';
import { SolutionImagesService } from '../services/solution-images/solution-images.service';

export interface ICounter {
    pause?: boolean;
    counterValue?: number;
}

export const fadeIn = animation([
    style({ opacity: 0.2 }),
    animate('4s', style({ opacity: 1 })),
]);

@Component({
    selector: 'niq-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    animations: [
        trigger('carouselAnimation', [
            transition('void=>*', [useAnimation(fadeIn)]),
        ]),
    ],
})
export class CarouselComponent implements OnInit, OnDestroy {
    private _counterSubject: Subject<ICounter> = new Subject();
    @Input() public slides: Array<any>;
    @Input() public slideDuration: number;
    public currentIndex: number = 0;
    public counterValue: string;
    public interval: Subscription;
    public spinner = false;
    public imageBlob: Blob;
    public imageSlides: Array<any> = [];
    public sub: Subscription[] = [];

    constructor(
        private _solutionImageService: SolutionImagesService,
        private _sanitizer: DomSanitizer
    ) { }

    public ngOnInit(): void {
        this.slides = this.slides ? this.slides : [];
        this._solutionImageService
            .get('place-holder.png', 'benefits')
            .subscribe((data: any) => {
                this.imageBlob = data['place-holder.png'];
            });
        this.initInterval();
    }

    public initInterval(): void {
        this.getImages(this.currentIndex);
        if (this.slides.length === 1) {
            return;
        }
        if (this.interval) {
            this.interval.unsubscribe();
        }
        this.interval = this.slideInterval(this.slideDuration * 10).subscribe(
            (d) => {
                this.counterValue = d.counterValue + '%';
                if (d.counterValue === 100) {
                    this.next();
                }
            }
        );
        this.sub.push(this.interval);
    }

    public next(): void {
        if (this.currentIndex < this.slides.length - 1) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }
        this.initInterval();
    }

    public pre(): void {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        } else {
            this.currentIndex = this.slides.length - 1;
        }
        this.initInterval();
    }

    public setSlide(i): void {
        this.currentIndex = i;
    }

    @HostListener('mouseenter')
    public onMouseEnter(): void {
        this.pauseCounter();
    }

    @HostListener('mouseleave')
    public onMouseLeave(): void {
        this.resumeCounter();
    }

    public slideInterval(duration: number): Observable<ICounter> {
        return this._counterSubject.pipe(
            startWith({ pause: false, counterValue: 0 }),
            scan((acc, val) => ({ ...acc, ...val })),
            switchMap((state) =>
                state.pause
                    ? NEVER
                    : interval(duration).pipe(
                        map(() => {
                            state.counterValue += 1;
                            return state;
                        })
                    )
            )
        );
    }

    public pauseCounter(): void {
        this._counterSubject.next({ pause: true });
    }

    public resumeCounter(): void {
        this._counterSubject.next({ pause: false });
    }

    public getImages(curInd: number): void {
        if (this.imageSlides.length === this.slides.length) {
            return;
        }
        this.spinner = true;
        this.pauseCounter();
        const subRef = this._solutionImageService
            .get(this.slides[curInd], 'slideshow')
            .subscribe(
                (data: any) => {
                    this.spinner = false;
                    this.imageBlob = data[this.slides[curInd]];
                    this.createImageFromBlob();
                    this.resumeCounter();
                },
                () => {
                    this.spinner = false;
                    this.createImageFromBlob();
                    this.resumeCounter();
                }
            );

        this.sub.push(subRef);
    }

    public createImageFromBlob(): void {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.imageSlides[this.currentIndex] =
                    this._sanitizer.bypassSecurityTrustUrl(reader.result.toString());
            },
            false
        );
        if (this.imageBlob) {
            reader.readAsDataURL(this.imageBlob);
        }
    }

    public ngOnDestroy(): void {
        this.sub.forEach((ele) => {
            ele.unsubscribe();
        });
    }
}
