import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EMPTY_STATES } from '../constant';
import { SKUList } from '../models/sku-list/skulist';
import { SkulistService } from '../services/skulists/skulist.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'niq-design-options-list',
  templateUrl: './design-options-list.component.html',
  styleUrls: ['./design-options-list.component.scss']
})
export class DesignOptionsListComponent implements OnInit, OnDestroy {
  @Input() public link: string;

  /**
   * List of sku's to be loaded.
   *
   * @returns  {void}
   * @memberof SkuListComponent
   */
  public skuLists: Array<SKUList> = [];

  /**
   * Boolean fieldind to toggle spinner.
   *
   * @returns  {void}
   * @memberof SkuListComponent
   */
  public spinner = false;
  /**
   * Empty state constant.
   *
   * @returns  {void}
   * @memberof SkuListComponent
   */
  public emptyConst = EMPTY_STATES.design_options_lists;

  public sub: Subscription;

  constructor(private _skuListService: SkulistService) {}

  public ngOnInit(): void {
    this.getSkuList();
  }

  /**
   * Loads the sku slit data.
   *
   * @returns  {void}
   * @memberof SkuListComponent
   */
  public getSkuList(): void {
      this.spinner = true;
      this.sub = this._skuListService.get(this.link).subscribe(
          (data) => {
              this.spinner = false;
              this.skuLists = data;
          },
          (error) => {
              console.error(error);
          }
      );
  }

  public ngOnDestroy(): void {
      this.sub.unsubscribe();
  }
}
