<niq-spinner [display]="spinner"></niq-spinner>
<div class="project-grid-container" *ngIf="!spinner || (projects && projects.length > 0)">
  <niq-empty-page [icon]="emptyConst.icon" [leadText]="emptyConst.lead_text" [supportText]="emptyConst.support_text"
    [pageSize]="'large'" *ngIf="!projects || projects.length === 0"></niq-empty-page>
  <div class="grid-header" *ngIf="projects && projects.length > 0">
    <mat-grid-list cols="24" rowHeight="40px">
      <mat-grid-tile *ngFor="let tile of gridTitle" [colspan]="tile.cols" [rowspan]="tile.rows"
        [class]="tile.className">
        <span (click)="sortByField(tile.dbPropertyName)" class="cursor-pointer" matTooltipClass="custom-tooltip"
          [matTooltip]="tile.tooltip">
          {{ tile.text | translate }}
        </span>
        <button mat-icon-button *ngIf="tile.dbPropertyName === fieldClicked && sortingOrder === tile.dbPropertyName"
          (click)="sortByField(tile.dbPropertyName)" class="sort-icons" class = "sort-arrows" [disableRipple]="true">
          <span class="sif" [class]="isDesc ? 'sif-arrow-s' : 'sif-arrow-n'"></span>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div class="grid-container" *ngIf="projects && projects.length > 0">
    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScroll()">
      <mat-card *ngFor="let project of projects" [id]="project.id" [@backgroudAnimation]="project.isExpanded ? 1 : 0">
        <mat-grid-list cols="24" rowHeight="85px" (mouseover)="showPinIcon(project, true)"
          (mouseleave)="showPinIcon(project, false)">
          <mat-grid-tile colspan="1" [ngStyle]="{ cursor: 'pointer' }" (click)="showHideProjectDetails(project)">
            <span class="chevron" *ngIf="!(project && project.isExpanded)">
              <span class="sif sif-chevron-e"></span>
            </span>
            <span class="chevron" *ngIf="project && project.isExpanded">
              <span class="sif sif-chevron-s"></span>
            </span>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="status-container" (click)="showHideProjectDetails(project)"
            (mouseenter)="project.hoverProjectStatus = true" (mouseleave)="project.hoverProjectStatus = false">
            <niq-delivered-badge class="result-delivered-badge" *ngIf="project?.status === 'Results Ready'"
              [ngStyle]="{ color: '#00eb00' }" [class]="
                project.isExpanded || project.hoverProjectStatus
                  ? 'result-ready-hover'
                  : ''
              ">
            </niq-delivered-badge>

            <niq-donut-chart class="status-progress-spinner" [solutionType]="project?.solutionCode" [displayIcon]="
                statusConfig[project.status.replace(' ', '_')].icon
              " [value]="statusConfig[project.status.replace(' ', '_')].value"
              [progressSpinnerColor]="projectStatusBGColor.color"
              [baseProgressSpinnerColor]="projectStatusBGColor.color" [strokeWidth]="5" [diameter]="
                project.isExpanded || project.hoverProjectStatus ? 48 : 58
              " [statusType]="project?.status" [class]="
                project.isExpanded || project.hoverProjectStatus
                  ? 'with-status'
                  : ''
              ">
            </niq-donut-chart>
            <div class="status-desc with-status" *ngIf="project.isExpanded || project.hoverProjectStatus">
              {{ project?.status.toUpperCase() }}
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1"></mat-grid-tile>
          <mat-grid-tile colspan="8" class="project-details-container">
            <div>
              <label><a [href]="navigate(project)">{{ project?.name }}</a></label>
              <p *ngIf="project && (project.workbenchId || project.client)">
                <span *ngIf="project.workbenchId">
                  {{
                  "project.table_content.additional_info.workbenchId"
                  | translate
                  }} {{ project.workbenchId }}
                </span>
                <span *ngIf="project.workbenchId && project.client"> - </span>
                <span *ngIf="project.client">{{ project.client }}</span>
              </p>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="3" class="solution-container">
            {{ project?.solutionName }}
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="country-container" (mouseenter)="
              countriesHover = project?.countries.fielding.length > 1
            " (mouseleave)="countriesHover = false">
            <div [matTooltip]="
                project?.countries.fielding.length > 1
                  ? project?.countries.fieldingStr
                  : ''
              " matTooltipClass="custom-tooltip">
              {{
              project?.countries.fielding.length > 1
              ? project?.countries.fielding[0] +
              "...(" +
              project.countries.fielding.length +
              ")"
              : project?.countries.fielding[0]
              }}
            </div>
          </mat-grid-tile>

          <mat-grid-tile colspan="2" class="lastUpdated-container">
            <div>
              {{project?.lastUpdated | date: 'MMM d, yyyy'}}
            </div>
          </mat-grid-tile>

          <mat-grid-tile colspan="2" class="dateCreated-container">
            <div>
              {{project?.dateCreated | date: 'MMM d, yyyy'}}
            </div>
          </mat-grid-tile>

          <mat-grid-tile colspan="3">
            <div [matTooltip]="'Click to remove from favorites'" matTooltipClass="custom-tooltip"
              [hidden]="!project?.isFavorite || project?.showHidePinEllipseIcon" class="pin-project flex-start">
              <button mat-icon-button class="star-position">
                <span class="sif sif-star-filled"></span>
              </button>
            </div>
            <div class="pin-project" [hidden]="!project?.showHidePinEllipseIcon">
              <div class="icons">
                <!-- favorite -->
                <div [matTooltip]="!project?.isFavorite
                  ? favCount < 15
                  ? 'Click to add as favorite'
                  : 'You have reached the limit of 15 favourite projects. Un-tag some projects to mark new ones as favourite'
                  : 'Click to remove from favorites'" matTooltipClass="custom-tooltip">
                  <button mat-icon-button *ngIf="!project?.isFavorite" [disabled]="favCount >= 15"
                    (click)="saveFavorite(project)">
                    <span class="sif sif-star"></span>
                  </button>
                  <button mat-icon-button *ngIf="project?.isFavorite" (click)="saveFavorite(project)">
                    <span class="sif sif-star-filled"></span>
                  </button>
                </div>
                <div
                  *ngIf="project.canRename || (project?.canViewSurvey && project.surveyIds?.length > 0) ||
                  (project.canViewFielding && project.fieldingIds?.length > 0) ||
                  (project.canViewAnalytics && project.fieldingIds?.length > 0) ||
                  (project.canViewLpoSimulator && project.fieldingIds?.length > 0) ||
                  project.studioCollabsLink || (project.canViewStdProjAdministrator && project.studioAdministrationLink)">
                  <button mat-icon-button matTooltip="Click for more options" matTooltipClass="custom-tooltip"
                    [matMenuTriggerFor]="menu" (click)="onEllipsisClick(project)" #clickMenuTrigger="matMenuTrigger"
                    [class]="ellipseIconClicked ? 'icon-active' : ''">
                    <span class="sif sif-ellipsis-vertical"></span>
                  </button>
                </div>
              
              <mat-menu #menu="matMenu" (closed)="
                  ellipseIconClicked = false; showPinIcon(project, false)
                ">
                <div [matTooltip]="
                    project?.status.toUpperCase() === 'INPUTS'
                      ? 'Change the project name'
                      : 'Locked project cannot be renamed'
                  " matTooltipClass="custom-tooltip" *ngIf="project.canRename">
                  <button mat-menu-item (click)="renameProject(project)"
                    [disabled]="project?.status.toUpperCase() !== 'INPUTS'">
                    <span translate>{{
                      "project.table_content.actions.rename"
                      }}</span>
                  </button>
                </div>
                
                <hr *ngIf="
                    (project?.canViewSurvey && project.surveyIds?.length > 0) ||
                    (project.canViewFielding && project.fieldingIds?.length > 0) ||
                    (project.canViewAnalytics && project.fieldingIds?.length > 0) ||
                    (project.canViewLpoSimulator && project.fieldingIds?.length > 0)
                  " />
                <div *ngIf="
                    project?.canViewSurvey && project.surveyIds?.length > 0
                  " class="redirect-link" (click)="trackBY(project, 'survey')"
                  [matTooltip]="'View or edit survey'" matTooltipClass="custom-tooltip">
                    <a mat-menu-item [href]="redirectToSurveyEditor(project)">
                      <span translate>{{
                        "project.table_content.actions.survey_editor"
                        }}</span>
                    </a>
                </div>
                <div *ngIf="
                    project.canViewFielding && project.fieldingIds?.length > 0
                  " class="redirect-link" (click)="trackBY(project, 'fielding')"
                  [matTooltip]="'Preview and manage fielding data'" matTooltipClass="custom-tooltip">
                    <a mat-menu-item [href]="redirectToFieldingPage(project)">
                      <span translate>{{
                        "project.table_content.actions.fielding_page"
                        }}</span>
                    </a>
                </div>
                <div *ngIf="
                   project.canViewAnalytics && project.fieldingIds?.length > 0
                  " class="redirect-link" (click)="trackBY(project, 'analytics')"
                  [matTooltip]="'Tabulate project data'" matTooltipClass="custom-tooltip">
                    <a mat-menu-item [href]="redirectToAnalytics(project)">
                      <span translate>{{
                        "project.table_content.actions.analytics"
                        }}</span>
                    </a>
                </div>
                <div *ngIf="
                   project.canViewLpoSimulator && project.fieldingIds?.length > 0
                  " class="redirect-link" (click)="trackBY(project, 'lpo-simulator')"
                  [matTooltip]="'Create and run scenarios in the simulator'" matTooltipClass="custom-tooltip simulator-tooltip">
                  <a mat-menu-item [href]="redirectToLpoSimulator(project)">
                    <span translate>{{
                      "project.table_content.actions.simulator"
                      }}</span>
                  </a>
                </div>
                <hr *ngIf="project.canRename">
                <div *ngIf="project.studioCollabsLink" class="redirect-link"
                  (click)="trackBY(project, 'studio collaborators')" matTooltip="Manage Collaborators"
                  matTooltipClass="custom-tooltip">
                  <a mat-menu-item [href]="redirectTo(project.studioCollabsLink)">
                    <span translate>{{
                      "project.table_content.actions.collaborators"
                      }}</span>
                  </a>
                </div>
                <hr *ngIf="project.canViewStdProjAdministrator && project.studioAdministrationLink">
                <div *ngIf="project.canViewStdProjAdministrator && project.studioAdministrationLink"
                  class="redirect-link" (click)="trackBY(project, 'project administration')"
                  [matTooltip]="'Set up draft and live surveys'" matTooltipClass="custom-tooltip">
                    <a mat-menu-item [href]="redirectTo(project.studioAdministrationLink)">
                      <span translate>{{
                        "project.table_content.actions.studio_administration"
                        }}</span>
                    </a>
                </div>
                <hr>
                <div [matTooltip]="project?.deleteProjectToolTip" *ngIf="project.canDelete" matTooltipClass="custom-tooltip">
                  <button mat-menu-item (click)="deleteProject(project)" [disabled]="
                  project?.status.toUpperCase() !== 'INPUTS' ||
                  project.surveyIds?.length > 0
                ">
                    <span translate>{{
                      "project.table_content.actions.delete"
                      }}</span>
                  </button>
                </div>
              </mat-menu>
                <!-- duplicate -->
                <div>
                  <button mat-icon-button matTooltip="Duplicate Project" matTooltipClass="custom-tooltip"
                    (click)="openDuplicateProject(project)" *ngIf="project.canDuplicate">
                    <span class="sif sif-copy"></span>
                  </button>
                </div>
                <!-- more option -->
                
              
              <div class="nohover" [matTooltip]="'Manage Project Elements'" matTooltipClass="custom-tooltip">
                <a class="edit-Projects" [href]="redirectToProjectEditor(project)">
                  <button mat-icon-button (click)="onProjectEditClick(project)">
                    <span class="sif sif-edit"></span>
                  </button>
                </a>
              </div>
              
              </div>
            </div>
            
          </mat-grid-tile>
        </mat-grid-list>
        <div [@bodyExpansion] style="overflow: 'hidden';" *ngIf="project?.isExpanded">
          <mat-grid-list cols="12" class="dynamic-height">
            <div class="concepts-fielding-container collapsible">
              <div [class]="project && !project.fieldingsLink ? 'width-full' : ''">
                <niq-concepts *ngIf="project && project.conceptsLink" [link]="project.conceptsLink"></niq-concepts>
                <niq-sku-list *ngIf="project && project.skuListsLink" [link]="project.skuListsLink"></niq-sku-list>
                <niq-design-options-list *ngIf="project && project.designOptionsLink" [link]="project.designOptionsLink"></niq-design-options-list>
              </div>
              <div *ngIf="project && project.fieldingsLink">
                <niq-fieldings [link]="project.fieldingsLink"></niq-fieldings>
              </div>
            </div>
          </mat-grid-list>
        </div>
      </mat-card>
    </div>
  </div>
</div>