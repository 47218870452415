import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { FieldingModel } from '../models/fielding/fielding-model';
import { ProjectModel } from '../models/project/project-model';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { FieldingDataService } from '../services/fieldings/fielding-data.service';
import { ProjectService } from '../services/projects/project.service';
import * as platformConstants from '../constant';
import { MessageBarService } from '../services/mesasgeBar/message-bar.service';

/**
 * Model for Grid Header.
 *
 * @export
 * @interface GridHeader
 */
export interface GridHeader {
    /**
     * dynamic scss classname.
     *
     * @type      {string}
     * @memberof  GridHeader
     */
    className: string;

    /**
     * column size.
     *
     * @type      {number}
     * @memberof  GridHeader
     */
    cols: number;

    /**
     * row size.
     *
     * @type      {number}
     * @memberof  GridHeader
     */
    rows: number;

    /**
     * Header name.
     *
     * @type      {string}
     * @memberof  GridHeader
     */
    text: string;
}

@Component({
    selector: 'niq-panel-project-details',
    templateUrl: './panel-project-details.component.html',
    styleUrls: ['./panel-project-details.component.scss'],
})
export class PanelProjectDetailsComponent implements OnInit, OnChanges {
    @Input() public newProject: ProjectModel;

    /**
     * gird header details array object.
     *
     * @type      {Array<GridHeader>}
     * @memberof  PanelProjectDetailsComponent
     */
    public gridTitle: Array<GridHeader> = [];

    /**
     * project details array object.
     *
     * @type      {Array<ProjectModel>}
     * @memberof  PanelProjectDetailsComponent
     */
    public projects: Array<ProjectModel> = [];

    /**
     * fielding details.
     *
     * @type      {Array<ProjectModel>}
     * @memberof  PanelProjectDetailsComponent
     */
    public fieldingData: Array<FieldingModel> = [];

    /**
     * Flag to know if all scroll reached the end.
     *
     * @type      {boolean}
     * @memberof  PanelProjectDetailsComponent
     */
    public finishedScroll: boolean = false;

    /**
     * Limit to pull number of projects in an API call.
     *
     * @type      {number}
     * @memberof  PanelProjectDetailsComponent
     */
    private _limit: number = platformConstants.PROJECTS_API_LIMIT;

    /**
     * Offset number to be sent in the projects API call.
     *
     * @type      {number}
     * @memberof  PanelProjectDetailsComponent
     */
    private _offset: number = platformConstants.PROJECTS_API_OFFEST;

    /**
     * Flag to enable/disable spinner
     *
     * @type      {number}
     * @memberof  PanelProjectDetailsComponent
     */
    public spinner: boolean = false;

    /**
     * Flag to either fetch fielding details or not.
     *
     * @type      {number}
     * @memberof  PanelProjectDetailsComponent
     */
    public fetchFielding: boolean = false;

    /**
     * Incidence value calculated from # of completes & terminates.
     *
     * @type      {number}
     * @memberof  PanelProjectDetailsComponent
     */
    public incidence: number;

    public emptyConst = platformConstants.EMPTY_STATES.filter_search;
    public statusConfig = platformConstants.PROJECT_STATUSES;
    public projectStatusBGColor = platformConstants.PROJECT_STATUS_BG_COLOR;
    public fieldingErrorConst: any = platformConstants.ERROR_STATES.fieldings;
    public fieldingEmptyConst = platformConstants.EMPTY_STATES.fieldings;
    public isfieldingError = false;

    /**
     * Constructor.
     *
     * @param ProjectService
     * @param MatDialog
     * @param AnalyticsService
     * @param TranslateService
     */
    constructor(
        private _service: ProjectService,
        private _analyticsService: AnalyticsService,
        public translate: TranslateService,
        private _fieldingDataService: FieldingDataService,
        private _configService: AppConfigService,
        private _messageBar: MessageBarService
    ) { }

    /**
     * Defining and initializing the objects
     *
     * @OnInit - angular Lifecyclehook
     */
    public ngOnInit(): void {
        // initializing the grid header details.
        this.gridTitle = [
            {
                text: 'project.table_header.project_name',
                cols: 23,
                rows: 1,
                className: 'grid-title left',
            },
        ];
        this.getProjects(this.finishedScroll, this._limit, this._offset);
    }

    /**
     * @method  ngOnChanges()
     *
     * when a new project is created, the new project is added to the home project list.
     */
    public ngOnChanges(): void {
        if (this.newProject) {
            this.projects.unshift(this.newProject);
        }
    }

    /**
     * @method  getProjects()
     *
     * getting all the project details records/list.
     */
    public getProjects(finishedScroll: boolean, limit: number, offset: number): void {
        if (finishedScroll) {
            return;
        }
        this.spinner = true;

        this._service.get(limit, offset).subscribe((data: any) => {
            this.spinner = false;
            if (data) {
                this.mapProjectObject(data);
            }

            if (!data.body || data.body.length === 0) {
                this.finishedScroll = true;
            }
        });
    }

    public mapProjectObject(data: any): void {
        data.body.map((ele) => {
            ele.links.forEach((link) => {
                if (link.rel === 'fieldings_api') {
                    ele.fieldingsLink = link.href;
                }
            });
        });
        this.projects = this.projects.concat(data.body);
        this._offset = this._offset + this._limit;
    }

    /**
     * @method  onScroll()
     *
     * Method gets invoked when user reaches towards the end of the page to pull more projects.
     */
    public onScroll(): void {
        this.getProjects(this.finishedScroll, this._limit, this._offset);
    }

    /**
     * @method showHideProjectDetails()
     * @param  {ProjectModel}
     *
     * to open and close the project status panel to view Concepts and Fielding Details
     */
    public showHideProjectDetails(project: ProjectModel): void {
        if (!project) {
            return;
        }
        this.projects.forEach((ele) => {
            if (ele.id === project.id) {
                ele.isExpanded = !ele.isExpanded;
                if (ele.isExpanded) {
                    this.displayFieldings(project);
                }
                this._analyticsService.trackDisplay(
                    ele.isExpanded,
                    this.translate.instant('project.table_header.project_status'),
                    {
                        projectId: project.id,
                    }
                );
            } else {
                ele.isExpanded = false;
            }
        });
    }

    /**
     * @method displayFieldings()
     * @param  {ProjectModel}
     *
     * Fetches fieding data for the project.
     */

    public displayFieldings(project: ProjectModel): void {
        this.fieldingData = [];
        this.fetchFielding = true;
        this.isfieldingError = false;

        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (project && project.isExpanded && project['fieldingsLink']) {
            this._fieldingDataService
                // eslint-disable-next-line @typescript-eslint/dot-notation
                .get(project['fieldingsLink'])
                .subscribe(
                    (data: any) => {
                        if (data && data.length > 0) {
                            this.fieldingData = [];
                            data.forEach((fieldingObj: any) => {
                                const incidence =
                                    fieldingObj.completes !== 0 || fieldingObj.terminates !== 0
                                        ? (fieldingObj.completes /
                                            (fieldingObj.completes + fieldingObj.terminates)) *
                                        100
                                        : 0.0;
                                this.incidence = Math.floor(incidence);
                                this.fieldingData.push(fieldingObj);
                                this.fetchFielding = false;
                            });
                        } else {
                            this.fetchFielding = false;
                        }
                    },
                    () => {
                        this.fetchFielding = false;
                        this.isfieldingError = true;
                        this._messageBar.openSnackBar(
                            platformConstants.SNACK_BAR_TYPE_ERROR,
                            'There was an error loading fielding data for the project.'
                        );
                    }
                );
        }
    }

    /**
     * @method navigate()
     * @param  {ProjectModel}
     *
     * Navigates the user to Studio fieldings page.
     */
    public navigate(project: ProjectModel): string {
        return `${this._configService.config.studio.url}/#/projects/${project.referenceId}/fieldings`;
    }

    public trackFieldingNav(project: ProjectModel): void {
        this._analyticsService.track('Navigates to fieldings page', {
            projectId: project.referenceId,
        });
    }
}
