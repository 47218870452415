<div class="home-container">
  <div class="tool-bar">
    <div class="tool-bar-container align-items-center">
      <div class="tool-bar-left">
        <a routerLink="/home"><span class="sif sif-arrow-w"></span></a>
        <label class="header-label">
          Bulk Share Projects
        </label>
      </div>
    </div>
  </div>
  <div class="bulk-share-container">
    <p>{{'project.bulkshare.sub_text' | translate}}</p>
    <div>
      <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>

      <form [formGroup]="bulkshareForm">
        <div>
          <label>{{'project.bulkshare.form_label_from' | translate}}</label>
          <input [class.required-error]="bulkshareForm.get('fromUser').invalid && bulkshareForm.get('fromUser').touched"
            type="email" name="fromUser" formControlName="fromUser" [placeholder]="placeholderFrom" cdkFocusInitial>
          <div
            *ngIf="(bulkshareForm.get('fromUser').invalid && bulkshareForm.get('fromUser').touched) || bulkshareForm.get('fromUser').dirty">
            <small *ngIf="bulkshareForm.get('fromUser').errors?.required"
              class="error">{{'project.bulkshare.error_messages.sender_required' | translate}}</small>
            <small *ngIf="bulkshareForm.value.fromUser.length > 0 && validateEmail(bulkshareForm.value.fromUser)"
              class="error">{{'project.bulkshare.error_messages.valid_email' | translate}}</small>
          </div>
        </div>
        <div>
          <label>{{'project.bulkshare.form_label_to' | translate}}</label>
          <input [class.required-error]="bulkshareForm.get('toUser').invalid && bulkshareForm.get('toUser').touched"
            type="email" name="toUser" formControlName="toUser" [placeholder]="placeholderTo">
          <div
            *ngIf="(bulkshareForm.get('toUser').invalid && bulkshareForm.get('toUser').touched) || bulkshareForm.get('toUser').dirty">
            <small *ngIf="bulkshareForm.get('toUser').errors?.required"
              class="error">{{'project.bulkshare.error_messages.recipient_required' | translate}}</small>
            <small *ngIf="bulkshareForm.value.toUser.length > 0 && validateEmail(bulkshareForm.value.toUser)"
              class="error">{{'project.bulkshare.error_messages.valid_email' | translate}}</small>
          </div>
        </div>
        <button class="mat-flat-button mat-primary" mat-raised-button color="primary" [disabled]="!bulkshareForm.valid"
          (click)="share()">{{'project.bulkshare.controls.share' | translate}}</button>
      </form>
    </div>
    <div class="bulk-shared-status-table" *ngIf="dataSource.data.length > 0">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- User Account Column -->
        <ng-container matColumnDef="userAccount">
          <th mat-header-cell *matHeaderCellDef> User Account </th>
          <td mat-cell *matCellDef="let element"> {{ element.userAccount }} </td>
        </ng-container>

        <!-- Shared With Column -->
        <ng-container matColumnDef="sharedWith">
          <th mat-header-cell *matHeaderCellDef> Shared With </th>
          <td mat-cell *matCellDef="let element"> {{ element.sharedWith }} </td>
        </ng-container>

        <!-- Last Shared Column -->
        <ng-container matColumnDef="lastShared">
          <th mat-header-cell *matHeaderCellDef> Last Shared </th>
          <td mat-cell *matCellDef="let element"> {{ element.lastShared | date: 'M/d/YYYY' }} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{ element.status | titlecase }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          [class]="row.status === 'COMPLETED' ? 'disabled-row' : row.status === 'FAILURE' ? 'failure-row' : ''">
        </tr>
      </table>
    </div>
  </div>
</div>
