<div class="filter-container" *ngIf="!openFilter">
  <div class="align-items-center filter-section" mat-form-field>
    <div class="flex-search-filter">
      <div class="search-bar align-items-center">
        <span class="sif sif-search"></span>
        <niq-search-input class="search-input" [searchInputValue]="searchPhase" [matTooltip]="searchTooltipText"
          [matTooltipClass]="['custom-tooltip-text-bar', 'pre-tooltip']" (searchText)="setSearchText($event)">
        </niq-search-input>
      </div>
      <div class="filter-display-container" (mouseenter)="optionsHover = true" (mouseleave)="optionsHover = false">
        <div class="saved-filter-menu" [matMenuTriggerFor]="menu"
          (menuOpened)="filterToggle = true; optionsHover = false" (menuClosed)="filterToggle = false">
          <span role="textbox" [matTooltip]="filterName.toolTipStr"
            matTooltipClass="custom-tooltip">{{filterName.name}}</span>
          <span class="chevron" *ngIf="!filterToggle">
            <span class="sif sif-chevron-s"></span>
          </span>
          <span class="chevron" *ngIf="filterToggle">
            <span class="sif sif-chevron-n"></span>
          </span>
        </div>
        <mat-menu #menu="matMenu">
          <div *ngFor="let item of nameFilter">
            <button mat-menu-item [class]="item.isUserPreference ? 'selection' : ''" [disabled]="item.isUserPreference"
              (click)="newSavedFilterName = item.name; onNewFilterChange(item)">
              {{item.name}}
            </button>
          </div>
        </mat-menu>
        <div></div>
        <div class="filterWrapper" (click)="openFilter = true; editFilters()" style="cursor: pointer;z-index: 99;">
          <span class="splitter splitter-beginner">|</span>
          <div class="filterClientDropdown" [matTooltip]="filterClient.toolTipStr" matTooltipClass="custom-tooltip" *ngIf="clientViewPermissions">
            <span>{{ filterClient.name }}</span>
          </div>
          <span class="splitter" *ngIf="clientViewPermissions">|</span>
          <div class="filterSolutionTypesDropdown"  [matTooltip]="filterSolutionTypes.toolTipStr" matTooltipClass="custom-tooltip">
            <span>{{ filterSolutionTypes.name }}</span>
          </div>
          <span class="splitter">|</span>
          <div class="filterStatusesDropdown" [matTooltip]="filterStatuses.toolTipStr" matTooltipClass="custom-tooltip">
            <span>{{ filterStatuses.name }}</span>
          </div>
          <span class="splitter">|</span>
          <div class="filterCountriesDropdown" [matTooltip]="filterCountries.toolTipStr" matTooltipClass="custom-tooltip">
            <span>{{ filterCountries.name }}</span>
          </div>
          <span class="splitter">|</span>
          <div class="filterLookupsDropdown" [matTooltip]="filterLookups.toolTipStr" matTooltipClass="custom-tooltip">
            <span>{{ filterLookups.name }}</span>
          </div>
          <span class="splitter splitter-end" [hidden]="!optionsHover">|</span>
        </div>

        <button [hidden]="!optionsHover" (click)="openFilter = true; editFilters()" class="action-btn"
          matTooltip="Edit View" matTooltipClass="custom-tooltip">
          <span class="sif sif-edit"></span>
        </button>
        <button [hidden]="!optionsHover" [disabled]="savedFilterCount &gt; 14" (click)="openFilter = true; addFilters()"
          class="action-btn">
          <span class="sif sif-add"
            [matTooltip]="savedFilterCount &gt; 14 ? 'Cannot have more than 15 views' : 'Add View'"
            matTooltipClass="custom-tooltip"></span>
        </button>
        <button [hidden]="!optionsHover" *ngIf="nameFilter && !nameFilter[0]?.isUserPreference === true"
          (click)="deleteFilters()" class="action-btn" matTooltip="Delete View" matTooltipClass="custom-tooltip">
          <span class="sif sif-delete"></span>
        </button>
        <button [hidden]="!(filterDirty && optionsHover)"
          *ngIf="nameFilter && !nameFilter[0]?.isUserPreference === false" (click)="resetFilter()"
          matTooltip="Reset Filters" matTooltipClass="custom-tooltip" class="action-btn"
          [disabled]="isProjectsNull || isSolutionsNull || isStatusesNull || isCountriesNull || filterNameError!==''">
          <span class="sif sif-reset"></span>
        </button>

      </div>
    </div>

    <div class="project-count">
      <h6 *ngIf="(projectCount && projectCount &gt; 0); else noProject">{{projectCount | number}} Project</h6>
      <h6 *ngIf="projectCount &gt; 1">s</h6>
      <ng-template #noProject>
        <h6> No Projects</h6>
      </ng-template>
    </div>
  </div>
</div>
<div class="apply-filter-section filter-section" *ngIf="openFilter">
  <h6><span class="sif sif-filter"></span></h6>
  <div class="apply-filter-options align-items-center">
    <div class="dropdown-bar align-items-center">
      <div class="dropdown-input search-text-container"
        [matTooltip]="newSavedFilterNameCopy === 'Default View' ? 'Default View Cannot be renamed/edited' : ''"
        matTooltipClass="custom-tooltip">
        <input #saveFilterInput type="text" class="search-input" [class]="filterNameError ? 'error-box' : '' "
          placeholder="Name your view" [value]="newSavedFilterName" maxlength="25"
          (input)="setFilterName(saveFilterInput.value)" [disabled]="newSavedFilterNameCopy === 'Default View'" />
      </div>
      <div class="dropdown-input" *ngIf="clientViewPermissions">
        <niq-drop-down [displayType]="'regular'" [displayOptions]="clients" [arrayName]="'clients'"
          [flagKey]="'isUserPreference'" [displayKey]="'name'" [addRadioIndent]="false"
          (userSelections)="setSelectedFilterOption($event, 'clients')" (ddMenuClosed)="menuClosed = $event"
          (click)="trackFilterDropDownAction('clients')">
        </niq-drop-down>
      </div>
      <div class="dropdown-input">
        <niq-drop-down [displayType]="'checkbox'" [displayOptions]="solutionTypes" [arrayName]="'types'"
          [flagKey]="'isUserPreference'" [displayKey]="'name'" [displayChildKey]="'name'" [childKey]="'subtasks'"
          (userSelections)="setSelectedFilterOption($event, 'types')" (nullSelections)="isSolutionsNull = $event"
          (ddMenuClosed)="menuClosed = $event" (click)="trackFilterDropDownAction('types')">
        </niq-drop-down>
      </div>
      <div class="dropdown-input">
        <niq-drop-down [displayType]="'checkbox'" [displayOptions]="statuses" [arrayName]="'statuses'"
          [flagKey]="'isUserPreference'" [displayKey]="'name'" [displayChildKey]="'name'" [childKey]="'subtasks'"
          (userSelections)="setSelectedFilterOption($event, 'statuses')" (nullSelections)="isStatusesNull = $event"
          (ddMenuClosed)="menuClosed = $event" (click)="trackFilterDropDownAction('statuses')">
        </niq-drop-down>
      </div>
      <div class="dropdown-input">
        <niq-drop-down [displayType]="'checkbox'" [displayOptions]="countries" [arrayName]="'countries'"
          [flagKey]="'isUserPreference'" [displayKey]="'name'" [displayChildKey]="'name'" [childKey]="'subtasks'"
          (userSelections)="setSelectedFilterOption($event, 'countries')" (nullSelections)="isCountriesNull = $event"
          (ddMenuClosed)="menuClosed = $event" (click)="trackFilterDropDownAction('countries')">
        </niq-drop-down>
      </div>
      <div class="dropdown-input">
        <niq-drop-down [displayType]="'regular'" [displayOptions]="lookupsFilter" [arrayName]="'clients'"
          [flagKey]="'isUserPreference'" [displayKey]="'name'" [addRadioIndent]="false"
          (userSelections)="setSelectedFilterOption($event, 'lookupsFilter')" (ddMenuClosed)="menuClosed = $event"
          (click)="trackFilterDropDownAction('lookupsFilter')">
        </niq-drop-down>
      </div>
    </div>
    <div class="controller-btn">
      <button mat-button mat-flat-button color="secondary" class="mat-flat-button mat-secondary hover-btn"
        (click)="applyFilters(true); openFilter = false; optionsHover = false"
        [disabled]="isProjectsNull || isSolutionsNull || isStatusesNull || isCountriesNull || filterNameError!=='' || saveFilterInput.value !== null && saveFilterInput.value?.length === 0">
        <span [matTooltip]="filterNameError" matTooltipClass="custom-tooltip">Apply</span>
      </button>
      
    </div>
    <button (click)="applyFilters(false); openFilter = false; optionsHover = false" class="action-btn close-btn">
      <span class="sif sif-close"></span>
    </button>
  </div>
