<niq-spinner [display]="showSpinnerOnDuplicate"></niq-spinner>
<h2 mat-dialog-title>
  {{'project.duplicate_project.text' | translate}}
  <span mat-button class="close-icon" [mat-dialog-close]="true">
    <span class="sif sif-close"></span>
  </span>
</h2>
<mat-dialog-content (keydown)="$event.key === 'Escape' ? $event.stopPropagation() ? '' : dialogRef.close(): ''">

  <div class="duplication-info-div">
    <niq-input-box [inputBoxValue]="solutionName" [projDuplicationInfSectionClass]="'duplication'" [maxLength]="100" [width]="100"
      [hasLabel]="true" [displayLabel]="labelName" [isRequired]="true" [inputPlaceholder]="projectNamePlaceHolder"
      (isInputValid)="isPrjNameValid = $event" [errorResponse]="prjNameErrorMessage"
      (inputBoxUpdatedValue)="setDuplicateData($event, 'name')">
    </niq-input-box>

    <niq-input-box *ngIf='this.data.isWorkbenchVisible' [inputBoxValue]="data.workbenchId"
      [maxLength]="10" [hasLabel]="true" [displayLabel]="labelWorkbench"
      [inputType]="'number'" [inputPlaceholder]="workbenchIdPlaceHolder" [width]="95"
      (inputBoxUpdatedValue)="setDuplicateData($event, 'workbenchId')">
    </niq-input-box>
  </div>


  <p translate>{{'project.duplicate_project.select_options'}}
  </p>
  <div class="all-container">
    <mat-checkbox [(ngModel)]="includeAllChecked" (change)="selectAllOptionDuplicate()"
      [checked]="isAllOptionsSelected()"
      [indeterminate]="partialComplete()">
      {{'project.duplicate_project.selected_all' | translate}}</mat-checkbox>
  </div>
  <div class="option-container" *ngIf="this.isConcept()">
    <mat-checkbox [(ngModel)]="this.categoryList[1].isSelected">
      {{'project.duplicate_project.selected_concepts' | translate}}</mat-checkbox>
  </div>
  <div class="option-container" *ngIf="this.data.duplicateProject.solutionCode === 'LPO'">
    <mat-checkbox [(ngModel)]="this.categoryList[1].isSelected">
      {{'project.duplicate_project.selected_skulist' | translate}}</mat-checkbox>
  </div>
  <div class="option-container" *ngIf="this.data.duplicateProject.solutionCode === 'DD'">
    <mat-checkbox [(ngModel)]="this.categoryList[1].isSelected">
      {{'project.duplicate_project.selected_designlist' | translate}}</mat-checkbox>
  </div>
  <div class="option-container">
    <mat-checkbox [(ngModel)]="this.categoryList[2].isSelected">
      {{'project.duplicate_project.selected_audience' | translate}}</mat-checkbox>
  </div>
  <div class="option-container" *ngIf="this.data.duplicateProject.solutionCode !== 'NOx'">
    <mat-checkbox [(ngModel)]="this.categoryList[3].isSelected">
      {{'project.duplicate_project.selected_analysis' | translate}}</mat-checkbox>
  </div>
  <div class="option-container">
    <mat-checkbox [(ngModel)]="this.categoryList[4].isSelected">
      {{'project.duplicate_project.selected_documents' | translate}}</mat-checkbox>
  </div>
  <div class="collaborator-container">
    <mat-checkbox [(ngModel)]="this.categoryList[0].isSelected" (change)="getCollaborators()">
      {{'project.duplicate_project.selected_collaborators' | translate}}</mat-checkbox>
    <niq-spinner-small *ngIf="spinner"></niq-spinner-small>
    <div *ngIf="this.categoryList[0].isSelected && dataSource.data.length > 0">
      <div class="extra-info">
        <label class="grey-font">{{'project.duplicate_project.share_and_notify' | translate}}</label>
        <span class="blue-font" (click)="expandCollaborator = !expandCollaborator">
          &nbsp;&nbsp;
          {{
          selectedCollaboratorCount > 0
          ? selectedCollaboratorCount
          : "No "
          }}
          {{'project.duplicate_project.collaborators_selected' | translate}}<span class="sif"
            [class]="expandCollaborator ? 'sif-chevron-n' : 'sif-chevron-s'"></span>
        </span>
      </div>
      <div class="collaborator-table">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null; selectedCollaboratorCount = selection.selected.length;"
                [checked]="selection.isSelected(row)" [disabled]="row.isDisableDefault">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="projectRole">
            <th mat-header-cell *matHeaderCellDef> Role </th>
            <td mat-cell *matCellDef="let element"> {{element.projectRole}} </td>
          </ng-container>

          <!-- Client Column -->
          <ng-container matColumnDef="organization">
            <th mat-header-cell *matHeaderCellDef> Client </th>
            <td mat-cell *matCellDef="let element"> {{element.organization}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="!row.isDisableDefault && selection.toggle(row); selectedCollaboratorCount = selection.selected.length;"
            [class]="row.isDisableDefault ? 'disabledRow mat-checkbox-border-disabled' : ''">
          </tr>
        </table>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="align-items-right">
  <button class="mat-secondary" mat-button mat-dialog-close>Cancel</button>
  <button class="mat-flat-button mat-primary" mat-raised-button color="primary" cdkFocusInitial
    (click)="createDuplicateProject()"
    [disabled]="!isPrjNameValid ? !isPrjNameValid : (this.data.duplicateProject.name.length === 0 || this.data.duplicateProject.name.length > 100 || this.data.duplicateProject.workbenchId?.length > 10 || isAllDuplicationOptsUnselect)">Duplicate</button>
</mat-dialog-actions>
